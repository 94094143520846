import React, {Fragment, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import {useTranslation} from "react-i18next";
import {getDashboardDynamicFilterLabelTranslations} from "../../constants/getDashboardDynamicFilterLabelTranslations";
import CustomDialogTitle from "../custom-dialog-title";
import InfiniteScrollTable from "../infinite-scroll-table";
import {getTextWidth} from "../../utils/style";
import {getNode, getNodes} from "../../utils/tree";

const $ = window.jQuery;

const styles = theme => ({
  root: {},
  filter: {
    display: "inline-block",
    verticalAlign: "bottom",
    marginRight: 16,
    "& label": {
      fontSize: 16
    },
    padding: "4px 0"
  },
  item: {
    fontSize: 14
  }
});

const getLabelsMap = tree => {
  const map = {};
  getNodes(tree, "children", () => true).forEach(({id, name}) => (map[id] = name));

  return map;
};

function DashboardFilters(props) {
  const {
    classes,
    filters,
    selectValues,
    setSelectValues,
    lastValorizedIdx,
    setLastValorizedIdx,
    filterLevels,
    onFilterApply,
    onFilterReset
  } = props;

  const labels = filters?.labels || [];
  const values = filters?.values || [];

  const {t} = useTranslation();

  const [isApplyDisabled, setIsApplyDisable] = useState(false);

  const [visibleId, setVisibleId] = useState(null);

  const [labelsMap] = useState(getLabelsMap(values));

  const handleFilterSelect = (dimension, value) => {
    const newSelectedValues = {};
    let found = false;
    labels.forEach(label => {
      if (!found) {
        if (label !== dimension) {
          newSelectedValues[label] = selectValues[label];
        } else {
          found = true;
          newSelectedValues[label] = value;
        }
      } else {
        newSelectedValues[label] = "";
      }
    });
    setSelectValues(newSelectedValues);
    setLastValorizedIdx(visibleId);
    setVisibleId(null);
    setIsApplyDisable(false);
  };

  let data = [];
  if (visibleId !== null) {
    data =
      visibleId === 0
        ? values
        : getNode(values, "children", ({id}) => id === selectValues[labels[visibleId - 1]]).children;
  }

  return (
    <Fragment>
      <div className={classes.root}>
        {(() => {
          const selects = [];

          let lastLavorizableIdx = -1;
          labels.forEach((label, idx) => {
            if (filterLevels?.[labels[idx]] === true) {
              lastLavorizableIdx = idx;
            }
          });

          const getTextWidthEl = $("<span>")
            .css({
              visibility: "hidden",
              position: "absolute",
              fontSize: 16
            })
            .appendTo("body")
            .get(0);

          let filterValues = values;

          for (let i = 0; i <= Math.min(lastValorizedIdx + 1, lastLavorizableIdx); i++) {
            const label = labels[i];
            const minWidth = getTextWidth(label, getTextWidthEl) + 32;

            if (i > 0) {
              const idx = filterValues.findIndex(({id}) => id === selectValues[labels[i - 1]]);
              filterValues = filterValues[idx]?.children || [];
            }

            if (filterValues.length > 0) {
              selects.push(
                <div key={i} className={classes.filter}>
                  <FormControl style={{display: "inline-block", verticalAlign: "bottom"}}>
                    <InputLabel>{getDashboardDynamicFilterLabelTranslations(t)[label] || label}</InputLabel>
                    <Select
                      open={false}
                      style={{minWidth: minWidth}}
                      value={selectValues[label] || ""}
                      renderValue={value => labelsMap[value]}
                      className={classes.item}
                      onOpen={() => setVisibleId(i)}
                      SelectDisplayProps={{"aria-haspopup": true}}
                    >
                      {filterValues.map(({id}, idx) => (
                        <MenuItem key={idx} value={id}>
                          {id}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              );
            }
          }

          $(getTextWidthEl).remove();

          if (lastLavorizableIdx >= 0) {
            selects.push(
              <Button
                key={labels.length + 1}
                onClick={() => {
                  onFilterApply(selectValues[labels[lastValorizedIdx]]);
                  setIsApplyDisable(true);
                }}
                disabled={isApplyDisabled || lastValorizedIdx < 0 || filterLevels?.[labels[lastValorizedIdx]] === false}
              >
                {t("commons.confirm.apply")}
              </Button>
            );
            selects.push(
              <Button
                key={labels.length}
                onClick={() => {
                  onFilterReset();
                  setIsApplyDisable(true);
                }}
                disabled={lastValorizedIdx < 0}
              >
                {t("commons.confirm.reset")}
              </Button>
            );
          }

          return selects;
        })()}
      </div>

      <Dialog open={visibleId !== null} onClose={() => setVisibleId(null)} fullWidth maxWidth="md">
        <CustomDialogTitle onClose={() => setVisibleId(null)}>
          {t("components.dashboardFilters.modals.title", {
            level: getDashboardDynamicFilterLabelTranslations(t)[labels[visibleId]] || labels[visibleId]
          })}
        </CustomDialogTitle>
        <DialogContent>
          <InfiniteScrollTable
            data={data}
            getRowKey={({id}) => id}
            showHeader={false}
            columns={[
              {
                title: "",
                dataIndex: "id",
                render: (_, {id, name}) => `[${id}] ${name}`,
                renderText: (_, {id, name}) => `[${id}] ${name}`
              }
            ]}
            onRowClick={rowData => handleFilterSelect(labels[visibleId], rowData.id)}
            getRowStyle={rowData => ({
              background: rowData.id === selectValues[labels[visibleId]] ? "#fff9e5" : undefined
            })}
            height={400}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisibleId(null)} color="primary">
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default withStyles(styles)(DashboardFilters);

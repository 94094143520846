import React, {useMemo} from "react";
import DatasetStaticFilters from "./DatasetStaticFilters";
import {getFormattedDimensionValueLabelFromCodelists} from "../../utils/dataset";

const DatasetFiltersCodelistsWrapper = ({
  nodeCode,
  datasetId,
  codelistMaps,
  dimensionsInfo,
  jsonStat,
  layout,
  labelFormat,
  isDimensionAllowed
}) => {
  const values = useMemo(() => {
    if (!layout || !codelistMaps) {
      return [];
    } else {
      const filtersValue = layout?.filtersValue;
      const filterDims = layout.filters.filter(dim => !isDimensionAllowed || isDimensionAllowed(dim));
      return filterDims.map(dim => ({
        dim: dim,
        dimLabel: dimensionsInfo?.[dim]?.label,
        value: filtersValue[dim],
        valueLabel: getFormattedDimensionValueLabelFromCodelists(dim, filtersValue[dim], codelistMaps, labelFormat)
      }));
    }
  }, [layout, codelistMaps, labelFormat, isDimensionAllowed, dimensionsInfo]);

  return (
    layout && (
      <DatasetStaticFilters
        datasetId={`${nodeCode},${datasetId}`}
        values={values}
        jsonStat={jsonStat}
        labelFormat={labelFormat}
      />
    )
  );
};

export default DatasetFiltersCodelistsWrapper;

import React, {Fragment, useEffect, useRef, useState} from "react";
import {Tab, Tabs, withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import BitSet from "bitset";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomEmpty from "../../custom-empty";
import FullscreenDialog from "../../fullscreen-dialog";
import Calculator from "./Calculator";
import Preview from "./Preview";
import VariationAndGrowthRates from "./VariationAndGrowthRates";
import {
  fetchDatasetMVIndicatorPreview,
  publishDatasetMVIndicator,
  resetDatasetMVIndicatorPreview
} from "../../../state/dataset/multi-viewer/actions";
import {MARGINAL_DIMENSION_KEY} from "../../../utils/dataset";
import {isMultiLanguageNameUnique} from "../../../utils/validator";
import {getDimensionValueFromIdx} from "../../table/utils";
import "./style.css";

const $ = window.jQuery;

const TAB_ID_CALCULATOR = "calculator";
const TAB_ID_STATISTICS = "statistics";

const styles = theme => ({
  root: {
    height: "100%"
  },
  tabContent: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100% - 60px)",
    marginTop: 12,
    padding: 12
  }
});

const mapStateToProps = ({config, app, node, dataset}) => ({
  indicatorsBaseUrl: config.externalServices?.indicator,
  defaultLanguage: app.language,
  languages: app.languages,
  nodeId: node.nodeId,
  nodeCode: node.code,
  datasetId: dataset.multiViewer.datasetId,
  dataset: dataset.multiViewer.dataset,
  territoryDim: dataset.multiViewer.territoryDim,
  timeDim: dataset.multiViewer.timeDim,
  criteria: dataset.multiViewer.criteria,
  tableLayout: dataset.multiViewer.tableLayout,
  indicators: dataset.multiViewer.indicators,
  indicatorPreview: dataset.multiViewer.indicatorPreview,
  additionalDatasets: dataset.multiViewer.additionalDatasets
});

const mapDispatchToProps = dispatch => ({
  onPreviewFetch: (
    nodeId,
    nodeCode,
    datasetId,
    criteria,
    timeDim,
    territoryDim,
    indicators,
    additionalDatasets,
    defaultLanguage,
    languages,
    indicatorsBaseUrl
  ) =>
    dispatch(
      fetchDatasetMVIndicatorPreview(
        nodeId,
        nodeCode,
        datasetId,
        criteria,
        timeDim,
        territoryDim,
        indicators,
        additionalDatasets,
        defaultLanguage,
        languages,
        indicatorsBaseUrl
      )
    ),
  onPreviewReset: () => dispatch(resetDatasetMVIndicatorPreview()),
  onPublish: indicator => dispatch(publishDatasetMVIndicator(indicator))
});

const getStrFromIndex = index => {
  let res = "";
  while (index >= 0) {
    res = String.fromCharCode((index % 26) + 65) + res;
    index = Math.floor(index / 26) - 1;
  }
  return res;
};

const IndicatorCreateDialog = ({
  classes,
  indicatorsBaseUrl,
  defaultLanguage,
  languages,
  nodeId,
  nodeCode,
  datasetId,
  dataset,
  territoryDim,
  timeDim,
  criteria,
  tableLayout,
  indicators,
  indicatorPreview,
  additionalDatasets,
  isOpen,
  onClose,
  onPreviewFetch,
  onPreviewReset,
  onPublish
}) => {
  const {t} = useTranslation();

  const [tab, setTab] = useState(TAB_ID_CALCULATOR);
  const [worker] = useState(() => new Worker("./workers/getTableSupportStructuresWorker.js"));
  const [isNameErrorVisible, setNameErrorVisibility] = useState(false);
  const [tableSupportStructures, setTableSupportStructures] = useState(null);
  const [combinations, setCombinations] = useState(null);
  const [variables, setVariables] = useState(null);
  const [variablesDataset, setVariablesDataset] = useState(null);

  const calculatorFormRef = useRef();
  const variationAndGrowthRatesFormRef = useRef();

  const years = dataset?.dimension?.[timeDim]?.category?.index || [];

  useEffect(() => {
    return () => {
      if (worker) {
        worker.terminate();
      }
    };
  }, [worker]);

  useEffect(() => {
    if (dataset && tableLayout) {
      worker.onmessage = event => {
        setTableSupportStructures({
          ...event.data,
          valorizedCols: new BitSet(event.data.valorizedColsArr)
        });
      };
      worker.onerror = () => {
        setTableSupportStructures(null);
      };
      worker.postMessage({
        jsonStat: dataset,
        layout: tableLayout,
        isPreview: false,
        removeEmptyLines: true
      });
    } else {
      setTableSupportStructures(null);
      setCombinations(null);
      setVariables(null);
      setVariablesDataset(null);
    }
  }, [worker, dataset, tableLayout]);

  useEffect(() => {
    if (dataset && tableSupportStructures) {
      const {dimValuesMap, dimSpanMap, valorizedCols} = tableSupportStructures;

      const combinations = [];
      const variables = {};
      const variableDatasetMap = {};

      const variableDatasetList = [];
      let c = 0;
      for (let b of valorizedCols) {
        const idx = c;
        if (b) {
          let isIndicator = false;
          let combination = {};
          let variableDatasetId = `${nodeCode},${datasetId}`;
          tableLayout.cols.forEach(dim => {
            const dimValue = getDimensionValueFromIdx(dim, idx, dimValuesMap, dimSpanMap);
            if (dim !== MARGINAL_DIMENSION_KEY) {
              combination[dim] = dimValue;
            } else if ((dataset.extension.marginalvalues[dimValue].label || "").length === 0) {
              combination = {
                ...combination,
                ...dataset.extension.marginalvalues[dimValue].dimensionvalues
              };
              variableDatasetId = dataset.extension.marginalvalues[dimValue].datasetid;
            } else {
              isIndicator = true;
            }
          });
          if (!isIndicator) {
            combinations.push(combination);
            variableDatasetList.push(variableDatasetId);
          }
        }
        c++;
      }

      const letters = [];
      combinations.forEach((combination, idx) => {
        const noTimeCombinationStr = Object.keys(combination)
          .filter(dim => dim !== timeDim)
          .map(dim => combination[dim])
          .join(",");
        if (!letters.includes(noTimeCombinationStr)) {
          letters.push(noTimeCombinationStr);
        }
        const variable = combination[timeDim] + getStrFromIndex(letters.indexOf(noTimeCombinationStr));
        variables[variable] = combination;
        variableDatasetMap[variable] = variableDatasetList[idx];
      });

      setCombinations(combinations);
      setVariables(variables);
      setVariablesDataset(variableDatasetMap);
    }
  }, [tableSupportStructures, datasetId, nodeCode, dataset, tableLayout, timeDim]);

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle);
  }, []);

  useEffect(() => {
    handleStyle();
  });

  const handleStyle = () => {
    const tabsHeight = $("#indicator-create__tabs").outerHeight(true) || 0;
    $("indicator-create__tab-content").height(`calc(100% - ${tabsHeight}px)`);
  };

  const handleClose = () => {
    if (tab === TAB_ID_CALCULATOR) {
      if (calculatorFormRef.current) {
        calculatorFormRef.current.cancel(() => {
          onClose();
        });
      } else {
        onClose();
      }
    } else {
      if (variationAndGrowthRatesFormRef.current) {
        variationAndGrowthRatesFormRef.current.cancel(() => {
          onClose();
        });
      } else {
        onClose();
      }
    }
  };

  const handlePreviewOpen = indicator => {
    const filteredVariablesDataset = {};
    Object.keys(indicator.variables).forEach(
      variable => (filteredVariablesDataset[variable] = variablesDataset[variable])
    );
    const newIndicator = {
      ...indicator,
      variablesDataset: filteredVariablesDataset
    };
    onPreviewFetch(
      nodeId,
      nodeCode,
      datasetId,
      criteria,
      timeDim,
      territoryDim,
      [newIndicator],
      additionalDatasets,
      defaultLanguage,
      languages,
      indicatorsBaseUrl
    );
  };

  const handlePreviewClose = () => {
    onPreviewReset();
  };

  const handlePublish = indicator => {
    if (
      isMultiLanguageNameUnique(
        indicator.title,
        indicators.map(({title}) => title)
      )
    ) {
      const filteredVariablesDataset = {};
      Object.keys(indicator.variables).forEach(
        variable => (filteredVariablesDataset[variable] = variablesDataset[variable])
      );
      onPublish({
        ...indicator,
        variablesDataset: filteredVariablesDataset
      });
    } else {
      setNameErrorVisibility(true);
    }
  };

  const handleSubmit = f => {
    if (tab === TAB_ID_CALCULATOR) {
      if (calculatorFormRef.current) {
        calculatorFormRef.current.submit(f);
      }
    } else {
      if (variationAndGrowthRatesFormRef.current) {
        variationAndGrowthRatesFormRef.current.submit(f);
      }
    }
  };

  return (
    <Fragment>
      <FullscreenDialog open={isOpen && dataset !== null} onClose={handleClose} maxWidth={1280}>
        <CustomDialogTitle onClose={handleClose}>
          {t("components.indicatorDialogs.create.dialogs.form.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Box id="indicator-create__tabs" className={classes.root}>
            <Tabs
              value={tab}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(_, tab) => {
                if (tab === TAB_ID_CALCULATOR) {
                  document.getElementById("indicator-create__tab-content").style.overflowY = "auto";
                } else {
                  document.getElementById("indicator-create__tab-content").style.overflowY = "hidden";
                }
                setTab(tab);
              }}
            >
              <Tab value={TAB_ID_CALCULATOR} label={t("components.indicatorDialogs.create.tabs.calculator.title")} />
              <Tab value={TAB_ID_STATISTICS} label={t("components.indicatorDialogs.create.tabs.statistics.title")} />
            </Tabs>
            <div id="indicator-create__tab-content" className={classes.tabContent}>
              {dataset !== null && tab === TAB_ID_CALCULATOR && (
                <Calculator
                  years={years}
                  nodeCode={nodeCode}
                  dataset={dataset}
                  datasetId={datasetId}
                  timeDim={timeDim}
                  combinations={combinations}
                  variables={variables}
                  variablesDataset={variablesDataset}
                  ref={calculatorFormRef}
                />
              )}
              {dataset !== null && tab === TAB_ID_STATISTICS && (
                <VariationAndGrowthRates
                  dataset={dataset}
                  datasetId={datasetId}
                  years={years}
                  nodeCode={nodeCode}
                  timeDim={timeDim}
                  combinations={combinations}
                  variables={variables}
                  variablesDataset={variablesDataset}
                  ref={variationAndGrowthRatesFormRef}
                />
              )}
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("commons.confirm.close")}</Button>
          <Button onClick={() => handleSubmit(handlePreviewOpen)}>
            {t("components.indicatorDialogs.create.actions.showPreview")}
          </Button>
          <Button onClick={() => handleSubmit(handlePublish)}>
            {t("components.indicatorDialogs.create.actions.publish")}
          </Button>
        </DialogActions>
      </FullscreenDialog>

      <FullscreenDialog open={indicatorPreview !== null} onClose={handlePreviewClose}>
        <CustomDialogTitle onClose={handlePreviewClose}>
          {t("components.indicatorDialogs.create.dialogs.preview.title")}
        </CustomDialogTitle>
        <DialogContent>
          {indicatorPreview !== "" ? (
            <Preview jsonStat={indicatorPreview} territoryDim={territoryDim} timeDim={timeDim} />
          ) : (
            <CustomEmpty text={t("components.indicatorDialogs.create.dialogs.emptyPreview")} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePreviewClose}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </FullscreenDialog>

      <Dialog open={isNameErrorVisible} onClose={() => setNameErrorVisibility(false)}>
        <CustomDialogTitle onClose={() => setNameErrorVisibility(false)}>
          {t("components.indicatorDialogs.create.dialogs.error.duplicateName.title")}
        </CustomDialogTitle>
        <DialogContent>{t("components.indicatorDialogs.create.dialogs.error.duplicateName.content")}</DialogContent>
        <DialogActions>
          <Button onClick={() => setNameErrorVisibility(false)}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(IndicatorCreateDialog);

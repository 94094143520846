const ACTION_EXTRAS = "ACTION_EXTRAS";
const APP_CONFIG = "APP_CONFIG";
const CURRENT_NODE_CONFIG = "CURRENT_NODE_CONFIG";

export const getActionExtras = action => action[ACTION_EXTRAS];
export const getAppConfig = action => action[APP_CONFIG];
export const getCurrentNodeConfig = action => action[CURRENT_NODE_CONFIG];

const actionDecoratorMiddlewareFactory =
  t =>
  ({getState}) =>
  next =>
  action => {
    const state = getState();

    let decoratedAction = {
      ...action,
      [ACTION_EXTRAS]: {
        language: state?.app?.language,
        languages: state?.app?.languages,
        t: t
      },
      [APP_CONFIG]: state?.appConfig,
      [CURRENT_NODE_CONFIG]: state?.node
    };

    return next(decoratedAction);
  };

export default actionDecoratorMiddlewareFactory;

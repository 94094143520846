import React, {Fragment, useEffect, useState} from "react";
import {withStyles} from "@material-ui/core";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import BarChartIcon from "@material-ui/icons/BarChart";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import ChartLayout from "../../chart-layout";
import Criteria from "../../criteria";
import CustomDialogTitle from "../../custom-dialog-title";
import DatasetMetadataButton from "../../dataset-metadata-button";
import FullscreenDialog from "../../fullscreen-dialog";
import TableLayout from "../../table-layout";
import {viewersFactory} from "../constant";
import {ViewerMode} from "../../../state/dataset/constants";
import {
  enableDatasetSVDatasetFetch,
  fetchDatasetSVStructureCodelist,
  fetchDatasetSVStructureCodelistFull,
  hideDatasetSVCriteria,
  hideDatasetSVCriteriaAlert,
  hideDatasetSVCriteriaObsCountWarning,
  hideDatasetSVLayout,
  hideDatasetSVStructureCodelistFull,
  setDatasetSVStructureCriteria,
  setDatasetSVViewer,
  showDatasetSVCriteria,
  showDatasetSVLayout,
  submitDatasetSVChartFilterTree,
  submitDatasetSVChartLayout,
  submitDatasetSVTableFilterTree,
  submitDatasetSVTableLayout
} from "../../../state/dataset/single-viewer/actions";
import {
  getDatasetSize,
  getDimensionFilterValues,
  getFilterTreeFromJsonStat,
  getInitialFiltersValue,
  MAX_ALLOWED_CELLS
} from "../../../utils/dataset";
import {
  FREQ_ANNUAL,
  getMinAndMax,
  getTimeValuesFromRange,
  SUPPORTED_FREQ_VALUES
} from "../../../utils/timePeriodAndFreq";
import {getNodes} from "../../../utils/tree";

const styles = theme => ({
  root: {
    width: 80,
    height: "100%",
    overflow: "auto",
    padding: "8px 0",
    marginRight: 16,
    "& .MuiBottomNavigation-root": {
      height: "unset !important"
    },
    "& .MuiBottomNavigationAction-label": {
      fontSize: "13px !important"
    }
  },
  floatingMenuOption: {
    color: "gray"
  },
  floatingMenuOptionSelected: {
    color: theme.palette.primary.main
  },
  divider: {
    margin: "4px 0"
  },
  navigationActionDisabled: {
    color: "rgba(0, 0, 0, 0.26) !important",
    "& svg": {
      color: "rgba(0, 0, 0, 0.26)"
    }
  }
});

const mapStateToProps = ({hub, node, dataset}) => ({
  maxObservations: hub.hub.maxObservationsAfterCriteria || Number.MAX_SAFE_INTEGER,
  maxCells: hub.hub.maxCells || MAX_ALLOWED_CELLS,
  node: node,
  mode: dataset.commons.mode,
  type: dataset.commons.type,
  jsonStat: dataset.singleViewer.dataset,
  isEmptyData: dataset.singleViewer.isEmptyData,
  isTooBigData: dataset.singleViewer.isTooBigData,
  isTooLongQuery: dataset.singleViewer.isTooLongQuery,
  isCriteriaVisible: dataset.singleViewer.isCriteriaVisible,
  isCriteriaAlertVisible: dataset.singleViewer.isCriteriaAlertVisible,
  isObsCountWarningVisible: dataset.singleViewer.isObsCountWarningVisible,
  dimensions: dataset.singleViewer.dimensions,
  dimensionsInfo: dataset.singleViewer.dimensionsInfo,
  timeDim: dataset.singleViewer.timeDim,
  freqDim: dataset.singleViewer.freqDim,
  viewerIdx: dataset.singleViewer.viewerIdx,
  templateLayouts: dataset.singleViewer.template?.layouts,
  isLayoutVisible: dataset.singleViewer.isLayoutVisible,
  tableLayout: dataset.singleViewer.tableLayout,
  mapLayout: dataset.singleViewer.mapLayout,
  chartLayout: dataset.singleViewer.chartLayout,
  labelFormat: dataset.singleViewer.labelFormat,
  criteria: dataset.singleViewer.criteria,
  enableCriteria: dataset.singleViewer.enableCriteria,
  enableLayout: dataset.singleViewer.enableLayout,
  codelists: dataset.singleViewer.codelists,
  codelistsLength: dataset.singleViewer.codelistsLength,
  codelistFetchError: dataset.singleViewer.codelistFetchError,
  isTableEnabled: dataset.singleViewer.isTableEnabled,
  isMapEnabled: dataset.singleViewer.isMapEnabled,
  isChartEnabled: dataset.singleViewer.isChartEnabled,
  tableLockedDimensions: dataset.singleViewer.tableLockedDimensions,
  graphLockedDimensions: dataset.singleViewer.graphLockedDimensions,
  missingFilterValues: dataset.singleViewer.missingFilterValues,
  checkDatasetSize: dataset.singleViewer.checkDatasetSize
});

const mapDispatchToProps = dispatch => ({
  onFetchDatasetEnable: maxObservations => dispatch(enableDatasetSVDatasetFetch(maxObservations)),
  onViewerSet: viewerIdx => dispatch(setDatasetSVViewer(viewerIdx)),
  onLayoutShow: () => dispatch(showDatasetSVLayout()),
  onLayoutHide: () => dispatch(hideDatasetSVLayout()),
  onTableLayoutSet: layout => dispatch(submitDatasetSVTableLayout(layout)),
  onTableFilterTreeSet: filterTree => dispatch(submitDatasetSVTableFilterTree(filterTree)),
  onChartLayoutSet: layout => dispatch(submitDatasetSVChartLayout(layout)),
  onChartFilterTreeSet: filterTree => dispatch(submitDatasetSVChartFilterTree(filterTree)),
  onCriteriaShow: () => dispatch(showDatasetSVCriteria()),
  onCriteriaHide: () => dispatch(hideDatasetSVCriteria()),
  onSetCriteria: criteria => dispatch(setDatasetSVStructureCriteria(criteria)),
  onCriteriaAlertHide: () => dispatch(hideDatasetSVCriteriaAlert()),
  fetchCodelist: (
    nodeId,
    nodeCode,
    datasetId,
    mode,
    type,
    dimensionId,
    criteria,
    freq,
    defaultLastNPeriods,
    preserveFiltersWithDynamic
  ) =>
    dispatch(
      fetchDatasetSVStructureCodelist(
        nodeId,
        nodeCode,
        datasetId,
        mode,
        type,
        dimensionId,
        criteria,
        freq,
        defaultLastNPeriods,
        preserveFiltersWithDynamic
      )
    ),
  onCriteriaObsCountWarningHide: () => dispatch(hideDatasetSVCriteriaObsCountWarning()),
  fetchCodelistFull: (nodeId, datasetId, dimensionId, missingFilterValueIds) =>
    dispatch(fetchDatasetSVStructureCodelistFull(nodeId, datasetId, dimensionId, missingFilterValueIds)),
  onCodelistFullHide: () => dispatch(hideDatasetSVStructureCodelistFull())
});

function SingleViewerSidebar(props) {
  const {
    classes,

    nodeId,
    nodeCode,
    nodeExtras,
    datasetId,
    datasetMap,

    maxObservations,
    maxCells,
    node,
    mode,
    type,
    jsonStat,
    isEmptyData,
    isTooBigData,
    isTooLongQuery,
    isCriteriaVisible,
    isCriteriaAlertVisible,
    isObsCountWarningVisible,
    dimensions,
    dimensionsInfo,
    timeDim,
    freqDim,
    viewerIdx,
    templateLayouts,
    isLayoutVisible,
    tableLayout,
    mapLayout,
    chartLayout,
    labelFormat,
    criteria,
    enableCriteria,
    enableLayout,
    codelists,
    codelistsLength,
    codelistFetchError,
    isTableEnabled,
    isMapEnabled,
    isChartEnabled,
    tableLockedDimensions,
    graphLockedDimensions,
    missingFilterValues,
    checkDatasetSize,

    onFetchDatasetEnable,
    onViewerSet,
    onLayoutShow,
    onLayoutHide,
    onTableLayoutSet,
    onTableFilterTreeSet,
    onChartLayoutSet,
    onChartFilterTreeSet,
    onCriteriaShow,
    onCriteriaHide,
    onSetCriteria,
    onCriteriaAlertHide,
    fetchCodelist,
    onCriteriaObsCountWarningHide,
    fetchCodelistFull,
    onCodelistFullHide
  } = props;

  const {t} = useTranslation();

  const viewers = viewersFactory(t);

  const [anchorEl, setAnchorEl] = useState(null);

  const [tmpTableLayout, setTmpTableLayout] = useState(null);
  const [tmpChartLayout, setTmpChartLayout] = useState(null);

  const [isCriteriaValid, setCriteriaValidity] = useState(true);

  const [isObsCountErrorVisible, setObsCountErrorVisibility] = useState(false);

  const defaultLastNPeriods = nodeExtras?.DefaultLastNPeriods;

  useEffect(() => {
    setTmpTableLayout(tableLayout);
  }, [tableLayout]);

  useEffect(() => {
    setTmpChartLayout(chartLayout);
  }, [chartLayout]);

  const handleViewerSelect = index => {
    onViewerSet(index);
    setAnchorEl(null);
  };

  const handleLayoutOpen = () => {
    onLayoutShow();
  };

  const handleLayoutClose = () => {
    onLayoutHide();
    setTmpTableLayout(tableLayout);
    setTmpChartLayout(chartLayout);
  };

  const handleLayoutSubmit = () => {
    onLayoutHide();

    if (viewerIdx === 0) {
      let filtersValue = tableLayout.filtersValue;

      if (tableLayout.filters.join("+") !== tmpTableLayout.filters.join("+")) {
        const newTableFilterTree = getFilterTreeFromJsonStat(tmpTableLayout.filters, jsonStat); // TODO: use worker
        onTableFilterTreeSet(newTableFilterTree);
        filtersValue = getInitialFiltersValue(jsonStat, tmpTableLayout, newTableFilterTree);
      }

      onTableLayoutSet({
        ...tmpTableLayout,
        filtersValue: filtersValue
      });
    } else if (viewerIdx >= 2) {
      const newPrimaryDim = tmpChartLayout.primaryDim[0];
      const newSecondaryDim = tmpChartLayout.secondaryDim[0];
      const prevPrimaryDim = chartLayout.primaryDim[0];
      const prevSecondaryDim = chartLayout.secondaryDim[0];

      const isSecondaryDimChanged =
        (!prevSecondaryDim && !!newSecondaryDim) ||
        (!!prevSecondaryDim && !newSecondaryDim) ||
        (!!prevSecondaryDim && !!newSecondaryDim && prevSecondaryDim !== newSecondaryDim);

      const filters = [...tmpChartLayout.primaryDim, ...tmpChartLayout.secondaryDim, ...tmpChartLayout.filters];
      const newChartFilterTree = getFilterTreeFromJsonStat(filters, jsonStat);
      if (newPrimaryDim !== prevPrimaryDim) {
        tmpChartLayout.primaryDimValues = jsonStat.dimension[newPrimaryDim].category.index;
        tmpChartLayout.secondaryDimValues = newSecondaryDim
          ? getDimensionFilterValues(newSecondaryDim, jsonStat, tmpChartLayout, newChartFilterTree)
          : [];
      } else if (isSecondaryDimChanged) {
        tmpChartLayout.secondaryDimValues = newSecondaryDim
          ? getDimensionFilterValues(newSecondaryDim, jsonStat, tmpChartLayout, newChartFilterTree)
          : [];
      }
      onChartLayoutSet({
        ...tmpChartLayout,
        filtersValue: getInitialFiltersValue(jsonStat, tmpChartLayout, newChartFilterTree)
      });
      onChartFilterTreeSet(newChartFilterTree);
    }
  };

  const handleCriteriaOpen = () => {
    onCriteriaShow();
  };

  const handleCriteriaClose = () => {
    onCriteriaHide();
    setCriteriaValidity(true);
  };

  const handleCriteriaSubmit = () => {
    if (!checkDatasetSize) {
      onFetchDatasetEnable(maxObservations);
    } else {
      const datasetSize = (dimensions || []).reduce((acc, {id: dimId}, idx) => {
        if (dimId !== timeDim) {
          if ((criteria[dimId]?.filterValues || []).length > 0) {
            return acc * criteria[dimId].filterValues.length;
          } else {
            return acc * codelistsLength[idx];
          }
        } else {
          const freqs = freqDim
            ? (criteria[freqDim]?.filterValues || []).length > 0
              ? criteria[freqDim].filterValues
              : getNodes(codelists[freqDim], "children").map(({id}) => id)
            : [FREQ_ANNUAL];
          const freq = SUPPORTED_FREQ_VALUES.find(freq => freqs.includes(freq)) || FREQ_ANNUAL;
          const {min, max} = getMinAndMax(codelists[timeDim], freq, node);

          if (criteria[timeDim]?.from && criteria[timeDim]?.to) {
            const timeValues = getTimeValuesFromRange(
              freqs,
              moment(criteria[timeDim].from),
              moment(criteria[timeDim].to)
            );
            return acc * timeValues.length;
          } else if (criteria[timeDim]?.period) {
            const timeValuesByFreq = getTimeValuesFromRange([freq], moment(min), moment(max));
            const from =
              criteria[timeDim].period > timeValuesByFreq.length
                ? min
                : timeValuesByFreq[timeValuesByFreq.length - criteria[timeDim].period];
            const to = timeValuesByFreq[timeValuesByFreq.length - 1];
            const timeValues = getTimeValuesFromRange(freqs, from, to);
            return acc * timeValues.length;
          } else {
            const timeValues = getTimeValuesFromRange(freqs, moment(min), moment(max));
            return acc * timeValues.length;
          }
        }
      }, 1);

      if (datasetSize > maxObservations) {
        setObsCountErrorVisibility(true);
      } else {
        onFetchDatasetEnable(maxObservations);
      }
    }
  };

  const isCriteriaDisabled = !enableCriteria;
  const isLayoutDisabled = !enableLayout || !jsonStat || !(!!tableLayout || !!mapLayout || !!chartLayout);
  const isTableDisabled = !jsonStat || !tableLayout;
  const isChartDisabled = !jsonStat || !chartLayout;
  const isMapDisabled = !jsonStat || !mapLayout;

  const metadataUrl = datasetMap[datasetId]?.referenceMetadata || null;

  const datasetSize = getDatasetSize(dimensionsInfo, tmpTableLayout);

  return (
    <Fragment>
      <Card className={classes.root} role="menu">
        <BottomNavigation showLabels onChange={handleCriteriaOpen}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.singleViewer.sidebar.criteria")}
            icon={<SettingsIcon />}
            className={isCriteriaDisabled ? classes.navigationActionDisabled : ""}
            disabled={isCriteriaDisabled}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation showLabels onChange={handleLayoutOpen}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.singleViewer.sidebar.layout")}
            icon={<EditIcon />}
            className={isLayoutDisabled || viewerIdx === 1 ? classes.navigationActionDisabled : ""}
            disabled={isLayoutDisabled || viewerIdx === 1}
            role="menuitem"
          />
        </BottomNavigation>
        <Divider className={classes.divider} />

        {metadataUrl && (
          <Fragment>
            <DatasetMetadataButton
              metadataUrl={metadataUrl}
              datasetId={datasetId}
              nodeId={nodeId}
              showAsBottomNavigation={true}
            />
            <Divider className={classes.divider} />
          </Fragment>
        )}

        {isTableEnabled && !templateLayouts?.disableTable && (
          <BottomNavigation showLabels value={viewerIdx === 0 ? 0 : null} onChange={() => handleViewerSelect(0)}>
            <BottomNavigationAction
              label={viewers[0].title}
              icon={viewers[0].icon}
              className={isTableDisabled ? classes.navigationActionDisabled : ""}
              disabled={isTableDisabled}
              role="menuitem"
            />
          </BottomNavigation>
        )}

        {isChartEnabled && !templateLayouts?.disableChart && (
          <BottomNavigation
            showLabels
            value={viewerIdx >= 2 ? 0 : null}
            onChange={({currentTarget}) => setAnchorEl(currentTarget)}
          >
            <BottomNavigationAction
              label={viewerIdx >= 2 ? viewers[viewerIdx].title : t("scenes.dataViewer.singleViewer.sidebar.chart")}
              icon={viewerIdx >= 2 ? viewers[viewerIdx].icon : <BarChartIcon />}
              className={isChartDisabled ? classes.navigationActionDisabled : ""}
              disabled={isChartDisabled}
              role="menuitem"
            />
          </BottomNavigation>
        )}

        {isMapEnabled && !templateLayouts?.disableMap && !isMapDisabled && (
          <BottomNavigation showLabels value={viewerIdx === 1 ? 0 : null} onChange={() => handleViewerSelect(1)}>
            <BottomNavigationAction
              label={viewers[1].title}
              icon={viewers[1].icon}
              className={isMapDisabled ? classes.navigationActionDisabled : ""}
              role="menuitem"
            />
          </BottomNavigation>
        )}

        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          {viewers
            .slice(2)
            .filter(({hidden}) => hidden !== true)
            .map(option => (
              <MenuItem
                className={`${classes.floatingMenuOption} ${
                  viewerIdx === option.key ? classes.floatingMenuOptionSelected : ""
                }`}
                key={option.key}
                selected={viewerIdx === option.key}
                onClick={() => handleViewerSelect(option.key)}
              >
                {option.icon}
                <Typography style={{marginLeft: 8}}>{option.title}</Typography>
              </MenuItem>
            ))}
        </Menu>

        <Dialog open={isObsCountErrorVisible} maxWidth="md" onClose={() => setObsCountErrorVisibility(false)}>
          <CustomDialogTitle onClose={() => setObsCountErrorVisibility(false)}>
            {t("scenes.dataViewer.singleViewer.dialogs.obsCountError.title")}
          </CustomDialogTitle>
          <DialogContent>{t("scenes.dataViewer.singleViewer.dialogs.obsCountError.content")}</DialogContent>
          <DialogActions>
            <Button onClick={() => setObsCountErrorVisibility(false)}>{t("commons.confirm.close")}</Button>
          </DialogActions>
        </Dialog>

        <FullscreenDialog open={isCriteriaVisible} onClose={handleCriteriaClose}>
          <CustomDialogTitle onClose={handleCriteriaClose}>
            {t("scenes.dataViewer.singleViewer.dialogs.criteria.title")}
          </CustomDialogTitle>
          <DialogContent className={classes.criteriaContent}>
            <Criteria
              viewerMode={ViewerMode.SingleViewer}
              nodeId={nodeId}
              nodeCode={nodeCode}
              datasetId={datasetId}
              dimensions={dimensions}
              timeDim={timeDim}
              freqDim={freqDim}
              mode={mode}
              type={type}
              criteria={criteria}
              onSetCriteria={onSetCriteria}
              codelists={codelists}
              codelistsLength={codelistsLength}
              fetchCodelist={fetchCodelist}
              codelistFetchError={codelistFetchError}
              isCriteriaValid={isCriteriaValid}
              setCriteriaValidity={setCriteriaValidity}
              isObsCountWarningVisible={isObsCountWarningVisible}
              onCriteriaObsCountWarningHide={onCriteriaObsCountWarningHide}
              onSubmit={handleCriteriaSubmit}
              defaultLastNPeriods={defaultLastNPeriods}
              showCodelistInfo
              missingFilterValues={missingFilterValues}
              fetchCodelistFull={fetchCodelistFull}
              onCodelistFullHide={onCodelistFullHide}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCriteriaClose}>{t("commons.confirm.cancel")}</Button>
            <Button autoFocus color="primary" onClick={handleCriteriaSubmit} disabled={!isCriteriaValid}>
              {t("commons.confirm.apply")}
            </Button>
          </DialogActions>
        </FullscreenDialog>

        <Dialog open={isLayoutVisible && viewerIdx === 0} fullWidth maxWidth="md" onClose={handleLayoutClose}>
          <CustomDialogTitle onClose={handleLayoutClose}>
            {t("scenes.dataViewer.dialogs.tableLayout.title")}
          </CustomDialogTitle>
          <DialogContent>
            <TableLayout
              layout={tmpTableLayout}
              dimensionsInfo={dimensionsInfo}
              lockedDimensions={tableLockedDimensions}
              setLayout={setTmpTableLayout}
              jsonStat={jsonStat}
              labelFormat={labelFormat}
              alertText={
                datasetSize > maxCells ? t("scenes.dataViewer.singleViewer.sidebar.alerts.table.tooMuchCells") : null
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLayoutClose}>{t("commons.confirm.cancel")}</Button>
            <Button autoFocus onClick={handleLayoutSubmit} disabled={datasetSize > maxCells} color="primary">
              {t("commons.confirm.apply")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isLayoutVisible && viewerIdx >= 2} fullWidth maxWidth="md" onClose={handleLayoutClose}>
          <CustomDialogTitle onClose={handleLayoutClose}>
            {t("scenes.dataViewer.dialogs.chartLayout.title")}
          </CustomDialogTitle>
          <DialogContent>
            <ChartLayout
              layout={tmpChartLayout}
              dimensionsInfo={dimensionsInfo}
              lockedDimensions={graphLockedDimensions}
              setLayout={setTmpChartLayout}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLayoutClose}>{t("commons.confirm.cancel")}</Button>
            <Button autoFocus onClick={handleLayoutSubmit} color="primary">
              {t("commons.confirm.apply")}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>

      <Dialog open={isCriteriaAlertVisible} fullWidth maxWidth="sm" onClose={onCriteriaAlertHide}>
        {(() => {
          if (isEmptyData) {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.emptyData.title")}
              </CustomDialogTitle>
            );
          } else if (isTooBigData) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooBigData.title")}
                </CustomDialogTitle>
                <DialogContent>{t("scenes.dataViewer.sidebar.warning.tooBigData.content")}</DialogContent>
              </Fragment>
            );
          } else if (isTooLongQuery) {
            return (
              <Fragment>
                <CustomDialogTitle onClose={onCriteriaAlertHide}>
                  {t("scenes.dataViewer.sidebar.warning.isTooLongQuery.title")}
                </CustomDialogTitle>
                <DialogContent>{t("scenes.dataViewer.sidebar.warning.isTooLongQuery.content")}</DialogContent>
              </Fragment>
            );
          } else {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.genericError.title")}
              </CustomDialogTitle>
            );
          }
        })()}
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              onCriteriaAlertHide();
              onCriteriaShow();
            }}
          >
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(SingleViewerSidebar);

import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from "@material-ui/core/styles/withTheme";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import "./style.css";

const styles = theme => ({
  container: {
    width: "100%",
    height: "100%"
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 2000,
    background: "rgba(0, 0, 0, 0.7)"
  },
  progressContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  progress: {
    width: 320,
    maxWidth: "50%"
  },
  messagesContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center"
  },
  messages: {
    display: "inline-block",
    marginTop: "calc(50vh + 48px)"
  },
  childrenContainer: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%"
  },
  messageRow: {
    height: 32
  }
});

const mapStateToProps = state => ({
  messages: state.spinner.messages
});

const Spinner = ({classes, theme, children, messages, t}) => {
  return (
    <Box className={classes.container}>
      <Box id="spinner" className={classes.overlay + " " + (messages.length === 0 ? "invisible" : "")}>
        <Box className={classes.progressContainer}>
          <LinearProgress
            className={classes.progress}
            color="secondary"
            aria-label={t("components.spinner.ariaLabel")}
          />
        </Box>
        <Box className={classes.messagesContainer}>
          <Box className={classes.messages} aria-live="polite">
            {messages.map(({uid, message, removed, isError}) => (
              <Grid
                key={uid}
                container
                justifyContent={messages.filter(({removed}) => removed).length > 0 ? "space-between" : "center"}
                className={classes.messageRow}
                spacing={2}
              >
                <Grid item>
                  <Typography color="secondary">
                    {message}
                    {!removed && <span aria-hidden={true}>...</span>}
                  </Typography>
                </Grid>
                {removed && (
                  <Grid
                    item
                    style={{color: isError ? theme.palette.error.main : theme.palette.success.main}}
                    aria-hidden={true}
                  >
                    {isError ? <CancelIcon fontSize="small" /> : <CheckCircleIcon fontSize="small" />}
                  </Grid>
                )}
              </Grid>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        className={classes.childrenContainer}
        style={{overflow: messages.length > 0 ? "hidden" : "initial"}}
        aria-busy={messages.length > 0}
      >
        {children}
      </Box>
    </Box>
  );
};

export default compose(withStyles(styles), withTheme, connect(mapStateToProps), withTranslation())(Spinner);

import React, {Component} from "react";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Zoom from "@material-ui/core/Zoom";
import {ArrowUpward} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  fab: {
    position: "fixed",
    right: 8,
    bottom: 55,
    height: "50px",
    width: "50px",
    zIndex: theme.zIndex.appBar
  },
  childrenContainer: {
    width: "100%",
    height: "100%"
  },
  oldBrowserWarning: {
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 1999,
    height: 48,
    width: "100%",
    background: "rgba(0, 0, 0, 0.7)",
    fontSize: 16,
    lineHeight: "34px",
    color: "white",
    padding: 8
  }
});

class Page extends Component {
  state = {
    transparent: true,
    isOldBrowserWarningVisible: false
  };

  scrollTop() {
    window.scroll({top: 0, left: 0, behavior: "smooth"});
  }

  handleScroll = () => {
    const {transparent} = this.state;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > 50 && transparent) {
      this.setState({transparent: false});
    }

    if (scrollTop <= 50 && !transparent) {
      this.setState({transparent: true});
    }
  };

  handleStyle = () => {
    const nodeHeaderHeight = document.getElementById("node-header")
      ? document.getElementById("node-header").offsetHeight
      : 0;
    if (document.getElementById("main")) {
      document.getElementById("main").setAttribute("style", `padding-top: ${nodeHeaderHeight}px`);
    }
  };

  closeWarning = () => {
    this.setState({isOldBrowserWarningVisible: false});
  };

  componentDidMount() {
    // Internet Explorer 6-11
    const isIE = !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    this.setState({isOldBrowserWarningVisible: isIE || isEdge});

    window.scroll({top: 0, left: 0});

    window.addEventListener("scroll", this.handleScroll);

    window.addEventListener("resize", this.handleStyle);

    if (this.props.title && this.props.title.length > 0) {
      document.title = this.props.title;
    }
  }

  componentDidUpdate() {
    if (this.props.title && this.props.title.length > 0) {
      document.title = this.props.title;
    }
    this.handleScroll();
    this.handleStyle();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleStyle);
  }

  render() {
    const {classes, children, id, t} = this.props;
    const {transparent, isOldBrowserWarningVisible} = this.state;

    return (
      <div id={id} className={classes.root}>
        {isOldBrowserWarningVisible && (
          <div className={classes.oldBrowserWarning}>
            <Grid container justifyContent="space-between">
              <Grid item>
                This browser in not completely supported, please consider to install the new version of{" "}
                <a
                  href="https://www.microsoft.com/edge"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: "white"}}
                >
                  Microsoft Edge
                </a>
              </Grid>
              <Grid item>
                <IconButton onClick={this.closeWarning} style={{color: "white", padding: 7}}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        )}
        <Zoom in={!transparent}>
          <Fab
            className={classes.fab}
            color="secondary"
            onClick={this.scrollTop}
            aria-label={t("components.page.actions.goBackToTop")}
          >
            <ArrowUpward />
          </Fab>
        </Zoom>
        <div className={classes.childrenContainer}>{children}</div>
      </div>
    );
  }
}

export default compose(withStyles(styles), withTranslation())(Page);

import React, {Fragment, useEffect, useState} from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CancelIcon from "@material-ui/icons/Cancel";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {goToDataset} from "../../../links";
import CustomDialogTitle from "../../custom-dialog-title";
import {getDatasetStorageKey} from "../../../utils/other";

const SCROLL_STEP = 100;

const styles = theme => ({
  root: {
    whiteSpace: "nowrap"
  },
  controls: {
    display: "inline-block",
    verticalAlign: "middle",
    width: 72,
    margin: "0 12px",
    "& button": {
      padding: 6
    }
  },
  sheets: {
    overflow: "hidden",
    display: "inline-block",
    verticalAlign: "middle",
    width: `calc(100% - 112px)`,
    marginLeft: 8,
    marginRight: 8
  },
  sheetContainer: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: 8
  },
  sheet: {
    maxWidth: 200
  }
});

function SingleViewerFooter(props) {
  const {classes, dataset, onDatasetsChange} = props;

  const {t} = useTranslation();

  const [datasets, setDatasets] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState(-1);

  const [datasetToRemove, setDatasetToRemove] = useState(null);

  const [isScrollLeftDisabled, setIsScrollLeftDisabled] = useState(true);
  const [isScrollRightDisabled, setIsScrollRightDisabled] = useState(true);

  const handleScrollbar = () => {
    const sheets = document.getElementById("data-viewer__footer__sheets");
    setIsScrollLeftDisabled(sheets?.scrollLeft === 0);
    setIsScrollRightDisabled(sheets?.scrollLeft >= sheets?.scrollWidth - sheets?.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleScrollbar);
    return () => window.removeEventListener("resize", handleScrollbar);
  }, []);

  useEffect(() => {
    let newDatasets = JSON.parse(sessionStorage.getItem("datasets")) || [];
    setDatasets(newDatasets);
  }, []);

  useEffect(() => {
    const newSelectedIdx = (datasets || []).findIndex(
      data => getDatasetStorageKey(data) === getDatasetStorageKey(dataset)
    );
    setSelectedIdx(newSelectedIdx);

    const sheets = document.getElementById("data-viewer__footer__sheets");
    if (sheets) {
      sheets.scrollLeft = newSelectedIdx * 208;
    }
    handleScrollbar();

    onDatasetsChange();
  }, [datasets, dataset, onDatasetsChange]);

  const handleRemoveDataset = () => {
    const newDatasets = datasets.filter(
      dataset => getDatasetStorageKey(dataset) !== getDatasetStorageKey(datasetToRemove)
    );

    sessionStorage.setItem("datasets", JSON.stringify(newDatasets));
    sessionStorage.removeItem(getDatasetStorageKey(datasetToRemove));
    setDatasets(newDatasets);

    setDatasetToRemove(null);
  };

  const handleAddDataset = () => {
    const newDatasets = _.uniqWith(
      [...datasets, dataset],
      (a, b) => getDatasetStorageKey(a) === getDatasetStorageKey(b)
    );

    sessionStorage.setItem("datasets", JSON.stringify(newDatasets));
    setDatasets(newDatasets);
  };

  const handleScrollLeft = () => {
    const sheets = document.getElementById("data-viewer__footer__sheets");
    if (sheets) {
      sheets.scrollLeft -= SCROLL_STEP;
    }
    handleScrollbar();
  };

  const handleScrollRight = () => {
    const sheets = document.getElementById("data-viewer__footer__sheets");
    if (sheets) {
      sheets.scrollLeft += SCROLL_STEP;
    }
    handleScrollbar();
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.controls}>
          <IconButton
            onClick={handleScrollLeft}
            disabled={isScrollLeftDisabled}
            aria-label={t("scenes.dataViewer.footer.actions.scrollLeft.ariaLabel")}
          >
            <ArrowLeftIcon />
          </IconButton>
          <IconButton
            onClick={handleScrollRight}
            disabled={isScrollRightDisabled}
            aria-label={t("scenes.dataViewer.footer.actions.scrollRight.ariaLabel")}
          >
            <ArrowRightIcon />
          </IconButton>
        </div>
        <div id="data-viewer__footer__sheets" className={classes.sheets}>
          {datasets.map((dataset, key) => (
            <div key={key} className={classes.sheetContainer}>
              <Tooltip title={dataset.datasetTitle}>
                <Chip
                  className={classes.sheet}
                  label={dataset.datasetTitle}
                  color={key === selectedIdx ? "primary" : undefined}
                  onClick={() => goToDataset(dataset.nodeCode, dataset.categoryPath, dataset.datasetId, dataset.viewId)}
                  deleteIcon={
                    <Tooltip title={t("scenes.dataViewer.footer.dataset.delete")}>
                      <CancelIcon />
                    </Tooltip>
                  }
                  onDelete={() =>
                    setDatasetToRemove({
                      nodeCode: dataset.nodeCode,
                      categoryPath: dataset.categoryPath,
                      datasetId: dataset.datasetId,
                      viewId: dataset.viewId
                    })
                  }
                />
              </Tooltip>
            </div>
          ))}
          <Chip
            label={t("scenes.dataViewer.footer.dataset.add")}
            color={"primary"}
            onClick={() => {
              handleAddDataset();
            }}
            disabled={selectedIdx !== -1}
          />
        </div>
      </div>

      <Dialog open={datasetToRemove !== null} onClose={() => setDatasetToRemove(null)}>
        <CustomDialogTitle onClose={() => setDatasetToRemove(null)}>
          {t("scenes.dataViewer.footer.dialogs.datasetDelete.title")}
        </CustomDialogTitle>
        <DialogActions>
          <Button onClick={() => setDatasetToRemove(null)} color="primary">
            {t("commons.confirm.cancel")}
          </Button>
          <Button onClick={handleRemoveDataset} color="primary" autoFocus>
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default withStyles(styles)(SingleViewerFooter);

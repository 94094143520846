import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {withStyles} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {compose} from "redux";
import CustomEmpty from "../../custom-empty";
import FormLabelWithTooltip from "../../form-label-with-tooltip";
import I18nTextField from "../../i18n-text-field";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../../label-format-selector/constants";
import SearchInput from "../../search-input";
import {YEAR_SELECT_WIDTH} from "./constants";
import Keypad, {KeypadValues} from "./Keypad";
import {ValidatorFSM, ValidatorInputs, ValidatorStates} from "./validator";
import {getDimensionLabel, getDimensionValueLabel, getFormattedDimensionValueLabel} from "../../../utils/dataset";
import {validateI18nObj} from "../../../utils/i18n";
import "./style.css";

const styles = theme => ({
  tooltip: {
    fontSize: 13
  },
  tooltipElement: {
    marginBottom: 4,
    fontWeight: "normal"
  }
});

const Calculator = (
  {classes, years, nodeCode, dataset, datasetId, timeDim, combinations, variables, variablesDataset},
  ref
) => {
  const {t} = useTranslation();

  const {
    register,
    formState: {errors},
    handleSubmit,
    watch,
    setValue
  } = useForm({
    defaultValues: {
      title: {},
      year: "",
      formula: [],
      variables: {}
    }
  });

  const [isExpressionVisible, setExpressionVisibility] = useState(false);
  const [fsmStates, setFsmStates] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [yearValues, setYearValues] = useState(null);
  const [variablesArr, setVariablesArr] = useState(null);

  useEffect(() => {
    if (combinations) {
      const variablesArr = Object.keys(variables || {})
        .map(key => ({
          id: key,
          label: Object.keys(variables[key])
            .filter(key => key !== timeDim)
            .map(dim =>
              getFormattedDimensionValueLabel(
                dataset,
                variablesDataset[key],
                dim,
                variables[key][dim],
                LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
                t
              )
            )
            .join(", ")
        }))
        .sort((a, b) => {
          const aYear = years.find(year => a.id.includes(year));
          const aLetter = a.id.slice(aYear.length);
          const bYear = years.find(year => b.id.includes(year));
          const bLetter = b.id.slice(bYear.length);

          if (aYear !== bYear) {
            if (aYear > bYear) {
              return -1;
            } else {
              return 1;
            }
          } else {
            if (aLetter < bLetter) {
              return -1;
            } else {
              return 1;
            }
          }
        });
      setVariablesArr(variablesArr);
    }
  }, [combinations, variables, variablesDataset, dataset, timeDim, years, t]);

  useEffect(() => {
    setYearValues(years.slice().reverse());
  }, [years, setYearValues]);

  useEffect(() => {
    if ((yearValues || []).length > 0) {
      setValue("year", yearValues[0]);
    }
  }, [yearValues, setValue]);

  useEffect(() => {
    register("title", {
      validate: val => validateI18nObj(val) || t("commons.validation.requiredAnyLanguage")
    });
    register("year", {
      required: t("commons.validation.required")
    });
    register("formula", {
      validate: val => (val || []).length > 0 || t("commons.validation.required")
    });
    register("variables");
  }, [register, t]);

  useEffect(() => setFsmStates([ValidatorStates.ExpressionStart]), []);

  const handleClick = e => {
    if (
      e.target &&
      ((typeof e.target.className === "string" &&
        e.target.className.includes("indicator-calculator-dialog__click-listen")) ||
        (e.target.parentElement &&
          typeof e.target.parentElement.className === "string" &&
          e.target.parentElement.className.includes("indicator-calculator-dialog__click-listen")) ||
        (typeof e.target.className === "object" &&
          e.target.className.baseVal &&
          e.target.className.baseVal.includes("indicator-calculator-dialog__click-listen")) ||
        (e.target.parentElement &&
          typeof e.target.parentElement.className === "object" &&
          e.target.parentElement.className.baseVal &&
          e.target.parentElement.className.baseVal.includes("indicator-calculator-dialog__click-listen")) ||
        (document.getElementById("indicator-calculator-dialog__expression-container") &&
          document.getElementById("indicator-calculator-dialog__expression-container").contains(e.target)))
    ) {
      setExpressionVisibility(true);
    } else {
      setExpressionVisibility(false);
    }
  };

  const handleFormulaChange = formula => {
    const usedVariables = {};
    formula.forEach(el => {
      if (variables[el]) {
        usedVariables[el] = variables[el];
      }
    });
    setValue("formula", formula);
    setValue("variables", usedVariables);
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  const formula = watch("formula");

  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(indicator => {
        f(indicator);
      })();
    },
    cancel(f) {
      f();
    }
  }));

  return (
    <Box style={{height: "calc(100% - 148px)"}}>
      <Grid container spacing={2} style={{marginBottom: 16}}>
        <Grid item style={{width: `calc(100% - ${YEAR_SELECT_WIDTH}px)`}}>
          <FormControl fullWidth>
            <I18nTextField
              name="title"
              label={t("components.indicatorDialogs.create.form.fields.title.label")}
              value={watch("title") || {}}
              onChange={value => setValue("title", value)}
              error={!!errors.title}
              helperText={errors.title?.message}
              required
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item style={{width: YEAR_SELECT_WIDTH}}>
          <FormControl fullWidth>
            <TextField
              select
              name="year"
              label={t("components.indicatorDialogs.create.form.fields.year.label")}
              value={watch("year")}
              onChange={ev => setValue("year", ev.target.value)}
              error={!!errors.year}
              helperText={errors.year?.message}
              required
              variant="outlined"
              fullWidth
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {(yearValues || []).map(v => (
                <MenuItem value={v} key={v}>
                  {v}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
      <div id="indicator-calculator-dialog__expression-container">
        <Grid container style={{marginBottom: 24}}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                disabled
                name="formula"
                label={
                  <FormLabelWithTooltip
                    tooltip={t("components.indicatorDialogs.create.form.fields.formula.disclaimer")}
                  >
                    {t("components.indicatorDialogs.create.form.fields.formula.label")}
                  </FormLabelWithTooltip>
                }
                value={formula.join("")}
                error={!!errors.formula}
                helperText={errors.formula?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{color: "rgba(0, 0, 0, 0.54)"}}>
                      {isExpressionVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </InputAdornment>
                  )
                }}
                required
                variant="outlined"
                fullWidth
              />
            </FormControl>
            {isExpressionVisible &&
              (combinations && variables ? (
                <Paper style={{padding: 16}}>
                  <Grid
                    container
                    id="indicator-calculator-dialog__expression-popup"
                    spacing={2}
                    alignItems="flex-start"
                  >
                    <Grid
                      item
                      className="indicator-calculator-dialog__table-container"
                      style={{width: `calc(100% - ${YEAR_SELECT_WIDTH}px)`, height: 250, marginTop: 4}}
                    >
                      <Grid container justifyContent="flex-end" style={{marginBottom: 16}}>
                        <Grid item className="indicator-calculator-dialog__search-container" xs={12}>
                          <SearchInput
                            placeholder={t("components.indicatorDialogs.create.form.search") + "..."}
                            onChange={e => setSearchText(e.target.value)}
                            query={searchText}
                            noAutocomplete
                          />
                        </Grid>
                      </Grid>
                      <div
                        style={{
                          width: "100%",
                          height: "calc(100% - 43px)",
                          overflow: "auto",
                          borderRadius: 4,
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          padding: 4
                        }}
                      >
                        {(variablesArr || [])
                          .filter(v => v.label.includes(searchText) || v.id.includes(searchText))
                          .map(v => (
                            <Tooltip
                              key={v.id}
                              title={
                                <div className={classes.tooltip}>
                                  {Object.keys(variables[v.id]).map((dim, dimIdx) => (
                                    <div key={dimIdx} className={classes.tooltipElement}>
                                      <b>{getDimensionLabel(dataset, variablesDataset[v.id], dim)}</b>:{" "}
                                      <i>
                                        {getDimensionValueLabel(
                                          dataset,
                                          variablesDataset[v.id],
                                          dim,
                                          variables[v.id][dim]
                                        )}
                                      </i>
                                    </div>
                                  ))}
                                </div>
                              }
                              placement="top"
                            >
                              <Chip
                                avatar={<Avatar className="indicator-calculator-dialog__click-listen">{v.id}</Avatar>}
                                variant="outlined"
                                className="indicator-calculator-dialog__click-listen"
                                style={{margin: 3, maxWidth: "calc(100% - 6px)"}}
                                label={v.label}
                                disabled={!ValidatorFSM[fsmStates[0]][ValidatorInputs.Variable]}
                                onClick={() => {
                                  handleFormulaChange([...formula, v.id]);
                                  setFsmStates([ValidatorFSM[fsmStates[0]][ValidatorInputs.Variable], ...fsmStates]);
                                }}
                              />
                            </Tooltip>
                          ))}
                      </div>
                    </Grid>
                    <Grid item style={{width: YEAR_SELECT_WIDTH}}>
                      <div style={{width: 200, margin: "auto"}}>
                        <Keypad
                          onClick={val => {
                            if (val === KeypadValues.C) {
                              handleFormulaChange([]);
                              setFsmStates([ValidatorStates.ExpressionStart]);
                            } else if (val === KeypadValues.D) {
                              handleFormulaChange(formula.slice(0, formula.length - 1));
                              if (fsmStates.length > 1) {
                                setFsmStates(fsmStates.slice(1));
                              }
                            } else {
                              const pressed = Object.keys(KeypadValues).find(k => KeypadValues[k] === val);
                              handleFormulaChange([...formula, pressed]);
                              if (pressed === "(") {
                                setFsmStates([
                                  ValidatorFSM[fsmStates[0]][ValidatorInputs.LeftParenthesis],
                                  ...fsmStates
                                ]);
                              } else if (pressed === ")") {
                                setFsmStates([
                                  ValidatorFSM[fsmStates[0]][ValidatorInputs.RightParenthesis],
                                  ...fsmStates
                                ]);
                              } else if (pressed === ",") {
                                setFsmStates([ValidatorFSM[fsmStates[0]][ValidatorInputs.Comma], ...fsmStates]);
                              } else if (pressed === "+" || pressed === "-" || pressed === "*" || pressed === "/") {
                                setFsmStates([ValidatorFSM[fsmStates[0]][ValidatorInputs.Operation], ...fsmStates]);
                              } else {
                                setFsmStates([ValidatorFSM[fsmStates[0]][ValidatorInputs.Digit], ...fsmStates]);
                              }
                            }
                          }}
                          numberDisabled={!ValidatorFSM[fsmStates[0]][ValidatorInputs.Digit]}
                          operationDisabled={!ValidatorFSM[fsmStates[0]][ValidatorInputs.Operation]}
                          commaDisabled={!ValidatorFSM[fsmStates[0]][ValidatorInputs.Comma]}
                          leftParDisabled={!ValidatorFSM[fsmStates[0]][ValidatorInputs.LeftParenthesis]}
                          rightParDisabled={
                            !ValidatorFSM[fsmStates[0]][ValidatorInputs.RightParenthesis] ||
                            formula.filter(c => c === "(").length - formula.filter(c => c === ")").length <= 0
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              ) : (
                <CustomEmpty
                  text={t("components.indicatorDialogs.create.form.loading") + "..."}
                  image={<CircularProgress />}
                />
              ))}
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default compose(withStyles(styles), forwardRef)(Calculator);

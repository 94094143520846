import React from "react";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import {getUserErrorsTranslations} from "../../constants/getUserErrorsTranslations";

const UserErrors = ({errors}) => {
  const {t} = useTranslation();

  return errors !== null && errors !== undefined
    ? errors.map((str, index) => (
        <Alert
          severity="error"
          style={{
            marginBottom: index + 1 < errors.length ? 8 : 16
          }}
          key={index}
        >
          {getUserErrorsTranslations(t)[str] || t("errors.user.generic")}
        </Alert>
      ))
    : null;
};

export default UserErrors;

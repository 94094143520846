import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {compose} from "redux";
import {goToHome} from "../../links";
import CustomEmpty from "../custom-empty";
import Footer from "../footer";
import NodeHeader from "../node-header";
import Page from "../page";
import {getPageTitle} from "../../utils/other";

const styles = theme => ({
  fullWidthContainer: {
    backgroundColor: "#f5f5f5",
    width: "100%",
    height: "100%",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    "& #footer": {
      paddingTop: 0
    }
  },
  textMain: {
    color: theme.palette.primary.main,
    fontSize: 48
  },
  textSecondary: {
    color: theme.palette.primary.main
  },
  button: {
    marginTop: 48
  }
});

const mapStateToProps = state => ({
  hub: state.hub,
  baseURL: state.config.baseURL
});

const Error = ({t, classes, hub}) => {
  const location = useLocation();

  return (
    <Fragment>
      <HashLink to={{hash: "#main", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.main")}
      </HashLink>
      <HashLink to={{hash: "#footer", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.footer")}
      </HashLink>
      <Page
        title={getPageTitle(
          [t("scenes.error.title") === "scenes.error.title" ? "Errore" : t("scenes.error.title"), hub?.hub?.name],
          t
        )}
      >
        <NodeHeader emptyHeader hub={hub.hub} nodes={hub.nodes} />
        <main id="main" className={classes.fullWidthContainer}>
          <CustomEmpty
            text={
              <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                <Grid item id="error-page__text__main">
                  <Typography variant="h1" align="center" className={classes.textMain}>
                    {t("scenes.error.text.main") === "scenes.error.text.main"
                      ? "Si è verificato un errore."
                      : t("scenes.error.text.main")}
                  </Typography>
                </Grid>
                <Grid item id="error-page__text__secondary">
                  <Typography variant="subtitle1" align="center" className={classes.textSecondary}>
                    {t("scenes.error.text.secondary") === "scenes.error.text.secondary"
                      ? "L'URL richiesto non è stato trovato su questo server."
                      : t("scenes.error.text.secondary")}
                  </Typography>
                </Grid>
                <Grid item id="error-page__button">
                  <Button
                    color="secondary"
                    variant="contained"
                    className={classes.button}
                    onClick={() => {
                      goToHome();
                      window.location.reload();
                    }}
                  >
                    {t("scenes.error.button") === "scenes.error.button"
                      ? "Vai alla homepage"
                      : t("scenes.error.button")}
                  </Button>
                </Grid>
              </Grid>
            }
          />
          <Footer />
        </main>
      </Page>
    </Fragment>
  );
};

export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps))(Error);

import React, {useEffect} from "react";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/pt";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

const getLocale = (lang = "en") => {
  const map = {
    en: "en",
    it: "it",
    fr: "fr",
    es: "es",
    pt: "pt",
    de: "de"
  };

  return map[lang] || map.en;
};

const getFormat = (lang = "en") => {
  const map = {
    en: "yyyy/MM/DD",
    it: "DD/MM/yyyy"
  };

  return map[lang] || map.en;
};

const CustomDatePicker = ({
  appLang,

  label,
  value,
  minDate,
  maxDate,
  required,
  error,
  helperText,
  onChange,

  openTo = "date",
  views = ["year", "month", "date"],
  format = getFormat(appLang),
  inputVariant = undefined,
  className = undefined
}) => {
  const {t} = useTranslation();

  useEffect(() => {
    moment.locale(appLang);
  }, [appLang]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={getLocale(appLang)}>
      <DatePicker
        label={label}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        required={required}
        error={error}
        helperText={helperText}
        onChange={onChange}
        openTo={openTo}
        views={views}
        format={format}
        inputVariant={inputVariant}
        className={className}
        clearable
        cancelLabel={t("components.customDatePicker.cancelLabel")}
        clearLabel={t("components.customDatePicker.clearLabel")}
        okLabel={t("components.customDatePicker.okLabel")}
        todayLabel={t("components.customDatePicker.todayLabel")}
      />
    </MuiPickersUtilsProvider>
  );
};

export default connect(state => ({
  appLang: state.app.language
}))(CustomDatePicker);

import React, {Fragment, useCallback, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import {createPortal} from "react-dom";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {v4 as uuidv4} from "uuid";
import Call from "../../../hocs/call";
import SingleViewerFooter from "./Footer";
import SingleViewerHeader from "./Header";
import SingleViewerSidebar from "./Sidebar";
import SingleViewerViewer from "./Viewer";
import {
  backupDatasetSVState,
  fetchDatasetSVDataset,
  hideDatasetSVDownloadWarning,
  hideDatasetSVUnavailableViewWarning,
  setDatasetSVFullScreen
} from "../../../state/dataset/single-viewer/actions";
import {useIsMounted} from "../../../utils/customHooks";
import {getDatasetStorageKey} from "../../../utils/other";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  header: {
    width: "100%"
  },
  sidebarViewerContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    minHeight: 400,
    minWidth: 560
  },
  sidebar: {
    height: "100%"
  },
  viewer: {
    height: "100%"
  },
  footer: {
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    padding: 8
  }
});

const mapStateToProps = ({catalog, dataset}) => ({
  datasetMap: (catalog.uncategorizedDatasets || []).reduce(
    (acc, v) => ({...acc, [v.identifier]: v}),
    catalog.datasets || {}
  ),
  datasetUuid: dataset.singleViewer.datasetUuid,
  isFetchDatasetDisabled: dataset.singleViewer.isFetchDatasetDisabled,
  criteria: dataset.singleViewer.criteria,
  isDownloadWarningVisible: dataset.singleViewer.isDownloadWarningVisible,
  isUnavailableViewWarningVisible: dataset.singleViewer.isUnavailableViewWarningVisible,
  isFullscreen: dataset.singleViewer.isFullscreen
});

const mapDispatchToProps = dispatch => ({
  onDatasetFetch: ({nodeId, datasetId, datasetTitle, criteria}) =>
    dispatch(fetchDatasetSVDataset(nodeId, datasetId, datasetTitle, criteria)),
  onDownloadWarningHide: () => dispatch(hideDatasetSVDownloadWarning()),
  onUnavailableViewHide: () => dispatch(hideDatasetSVUnavailableViewWarning()),
  onSetFullscreen: isFullscreen => dispatch(setDatasetSVFullScreen(isFullscreen)),
  onDatasetBackup: datasetUuid => dispatch(backupDatasetSVState(datasetUuid))
});

const handleStyle = () => {
  const headerHeight = document.getElementById("data-viewer__header")?.offsetHeight || 0;
  const footerHeight = document.getElementById("data-viewer__footer")?.offsetHeight || 0;
  $("#data-viewer__sidebar-viewer-container").height(`calc(100% - ${headerHeight + footerHeight}px)`);

  const sidebarWidth = document.getElementById("data-viewer__sidebar")?.offsetWidth || 0;
  $("#data-viewer__viewer").width(`calc(100% - ${sidebarWidth}px)`);
};

function SingleViewer(props) {
  const {
    classes,

    nodeId,
    nodeCode,
    categoryPath,
    datasetId,
    datasetTitle,
    viewId,
    attachedFiles,
    nodeExtras,

    datasetMap,
    datasetUuid,
    isFetchDatasetDisabled,
    criteria,
    isDownloadWarningVisible,
    isUnavailableViewWarningVisible,
    isFullscreen,

    onDatasetFetch,
    onDownloadWarningHide,
    onUnavailableViewHide,
    onSetFullscreen,
    onDatasetBackup
  } = props;

  const {t} = useTranslation();

  const isMounted = useIsMounted();

  const [chartId] = useState("chart__" + uuidv4());

  const [mapId] = useState("map__" + uuidv4());

  useEffect(() => {
    return () => {
      const storedDatasets = JSON.parse(sessionStorage.getItem("datasets") || "[]");
      if (datasetUuid && storedDatasets.map(getDatasetStorageKey).includes(datasetUuid)) {
        onDatasetBackup(datasetUuid);
      }
    };
  }, [datasetUuid, onDatasetBackup]);

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle);
  }, []);

  useEffect(() => {
    handleStyle();
  });

  const onToggleFullScreen = useCallback(() => {
    const isEnteringFullscreen = !isFullscreen;

    const appContainer = document.getElementById("app-container");
    appContainer.style.visibility = isEnteringFullscreen ? "hidden" : "";
    const fullscreenContainer = document.getElementById("fullscreen-container");
    fullscreenContainer.style.display = isEnteringFullscreen ? "block" : "none";

    onSetFullscreen(isEnteringFullscreen);
  }, [isFullscreen, onSetFullscreen]);

  return (
    <Fragment>
      {isMounted() &&
        createPortal(
          <div id="data-viewer" className={`${classes.root} data-viewer__single-viewer`}>
            <Call
              cb={onDatasetFetch}
              cbParam={{
                nodeId,
                datasetId,
                datasetTitle,
                criteria
              }}
              disabled={!criteria || isFetchDatasetDisabled}
            >
              <div
                id="data-viewer__header"
                className={classes.header}
                style={{padding: isFullscreen ? "0 0 16px 0" : "4px 16px 12px"}}
              >
                <SingleViewerHeader
                  nodeId={nodeId}
                  nodeCode={nodeCode}
                  nodeExtras={nodeExtras}
                  datasetId={datasetId}
                  datasetTitle={datasetTitle}
                  viewId={viewId}
                  attachedFiles={attachedFiles}
                  chartId={chartId}
                  mapId={mapId}
                  onRender={handleStyle}
                  datasetMap={datasetMap}
                  onToggleFullScreen={onToggleFullScreen}
                />
              </div>
              <div
                id="data-viewer__sidebar-viewer-container"
                className={classes.sidebarViewerContainer}
                style={{padding: isFullscreen ? "0" : "0 16px"}}
              >
                <div
                  id="data-viewer__sidebar"
                  className={classes.sidebar}
                  style={{display: isFullscreen ? "none" : "block"}}
                >
                  <SingleViewerSidebar
                    nodeId={nodeId}
                    nodeCode={nodeCode}
                    nodeExtras={nodeExtras}
                    datasetId={datasetId}
                    datasetTitle={datasetTitle}
                    chartId={chartId}
                    mapId={mapId}
                    datasetMap={datasetMap}
                  />
                </div>
                <div id="data-viewer__viewer" className={classes.viewer}>
                  <SingleViewerViewer
                    nodeId={nodeId}
                    nodeExtras={nodeExtras}
                    datasetId={datasetId}
                    datasetTitle={datasetTitle}
                    chartId={chartId}
                    mapId={mapId}
                  />
                </div>
              </div>
              <div
                id="data-viewer__footer"
                className={classes.footer}
                style={{display: isFullscreen ? "none" : "block"}}
              >
                <SingleViewerFooter
                  dataset={{
                    nodeCode: nodeCode,
                    categoryPath: categoryPath,
                    datasetId: datasetId,
                    datasetTitle: datasetTitle,
                    viewId: viewId
                  }}
                  onDatasetsChange={handleStyle}
                />
              </div>
            </Call>
          </div>,
          !isFullscreen
            ? document.getElementById("data-viewer-container")
            : document.getElementById("fullscreen-container")
        )}

      <Dialog open={isDownloadWarningVisible} maxWidth="md" onClose={onDownloadWarningHide}>
        <DialogContent>{t("scenes.dataViewer.dialogs.downloadFormat.content")}</DialogContent>
        <DialogActions>
          <Button onClick={onDownloadWarningHide}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isUnavailableViewWarningVisible} maxWidth="md" onClose={onUnavailableViewHide}>
        <DialogContent>{t("scenes.dataViewer.dialogs.unavailableView.content")}</DialogContent>
        <DialogActions>
          <Button onClick={onUnavailableViewHide}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(SingleViewer);

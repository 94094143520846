import React, {Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import {withTranslation} from "react-i18next";
import AutoSearchInput from "../../auto-search-input";

const PreHeader = ({
  t,
  searchText,
  onSearch,
  selectedRowCount,
  tableActions,
  renderTableAction,
  leftActions,
  rightActions
}) => {
  const hasToolbarActions =
    (leftActions !== null && leftActions !== undefined) || (rightActions !== null && rightActions !== undefined);

  return (
    <Grid container style={{marginBottom: 4}}>
      <Grid item xs={hasToolbarActions ? 6 : 3}>
        <Grid container justifyContent="space-between">
          <Grid item>{leftActions}</Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              {tableActions && selectedRowCount > 0 && (
                <Fragment>
                  <Grid item style={{padding: "14px 16px 14px 8px"}}>
                    {t("components.infiniteScrollTable.preHeader.selectedCount", {count: selectedRowCount})}
                  </Grid>
                  {tableActions.map((action, index) => (
                    <Grid item key={index}>
                      {renderTableAction(action)}
                    </Grid>
                  ))}
                </Fragment>
              )}
              {rightActions && <Grid item>{rightActions}</Grid>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={hasToolbarActions ? 6 : 9} style={{padding: "8px 0"}}>
        <AutoSearchInput value={searchText} onSearch={onSearch} />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(PreHeader);

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import {withTranslation} from "react-i18next";

const Footer = ({t, isLoading, isHidden, rowNumStart, rowNumEnd, rowCount}) => (
  <Grid container justifyContent="flex-end" style={{marginTop: 8}}>
    <Grid item>
      {!isHidden ? (
        isLoading ? (
          <CircularProgress
            size="small"
            className="infinite-scroll-table__footer__spinner"
            style={{
              fontSize: 10,
              width: 13,
              height: 13,
              marginRight: 2
            }}
          />
        ) : (
          t("components.infiniteScrollTable.footer.rowCount", {rowNumStart, rowNumEnd, rowCount})
        )
      ) : (
        <span />
      )}
    </Grid>
  </Grid>
);

export default withTranslation()(Footer);

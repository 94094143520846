import {combineReducers} from "redux";
import {
  ALL_FULL,
  ALL_PARTIAL,
  ALL_PARTIAL_OPTIMIZED,
  CRITERIA_SELECTION_MODE_ALL,
  CRITERIA_SELECTION_MODE_STEP_BY_STEP,
  CRITERIA_SELECTION_TYPE_DYNAMIC,
  CRITERIA_SELECTION_TYPE_FULL,
  CRITERIA_SELECTION_TYPE_PARTIAL,
  DatasetState,
  STEP_BY_STEP_DYNAMIC,
  STEP_BY_STEP_FULL,
  STEP_BY_STEP_PARTIAL,
  ViewerMode
} from "./constants";
import {
  DATASET_CACHE_INFO_FETCH,
  DATASET_CACHE_INFO_TTL_UPDATE,
  DATASET_DELETE_TEMPLATE,
  DATASET_STATE_RESET,
  DATASET_STRUCTURE_FETCH,
  DATASET_STRUCTURE_FETCH_ENABLE,
  DATASET_VIEWER_MODE_SET
} from "./datasetActions";
import {DATASET_MV_VIEW_TEMPLATE_SUBMIT} from "./multi-viewer/actions";
import datasetMVReducer from "./multi-viewer/reducer";
import {DATASET_SVP_VIEW_TEMPLATE_SUBMIT} from "./single-viewer-plus/actions";
import datasetSVPReducer from "./single-viewer-plus/reducer";
import {DATASET_SV_VIEW_TEMPLATE_SUBMIT} from "./single-viewer/actions";
import datasetSVReducer from "./single-viewer/reducer";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";

const initialState = {
  viewerMode: null,

  mode: null,
  type: null,

  isFetchStructureDisabled: true,

  datasetId: null,
  nodeCode: null,
  categoryPath: null,
  viewId: null,

  datasetCacheInfo: null
};

const datasetReducer = combineReducers({
  singleViewer: datasetSVReducer,
  singleViewerPlus: datasetSVPReducer,
  multiViewer: datasetMVReducer,
  commons: (state: DatasetState = initialState, action) => {
    switch (action.type) {
      case DATASET_STATE_RESET: {
        return {
          ...initialState
        };
      }
      case DATASET_VIEWER_MODE_SET: {
        return {
          ...initialState,
          viewerMode: action.viewerMode,
          isFetchStructureDisabled: false,
          datasetId: state.datasetId,
          nodeCode: state.nodeCode,
          categoryPath: state.categoryPath,
          viewId: state.viewId
        };
      }
      case DATASET_STRUCTURE_FETCH_ENABLE: {
        return {
          ...initialState,
          isFetchStructureDisabled: false,
          datasetId: action.payload.datasetId,
          nodeCode: action.payload.nodeCode,
          categoryPath: action.payload.categoryPath,
          viewId: action.payload.viewId || null
        };
      }
      case REQUEST_INIT: {
        switch (action.payload.label) {
          case DATASET_STRUCTURE_FETCH: {
            return {
              ...state,
              isFetchStructureDisabled: true
            };
          }
          default:
            return state;
        }
      }
      case REQUEST_SUCCESS: {
        switch (action.payload.label) {
          case DATASET_STRUCTURE_FETCH: {
            let criteriaView = action.payload.response.criteriaView;

            const datasetWithNotCodedDim = action.payload.response.criteria.some(
              (dim: any) =>
                dim.id !== action.payload.response.timeDimension &&
                (dim.extra?.DataStructureRef === null || dim.extra?.DataStructureRef === undefined)
            );
            if (criteriaView === ALL_PARTIAL_OPTIMIZED && datasetWithNotCodedDim) {
              criteriaView = ALL_PARTIAL;
            }

            let viewerMode = state.viewerMode
              ? state.viewerMode
              : action.payload.extra.isMultiViewerTheme
              ? ViewerMode.MultiViewer
              : criteriaView === ALL_PARTIAL_OPTIMIZED
              ? ViewerMode.SingleViewerPlus
              : ViewerMode.SingleViewer;

            const mode =
              criteriaView === ALL_FULL || criteriaView === ALL_PARTIAL || criteriaView === ALL_PARTIAL_OPTIMIZED
                ? CRITERIA_SELECTION_MODE_ALL
                : criteriaView === STEP_BY_STEP_FULL ||
                  criteriaView === STEP_BY_STEP_PARTIAL ||
                  criteriaView === STEP_BY_STEP_DYNAMIC
                ? CRITERIA_SELECTION_MODE_STEP_BY_STEP
                : null;

            const type =
              criteriaView === ALL_FULL || criteriaView === STEP_BY_STEP_FULL
                ? CRITERIA_SELECTION_TYPE_FULL
                : criteriaView === ALL_PARTIAL ||
                  criteriaView === ALL_PARTIAL_OPTIMIZED ||
                  criteriaView === STEP_BY_STEP_PARTIAL
                ? CRITERIA_SELECTION_TYPE_PARTIAL
                : criteriaView === STEP_BY_STEP_DYNAMIC
                ? CRITERIA_SELECTION_TYPE_DYNAMIC
                : null;

            if (mode === null || type === null) {
              viewerMode = ViewerMode.Error;
            }

            return {
              ...state,
              viewerMode: viewerMode,
              mode: mode,
              type: type
            };
          }
          case DATASET_SV_VIEW_TEMPLATE_SUBMIT:
          case DATASET_SVP_VIEW_TEMPLATE_SUBMIT:
          case DATASET_MV_VIEW_TEMPLATE_SUBMIT: {
            return {
              ...state,
              isFetchStructureDisabled: action.payload.extra.isView
            };
          }
          case DATASET_CACHE_INFO_FETCH: {
            return {
              ...state,
              datasetCacheInfo: action.payload.response
            };
          }
          case DATASET_CACHE_INFO_TTL_UPDATE: {
            const datasetCacheInfo = state.datasetCacheInfo;
            datasetCacheInfo.ttl = action.payload.response.ttl;
            return {
              ...state,
              datasetCacheInfo: datasetCacheInfo
            };
          }
          case DATASET_DELETE_TEMPLATE: {
            return {
              ...initialState
            };
          }
          default:
            return state;
        }
      }
      case REQUEST_ERROR: {
        switch (action.payload.label) {
          case DATASET_STRUCTURE_FETCH: {
            return {
              ...initialState,
              viewerMode: ViewerMode.Error,
              datasetId: state.datasetId,
              nodeCode: state.nodeCode,
              categoryPath: state.categoryPath,
              viewId: state.viewId
            };
          }
          default:
            return state;
        }
      }
      default:
        return state;
    }
  }
});

export default datasetReducer;

import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {compose} from "redux";

const Form = compose(forwardRef)(({hub}, ref) => {
  const {
    register,
    formState: {errors},
    handleSubmit,
    watch
  } = useForm({});

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const {t} = useTranslation();

  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(val => {
        const data = {
          ...val,
          passwordConfirm: undefined
        };
        f(data);
      })();
    },
    cancel(f) {
      f();
    }
  }));

  return (
    <Grid container spacing={3}>
      {hub && (
        <Fragment>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                {...register("password", {
                  required: t("commons.validation.required")
                })}
                name="password"
                label={t("scenes.machineToMachineSettings.settings.fields.password")}
                error={!!errors.password}
                helperText={errors.password?.message}
                variant="outlined"
                required
                type={showPassword ? undefined : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  autoComplete: "new-password"
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                {...register("passwordConfirm", {
                  required: t("commons.validation.required"),
                  validate: val =>
                    val === watch("password") ||
                    t("scenes.machineToMachineSettings.settings.validation.passwordsMustBeEqual")
                })}
                name="passwordConfirm"
                label={t("scenes.machineToMachineSettings.settings.fields.passwordConfirm")}
                error={!!errors.passwordConfirm}
                helperText={errors.passwordConfirm?.message}
                variant="outlined"
                required
                type={showPasswordConfirm ? undefined : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
                        {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  autoComplete: "new-password"
                }}
              />
            </FormControl>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
});

const MachineToMachineSettingsForm = ({hub}, ref) => {
  return hub && <Form ref={ref} hub={hub} />;
};

export default compose(forwardRef)(MachineToMachineSettingsForm);

import {useCallback, useEffect, useRef, useState} from "react";

export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export function useIsMounted() {
  const isMounted = useRef(false); // unmounted by default

  useEffect(() => {
    isMounted.current = true; // mounted

    return () => {
      isMounted.current = false; // unmounted
    };
  }, []); // run once on mount

  return useCallback(() => isMounted.current, []); // return function that checks mounted status
}

export function useForceUpdate() {
  const [, setValue] = useState(0); // integer state

  return () => setValue(value => value + 1); // update state to force render
}

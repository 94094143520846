import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {withTranslation} from "react-i18next";

const GeometriesWarningDialog = ({t, isOpen, onClose}) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogContent onClose={onClose}>{t("components.map.dialogs.noGeometry.title")}</DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{t("commons.confirm.close")}</Button>
    </DialogActions>
  </Dialog>
);

export default withTranslation()(GeometriesWarningDialog);

import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import Call from "../../../hocs/call";
import {goToDashboard} from "../../../links";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomMaterialTable from "../../custom-material-table";
import Dashboard from "../../dashboard";
import DashboardBuilder from "../../dashboard-builder";
import FullscreenDialog from "../../fullscreen-dialog";
import {
  changeOtherConfigDashboard,
  clearOtherConfigDashboards,
  clearOtherConfigDashboardsDataset,
  clearOtherConfigDashboardViews,
  closeOtherConfigDashboard,
  createOtherConfigDashboard,
  deleteOtherConfigDashboard,
  fetchOtherConfigDashboards,
  fetchOtherConfigDashboardsDataset,
  fetchOtherConfigDashboardViews,
  hideOtherConfigDashboard,
  setOtherConfigDashboardsDatasetFilter,
  submitOtherConfigDashboardCreate,
  submitOtherConfigDashboardUpdate,
  updateOtherConfigDashboard
} from "../../../state/otherConfig/otherConfigActions";
import {CRITERIA_FILTER_TYPE_CODES} from "../../../utils/criteria";
import {
  DASHBOARD_ELEM_FILTER_DIMENSION_KEY,
  DASHBOARD_ELEM_TYPE_KEY,
  DASHBOARD_ELEM_TYPE_VALUE_VIEW,
  DASHBOARD_ELEM_VALUE_KEY,
  DASHBOARD_STATUS_REQUEST_SUCCESS,
  getViewIdxFromRowAndCol
} from "../../../utils/dashboards";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj, validateI18nObj} from "../../../utils/i18n";

const styles = theme => ({
  tableToolbar: {
    marginBottom: theme.spacing(1)
  },
  previewDialog: {
    "& .MuiDialogTitle-root, & .MuiDialogContent-root, & .MuiDialogActions-root": {
      background: "#f5f5f5"
    }
  }
});

const mapStateToProps = state => ({
  defaultLanguage: state.app.language,
  languages: state.app.languages,
  dashboardFilterConfig: state.dashboardFilterConfig,
  needDashboards: state.otherConfig.needDashboards,
  dashboards: state.otherConfig.dashboards,
  dashboard: state.otherConfig.dashboard,
  dashboardViews: state.otherConfig.dashboardViews,
  dashboardJsonStats: state.otherConfig.dashboardJsonStats,
  dashboardLayoutObjs: state.otherConfig.dashboardLayoutObjs,
  dashboardFilterTrees: state.otherConfig.dashboardFilterTrees,
  dashboardTimePeriodsByFreq: state.otherConfig.dashboardTimePeriodsByFreq
});

const mapDispatchToProps = dispatch => ({
  fetchDashboards: () => dispatch(fetchOtherConfigDashboards()),
  clearDashboards: () => dispatch(clearOtherConfigDashboards()),
  createDashboard: dashboard => dispatch(createOtherConfigDashboard(dashboard)),
  updateDashboard: dashboardId => dispatch(updateOtherConfigDashboard(dashboardId)),
  changeDashboard: dashboard => dispatch(changeOtherConfigDashboard(dashboard)),
  submitDashboardCreate: (dashboard, dashboardConfig) =>
    dispatch(submitOtherConfigDashboardCreate(dashboard, dashboardConfig)),
  submitDashboardUpdate: (dashboardId, dashboard, dashboardConfig) =>
    dispatch(submitOtherConfigDashboardUpdate(dashboardId, dashboard, dashboardConfig)),
  deleteDashboard: dashboardId => dispatch(deleteOtherConfigDashboard(dashboardId)),
  hideDashboard: () => dispatch(hideOtherConfigDashboard()),
  fetchViews: () => dispatch(fetchOtherConfigDashboardViews()),
  clearViews: () => dispatch(clearOtherConfigDashboardViews()),
  onClose: () => dispatch(closeOtherConfigDashboard()),
  fetchDataset: (nodeId, datasetId, criteria, requestIds) =>
    dispatch(fetchOtherConfigDashboardsDataset(nodeId, datasetId, criteria, requestIds)),
  onFilterSet: (dashboardId, viewIdx, dimension, value) =>
    dispatch(setOtherConfigDashboardsDatasetFilter(viewIdx, dimension, value)),
  clearDatasets: () => dispatch(clearOtherConfigDashboardsDataset())
});

const getStaticDashboard = dashboard => {
  if (!dashboard) {
    return null;
  }

  const staticDashboard = _.cloneDeep(dashboard);
  staticDashboard.dashboardConfig.forEach(row => {
    row.forEach(col => {
      col[DASHBOARD_ELEM_FILTER_DIMENSION_KEY] = null;
    });
  });

  return staticDashboard;
};

const initialDashboard = {
  title: {},
  dashboardConfig: [],
  viewIds: [],
  views: {},
  filterLevels: {}
};

const UserDashboardsSettingsForm = (
  {
    classes,
    defaultLanguage,
    languages,
    dashboardFilterConfig,
    nodes,
    needDashboards,
    dashboards,
    dashboard,
    fetchDashboards,
    clearDashboards,
    createDashboard,
    updateDashboard,
    changeDashboard,
    submitDashboardCreate,
    submitDashboardUpdate,
    deleteDashboard,
    hideDashboard,
    dashboardViews,
    fetchViews,
    clearViews,
    onClose,
    dashboardJsonStats,
    dashboardLayoutObjs,
    dashboardFilterTrees,
    dashboardTimePeriodsByFreq,
    onFilterSet,
    fetchDataset,
    clearDatasets,
    onDashboardsClose
  },
  ref
) => {
  const [deleteDashboardId, setDeleteDashboardId] = useState(null);
  const [updateDashboardId, setUpdateDashboardId] = useState(null);

  const [isPreviewVisible, setPreviewVisibility] = useState(false);

  const {t} = useTranslation();

  useEffect(() => {
    fetchDashboards();
  }, [fetchDashboards]);

  useEffect(() => {
    if (needDashboards) {
      fetchDashboards();
    }
  }, [dashboards, needDashboards, fetchDashboards]);

  useImperativeHandle(ref, () => ({
    cancel(f) {
      clearDashboards();
      onClose();
      f();
    }
  }));

  const handleFetchDatasets = () => {
    const requests = [];

    const isDetailLevelFilterEnabled = Object.values(dashboard?.filterLevels || {}).filter(val => val).length > 0;

    dashboard.dashboardConfig.forEach((row, rowIdx) => {
      row.forEach((col, colIdx) => {
        if (col[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_VIEW) {
          const view = dashboard.views[col[DASHBOARD_ELEM_VALUE_KEY]];

          let newCriteria = view.criteria;

          const filterDimension = col[DASHBOARD_ELEM_FILTER_DIMENSION_KEY];

          if ((filterDimension || "").length > 0 && isDetailLevelFilterEnabled) {
            newCriteria = {
              ...newCriteria,
              [filterDimension]: {
                ...newCriteria[filterDimension],
                id: filterDimension,
                type: CRITERIA_FILTER_TYPE_CODES,
                filterValues: dashboardFilterConfig?.defaultValue ? [dashboardFilterConfig.defaultValue] : []
              }
            };
          }

          const request = requests.find(({nodeId, datasetId, criteria}) => {
            return nodeId === view.nodeId && datasetId === view.datasetId && _.isEqual(criteria, newCriteria);
          });

          if (request === undefined) {
            requests.push({
              nodeId: view.nodeId,
              datasetId: view.datasetId.split("+").join(","),
              criteria: newCriteria,
              requestIds: [getViewIdxFromRowAndCol(rowIdx, colIdx)]
            });
          } else {
            request.requestIds.push(getViewIdxFromRowAndCol(rowIdx, colIdx));
          }
        }
      });
    });
    requests.forEach(({nodeId, datasetId, criteria, requestIds}) =>
      fetchDataset(nodeId, datasetId, criteria, requestIds)
    );
  };

  const handleDashboardClose = () => {
    hideDashboard();
    setUpdateDashboardId(null);
  };

  const handleDashboardSubmit = () => {
    if (updateDashboardId) {
      submitDashboardUpdate(updateDashboardId, dashboard, dashboardFilterConfig);
    } else {
      submitDashboardCreate(dashboard, dashboardFilterConfig);
      handleDashboardClose();
    }
  };

  return (
    dashboards && (
      <Fragment>
        <CustomMaterialTable
          rightActions={
            <Button size="small" startIcon={<AddIcon />} onClick={() => createDashboard(initialDashboard)}>
              {t("scenes.dashboardsSettings.createDashboard")}
            </Button>
          }
          columns={[
            {
              field: "title",
              title: t("scenes.dashboardsSettings.table.columns.dashboardTitle"),
              render: ({title}) => localizeI18nObj(title, defaultLanguage, languages),
              customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
            }
          ]}
          data={dashboards}
          actions={[
            {
              icon: VisibilityIcon,
              tooltip: t("scenes.dashboardsSettings.table.actions.viewDashboard"),
              onClick: (_, {dashboardId}) =>
                window.location.href.toLowerCase().includes(`/dashboards/${dashboardId}`)
                  ? onDashboardsClose()
                  : goToDashboard(dashboardId)
            },
            {
              icon: EditIcon,
              tooltip: t("scenes.dashboardsSettings.table.actions.editDashboard"),
              onClick: (_, {dashboardId}) => {
                updateDashboard(dashboardId);
                setUpdateDashboardId(dashboardId);
              }
            },
            {
              icon: DeleteIcon,
              tooltip: t("scenes.dashboardsSettings.table.actions.deleteDashboard"),
              onClick: (_, {dashboardId}) => setDeleteDashboardId(dashboardId)
            }
          ]}
          options={{
            actionsColumnIndex: 1
          }}
        />

        <Dialog maxWidth="xs" open={deleteDashboardId !== null} onClose={() => setDeleteDashboardId(null)}>
          <CustomDialogTitle onClose={() => setDeleteDashboardId(null)}>
            {t("scenes.dashboardsSettings.modals.deleteDashboard.title")}
          </CustomDialogTitle>
          <DialogContent>{t("scenes.dashboardsSettings.modals.deleteDashboard.content")}</DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setDeleteDashboardId(null)}>
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              onClick={() => {
                deleteDashboard(deleteDashboardId);
                setDeleteDashboardId(null);
              }}
            >
              {t("commons.confirm.confirm")}
            </Button>
          </DialogActions>
        </Dialog>

        <FullscreenDialog open={dashboard !== null} onClose={handleDashboardClose}>
          <CustomDialogTitle onClose={handleDashboardClose}>
            {updateDashboardId
              ? t("scenes.dashboardsSettings.modals.updateDashboard.title")
              : t("scenes.dashboardsSettings.modals.createDashboard.title")}
          </CustomDialogTitle>
          <DialogContent>
            <DashboardBuilder
              nodes={nodes}
              dashboard={dashboard}
              onChange={changeDashboard}
              views={dashboardViews}
              fetchViews={fetchViews}
              onViewsHide={clearViews}
              jsonStats={dashboardJsonStats}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDashboardClose}>{t("commons.confirm.close")}</Button>
            <Button
              id="dashboard-builder__preview-button"
              style={{display: "none"}}
              onClick={() => setPreviewVisibility(true)}
            >
              {t("commons.confirm.preview")}
            </Button>
            <Button onClick={handleDashboardSubmit} disabled={dashboard && !validateI18nObj(dashboard.title)}>
              {t("commons.confirm.save")}
            </Button>
          </DialogActions>
        </FullscreenDialog>

        <FullscreenDialog
          open={isPreviewVisible}
          onClose={() => setPreviewVisibility(false)}
          className={classes.previewDialog}
        >
          <CustomDialogTitle onClose={() => setPreviewVisibility(false)}>
            {t("components.dashboardBuilder.modals.preview.title")}
          </CustomDialogTitle>
          <DialogContent>
            <Call cb={handleFetchDatasets}>
              <Dashboard
                dashboard={getStaticDashboard(dashboard)}
                dashboardStatus={DASHBOARD_STATUS_REQUEST_SUCCESS}
                jsonStats={dashboardJsonStats}
                layoutObjs={dashboardLayoutObjs}
                filterTrees={dashboardFilterTrees}
                timePeriodsByFreq={dashboardTimePeriodsByFreq}
                onFilterSet={onFilterSet}
                hideFullscreen
              />
            </Call>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setPreviewVisibility(false);
                clearDatasets();
              }}
            >
              {t("commons.confirm.close")}
            </Button>
          </DialogActions>
        </FullscreenDialog>
      </Fragment>
    )
  );
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(UserDashboardsSettingsForm);

import {
  clearDatasetCacheUrl,
  getArtefactCacheClearAllUrl,
  getArtefactCacheClearUrl,
  getClearNodeCatalogMemoryCacheUrl,
  getDataflowsCacheListUrl,
  getDataflowsCacheUpdateUrl,
  getDataflowsClearAllCacheUrl,
  getDataflowsCreateCacheUrl
} from "../../serverApi/urls";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";

export const DATAFLOW_CACHE_FETCH = "cache/fetch";
export const DATAFLOW_CACHE_CLEAR = "cache/clear";
export const DATAFLOW_CACHE_UPDATE = "cache/update";
export const DATAFLOW_CACHE_CREATE = "cache/create";
export const DATAFLOW_CACHE_DELETE = "cache/delete";
export const DATAFLOW_CACHE_DELETE_ALL = "cache/deleteAll";
export const DATAFLOW_CACHE_DELETE_CATALOG = "cache/deleteCatalog";
export const ARTEFACT_CACHE_DELETE_ALL = "cache/artefact/deleteAll";

export const fetchDataflowCache = (nodeId: number) =>
  initRequest(DATAFLOW_CACHE_FETCH, getDataflowsCacheListUrl(nodeId), undefined, undefined, t => ({
    onStart: t("scenes.nodesSettings.cacheSettings.messages.fetchDataflowCache.start")
  }));

export const updateDataflowCache = (nodeId: number, cacheId: string, ttl: number) =>
  initRequest(
    DATAFLOW_CACHE_UPDATE,
    getDataflowsCacheUpdateUrl(nodeId, cacheId),
    RequestMethod.PUT,
    {ttl: ttl},
    t => ({
      onStart: t("scenes.nodesSettings.cacheSettings.messages.updateDataflowCache.start")
    }),
    {
      ttl: ttl,
      nodeId: nodeId,
      cacheId: cacheId
    }
  );

export const createDataflowCache = (nodeId: number, data: any) =>
  initRequest(
    DATAFLOW_CACHE_CREATE,
    getDataflowsCreateCacheUrl(nodeId),
    RequestMethod.POST,
    data,
    t => ({
      onStart: t("scenes.nodesSettings.cacheSettings.messages.createDataflowCache.start")
    }),
    {
      oldData: data
    }
  );

export const deleteDataflowCache = (nodeId: number, dataflowId: string) =>
  initRequest(
    DATAFLOW_CACHE_DELETE,
    clearDatasetCacheUrl(nodeId, dataflowId.split("+").join(",")),
    RequestMethod.POST,
    {},
    t => ({
      onStart: t("scenes.nodesSettings.cacheSettings.messages.deleteDataflowCache.start")
    }),
    {
      nodeId: nodeId,
      dataflowId: dataflowId
    }
  );

export const clearDataflowCache = () => ({
  type: DATAFLOW_CACHE_CLEAR
});

export const deleteAllDataflowCache = (nodeId: number) =>
  initRequest(
    DATAFLOW_CACHE_DELETE_ALL,
    getDataflowsClearAllCacheUrl(nodeId),
    RequestMethod.POST,
    undefined,
    t => ({
      onStart: t("scenes.nodesSettings.cacheSettings.messages.deleteAllDataflowCache.start")
    }),
    {
      nodeId
    }
  );

export const deleteCatalogCache = (nodeId: number) =>
  initRequest(
    DATAFLOW_CACHE_DELETE_CATALOG,
    getClearNodeCatalogMemoryCacheUrl(nodeId),
    RequestMethod.POST,
    undefined,
    t => ({
      onStart: t("scenes.nodesSettings.cacheSettings.messages.deleteCatalogCache.start")
    }),
    {
      nodeId
    }
  );

export const deleteAllArtefactsCache = (nodeId: number, preserveDataflows: boolean) =>
  initRequest(
    ARTEFACT_CACHE_DELETE_ALL,
    getArtefactCacheClearAllUrl(nodeId, preserveDataflows),
    RequestMethod.POST,
    undefined,
    t => ({
      onStart: t("scenes.nodesSettings.cacheSettings.messages.deleteAllArtefactsCache.start")
    })
  );

export const deleteArtefactCache = (nodeId: number, structureType: string, structureId: string, references?: string) =>
  initRequest(
    ARTEFACT_CACHE_DELETE_ALL,
    getArtefactCacheClearUrl(nodeId, structureType, structureId, references),
    RequestMethod.POST,
    undefined,
    t => ({
      onStart: t("scenes.nodesSettings.cacheSettings.messages.deleteArtefactCache.start")
    })
  );

import React, {Fragment, useEffect, useState} from "react";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import CustomEmpty from "../custom-empty";
import DashboardRow from "./DashboardRow";
import {
  DASHBOARD_STATUS_MISSING,
  DASHBOARD_STATUS_REQUEST_ERROR,
  DASHBOARD_STATUS_REQUEST_START,
  DASHBOARD_STATUS_REQUEST_SUCCESS
} from "../../utils/dashboards";

function Dashboard(props) {
  const {
    dashboardId,
    dashboard: externalDashboard,
    dashboardStatus,
    filterValue,
    jsonStats,
    layoutObjs,
    filterTrees,
    timePeriodsByFreq,
    onFilterSet,
    onDownloadSubmit,
    hideFullscreen,
    onOptimizedDatasetFetch
  } = props;

  const {t} = useTranslation();
  const [dashboard, setDashboard] = useState(externalDashboard);

  useEffect(() => {
    setDashboard(prevDashboard => {
      if (_.isEqual(prevDashboard, externalDashboard)) {
        return prevDashboard;
      } else {
        return externalDashboard;
      }
    });
  }, [externalDashboard]);

  return dashboardStatus === DASHBOARD_STATUS_REQUEST_START ? (
    <CustomEmpty style={{height: 400}}>{t("components.dashboard.status.requestStart")}</CustomEmpty>
  ) : dashboardStatus === DASHBOARD_STATUS_REQUEST_ERROR ? (
    <CustomEmpty style={{height: 400}}>{t("components.dashboard.status.requestError")}</CustomEmpty>
  ) : dashboardStatus === DASHBOARD_STATUS_MISSING ? (
    <CustomEmpty style={{height: 400}}>{t("components.dashboard.status.missing")}</CustomEmpty>
  ) : dashboardStatus === DASHBOARD_STATUS_REQUEST_SUCCESS && dashboard ? (
    <Fragment>
      {(dashboard.dashboardConfig || []).map((row, idx) => (
        <DashboardRow
          key={idx}
          dashboardId={dashboardId}
          dashboard={dashboard}
          filterValue={filterValue}
          row={row}
          rowIdx={idx}
          jsonStats={jsonStats}
          layoutObjs={layoutObjs}
          filterTrees={filterTrees}
          timePeriodsByFreq={timePeriodsByFreq}
          onFilterSet={onFilterSet}
          onDownloadSubmit={onDownloadSubmit}
          hideFullscreen={hideFullscreen}
          onOptimizedDatasetFetch={onOptimizedDatasetFetch}
        />
      ))}
    </Fragment>
  ) : null;
}

export default Dashboard;

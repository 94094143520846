import {
  getCreateTemplateUrl,
  getCreateViewUrl,
  getDatasetCustomDownloadUrl,
  getDatasetDownloadUrl,
  getDatasetSdmxQueryUrl,
  getDatasetStructureCodelistsUrl,
  getDatasetStructureCodelistUrl,
  getDatasetStructureDynamicCodelistUrl,
  getDatasetUrl
} from "../../../serverApi/urls";
import {
  Criteria,
  CRITERIA_SELECTION_MODE_ALL,
  CRITERIA_SELECTION_TYPE_DYNAMIC,
  CRITERIA_SELECTION_TYPE_FULL
} from "../constants";
import {initRequest, RequestMethod} from "../../../middlewares/request/requestActions";
import {getCriteriaArrayFromObject} from "../../../utils/criteria";
import {
  DOWNLOAD_FORMAT_CSV,
  DOWNLOAD_FORMAT_EXCEL,
  downloadFormats,
  getCustomExportRequestBody
} from "../../../utils/download";

export const DATASET_SV_STATE_BACKUP = "dataset/sv/state/backup";

export const DATASET_SV_STRUCTURE_FETCH_INIT = "dataset/sv/structure/fetch/init";
export const DATASET_SV_STRUCTURE_FETCH_SUCCESS = "dataset/sv/structure/fetch/success";
export const DATASET_SV_STRUCTURE_FETCH_ERROR = "dataset/sv/structure/fetch/error";

export const DATASET_SV_DATASET_FETCH_ENABLE = "dataset/sv/dataset/fetch/enable";
export const DATASET_SV_DATASET_FETCH = "dataset/sv/dataset/fetch";

export const DATASET_SV_DATASET_FETCH_INIT = "dataset/sv/dataset/fetch/init";
export const DATASET_SV_DATASET_FETCH_SUCCESS = "dataset/sv/dataset/fetch/success";
export const DATASET_SV_DATASET_FETCH_ERROR = "dataset/sv/dataset/fetch/error";

export const DATASET_SV_CRITERIA_SHOW = "dataset/sv/criteria/show";
export const DATASET_SV_CRITERIA_HIDE = "dataset/sv/criteria/hide";
export const DATASET_SV_CRITERIA_ALERT_HIDE = "dataset/sv/criteria/alert/hide";
export const DATASET_SV_CRITERIA_OBS_COUNT_WARNING_HIDE = "dataset/sv/criteria/obs/count/warning/hide";
export const DATASET_SV_STRUCTURE_CODELIST_FETCH = "dataset/sv/structure/codelist/fetch";
export const DATASET_SV_STRUCTURE_CRITERIA_SET = "dataset/sv/structure/criteria/set";

export const DATASET_SV_VIEWER_SET = "dataset/sv/viewer/set";

export const DATASET_SV_LAYOUT_SHOW = "dataset/sv/layout/show";
export const DATASET_SV_LAYOUT_HIDE = "dataset/sv/layout/hide";
export const DATASET_SV_TABLE_LAYOUT_SUBMIT = "dataset/sv/table/layout/submit";
export const DATASET_SV_TABLE_FILTERS_TREE_SUBMIT = "dataset/sv/table/filters/tree/submit";
export const DATASET_SV_MAP_LAYOUT_SUBMIT = "dataset/sv/map/layout/submit";
export const DATASET_SV_MAP_DETAIL_LEVEL_SET = "dataset/sv/map/detail/level/set";
export const DATASET_SV_CHART_LAYOUT_SUBMIT = "dataset/sv/chart/layout/submit";
export const DATASET_SV_CHART_FILTERS_TREE_SUBMIT = "dataset/sv/chart/filters/tree/submit";

export const DATASET_SV_LABEL_FORMAT_SET = "dataset/sv/label/format/set";
export const DATASET_SV_VARIATION_SET = "dataset/sv/variation/set";
export const DATASET_SV_TEMPORAL_DIM_ORDER_SET = "dataset/sv/temporalDimOrder/set";

export const DATASET_SV_CHART_SETTINGS_SET = "dataset/sv/chart/settings/set";
export const DATASET_SV_MAP_SETTINGS_SET = "dataset/sv/map/settings/set";

export const DATASET_SV_DOWNLOAD_SUBMIT = "dataset/sv/download/submit";
export const DATASET_SV_DOWNLOAD_WARNING_HIDE = "dataset/sv/download/warning/hide";

export const DATASET_SV_VIEW_TEMPLATE_SHOW = "dataset/sv/view/template/show";
export const DATASET_SV_VIEW_TEMPLATE_HIDE = "dataset/sv/view/template/hide";
export const DATASET_SV_VIEW_TEMPLATE_SUBMIT = "dataset/sv/view/template/submit";
export const DATASET_SV_VIEW_ERROR_HIDE = "dataset/sv/view/error/hide";

export const DATASET_SV_UNAVAILABLE_VIEW_WARNING_HIDE = "dataset/sv/unavailable/view/warning/hide";

export const DATASET_SV_HTML_GENERATING_TIME_SET = "dataset/sv/html/generating/time/set";

export const DATASET_SV_SDMX_QUERY_SHOW = "dataset/sv/sdmx/query/show";
export const DATASET_SV_SDMX_QUERY_HIDE = "dataset/sv/sdmx/query/hide";
export const DATASET_SV_SDMX_QUERY_FETCH = "dataset/sv/sdmx/query/fetch";

export const DATASET_SV_STRUCTURE_CODELIST_FULL_FETCH = "dataset/sv/structure/codelist/full/fetch";
export const DATASET_SV_STRUCTURE_CODELIST_FULL_HIDE = "dataset/sv/structure/codelist/full/hide";

export const DATASET_SV_SET_FULLSCREEN = "dataset/sv/set/fullscreen";

export const backupDatasetSVState = (datasetUuid: string) => ({
  type: DATASET_SV_STATE_BACKUP,
  datasetUuid
});

export const fetchDatasetSVStructureInit = () => ({
  type: DATASET_SV_STRUCTURE_FETCH_INIT
});

export const fetchDatasetSVStructureSuccess = (datasetUuid: string, structure: any, viewId: string | null) => ({
  type: DATASET_SV_STRUCTURE_FETCH_SUCCESS,
  payload: {
    datasetUuid,
    structure,
    viewId
  }
});

export const fetchDatasetSVStructureError = () => ({
  type: DATASET_SV_STRUCTURE_FETCH_ERROR
});

export const enableDatasetSVDatasetFetch = (maxObservations?: number) => ({
  type: DATASET_SV_DATASET_FETCH_ENABLE,
  payload: {
    maxObservations
  }
});

export const fetchDatasetSVDataset = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string,
  criteria: {[key: string]: Criteria}
) =>
  initRequest(
    DATASET_SV_DATASET_FETCH,
    getDatasetUrl(nodeId, datasetId),
    RequestMethod.POST,
    getCriteriaArrayFromObject(criteria),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDataset", {title: datasetTitle})
    }),
    undefined,
    undefined,
    (statusCode: number) => statusCode === 413 || statusCode === 414
  );

export const fetchDatasetSVDatasetInit = () => ({
  type: DATASET_SV_DATASET_FETCH_INIT
});

export const fetchDatasetSVDatasetSuccess = (
  response: any,
  statusCode: number,
  responseHeaders: any,
  isEmptyData: boolean,
  isPartialData: boolean,
  isResponseNotValid: boolean,
  tableLayout: any,
  tableFilterTree: any,
  mapLayout: any,
  mapFilterTree: any,
  chartLayout: any,
  chartFilterTree: any,
  timePeriodsByFreq: any,
  worker: any
) => ({
  type: DATASET_SV_DATASET_FETCH_SUCCESS,
  payload: {
    response,
    statusCode,
    responseHeaders,
    isEmptyData,
    isPartialData,
    isResponseNotValid,
    tableLayout,
    tableFilterTree,
    mapLayout,
    mapFilterTree,
    chartLayout,
    chartFilterTree,
    timePeriodsByFreq,
    worker
  }
});

export const fetchDatasetSVDatasetError = (statusCode?: number) => ({
  type: DATASET_SV_DATASET_FETCH_ERROR,
  payload: {
    statusCode
  }
});

export const showDatasetSVCriteria = () => ({
  type: DATASET_SV_CRITERIA_SHOW
});

export const hideDatasetSVCriteria = () => ({
  type: DATASET_SV_CRITERIA_HIDE
});

export const hideDatasetSVCriteriaAlert = () => ({
  type: DATASET_SV_CRITERIA_ALERT_HIDE
});

export const hideDatasetSVCriteriaObsCountWarning = () => ({
  type: DATASET_SV_CRITERIA_OBS_COUNT_WARNING_HIDE
});

export const fetchDatasetSVStructureCodelist = (
  nodeId: number,
  nodeCode: string,
  datasetId: string,
  mode: string,
  type: string,
  dimensionId: string,
  criteria: {[key: string]: Criteria},
  freq: string,
  defaultLastNPeriods: number,
  preserveFiltersWithDynamic: boolean
) => {
  let flatCriteria = getCriteriaArrayFromObject(criteria);

  if (mode === CRITERIA_SELECTION_MODE_ALL) {
    return initRequest(
      DATASET_SV_STRUCTURE_CODELIST_FETCH,
      getDatasetStructureCodelistsUrl(nodeId, datasetId, type === CRITERIA_SELECTION_TYPE_FULL),
      undefined,
      undefined,
      t => ({
        onStart: t("scenes.dataset.actions.fetchingCodelists")
      }),
      {
        nodeCode: nodeCode,
        datasetId: datasetId,
        defaultLastNPeriods: defaultLastNPeriods,
        freq: freq,
        mode: mode,
        type: type
      }
    );
  } else if (type === CRITERIA_SELECTION_TYPE_DYNAMIC) {
    return initRequest(
      DATASET_SV_STRUCTURE_CODELIST_FETCH,
      getDatasetStructureDynamicCodelistUrl(nodeId, datasetId, dimensionId),
      RequestMethod.POST,
      flatCriteria,
      t => ({
        onStart: t("scenes.dataset.actions.fetchingCodelist")
      }),
      {
        nodeCode: nodeCode,
        datasetId: datasetId,
        defaultLastNPeriods: defaultLastNPeriods,
        preserveFiltersWithDynamic: preserveFiltersWithDynamic,
        freq: freq,
        mode: mode,
        type: type
      },
      undefined,
      (statusCode: number) => statusCode === 414
    );
  } else {
    return initRequest(
      DATASET_SV_STRUCTURE_CODELIST_FETCH,
      getDatasetStructureCodelistUrl(nodeId, datasetId, dimensionId, type === CRITERIA_SELECTION_TYPE_FULL),
      undefined,
      undefined,
      t => ({
        onStart: t("scenes.dataset.actions.fetchingCodelist")
      }),
      {
        nodeCode: nodeCode,
        datasetId: datasetId,
        defaultLastNPeriods: defaultLastNPeriods,
        freq: freq,
        mode: mode,
        type: type
      }
    );
  }
};

export const setDatasetSVStructureCriteria = (criteria: {[key: string]: Criteria}) => ({
  type: DATASET_SV_STRUCTURE_CRITERIA_SET,
  criteria
});

export const setDatasetSVViewer = (viewerIdx: number) => ({
  type: DATASET_SV_VIEWER_SET,
  viewerIdx
});

export const showDatasetSVLayout = () => ({
  type: DATASET_SV_LAYOUT_SHOW
});

export const hideDatasetSVLayout = () => ({
  type: DATASET_SV_LAYOUT_HIDE
});

export const submitDatasetSVTableLayout = (layout: any) => ({
  type: DATASET_SV_TABLE_LAYOUT_SUBMIT,
  layout
});

export const submitDatasetSVTableFilterTree = (filterTree: any) => ({
  type: DATASET_SV_TABLE_FILTERS_TREE_SUBMIT,
  filterTree
});

export const submitDatasetSVMapLayout = (layout: any) => ({
  type: DATASET_SV_MAP_LAYOUT_SUBMIT,
  layout
});

export const setDatasetSVMapDetailLevel = (detailLevel: number) => ({
  type: DATASET_SV_MAP_DETAIL_LEVEL_SET,
  detailLevel
});

export const submitDatasetSVChartLayout = (layout: any) => ({
  type: DATASET_SV_CHART_LAYOUT_SUBMIT,
  layout
});

export const submitDatasetSVChartFilterTree = (filterTree: any) => ({
  type: DATASET_SV_CHART_FILTERS_TREE_SUBMIT,
  filterTree
});

export const setDatasetSVLabelFormat = (labelFormat: string) => ({
  type: DATASET_SV_LABEL_FORMAT_SET,
  labelFormat
});

export const setDatasetSVVariation = (variation: {[key: string]: boolean}) => ({
  type: DATASET_SV_VARIATION_SET,
  variation
});

export const setDatasetSVTemporalDimOrder = (temporalDimOrder: string) => ({
  type: DATASET_SV_TEMPORAL_DIM_ORDER_SET,
  temporalDimOrder
});

export const setDatasetSVChartSettings = (chartSettings: any) => ({
  type: DATASET_SV_CHART_SETTINGS_SET,
  chartSettings
});

export const setDatasetSVMapSettings = (mapSettings: any) => ({
  type: DATASET_SV_MAP_SETTINGS_SET,
  mapSettings
});

export const submitDatasetSVDownload = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string,
  criteria: {[key: string]: Criteria},
  layout: any,
  format: string,
  extension: string,
  zipped: boolean,
  params: any,
  defaultLanguage: string,
  languages: string[],
  t: any
) => {
  let url, body;

  if (format === DOWNLOAD_FORMAT_EXCEL || format === DOWNLOAD_FORMAT_CSV) {
    url = getDatasetCustomDownloadUrl(nodeId, format, zipped);
    body = getCustomExportRequestBody(datasetId, datasetTitle, criteria, layout, params, defaultLanguage, languages, t);
  } else {
    // @ts-ignore
    const formatParam = downloadFormats()[format]?.param || format;
    url = getDatasetDownloadUrl(nodeId, datasetId, formatParam, zipped);
    body = getCriteriaArrayFromObject(criteria);
  }

  const type = format === DOWNLOAD_FORMAT_EXCEL ? "application/vnd.ms-excel" : "text/plain;charset=utf-8";

  return initRequest(
    DATASET_SV_DOWNLOAD_SUBMIT,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDataset")
    }),
    {
      fileSave: {
        name: `${datasetTitle} (${datasetId})`,
        extension: extension,
        type: type
      }
    },
    undefined,
    (statusCode: number) => statusCode === 406,
    undefined,
    undefined,
    true
  );
};

export const hideDatasetSVDownloadWarning = () => ({
  type: DATASET_SV_DOWNLOAD_WARNING_HIDE
});

export const showDatasetSVViewTemplate = (isView: boolean) => ({
  type: DATASET_SV_VIEW_TEMPLATE_SHOW,
  isView
});

export const hideDatasetSVViewTemplate = (isView: boolean) => ({
  type: DATASET_SV_VIEW_TEMPLATE_HIDE,
  isView
});

export const submitDatasetSVViewTemplate = (nodeId: number, viewTemplate: any, isView: boolean) =>
  initRequest(
    DATASET_SV_VIEW_TEMPLATE_SUBMIT,
    isView ? getCreateViewUrl(nodeId) : getCreateTemplateUrl(nodeId),
    RequestMethod.POST,
    viewTemplate,
    t => ({
      onStart: isView ? t("scenes.dataset.actions.savingView") : t("scenes.dataset.actions.savingTemplate")
    }),
    {
      isView: isView
    },
    undefined,
    (statusCode: number) => statusCode === 409
  );

export const hideDatasetSVViewError = () => ({
  type: DATASET_SV_VIEW_ERROR_HIDE
});

export const hideDatasetSVUnavailableViewWarning = () => ({
  type: DATASET_SV_UNAVAILABLE_VIEW_WARNING_HIDE
});

export const setDatasetSVHtmlGeneratingTime = (time: number) => ({
  type: DATASET_SV_HTML_GENERATING_TIME_SET,
  time
});

export const showDatasetSVSdmxQuery = () => ({
  type: DATASET_SV_SDMX_QUERY_SHOW
});

export const hideDatasetSVSdmxQuery = () => ({
  type: DATASET_SV_SDMX_QUERY_HIDE
});

export const fetchDatasetSVSdmxQuery = (
  nodeId: number,
  datasetId: string,
  criteria: {[key: string]: Criteria},
  datasetTitle: string
) =>
  initRequest(
    DATASET_SV_SDMX_QUERY_FETCH,
    getDatasetSdmxQueryUrl(nodeId, datasetId),
    RequestMethod.POST,
    getCriteriaArrayFromObject(criteria),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDatasetSdmxQuery", {title: datasetTitle})
    })
  );

export const fetchDatasetSVStructureCodelistFull = (
  nodeId: number,
  datasetId: string,
  dimensionId: string,
  missingFilterValueIds: string[]
) =>
  initRequest(
    DATASET_SV_STRUCTURE_CODELIST_FULL_FETCH,
    getDatasetStructureCodelistUrl(nodeId, datasetId, dimensionId, true),
    undefined,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingCodelist")
    }),
    {
      missingFilterValueIds: missingFilterValueIds
    }
  );

export const hideDatasetSVStructureCodelistFull = () => ({
  type: DATASET_SV_STRUCTURE_CODELIST_FULL_HIDE
});

export const setDatasetSVFullScreen = (isFullscreen: boolean) => ({
  type: DATASET_SV_SET_FULLSCREEN,
  isFullscreen
});

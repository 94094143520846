import React, {Fragment, useEffect, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import Call from "../../hocs/call";
import ButtonSelect from "../button-select";
import CustomDialogTitle from "../custom-dialog-title";
import CleanIcon from "../custom-icons/CleanIcon";
import FormLabelWithTooltip from "../form-label-with-tooltip";
import {
  clearCacheDataset,
  clearCacheDatasetStructures,
  deleteDatasetTemplate,
  fetchDatasetCacheInfo,
  updateDatasetCacheInfoTtl
} from "../../state/dataset/datasetActions";
import {secondsToHms} from "../../utils/formatters";
import {canDisplayTemplatesSettingsForm, canManageCache} from "../../utils/user";
import {isValidIntegerInInclusiveRange} from "../../utils/validator";

const styles = theme => ({
  root: {},
  divider: {
    width: "100%",
    color: "gray",
    fontStyle: "italic",
    fontSize: 14
  },
  option: {
    width: "100%"
  },
  caheInfoContainer: {
    "& > *": {
      marginBottom: theme.spacing(2)
    },
    "& > *:last-child": {
      marginBottom: 0
    }
  },
  titleContainer: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center"
  },
  valueContainer: {
    display: "flex",
    alignItems: "center"
  }
});

const mapStateToProps = state => ({
  user: state.user,
  nodeId: state.node?.nodeId,
  datasetId: state.dataset.commons.datasetId,
  datasetCacheInfo: state.dataset.commons.datasetCacheInfo
});

const mapDispatchToProps = dispatch => ({
  onClearCacheDataset: (nodeId, datasetId) => dispatch(clearCacheDataset(nodeId, datasetId)),
  onClearCacheDatasetStructures: (nodeId, datasetId) => dispatch(clearCacheDatasetStructures(nodeId, datasetId)),
  onDeleteDatasetTemplate: (nodeId, templateId) => dispatch(deleteDatasetTemplate(nodeId, templateId)),
  onFetchDatasetCacheInfo: ({nodeId, datasetId}) => dispatch(fetchDatasetCacheInfo(nodeId, datasetId)),
  onUpdateCache: (nodeId, datasetId, ttl) => dispatch(updateDatasetCacheInfoTtl(nodeId, datasetId, ttl))
});

const DatasetViewerTools = ({
  t,
  classes,
  user,
  nodeId,
  templateId,
  datasetId,
  datasetCacheInfo,
  onClearCacheDataset,
  onClearCacheDatasetStructures,
  onDeleteDatasetTemplate,
  onFetchDatasetCacheInfo,
  onUpdateCache
}) => {
  const [isModifyDialogVisible, setModifyDialogVisible] = useState(false);
  const [isTextFieldVisible, setTextFieldVisible] = useState(false);
  const [currentTtl, setCurrentTtl] = useState("");
  const [validTtl, setValidTtl] = useState("");

  const [isDeleteTemplateVisible, setDeleteTemplateVisibility] = useState(false);

  useEffect(() => {
    if (datasetCacheInfo !== null) {
      setValidTtl(datasetCacheInfo.ttl);
    }
  }, [datasetCacheInfo]);

  const canShowManageCache = canManageCache(user, nodeId);
  const canShowManageTemplate = templateId != null && canDisplayTemplatesSettingsForm(user, nodeId);
  const canShowAll = canShowManageCache && canShowManageTemplate;

  const buttonSelectItems = [];

  let ariaLabel = null;
  let tooltip = null;

  if (canShowAll) {
    ariaLabel = t("scenes.dataViewer.datasetViewerTools.action.cacheAndTemplate.label");
    tooltip = t("scenes.dataViewer.datasetViewerTools.action.cacheAndTemplate.tooltip");
  } else if (canShowManageCache) {
    ariaLabel = t("scenes.dataViewer.datasetViewerTools.action.cache.label");
    tooltip = t("scenes.dataViewer.datasetViewerTools.action.cache.tooltip");
  } else if (canShowManageTemplate) {
    ariaLabel = t("scenes.dataViewer.datasetViewerTools.action.template.label");
    tooltip = t("scenes.dataViewer.datasetViewerTools.action.template.tooltip");
  }
  if (canShowManageCache) {
    buttonSelectItems.push(
      <div className={classes.divider} key="cacheTitle">
        {t("scenes.dataViewer.datasetViewerTools.menu.cache.title")}
      </div>
    );
    buttonSelectItems.push(
      <div data-value={"modifyCacheValidity"} className={classes.option} key="modifyCacheValidity">
        {t("scenes.dataViewer.datasetViewerTools.menu.cache.values.modifyValidity")}
      </div>
    );
    buttonSelectItems.push(
      <div data-value={"invalidateCache"} className={classes.option} key="invalidateCache">
        {t("scenes.dataViewer.datasetViewerTools.menu.cache.values.invalidateCache")}
      </div>
    );
    buttonSelectItems.push(
      <div data-value={"invalidateStructuresCache"} className={classes.option} key="invalidateStructuresCache">
        {t("scenes.dataViewer.datasetViewerTools.menu.cache.values.invalidateStructuresCache")}
      </div>
    );
  }

  if (canShowManageTemplate) {
    buttonSelectItems.push(
      <div className={classes.divider} key="templateTitle">
        {t("scenes.dataViewer.datasetViewerTools.menu.template.title")}
      </div>
    );
    buttonSelectItems.push(
      <div data-value={"deleteTemplate"} className={classes.option} key="deleteTemplate">
        {t("scenes.dataViewer.datasetViewerTools.menu.template.values.delete")}
      </div>
    );
  }

  const onShowModifyControl = () => {
    setCurrentTtl(validTtl);
    setTextFieldVisible(true);
  };

  const onHideModifyControl = () => {
    setTextFieldVisible(false);
  };

  const onCloseDialog = () => {
    setTextFieldVisible(false);
    setModifyDialogVisible(false);
  };

  const onOpenDialog = () => {
    setTextFieldVisible(false);
    setModifyDialogVisible(true);
  };

  const onSaveTtl = (nodeId, datasetId, ttl) => {
    if ((ttl || "").length > 0 && !isValidIntegerInInclusiveRange(ttl, -1)) {
      window.error.show(t("scenes.dataViewer.datasetViewerTools.errors.invalidTtl.label"));
      return;
    }
    onUpdateCache(nodeId, datasetId, ttl);
    setValidTtl(ttl);
    setTextFieldVisible(false);
  };

  return (
    buttonSelectItems.length > 0 && (
      <Fragment>
        <ButtonSelect
          icon={<CleanIcon />}
          ariaLabel={ariaLabel}
          tooltip={tooltip}
          color="default"
          onChange={actionId => {
            switch (actionId) {
              case "modifyCacheValidity":
                onOpenDialog();
                break;
              case "invalidateCache":
                onClearCacheDataset(nodeId, datasetId);
                break;
              case "invalidateStructuresCache":
                onClearCacheDatasetStructures(nodeId, datasetId);
                break;
              case "deleteTemplate":
                setDeleteTemplateVisibility(true);
                break;
              default:
                break;
            }
          }}
        >
          {buttonSelectItems}
        </ButtonSelect>

        <Dialog open={isModifyDialogVisible} fullWidth maxWidth="md" onClose={onCloseDialog}>
          <CustomDialogTitle onClose={onCloseDialog}>
            {t("scenes.dataViewer.datasetViewerTools.dialogs.modifyValidity.title")}
          </CustomDialogTitle>
          <DialogContent className={classes.caheInfoContainer}>
            <Call cb={onFetchDatasetCacheInfo} cbParam={{nodeId, datasetId}}>
              {datasetCacheInfo && (
                <Grid container>
                  <Grid item xs={3} className={classes.titleContainer}>
                    {t("scenes.dataViewer.datasetViewerTools.dialogs.modifyValidity.table.dataflowFiles.label")}
                  </Grid>
                  <Grid item xs={3} className={classes.titleContainer}>
                    {t("scenes.dataViewer.datasetViewerTools.dialogs.modifyValidity.table.dataflowSize.label")}
                  </Grid>
                  <Grid item xs={4} className={classes.titleContainer}>
                    <FormLabelWithTooltip
                      tooltip={t(
                        "scenes.dataViewer.datasetViewerTools.dialogs.modifyValidity.table.dataflowValidity.tooltip"
                      )}
                    >
                      {t("scenes.dataViewer.datasetViewerTools.dialogs.modifyValidity.table.dataflowValidity.label")}
                    </FormLabelWithTooltip>
                  </Grid>
                  <Grid item xs={2}></Grid>

                  <Grid item xs={3} className={classes.valueContainer}>
                    {datasetCacheInfo.cachedDataflow}
                  </Grid>
                  <Grid item xs={3} className={classes.valueContainer}>
                    {`${datasetCacheInfo.cacheSize} Kb`}
                  </Grid>
                  <Grid item xs={4} className={classes.valueContainer}>
                    {isTextFieldVisible ? (
                      <TextField
                        hiddenLabel
                        id="dataset_ttl_id"
                        defaultValue={validTtl}
                        onChange={ev => setCurrentTtl(ev.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {t(
                                "scenes.dataViewer.datasetViewerTools.dialogs.modifyValidity.table.dataflowValidity.adornments.seconds"
                              )}
                            </InputAdornment>
                          )
                        }}
                        error={(currentTtl || "").length > 0 && !isValidIntegerInInclusiveRange(currentTtl, -1)}
                        helperText={
                          (currentTtl || "").length > 0 && !isValidIntegerInInclusiveRange(currentTtl, -1)
                            ? t("commons.validation.integerGreaterThanOrEmpty", {number: -1})
                            : null
                        }
                      />
                    ) : (
                      secondsToHms(validTtl, t)
                    )}
                  </Grid>

                  {isTextFieldVisible ? (
                    <Fragment>
                      <Grid item xs={1} className={classes.valueContainer}>
                        <Tooltip title={t("commons.confirm.save")}>
                          <span>
                            <IconButton
                              aria-label={t("commons.confirm.save")}
                              onClick={() => onSaveTtl(nodeId, datasetId, currentTtl)}
                              disabled={
                                (currentTtl || "").length > 0 && !isValidIntegerInInclusiveRange(currentTtl, -1)
                              }
                            >
                              <DoneIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={1} className={classes.valueContainer}>
                        <Tooltip title={t("commons.confirm.cancel")}>
                          <span>
                            <IconButton aria-label={t("commons.confirm.cancel")} onClick={() => onHideModifyControl()}>
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Grid item xs={1} className={classes.valueContainer}>
                      <Tooltip title={t("commons.confirm.edit")}>
                        <span>
                          <IconButton aria-label={t("commons.confirm.edit")} onClick={() => onShowModifyControl()}>
                            <EditIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              )}
            </Call>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseDialog}>{t("commons.confirm.close")}</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isDeleteTemplateVisible} onClose={() => setDeleteTemplateVisibility(false)}>
          <CustomDialogTitle onClose={() => setDeleteTemplateVisibility(false)}>
            {t("scenes.dataViewer.datasetViewerTools.dialogs.deleteTemplate.title")}
          </CustomDialogTitle>
          <DialogActions>
            <Button onClick={() => setDeleteTemplateVisibility(false)}>{t("commons.confirm.cancel")}</Button>
            <Button
              onClick={() => {
                setDeleteTemplateVisibility(false);
                onDeleteDatasetTemplate(nodeId, templateId);
              }}
            >
              {t("commons.confirm.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  );
};

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(DatasetViewerTools);

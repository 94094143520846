export const isValidIntegerInInclusiveRange = (val, min, max) => {
  if (val === null || val === undefined || val === "" || isNaN(val) || !/^[+-]?[0-9]*$/gm.test(val)) {
    return false;
  }

  const int = parseInt(val, 10);
  return !((min !== null && min !== undefined && int < min) || (max !== null && max !== undefined && int > max));
};

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

export const isMultiLanguageNameUnique = (name, names) => {
  const stringList = [];

  names.forEach(name => Object.values(name).forEach(name => stringList.push(name)));

  return !Object.values(name).find(localizedName => stringList.includes(localizedName));
};

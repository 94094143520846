import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const FlagIcon = props => (
  <SvgIcon {...props}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12.36 6l.4 2H18v6h-3.36l-.4-2H7V6h5.36M14 4H5v17h2v-7h5.6l.4 2h7V6h-5.6L14 4z" />
  </SvgIcon>
);

export default FlagIcon;

export const SEARCH_DIALOG_OPEN = "searchDialog/open";
export const SEARCH_DIALOG_CLOSE = "searchDialog/close";

export const openSearchDialog = () => ({
  type: SEARCH_DIALOG_OPEN
});

export const closeSearchDialog = () => ({
  type: SEARCH_DIALOG_CLOSE
});

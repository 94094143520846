import React from "react";
import {DialogActions} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import CustomDialogTitle from "../custom-dialog-title";
import {closeDeleteConflictModal} from "../../state/delete-conflict-modal/deleteConflictModalActions";

const getObjectTranslation = t => ({
  dashboard: t("components.deleteConflictModal.objectType.dashboard"),
  view: t("components.deleteConflictModal.objectType.view"),
  node: t("components.deleteConflictModal.objectType.node")
});

const DeleteConflictModal = ({response, onClose, onForce}) => {
  const {t} = useTranslation();

  return (
    <Dialog open={!!response} maxWidth="xs" fullWidth onClose={onClose}>
      <CustomDialogTitle onClose={onClose}>{t("components.deleteConflictModal.title")}</CustomDialogTitle>
      {response?.usedBy?.length > 0 && (
        <DialogContent>
          {onForce ? (
            <p>{t("components.deleteConflictModal.contentTitleWithForce")}</p>
          ) : (
            <p>{t("components.deleteConflictModal.contentTitle")}</p>
          )}
          <div>
            {response.usedBy.map(({type, title, id}, index) => (
              <ul key={index}>
                {getObjectTranslation(t)[type]} {id} - {title}
              </ul>
            ))}
          </div>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose}>{t("commons.confirm.close")}</Button>
        {onForce && <Button onClick={onForce}>{t("components.deleteConflictModal.force")}</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default connect(
  state => ({
    response: state.deleteConflictModal.response
  }),
  dispatch => ({
    onClose: () => dispatch(closeDeleteConflictModal())
  })
)(DeleteConflictModal);

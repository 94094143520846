/** base map **/

export const MAP_LAYERS_ALL = "ALL";
export const MAP_LAYERS_NONE = "NONE";

export const getMapLayerOptions = t => [
  {
    value: MAP_LAYERS_ALL,
    label: t ? t("components.map.layers.all") : MAP_LAYERS_ALL
  },
  {
    value: MAP_LAYERS_NONE,
    label: t ? t("components.map.layers.none") : MAP_LAYERS_NONE
  }
];

/** classification method **/

export const MAP_CLASSIFICATION_METHOD_EQUAL_INTERVAL = "equal_interval";
export const MAP_CLASSIFICATION_METHOD_NATURAL_BREAKS = "jenks";
export const MAP_CLASSIFICATION_METHOD_QUANTILE = "quantile";
export const MAP_CLASSIFICATION_METHOD_CUSTOM_INTERVALS = "custom_intervals";

export const getMapClassificationMethodOptions = t => [
  {
    value: MAP_CLASSIFICATION_METHOD_EQUAL_INTERVAL,
    label: t ? t("components.map.classificationMethod.equalsInterval") : MAP_CLASSIFICATION_METHOD_EQUAL_INTERVAL
  },
  {
    value: MAP_CLASSIFICATION_METHOD_NATURAL_BREAKS,
    label: t ? t("components.map.classificationMethod.naturalBreaks") : MAP_CLASSIFICATION_METHOD_NATURAL_BREAKS
  },
  {
    value: MAP_CLASSIFICATION_METHOD_QUANTILE,
    label: t ? t("components.map.classificationMethod.quantile") : MAP_CLASSIFICATION_METHOD_QUANTILE
  },
  {
    value: MAP_CLASSIFICATION_METHOD_CUSTOM_INTERVALS,
    label: t ? t("components.map.classificationMethod.customIntervals") : MAP_CLASSIFICATION_METHOD_CUSTOM_INTERVALS
  }
];

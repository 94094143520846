import React, {Fragment, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";
import SaveIcon from "@material-ui/icons/Save";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import Call from "../../../hocs/call";
import {NodeDataAccessibility, NodeVisibility} from "../../../model/IHubMinimalNode.d.ts";
import AttributeList from "../../attribute-list";
import ButtonSelect from "../../button-select";
import CustomDialogTitle from "../../custom-dialog-title";
import AttributeIcon from "../../custom-icons/AttributeIcon";
import DatasetMetadataButton from "../../dataset-metadata-button";
import I18nTextField from "../../i18n-text-field";
import ModulesPlaceholder from "../../modules-placeholder";
import Query from "../../query";
import DatasetViewerTools from "../DatasetViewerTools";
import MultiViewerTemplateBuilder from "../multi-viewer/TemplateBuilder";
import DataViewerTimings from "../Timings";
import {ViewerMode} from "../../../state/dataset/constants";
import {setDatasetViewerMode} from "../../../state/dataset/datasetActions";
import {
  fetchDatasetMVSdmxQuery,
  hideDatasetMVSdmxQuery,
  hideDatasetMVViewError,
  hideDatasetMVViewTemplate,
  showDatasetMVSdmxQuery,
  showDatasetMVViewTemplate,
  submitDatasetMVViewTemplate
} from "../../../state/dataset/multi-viewer/actions";
import {MERGED_DATASETS_TITLE_SEPARATOR} from "../../../utils/constants";
import {CRITERIA_FILTER_TYPE_PERIODS, getCriteriaArrayFromObject} from "../../../utils/criteria";
import {getDatasetAttributeMap, getSeriesAttributeMap} from "../../../utils/dataset";
import {DECIMAL_SEPARATOR_DEFAULT} from "../../../utils/formatters";
import {validateI18nObj} from "../../../utils/i18n";
import {
  canSaveAsView,
  canSaveTemplate,
  canUseDatasetTools,
  canViewTemplateOrAnnotationIcon,
  canViewTimesLog
} from "../../../utils/user";
import {isValidIntegerInInclusiveRange} from "../../../utils/validator";
import {
  getViewTemplateLayoutsFromChartSettings,
  getViewTemplateLayoutsFromMapSettings
} from "../../../utils/viewTemplate";

const $ = window.jQuery;

const styles = theme => ({
  headerTitle: {
    color: theme.palette.primary.main,
    display: "inline-block",
    verticalAlign: "top"
  },
  title: {
    paddingTop: 7,
    minHeight: 48,
    "& > h1:first-child": {
      marginLeft: 0
    },
    "& > h1:last-child": {
      marginRight: 0
    }
  },
  titleText: {
    display: "unset",
    fontSize: 28,
    fontWeight: 300,
    letterSpacing: 0,
    margin: "0 4px"
  },
  titleAction: {
    marginTop: -10
  },
  layoutIcon: {
    width: 48,
    height: 48,
    padding: 12,
    color: "rgba(0, 0, 0, 0.54)"
  },
  queryContainer: {
    "& > *": {
      marginBottom: theme.spacing(2)
    },
    "& > *:last-child": {
      marginBottom: 0
    }
  },
  headerExtraActions: {
    display: "inline-block",
    verticalAlign: "top"
  },
  viewerModeSelect: {
    display: "none",
    width: 48
  }
});

const mapStateToProps = ({app, user, hub, dataset}) => ({
  themeConfig: app.themeConfig,
  defaultLanguage: app.language,
  user: user,
  nodes: hub.nodes,
  dataset: dataset.multiViewer.dataset,
  timeDim: dataset.multiViewer.timeDim,
  isTableVisible: dataset.multiViewer.isTableVisible,
  isMapVisible: dataset.multiViewer.isMapVisible,
  isChartVisible: dataset.multiViewer.isChartVisible,
  chartType: dataset.multiViewer.chartType,
  view: dataset.multiViewer.view,
  template: dataset.multiViewer.template,
  hasViewLayout: dataset.multiViewer.hasViewLayout,
  hasTemplateLayout: dataset.multiViewer.hasTemplateLayout,
  hasAnnotationLayout: dataset.multiViewer.hasAnnotationLayout,
  tableLayout: dataset.multiViewer.tableLayout,
  mapLayout: dataset.multiViewer.mapLayout,
  chartLayout: dataset.multiViewer.chartLayout,
  labelFormat: dataset.multiViewer.labelFormat,
  temporalDimOrder: dataset.multiViewer.temporalDimOrder,
  criteria: dataset.multiViewer.criteria,
  decimalSeparator: dataset.multiViewer.decimalSeparator,
  decimalPlaces: dataset.multiViewer.decimalPlaces,
  tableEmptyChar: dataset.multiViewer.tableEmptyChar,
  chartSettings: dataset.multiViewer.chartSettings,
  mapSettings: dataset.multiViewer.mapSettings,
  timings: dataset.multiViewer.timings,
  isViewVisible: dataset.multiViewer.isViewVisible,
  isViewErrorVisible: dataset.multiViewer.isViewErrorVisible,
  viewErrorMessage: dataset.multiViewer.viewErrorMessage,
  isTemplateVisible: dataset.multiViewer.isTemplateVisible,
  isQueryVisible: dataset.multiViewer.isQueryVisible,
  structureQuery: dataset.multiViewer.structureQuery,
  dataQuery: dataset.multiViewer.dataQuery,
  detailLevel: dataset.multiViewer.detailLevel,
  territories: dataset.multiViewer.territories,
  indicators: dataset.multiViewer.indicators,
  showArithmeticMean: dataset.multiViewer.showArithmeticMean,
  showStandardDeviation: dataset.multiViewer.showStandardDeviation,
  showCoefficientOfVariation: dataset.multiViewer.showCoefficientOfVariation,
  additionalDatasets: dataset.multiViewer.additionalDatasets
});

const mapDispatchToProps = dispatch => ({
  onViewTemplateShow: isView => dispatch(showDatasetMVViewTemplate(isView)),
  onViewTemplateHide: isView => dispatch(hideDatasetMVViewTemplate(isView)),
  onViewTemplateSubmit: (nodeId, viewTemplate, isView) =>
    dispatch(submitDatasetMVViewTemplate(nodeId, viewTemplate, isView)),
  onViewErrorHide: isView => dispatch(hideDatasetMVViewError(isView)),
  onQueryShow: () => dispatch(showDatasetMVSdmxQuery()),
  onQueryHide: () => dispatch(hideDatasetMVSdmxQuery()),
  fetchQuery: ({nodeId, datasetId, criteria, datasetTitle}) =>
    dispatch(fetchDatasetMVSdmxQuery(nodeId, datasetId, criteria, datasetTitle)),
  setViewerMode: viewerMode => dispatch(setDatasetViewerMode(viewerMode))
});

const areNodesAllowedForSaveViewTemplate = (datasets, nodes) => {
  const datasetNodes = datasets.map(({nodeCode}) => nodeCode);
  return (
    nodes
      .filter(({code}) => datasetNodes.includes(code))
      .findIndex(
        ({visible, dataAccess}) => visible === NodeVisibility.No && dataAccess === NodeDataAccessibility.Profiled
      ) === -1
  );
};

function MultiViewerHeader({classes, ...props}) {
  const {
    nodeId,
    nodeCode,
    nodeExtras,
    datasetId,
    datasetTitle,
    viewId,
    attachedFiles,
    onRender,
    datasetMap,

    themeConfig,
    defaultLanguage,
    user,
    nodes,
    dataset,
    timeDim,
    isTableVisible,
    isMapVisible,
    isChartVisible,
    chartType,
    view,
    template,
    hasViewLayout,
    hasTemplateLayout,
    hasAnnotationLayout,
    tableLayout,
    mapLayout,
    chartLayout,
    labelFormat,
    temporalDimOrder,
    criteria,
    decimalSeparator,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    timings,
    isViewVisible,
    isViewErrorVisible,
    viewErrorMessage,
    isTemplateVisible,
    isQueryVisible,
    structureQuery,
    dataQuery,
    detailLevel,
    territories,
    indicators,
    showArithmeticMean,
    showStandardDeviation,
    showCoefficientOfVariation,
    additionalDatasets,

    onViewTemplateShow,
    onViewTemplateHide,
    onViewTemplateSubmit,
    onViewErrorHide,
    onQueryShow,
    onQueryHide,
    fetchQuery,
    setViewerMode
  } = props;
  const showQueryInfo = nodeExtras?.QueryInfo;

  const {t} = useTranslation();

  const [tempView, setTempView] = useState(null);
  const [tempTemplate, setTempTemplate] = useState(null);

  const [datasetAttributeMap, setDatasetAttributeMap] = useState({});
  const [seriesAttributeMap, setSeriesAttributeMap] = useState({});

  const [notes, setNotes] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [isTimingsVisible, setTimingsVisibility] = useState(false);

  const [isUpdatingView, setIsUpdatingView] = useState(false);

  useEffect(() => {
    const headerExtraActionsWidth = $("#data-viewer__header__extra-actions").outerWidth(true);
    $("#data-viewer__header__title-actions-container").width(`calc(100% - ${headerExtraActionsWidth}px)`);
  }, []);

  useEffect(() => {
    const actionsWidth = $("#data-viewer__header__actions").outerWidth(true);
    $("#data-viewer__header__title").width(`calc(100% - ${actionsWidth}px - 8px)`);
  });

  useEffect(() => {
    setDatasetAttributeMap(getDatasetAttributeMap(dataset));
  }, [dataset]);

  useEffect(() => {
    setSeriesAttributeMap(getSeriesAttributeMap(dataset, labelFormat));
  }, [dataset, labelFormat]);

  useEffect(() => {
    if (onRender) {
      onRender();
    }
  });

  const getCompleteViewTemplate = (viewTemplate, isUpdating) => ({
    defaultView: "table",
    enableCriteria: true,
    enableLayout: true,
    enableVariation: false,
    hiddenDimensions: [],
    ...viewTemplate,
    datasetId: datasetId,
    title: isUpdating
      ? viewTemplate.title
      : {
          [defaultLanguage]: [datasetTitle, ...additionalDatasets.map(({datasetTitle}) => datasetTitle)].join(
            ` ${MERGED_DATASETS_TITLE_SEPARATOR} `
          )
        },
    mode: ViewerMode.MultiViewer,
    criteria: getCriteriaArrayFromObject(criteria),
    layouts: {
      ...viewTemplate?.layouts,
      detailLevel: detailLevel,
      mapDetailLevel: undefined,
      territories: territories,
      labelFormat: labelFormat,
      temporalDimOrder: temporalDimOrder,
      isTableVisible: isTableVisible,
      isMapVisible: isMapVisible,
      isChartVisible: isChartVisible,
      chartType: chartType
    },
    decimalSeparator: {
      ...(viewTemplate?.decimalSeparator || {}),
      [defaultLanguage]:
        decimalSeparator !== null && decimalSeparator !== undefined ? decimalSeparator : DECIMAL_SEPARATOR_DEFAULT
    },
    decimalNumber: decimalPlaces === null || decimalPlaces === undefined || decimalPlaces < 0 ? "" : decimalPlaces
  });

  const handleViewOpen = isUpdating => {
    setIsUpdatingView(isUpdating);
    onViewTemplateShow(true);
    const completeView = getCompleteViewTemplate(view, isUpdating);
    setTempView({
      ...completeView,
      type: "view",
      layouts: {
        ...completeView.layouts,
        tableLayout: tableLayout,
        tableEmptyChar: tableLayout ? tableEmptyChar : undefined,
        mapLayout: mapLayout,
        ...(mapLayout ? getViewTemplateLayoutsFromMapSettings(mapSettings) : {}),
        chartLayout: chartLayout,
        ...(chartLayout ? getViewTemplateLayoutsFromChartSettings(chartSettings) : {}),
        showArithmeticMean: showArithmeticMean,
        showStandardDeviation: showStandardDeviation,
        showCoefficientOfVariation: showCoefficientOfVariation
      },
      indicatorsDefinition: JSON.stringify(indicators),
      additionalDatasets: JSON.stringify(additionalDatasets)
    });
  };

  const handleViewClose = () => {
    onViewTemplateHide(true);
    setTempView(null);
  };

  const handleViewSubmit = viewId => {
    onViewTemplateSubmit(
      nodeId,
      {
        ...tempView,
        viewTemplateId: viewId ? Number(viewId) : undefined,
        layouts: JSON.stringify(tempView.layouts)
      },
      true
    );
  };

  const handleTemplateOpen = isUpdating => {
    onViewTemplateShow(false);
    const completeTemplate = getCompleteViewTemplate(template, isUpdating);
    setTempTemplate({
      ...completeTemplate,
      type: "template",
      layouts: {
        ...completeTemplate.layouts,
        tableDefaultLayout: "custom",
        tableLayout: tableLayout,
        tableEmptyChar: tableLayout ? tableEmptyChar : undefined,
        mapLayout: mapLayout,
        ...(mapLayout ? getViewTemplateLayoutsFromMapSettings(mapSettings) : {}),
        chartLayout: chartLayout,
        ...(chartLayout ? getViewTemplateLayoutsFromChartSettings(chartSettings) : {})
      }
    });
  };

  const handleTemplateClose = () => {
    onViewTemplateHide(false);
    setTempTemplate(null);
  };

  const handleTemplateSubmit = () => {
    onViewTemplateSubmit(
      nodeId,
      {
        ...tempTemplate,
        layouts: JSON.stringify({
          ...tempTemplate.layouts,
          tableDefaultLayout: undefined
        }),
        decimalNumber:
          tempTemplate.decimalNumber === null ||
          tempTemplate.decimalNumber === undefined ||
          tempTemplate.decimalNumber === ""
            ? -1
            : tempTemplate.decimalNumber
      },
      false
    );
  };

  const handleNotesShow = notes => {
    setNotes(notes);
  };

  const handleNotesHide = () => {
    setNotes(null);
  };

  const handleAttributesShow = attributes => {
    setAttributes(attributes);
  };

  const handleAttributesHide = () => {
    setAttributes(null);
  };

  const handleTimingsShow = () => {
    setTimingsVisibility(true);
  };

  const handleTimingsHide = () => {
    setTimingsVisibility(false);
  };

  const isValidTemplateDecimalNumber =
    (tempTemplate?.decimalNumber || "").length === 0 ||
    isValidIntegerInInclusiveRange(tempTemplate?.decimalNumber, 0, 20);

  const isValidTemplate =
    validateI18nObj(tempTemplate?.title) &&
    validateI18nObj(tempTemplate?.decimalSeparator) &&
    isValidTemplateDecimalNumber;

  const datasets = [
    {
      nodeCode: nodeCode,
      datasetId: datasetId,
      datasetTitle: datasetTitle
    },
    ...additionalDatasets
  ];

  return (
    <Fragment>
      <div id="data-viewer__header__title-actions-container" className={classes.headerTitle}>
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Grid item id="data-viewer__header__title" className={classes.title}>
            {datasets.map(({datasetId, datasetTitle, nodeCode}, idx, arr) => {
              const notes = datasetMap[datasetId]?.note || "";
              const datasetAttributes = datasetAttributeMap?.[`${nodeCode},${datasetId}`] || [];
              const seriesAttributes = seriesAttributeMap?.[`${nodeCode},${datasetId}`] || [];
              const metadataUrl = datasetMap[datasetId]?.referenceMetadata || null;

              return (
                <Fragment key={idx}>
                  <Typography variant={"h1"} className={classes.titleText}>
                    {datasetTitle}
                  </Typography>
                  {notes.length > 0 && (
                    <Tooltip title={t("scenes.dataViewer.header.action.information.tooltip")}>
                      <IconButton
                        id="dataset-notes-btn"
                        aria-label={t("scenes.dataViewer.header.action.information.label")}
                        onClick={() => handleNotesShow(notes)}
                        className={classes.titleAction}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {datasetAttributes.concat(seriesAttributes).length > 0 && (
                    <Tooltip title={t("scenes.dataViewer.header.action.attributes.tooltip")}>
                      <IconButton
                        id="dataset-attributes-btn"
                        aria-label={t("scenes.dataViewer.header.action.attributes.label")}
                        onClick={() =>
                          handleAttributesShow({
                            datasetAttributes: datasetAttributes,
                            seriesAttributes: seriesAttributes
                          })
                        }
                        className={classes.titleAction}
                      >
                        <AttributeIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {metadataUrl && (
                    <DatasetMetadataButton
                      metadataUrl={metadataUrl}
                      datasetId={datasetId}
                      nodeId={nodeId}
                      externalClassName={classes.titleAction}
                    />
                  )}
                  {idx < arr.length - 1 && (
                    <Typography variant={"h1"} className={classes.titleText}>
                      {MERGED_DATASETS_TITLE_SEPARATOR}
                    </Typography>
                  )}
                </Fragment>
              );
            })}
          </Grid>
          <Grid item id="data-viewer__header__actions">
            <Grid container alignItems="center">
              {(() => {
                if (canViewTemplateOrAnnotationIcon(user, nodeId)) {
                  let title = null;
                  if (hasViewLayout) {
                    title = t("scenes.dataViewer.header.action.hasViewLayout.tooltip");
                  } else if (hasTemplateLayout) {
                    title = t("scenes.dataViewer.header.action.hasTemplateLayout.tooltip");
                  } else if (hasAnnotationLayout) {
                    title = t("scenes.dataViewer.header.action.hasAnnotationLayout.tooltip");
                  }

                  if (title) {
                    return (
                      <Grid item id="dataset-layout-info-btn" className={classes.layoutIcon}>
                        <Tooltip title={title}>
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                    );
                  }
                }
              })()}
              {canViewTimesLog(user, nodeId) && (
                <Grid item id="dataset-times-log-btn">
                  <Tooltip title={t("scenes.dataViewer.header.action.timesLog.tooltip")}>
                    <div>
                      <IconButton
                        aria-label={t("scenes.dataViewer.header.action.timesLog.label")}
                        onClick={handleTimingsShow}
                        disabled={timings === null}
                      >
                        <AccessTimeIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              )}
              {additionalDatasets.length === 0 && canUseDatasetTools(user) && (
                <Grid item id="dataset-cache-template-btn">
                  <DatasetViewerTools templateId={template?.viewTemplateId || null} />
                </Grid>
              )}
              {(canSaveAsView(user) || canSaveTemplate(user, nodeId)) &&
                areNodesAllowedForSaveViewTemplate(datasets, nodes) && (
                  <Grid item id="dataset-save-btn">
                    <ButtonSelect
                      icon={<SaveIcon />}
                      ariaLabel={t("scenes.dataViewer.header.action.save.label")}
                      tooltip={t("scenes.dataViewer.header.action.save.tooltip")}
                      color="default"
                      disabled={!tableLayout && !chartLayout && !mapLayout}
                    >
                      {canSaveAsView(user) && (
                        <div onClick={() => handleViewOpen(false)}>
                          {t("scenes.dataViewer.header.action.save.values.createView")}
                        </div>
                      )}
                      {canSaveAsView(user) && hasViewLayout && viewId && (
                        <div onClick={() => handleViewOpen(true)}>
                          {t("scenes.dataViewer.header.action.save.values.updateView")}
                        </div>
                      )}
                      {indicators.length === 0 && additionalDatasets.length === 0 && canSaveTemplate(user, nodeId) && (
                        <div onClick={() => handleTemplateOpen(hasTemplateLayout)}>
                          {hasTemplateLayout
                            ? t("scenes.dataViewer.header.action.save.values.updateTemplate")
                            : t("scenes.dataViewer.header.action.save.values.createTemplate")}
                        </div>
                      )}
                    </ButtonSelect>
                  </Grid>
                )}
              <ModulesPlaceholder id="data-viewer-header-button" />
              {(attachedFiles || []).length > 0 && (
                <Grid item id="dataset-attachments-btn">
                  <ButtonSelect
                    icon={<AttachFileIcon />}
                    ariaLabel={t("scenes.dataViewer.header.action.attachments.label")}
                    tooltip={t("scenes.dataViewer.header.action.attachments.tooltip")}
                    color="default"
                    onChange={({attachedFile}) => window.open(attachedFile.url)}
                  >
                    {attachedFiles.map((attachedFile, idx) => {
                      const fileName = attachedFile.url.split("/").pop();
                      return (
                        <div key={idx} data-value={{attachedFile}}>
                          {`${fileName} (${attachedFile.format})`}
                        </div>
                      );
                    })}
                  </ButtonSelect>
                </Grid>
              )}
              {showQueryInfo && (
                <Grid item id="dataset-query-btn">
                  <Tooltip title={t("scenes.dataViewer.header.action.query.tooltip")}>
                    <div>
                      <IconButton aria-label={t("scenes.dataViewer.header.action.query.label")} onClick={onQueryShow}>
                        <DeveloperModeIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div id="data-viewer__header__extra-actions" className={classes.headerExtraActions}>
        {themeConfig.isMultiViewer && (
          <div id="dataset-viewer-mode-btn" className={classes.viewerModeSelect}>
            <ButtonSelect
              icon={<HelpIcon />}
              ariaLabel={t("scenes.dataViewer.header.action.viewerMode.label")}
              tooltip={t("scenes.dataViewer.header.action.viewerMode.tooltip")}
              color="default"
              onChange={({mode}) => setViewerMode(mode)}
            >
              <div data-value={{mode: ViewerMode.SingleViewer}}>{ViewerMode.SingleViewer}</div>
              <div data-value={{mode: ViewerMode.MultiViewer}}>{ViewerMode.MultiViewer}</div>
            </ButtonSelect>
          </div>
        )}
      </div>

      <Dialog open={attributes !== null} fullWidth maxWidth="md" onClose={handleAttributesHide}>
        <CustomDialogTitle onClose={handleAttributesHide}>
          {t("scenes.dataViewer.header.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <AttributeList
            datasetAttributes={attributes?.datasetAttributes || []}
            seriesAttributes={attributes?.seriesAttributes || []}
            labelFormat={labelFormat}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAttributesHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isTimingsVisible} onClose={handleTimingsHide}>
        <DialogContent style={{width: 400}}>
          <DataViewerTimings timings={timings} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleTimingsHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isViewVisible} maxWidth="sm" fullWidth onClose={handleViewClose}>
        <CustomDialogTitle onClose={handleViewClose}>
          {isUpdatingView
            ? t("scenes.dataViewer.header.dialogs.view.title.update")
            : t("scenes.dataViewer.header.dialogs.view.title.create")}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {criteria?.[timeDim]?.type === CRITERIA_FILTER_TYPE_PERIODS && (
              <Grid item xs={12}>
                <Alert severity="warning">{t("scenes.dataViewer.warnings.viewTemplateLastNPeriods.label")}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <I18nTextField
                  label={t("scenes.dataViewer.header.dialogs.view.form.title.label")}
                  required
                  variant="outlined"
                  value={tempView?.title || ""}
                  onChange={value => setTempView({...tempView, title: value})}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose}>{t("commons.confirm.cancel")}</Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => handleViewSubmit(isUpdatingView ? viewId : null)}
            disabled={!tempView || !validateI18nObj(tempView.title)}
          >
            {t("commons.confirm.save")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isViewErrorVisible} maxWidth="md" onClose={onViewErrorHide}>
        <CustomDialogTitle onClose={onViewErrorHide}>
          {t("scenes.dataViewer.header.dialogs.duplicateViewError.title")}
        </CustomDialogTitle>
        <DialogContent>
          {viewErrorMessage && (
            <Fragment>
              {t("scenes.dataViewer.header.dialogs.duplicateViewError.content") + ": "}
              <b>{Object.keys(viewErrorMessage).join(", ")}</b>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onViewErrorHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isTemplateVisible} maxWidth="sm" fullWidth onClose={handleTemplateClose}>
        <CustomDialogTitle onClose={handleTemplateClose}>
          {hasTemplateLayout
            ? t("scenes.dataViewer.header.dialogs.template.title.update")
            : t("scenes.dataViewer.header.dialogs.template.title.create")}
        </CustomDialogTitle>
        <DialogContent>
          <MultiViewerTemplateBuilder
            template={tempTemplate}
            onChange={setTempTemplate}
            criteria={criteria}
            timeDim={timeDim}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTemplateClose}>{t("commons.confirm.cancel")}</Button>
          <Button color="primary" onClick={handleTemplateSubmit} disabled={!isValidTemplate}>
            {t("commons.confirm.save")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={notes !== null} fullWidth maxWidth="md" onClose={handleNotesHide}>
        <CustomDialogTitle onClose={handleNotesHide}>
          {t("scenes.dataViewer.header.dialogs.notes.title")}
        </CustomDialogTitle>
        <DialogContent>{notes}</DialogContent>
        <DialogActions>
          <Button onClick={handleNotesHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isQueryVisible} fullWidth maxWidth="md" onClose={onQueryHide}>
        <CustomDialogTitle onClose={onQueryHide}>{t("scenes.dataViewer.header.dialogs.query.title")}</CustomDialogTitle>
        <DialogContent className={classes.queryContainer}>
          <Call
            cb={fetchQuery}
            cbParam={{nodeId, datasetId, criteria, datasetTitle}}
            disabled={structureQuery !== null && dataQuery !== null}
          >
            {structureQuery && (
              <Query
                title={t("scenes.dataViewer.header.dialogs.query.content.structureQuery.title")}
                query={structureQuery}
              />
            )}
            {dataQuery && (
              <Query title={t("scenes.dataViewer.header.dialogs.query.content.dataQuery.title")} query={dataQuery} />
            )}
          </Call>
        </DialogContent>
        <DialogActions>
          <Button onClick={onQueryHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(MultiViewerHeader);

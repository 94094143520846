import React, {Fragment} from "react";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomDatePicker from "../custom-date-picker";
import CustomEmpty from "../custom-empty";
import {
  CRITERIA_FILTER_TYPE_EMPTY,
  CRITERIA_FILTER_TYPE_PERIODS,
  CRITERIA_FILTER_TYPE_RANGE
} from "../../utils/criteria";
import {getPeriodsFromYear} from "../../utils/timePeriodAndFreq";
import {canSaveAsView} from "../../utils/user";
import {isValidIntegerInInclusiveRange} from "../../utils/validator";

const styles = theme => ({
  root: {},
  selectContainer: {},
  formControl: {
    width: "100%"
  },
  timePeriodInput: {
    width: "100%"
  },
  alert: {
    padding: "0 !important"
  },
  lastPeriodsLabel: {
    height: 48,
    lineHeight: "48px"
  },
  lastPeriodsInput: {
    "& input": {
      height: 20,
      padding: 14
    }
  },
  lastPeriodsMenu: {
    maxHeight: 300
  }
});

const getYearsArray = (startYear, endYear) => {
  const res = [];

  if (startYear && endYear) {
    const startYearNumeric = Number(startYear);
    const endYearNumeric = Number(endYear);

    for (let i = startYearNumeric; i <= endYearNumeric; i++) {
      res.push(i + "");
    }
  }

  return res;
};

const getPeriod = (freq, date) => {
  if (freq && date) {
    const year = date.slice(0, 4);

    if (getPeriodsFromYear(year)[freq]) {
      const dateMoment = moment(date, "YYYY-MM-DD");

      return getPeriodsFromYear(year)[freq].values.find(period => {
        const start = moment(getPeriodsFromYear(year)[freq][period].start, "YYYY-MM-DD");
        const end = moment(getPeriodsFromYear(year)[freq][period].end, "YYYY-MM-DD");

        return dateMoment.isBetween(start, end, null, "[]");
      });
    }
  }

  return null;
};

const getNewTimePeriodFromYear = (timePeriod, date, year, minYear, maxYear, period, minPeriod, maxPeriod, isFrom) => {
  let newDate;

  if (isFrom) {
    if (year === minYear && period && minPeriod) {
      if (moment(year + period, "YYYY-MM-DD").isSameOrAfter(moment(timePeriod.minDate, "YYYY-MM-DD"))) {
        newDate = getPeriodsFromYear(year)[timePeriod.freq][period].start;
      } else {
        newDate = getPeriodsFromYear(year)[timePeriod.freq][minPeriod].start;
      }
    } else if (year === maxYear && period && maxPeriod) {
      if (moment(year + period, "YYYY-MM-DD").isSameOrBefore(moment(timePeriod.maxDate, "YYYY-MM-DD"))) {
        newDate = getPeriodsFromYear(year)[timePeriod.freq][period].start;
      } else {
        newDate = getPeriodsFromYear(year)[timePeriod.freq][maxPeriod].start;
      }
    } else {
      newDate = period ? getPeriodsFromYear(year)[timePeriod.freq][period].start : year + "-01-01";
    }
  } else {
    if (year === minYear && period && minPeriod) {
      if (period && moment(year + period, "YYYY-MM-DD").isSameOrAfter(moment(timePeriod.minDate, "YYYY-MM-DD"))) {
        newDate = getPeriodsFromYear(year)[timePeriod.freq][period].end;
      } else {
        newDate = getPeriodsFromYear(year)[timePeriod.freq][minPeriod].end;
      }
    } else if (year === maxYear && period && maxPeriod) {
      if (period && moment(year + period, "YYYY-MM-DD").isSameOrBefore(moment(timePeriod.maxDate, "YYYY-MM-DD"))) {
        newDate = getPeriodsFromYear(year)[timePeriod.freq][period].end;
      } else {
        newDate = getPeriodsFromYear(year)[timePeriod.freq][maxPeriod].end;
      }
    } else {
      newDate = period ? getPeriodsFromYear(year)[timePeriod.freq][period].end : year + "-12-31";
    }
  }

  return {
    ...timePeriod,
    fromDate: isFrom ? newDate : timePeriod.fromDate,
    toDate: !isFrom ? newDate : timePeriod.toDate
  };
};

const handleSetTimePeriodRange = (newTimePeriod, onSetTimePeriod, setCriteriaValidity) => {
  onSetTimePeriod(newTimePeriod);
  setCriteriaValidity(
    moment(newTimePeriod.fromDate, "YYYY-MM-DD").isSameOrBefore(moment(newTimePeriod.toDate, "YYYY-MM-DD"))
  );
};

const handleSetTimePeriodPeriods = (newTimePeriod, onSetTimePeriod, setCriteriaValidity) => {
  onSetTimePeriod(newTimePeriod);
  setCriteriaValidity(isValidIntegerInInclusiveRange(newTimePeriod.periods, 1));
};

const TimePeriod = ({t, classes, user, timePeriod, freqDim, onSetTimePeriod, isCriteriaValid, setCriteriaValidity}) => {
  const minYear = timePeriod.minDate ? moment(timePeriod.minDate).format("YYYY") : null;
  const maxYear = timePeriod.maxDate ? moment(timePeriod.maxDate).format("YYYY") : null;
  const fromYear = timePeriod.fromDate ? moment(timePeriod.fromDate).format("YYYY") : null;
  const toYear = timePeriod.toDate ? moment(timePeriod.toDate).format("YYYY") : null;

  const minPeriod = getPeriod(timePeriod.freq, timePeriod.minDate);
  const maxPeriod = getPeriod(timePeriod.freq, timePeriod.maxDate);
  const fromPeriod = getPeriod(timePeriod.freq, timePeriod.fromDate);
  const toPeriod = getPeriod(timePeriod.freq, timePeriod.toDate);

  const fromPeriods = getPeriodsFromYear(fromYear)[timePeriod.freq]
    ? getPeriodsFromYear(fromYear)[timePeriod.freq].values.slice(
        fromYear === minYear ? getPeriodsFromYear(fromYear)[timePeriod.freq].values.indexOf(minPeriod) : 0,
        fromYear === maxYear ? getPeriodsFromYear(fromYear)[timePeriod.freq].values.indexOf(maxPeriod) + 1 : undefined
      )
    : [];
  const toPeriods = getPeriodsFromYear(toYear)[timePeriod.freq]
    ? getPeriodsFromYear(toYear)[timePeriod.freq].values.slice(
        toYear === minYear ? getPeriodsFromYear(toYear)[timePeriod.freq].values.indexOf(minPeriod) : 0,
        toYear === maxYear ? getPeriodsFromYear(toYear)[timePeriod.freq].values.indexOf(maxPeriod) + 1 : undefined
      )
    : [];

  return (
    <Fragment>
      <Grid container spacing={3} style={{marginTop: 0}}>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
            <Grid item style={{fontSize: 16}} id="time-period__type-selector-label">
              {t("components.criteria.timePeriod.typeSelector.label") + ":"}
            </Grid>
            <Grid item>
              <FormControl fullWidth className={classes.field}>
                <TextField
                  select
                  value={timePeriod.selectorType}
                  onChange={ev => onSetTimePeriod({...timePeriod, selectorType: ev.target.value})}
                  SelectProps={{
                    SelectDisplayProps: {
                      "aria-haspopup": true,
                      "aria-labelledby": "time-period__type-selector-label"
                    }
                  }}
                >
                  {canSaveAsView(user) && (
                    <MenuItem key={CRITERIA_FILTER_TYPE_EMPTY} value={CRITERIA_FILTER_TYPE_EMPTY}>
                      {t("components.criteria.timePeriod.typeSelector.value.fullRange")}
                    </MenuItem>
                  )}
                  <MenuItem key={CRITERIA_FILTER_TYPE_RANGE} value={CRITERIA_FILTER_TYPE_RANGE}>
                    {t("components.criteria.timePeriod.typeSelector.value.customRange")}
                  </MenuItem>
                  {freqDim !== null && (
                    <MenuItem key={CRITERIA_FILTER_TYPE_PERIODS} value={CRITERIA_FILTER_TYPE_PERIODS}>
                      {t("components.criteria.timePeriod.typeSelector.value.onlyLastPeriods")}
                    </MenuItem>
                  )}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{marginTop: 16}}>
          {(() => {
            if (timePeriod.selectorType === CRITERIA_FILTER_TYPE_RANGE) {
              if (timePeriod.freq !== null) {
                return (
                  <Grid container spacing={4}>
                    {!isCriteriaValid && (
                      <Grid item xs={12} className={classes.alert}>
                        <Alert severity="warning">{t("components.criteria.timePeriod.alert.notValidRange")}</Alert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Grid container spacing={2} className={classes.selectContainer}>
                        <Grid item xs={12}>
                          {t("components.criteria.timePeriod.customRange.start")}
                        </Grid>
                        <Grid item xs={getPeriodsFromYear(fromYear)[timePeriod.freq] !== undefined ? 6 : 12}>
                          {fromYear && minYear && toYear && (
                            <FormControl className={classes.formControl}>
                              <InputLabel id="time-period__custom-range__start-year-label">
                                {t("components.criteria.timePeriod.customRange.year")}
                              </InputLabel>
                              <Select
                                value={fromYear}
                                onChange={ev => {
                                  const year = ev.target.value;
                                  const newTimePeriod = getNewTimePeriodFromYear(
                                    timePeriod,
                                    timePeriod.fromDate,
                                    year,
                                    minYear,
                                    maxYear,
                                    fromPeriod,
                                    minPeriod,
                                    maxPeriod,
                                    true
                                  );
                                  handleSetTimePeriodRange(newTimePeriod, onSetTimePeriod, setCriteriaValidity);
                                }}
                                SelectDisplayProps={{
                                  "aria-haspopup": true,
                                  "aria-labelledby": "time-period__custom-range__start-year-label"
                                }}
                              >
                                {getYearsArray(minYear, maxYear).map((year, idx) => (
                                  <MenuItem key={idx} value={year}>
                                    {year}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        </Grid>
                        {fromYear &&
                          getPeriodsFromYear(fromYear)[timePeriod.freq] !== undefined &&
                          fromPeriods &&
                          fromPeriod && (
                            <Grid item xs={6}>
                              <FormControl className={classes.formControl}>
                                <InputLabel id="time-period__custom-range__start-period-label">
                                  {t("components.criteria.timePeriod.customRange.period")}
                                </InputLabel>
                                <Select
                                  value={fromPeriod}
                                  onChange={ev => {
                                    const period = ev.target.value;
                                    const newTimePeriod = _.cloneDeep(timePeriod);
                                    newTimePeriod.fromDate =
                                      getPeriodsFromYear(fromYear)[timePeriod.freq][period].start;
                                    handleSetTimePeriodRange(newTimePeriod, onSetTimePeriod, setCriteriaValidity);
                                  }}
                                  SelectDisplayProps={{
                                    "aria-haspopup": true,
                                    "aria-labelledby": "time-period__custom-range__start-period-label"
                                  }}
                                >
                                  {fromPeriods.map((period, idx) => (
                                    <MenuItem key={idx} value={period}>
                                      {period}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} className={classes.selectContainer}>
                        <Grid item xs={12}>
                          {t("components.criteria.timePeriod.customRange.end")}
                        </Grid>
                        <Grid item xs={getPeriodsFromYear(toYear)[timePeriod.freq] !== undefined ? 6 : 12}>
                          {toYear && fromYear && maxYear && (
                            <FormControl className={classes.formControl}>
                              <InputLabel id="time-period__custom-range__end-year-label">
                                {t("components.criteria.timePeriod.customRange.year")}
                              </InputLabel>
                              <Select
                                value={toYear}
                                onChange={ev => {
                                  const year = ev.target.value;
                                  const newTimePeriod = getNewTimePeriodFromYear(
                                    timePeriod,
                                    timePeriod.toDate,
                                    year,
                                    minYear,
                                    maxYear,
                                    toPeriod,
                                    minPeriod,
                                    maxPeriod,
                                    false
                                  );
                                  handleSetTimePeriodRange(newTimePeriod, onSetTimePeriod, setCriteriaValidity);
                                }}
                                SelectDisplayProps={{
                                  "aria-haspopup": true,
                                  "aria-labelledby": "time-period__custom-range__end-year-label"
                                }}
                              >
                                {getYearsArray(minYear, maxYear).map((year, idx) => (
                                  <MenuItem key={idx} value={year}>
                                    {year}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        </Grid>
                        {toYear &&
                          getPeriodsFromYear(toYear)[timePeriod.freq] !== undefined &&
                          toPeriods &&
                          toPeriod && (
                            <Grid item xs={6}>
                              <FormControl className={classes.formControl}>
                                <InputLabel id="time-period__custom-range__end-period-label">
                                  {t("components.criteria.timePeriod.customRange.period")}
                                </InputLabel>
                                <Select
                                  value={toPeriods.includes(toPeriod) ? toPeriod : toPeriods[toPeriods.length - 1]}
                                  onChange={ev => {
                                    const period = ev.target.value;
                                    const newTimePeriod = _.cloneDeep(timePeriod);
                                    newTimePeriod.toDate = getPeriodsFromYear(toYear)[timePeriod.freq][period].end;
                                    handleSetTimePeriodRange(newTimePeriod, onSetTimePeriod, setCriteriaValidity);
                                  }}
                                  SelectDisplayProps={{
                                    "aria-haspopup": true,
                                    "aria-labelledby": "time-period__custom-range__end-period-label"
                                  }}
                                >
                                  {toPeriods.map((period, idx) => (
                                    <MenuItem key={idx} value={period}>
                                      {period}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              } else {
                return (
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <CustomDatePicker
                        label={t("components.criteria.timePeriod.customRange.from")}
                        minDate={moment(timePeriod.minDate)}
                        maxDate={moment(timePeriod.toDate)}
                        value={moment(timePeriod.fromDate)}
                        onChange={date =>
                          onSetTimePeriod({
                            ...timePeriod,
                            fromDate: date ? moment(date).format("YYYY-MM-DD") : timePeriod.minDate
                          })
                        }
                        className={classes.timePeriodInput}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomDatePicker
                        label={t("components.criteria.timePeriod.customRange.to")}
                        minDate={moment(timePeriod.fromDate)}
                        maxDate={moment(timePeriod.maxDate)}
                        value={moment(timePeriod.toDate)}
                        onChange={date =>
                          onSetTimePeriod({
                            ...timePeriod,
                            toDate: date ? moment(date).format("YYYY-MM-DD") : timePeriod.maxDate
                          })
                        }
                        className={classes.timePeriodInput}
                      />
                    </Grid>
                  </Grid>
                );
              }
            } else if (timePeriod.selectorType === CRITERIA_FILTER_TYPE_PERIODS) {
              return (
                <Grid container spacing={2} justifyContent="center" alignItems={"flex-start"}>
                  <Grid item>
                    <TextField
                      value={timePeriod.periods !== null && timePeriod.periods !== undefined ? timePeriod.periods : ""}
                      type="number"
                      variant="outlined"
                      className={classes.lastPeriodsInput}
                      style={{width: 240}}
                      onChange={({target}) => {
                        const newTimePeriod = _.cloneDeep(timePeriod);
                        newTimePeriod.periods = target.value !== "" ? Number(target.value) : null;
                        handleSetTimePeriodPeriods(newTimePeriod, onSetTimePeriod, setCriteriaValidity);
                      }}
                      error={!isValidIntegerInInclusiveRange(timePeriod.periods, 1)}
                      helperText={
                        !isValidIntegerInInclusiveRange(timePeriod.periods, 1)
                          ? t("components.criteria.timePeriod.selectLastPeriods.helperText")
                          : null
                      }
                      inputProps={{
                        "aria-labelledby": "time-period__last-period-selector-label"
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <div className={classes.lastPeriodsLabel} id="time-period__last-period-selector-label">
                      {t("components.criteria.timePeriod.selectLastPeriods.label")}
                    </div>
                  </Grid>
                </Grid>
              );
            } else {
              return <CustomEmpty text={t("components.criteria.timePeriod.fullRange.label")} style={{height: 64}} />;
            }
          })()}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(state => ({
    user: state.user
  }))
)(TimePeriod);

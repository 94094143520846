import {
  clearDatasetCacheUrl,
  getArtefactCacheClearUrl,
  getDatasetCacheInfoUrl,
  getDatasetCacheUpdateUrl,
  getDatasetStructureUrl,
  getDeleteTemplateUrl
} from "../../serverApi/urls";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";

export const DATASET_VIEWER_MODE_SET = "dataset/viewer/mode/set";
export const DATASET_STATE_RESET = "dataset/state/reset";
export const DATASET_STRUCTURE_FETCH_ENABLE = "dataset/structure/fetch/enable";
export const DATASET_STRUCTURE_FETCH = "dataset/structure/fetch";

export const DATASET_CACHE_INFO_FETCH = "dataset/cacheInfo/fetch";
export const DATASET_CACHE_INFO_TTL_UPDATE = "dataset/cacheInfo/ttl/update";
export const DATASET_CACHE_CLEAR = "dataset/cache/clear";
export const DATASET_STRUCTURES_CACHE_CLEAR = "datasetStructures/cache/clear";
export const DATASET_DELETE_TEMPLATE = "dataset/template/delete";

export const setDatasetViewerMode = (viewerMode: string) => ({
  type: DATASET_VIEWER_MODE_SET,
  viewerMode
});

export const resetDatasetState = () => ({
  type: DATASET_STATE_RESET
});

export const enableDatasetStructureFetch = (
  nodeCode: string,
  categoryPath: string[],
  datasetId: string,
  viewId: string
) => ({
  type: DATASET_STRUCTURE_FETCH_ENABLE,
  payload: {
    datasetId: datasetId,
    nodeCode: nodeCode,
    categoryPath: categoryPath,
    viewId: viewId
  }
});

export const fetchDatasetStructure = (nodeId: number, datasetId: string, viewId: string, isMultiViewerTheme: boolean) =>
  initRequest(
    DATASET_STRUCTURE_FETCH,
    getDatasetStructureUrl(nodeId, datasetId, viewId),
    RequestMethod.GET,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDatasetStructure")
    }),
    {
      nodeId: nodeId,
      datasetId: datasetId,
      viewId: viewId,
      isMultiViewerTheme: isMultiViewerTheme
    }
  );

export const fetchDatasetCacheInfo = (nodeId: number, datasetId: string) =>
  initRequest(DATASET_CACHE_INFO_FETCH, getDatasetCacheInfoUrl(nodeId, datasetId), RequestMethod.GET, undefined, t => ({
    onStart: t("scenes.dataset.actions.fetchingDatasetCacheInfo")
  }));

export const updateDatasetCacheInfoTtl = (nodeId: number, datasetId: string, ttl: number) =>
  initRequest(
    DATASET_CACHE_INFO_TTL_UPDATE,
    getDatasetCacheUpdateUrl(nodeId, datasetId),
    RequestMethod.PUT,
    {ttl: ttl},
    t => ({
      onStart: t("scenes.nodesSettings.cacheSettings.messages.updateDataflowCache.start")
    }),
    {
      ttl: ttl,
      nodeId: nodeId,
      datasetId: datasetId
    }
  );

export const clearCacheDataset = (nodeId: number, datasetId: string) =>
  initRequest(DATASET_CACHE_CLEAR, clearDatasetCacheUrl(nodeId, datasetId), RequestMethod.POST, undefined, t => ({
    onStart: t("scenes.dataset.actions.clearCache")
  }));

export const clearCacheDatasetStructures = (nodeId: number, datasetId: string) =>
  initRequest(
    DATASET_STRUCTURES_CACHE_CLEAR,
    getArtefactCacheClearUrl(nodeId, "Dataflow", datasetId, "Descendants"),
    RequestMethod.POST,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.clearStructuresCache")
    })
  );

export const deleteDatasetTemplate = (nodeId: number, id: number) =>
  initRequest(
    DATASET_DELETE_TEMPLATE,
    getDeleteTemplateUrl(nodeId, id),
    RequestMethod.DELETE,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.deletetemplate")
    }),
    {
      nodeId
    }
  );

import React, {Fragment, useEffect} from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import StorageIcon from "@material-ui/icons/Storage";
import {useTranslation} from "react-i18next";
import CatalogInfoButton from "../catalog-info-button";
import CustomLink from "../custom-link";
import DatasetAttachments from "../dataset-attachments";
import DatasetMetadataButton from "../dataset-metadata-button";
import SanitizedHTML from "../sanitized-html";

const $ = window.jQuery;

const styles = () => ({
  dataset: {
    display: "flex",
    alignItems: "center"
  },
  actions: {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    "& > button": {
      padding: 8
    }
  },
  description: {
    width: "100%",
    padding: "16px !important"
  },
  descriptionTextWrapper: {
    fontSize: 16
  },
  descriptionTextWrapperCollapsed: {
    maxHeight: 48,
    overflow: "hidden"
  },
  descriptionTextWrapperExpanded: {
    minHeight: 48
  },
  descriptionText: {
    "& > p": {
      margin: 0
    }
  },
  descriptionIcon: {
    display: "none",
    justifyContent: "flex-end"
  }
});

// based on https://stackoverflow.com/a/7616484
const getIdFromDatasetIdentifier = identifier => {
  if (!identifier) {
    return undefined;
  }

  let hash = 0,
    i,
    chr;
  if (identifier.length === 0) return hash;
  for (i = 0; i < identifier.length; i++) {
    chr = identifier.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // convert to 32bit integer
  }
  return "id__" + hash;
};

const handleStyle = (dataset, isExpanded) => {
  const textHeight = $(`#${getIdFromDatasetIdentifier(dataset.identifier)}__description__text`).outerHeight() || 0;
  const textWrapperHeight =
    $(`#${getIdFromDatasetIdentifier(dataset.identifier)}__description__text-wrapper`).outerHeight() || 0;
  $(`#${getIdFromDatasetIdentifier(dataset.identifier)}__description__icon`).css({
    display: textHeight > textWrapperHeight || isExpanded ? "flex" : "none"
  });
};

const DatasetCard = ({
  node,
  dataset,
  classes,
  isExpanded,
  to,
  onExpand,
  onCollapse,
  onClick,
  xs,
  md,
  isMultiCategorized
}) => {
  const {t} = useTranslation();

  useEffect(() => {
    const f = () => handleStyle(dataset, isExpanded);
    f();
    window.addEventListener("resize", f);
    return () => window.removeEventListener("resize", f);
  }, [dataset, isExpanded]);

  return (
    <Grid item xs={xs} md={md} id={getIdFromDatasetIdentifier(dataset.identifier)}>
      <Card>
        <div className={classes.dataset} style={{width: "100%"}}>
          <CustomLink
            to={to}
            onClick={onClick}
            text={
              <Fragment>
                {dataset.title}
                {isMultiCategorized && (
                  <Tooltip title={t("components.datasetCard.isMultiCategorized.title")}>
                    <InfoIcon fontSize="small" style={{margin: "0 0 -4px 8px"}} />
                  </Tooltip>
                )}
              </Fragment>
            }
            icon={<StorageIcon fontSize="small" />}
            textStyle={{
              fontSize: 18,
              minHeight: 48,
              padding: "16px 16px 0 16px"
            }}
            subText={dataset.source}
            subTextStyle={{
              fontSize: 14,
              fontWeight: "normal",
              color: "rgba(0, 0, 0, 0.54)",
              padding: "0 16px 16px 16px"
            }}
            disabled={dataset.catalogType === "ONLY_FILE"}
          />
          {(dataset.attachedDataFiles || dataset.note || dataset.referenceMetadata) && (
            <div className={classes.actions}>
              <DatasetAttachments title={dataset.title} attachedDataFiles={dataset.attachedDataFiles} />
              {dataset.note && <CatalogInfoButton note={dataset.note} />}
              {dataset.referenceMetadata && (
                <DatasetMetadataButton
                  metadataUrl={dataset.referenceMetadata}
                  datasetId={dataset.identifier}
                  nodeId={node.nodeId}
                  iconSize="small"
                />
              )}
            </div>
          )}
        </div>
        {dataset.description && (
          <div className={classes.description}>
            <div
              id={`${getIdFromDatasetIdentifier(dataset.identifier)}__description__text-wrapper`}
              className={`${classes.descriptionTextWrapper} ${
                isExpanded ? classes.descriptionTextWrapperExpanded : classes.descriptionTextWrapperCollapsed
              }`}
            >
              {dataset.description && (
                <SanitizedHTML
                  id={`${getIdFromDatasetIdentifier(dataset.identifier)}__description__text`}
                  html={dataset.description}
                  className={classes.descriptionText}
                />
              )}
            </div>
            <div
              id={`${getIdFromDatasetIdentifier(dataset.identifier)}__description__icon`}
              className={classes.descriptionIcon}
            >
              <Tooltip title={isExpanded ? t("commons.confirm.collapse") : t("commons.confirm.expand")}>
                <IconButton onClick={isExpanded ? onCollapse : onExpand}>
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      </Card>
    </Grid>
  );
};

export default withStyles(styles)(DatasetCard);

import React from "react";
import {useParams} from "react-router-dom";
import Dashboards from "../components/dashboards";

const DashboardsDomain = props => {
  const {dashboardId} = useParams();

  return <Dashboards {...props} dashboardId={dashboardId} />;
};

export default DashboardsDomain;

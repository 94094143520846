import React, {useEffect, useState} from "react";
import CountryLanguage from "@ladjs/country-language";
import {connect} from "react-redux";
import ButtonSelect from "../button-select";

const I18nInputAdornmentSelect = ({
  value,
  onChange,
  languages,
  ariaLabel,
  tooltip,
  customLangToCountry,
  showAsIcon
}) => {
  const [langToCountry, setLangToCountry] = useState(null);

  useEffect(() => {
    let res = {};

    languages.forEach(langCode =>
      CountryLanguage.getLanguageCountries(langCode, (err, countries) => {
        if (!err) {
          res[langCode] =
            customLangToCountry[langCode] ||
            countries.find(({code_2}) => code_2.toLowerCase() === langCode)?.code_2.toLowerCase() ||
            countries[0]?.code_2?.toLowerCase();
        }
      })
    );

    setLangToCountry(res);
  }, [languages, customLangToCountry]);

  if (!langToCountry) {
    return null;
  }

  const iconValue = langToCountry[value] ? (
    <span className={`fi fi-${langToCountry[value]}`} style={{width: 24}}>
      &nbsp;
    </span>
  ) : (
    <span>{value}</span>
  );

  return (
    <ButtonSelect
      value={!showAsIcon ? iconValue : undefined}
      icon={showAsIcon ? iconValue : undefined}
      onChange={onChange}
      ariaLabel={ariaLabel}
      tooltip={tooltip}
    >
      {languages.map(lang =>
        langToCountry[lang] ? (
          <span key={lang} className={`fi fi-${langToCountry[lang]}`} data-value={lang} aria-label={lang}>
            &nbsp;
          </span>
        ) : (
          <span key={lang} data-value={lang} aria-label={lang}>
            {(lang || "").toUpperCase()}
          </span>
        )
      )}
    </ButtonSelect>
  );
};

export default connect(state => ({
  languages: state.app.languages,
  customLangToCountry: state.appConfig.customLangToCountry
}))(I18nInputAdornmentSelect);

import React, {Fragment, useEffect, useState} from "react";
import {withStyles} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import GridOnIcon from "@material-ui/icons/GridOn";
import ListIcon from "@material-ui/icons/List";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {compose} from "redux";
import {
  getDatasetInternalUrl,
  getDatasetsInternalUrl,
  getHomeInternalUrl,
  getNodeCategoriesInternalUrl,
  getNodeInternalUrl
} from "../../links";
import CatalogInfoButton from "../catalog-info-button";
import CatalogMetadataButton from "../catalog-metadata-button";
import CategoriesTree from "../categories-tree";
import CustomEmpty from "../custom-empty";
import CustomLink from "../custom-link";
import DetailLevelSelect from "../detail-level-select";
import Footer from "../footer";
import NodeHeader from "../node-header";
import Page from "../page";
import {
  CATALOG_NAVIGATION_MODE_GRID,
  CATALOG_NAVIGATION_MODE_LIST,
  CATALOG_NAVIGATION_MODE_TREE
} from "../settings-select/nodes-settings-form/node-settings-form";
import DatasetCard from "./DatasetCard";
import ResultTree from "./ResultTree";
import {getPageTitle} from "../../utils/other";
import {getFilteredTreeWithPaths, getMappedTree} from "../../utils/tree";
import {scrollResultsToDatasetByParam} from "./utils";

const styles = theme => ({
  fullWidthContainer: {
    width: "100%",
    minHeight: "100%",
    backgroundColor: "#f5f5f5",
    display: "flex",
    flexDirection: "column"
  },
  toolbar: {
    minHeight: 48,
    marginTop: theme.spacing(3)
  },
  searchTitle: {
    fontSize: 24,
    fontWeight: 400,
    letterSpacing: 0,
    whiteSpace: "pre-wrap"
  },
  categoriesTitle: {
    fontSize: 28,
    fontWeight: 300,
    letterSpacing: 0,
    paddingBottom: 8,
    position: "relative",
    width: "calc(100% - 96px)",
    zIndex: 2
  },
  breadcrumbs: {
    minHeight: 48,
    padding: "8px 0",
    display: "flex"
  },
  breadcrumbsSeparator: {
    margin: "0 4px"
  },
  breadcrumbsLastElem: {
    color: theme.palette.primary.main,
    padding: 4
  },
  detailLevels: {
    padding: "8px 4px"
  },
  results: {
    marginTop: 8
  },
  row: {
    marginBottom: theme.spacing(1)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  cardActions: {
    padding: "4px 8px"
  },
  divider: {
    margin: "16px 0"
  },
  categoriesTree: {
    marginTop: -52,
    zIndex: 1
  },
  treeItemCategory: {
    "& .categories-tree__tree-item__node__icon": {
      width: 32,
      height: 32
    }
  },
  treeItemDataset: {
    "& .categories-tree__tree-item__dataset__icon": {
      margin: "0 6px"
    }
  }
});

const mapStateToProps = state => ({
  filteredCatalog: state.detailLevel.filteredCatalog,
  detailLevel: state.detailLevel.detailLevel
});

const Results = ({
  classes,
  query,
  hub,
  catalog,
  node,
  nodeCode,
  isDefault,
  categoryPath,
  subCategories,
  filters,
  filtersParams,
  t,
  detailLevel,
  filteredCatalog,
  datasets,
  isAccessible,
  onAccessibleDatasetFetch
}) => {
  const location = useLocation();

  const [expandedIndexes, setExpandedIndexes] = useState([]);

  const [catalogNavigationMode, setCatalogNavigationMode] = useState(
    hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase()).catalogNavigationMode
  );

  useEffect(() => {
    setExpandedIndexes([]);
  }, [categoryPath]);

  useEffect(() => scrollResultsToDatasetByParam());

  const nodeMinimalInfo = hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase());

  const nodeExtras = nodeMinimalInfo?.extras || [];
  const hideLabelOnCategoryWithImage = JSON.parse(
    nodeExtras.find(({key}) => key === "HideLabelOnCategoryWithImage")?.value || "[]"
  );

  if (filteredCatalog) {
    let categoryPathLabels = [];
    if (categoryPath) {
      if (categoryPath[0] === "uncategorized") {
        categoryPathLabels = [t("commons.catalog.uncategorized")];
      } else {
        let category;

        if (catalog.hasCategorySchemes) {
          category = catalog.categoryGroups.find(({id}) => id === categoryPath[0]);
        } else {
          category = catalog.categoryGroups[0].categories.find(({id}) => id === categoryPath[0]);
        }

        categoryPathLabels.push(category.label);
        for (let i = 1; i < categoryPath.length; i++) {
          category = (category.childrenCategories || category.categories).find(({id}) => id === categoryPath[i]);
          categoryPathLabels.push(category.label);
        }
      }
    }

    let categoryGroupsWithCount, uncategorizedDatasetsCount;

    if (query) {
      const testDSForQuery = ds =>
        (ds.title || "").toLowerCase().includes(query.toLowerCase()) ||
        !!ds.keywords?.find(keyword => keyword.toLowerCase().includes(query.toLowerCase()));

      const testCatForFilters = cat => filters.length === 0 || filters.includes(cat.id);

      const dssForCount = []; // satisfy only query (necessary to display count numbers)
      const dssForResults = []; // satisfy query and filters

      if (filteredCatalog.uncategorizedDatasets && filteredCatalog.uncategorizedDatasets.length > 0) {
        filteredCatalog.uncategorizedDatasets.forEach(ds => {
          ds.categoryPath = ["uncategorized"];

          if (testDSForQuery(ds)) {
            dssForCount.push(ds);
            if (filters.length === 0 || filters.includes("uncategorized")) {
              dssForResults.push(ds);
            }
          }
        });
      }

      const searchRecursive = (categories, categoryPath, overrideCatSatisfyFilters = false) => {
        categories.forEach(c => {
          const catSatisfyFilters = overrideCatSatisfyFilters || testCatForFilters(c);

          c.datasetIdentifiers.forEach(id => {
            const ds = {
              ...catalog.datasets[id],
              identifier: id,
              categoryPath: [...categoryPath, c.id]
            };
            if (testDSForQuery(ds)) {
              dssForCount.push(ds);
              if (catSatisfyFilters) {
                dssForResults.push(ds);
              }
            }
          });

          searchRecursive(c.childrenCategories, [...categoryPath, c.id], catSatisfyFilters);
        });
      };

      if (filteredCatalog.hasCategorySchemes) {
        // with category schemes
        filteredCatalog.categoryGroups.forEach(group => searchRecursive(group.categories, [group.id]));
      } else if (filteredCatalog.categoryGroups.length === 1) {
        // no category schemes
        searchRecursive(filteredCatalog.categoryGroups[0].categories, []);
      }

      const dssSatisfyQueryMap = {};
      dssForCount.forEach(ds => (dssSatisfyQueryMap[ds.identifier] = ds));

      const recursiveCountAndFilterZero = (tree, visited) =>
        tree
          .map(node => {
            const children = recursiveCountAndFilterZero(node.childrenCategories, visited).filter(
              ({count}) => count > 0
            );

            const uniqueDssSatisfyQueryCount =
              node.datasetIdentifiers?.filter(dsId => {
                if (dssSatisfyQueryMap[dsId] !== undefined) {
                  if (visited[dsId]) {
                    return false;
                  } else {
                    visited[dsId] = true;
                    return true;
                  }
                } else {
                  return false;
                }
              }).length || 0;

            return {
              ...node,
              childrenCategories: node.childrenCategories ? children : undefined,
              count: children.reduce((acc, node) => acc + node.count, 0) + uniqueDssSatisfyQueryCount
            };
          })
          .filter(({count}) => count > 0);

      categoryGroupsWithCount = filteredCatalog.categoryGroups
        .map(categoryGroup => {
          const categories = (categoryGroup?.categories || [])
            .map(category => {
              const visited = {};
              const childrenCategories = recursiveCountAndFilterZero(category.childrenCategories, visited);
              return {
                ...category,
                childrenCategories: childrenCategories,
                count:
                  childrenCategories.reduce((acc, node) => acc + node.count, 0) +
                  (category.datasetIdentifiers?.filter(dsId => dssSatisfyQueryMap[dsId] !== undefined).length || 0)
              };
            })
            .filter(({count}) => count > 0);
          return {
            ...categoryGroup,
            categories: categories,
            count: categories.reduce((acc, node) => acc + node.count, 0)
          };
        })
        .filter(({count}) => count > 0);

      uncategorizedDatasetsCount = filteredCatalog.uncategorizedDatasets.filter(
        ({identifier}) => dssSatisfyQueryMap[identifier] !== undefined
      ).length;

      // remove duplicates from results ( O(2n) )
      const resultsMap = {};
      dssForResults.forEach(ds => (resultsMap[ds.identifier] = ds));

      datasets = Object.keys(resultsMap).map(id => resultsMap[id]);
    }

    const filteredDatasets = detailLevel
      ? datasets.filter(({detailsLevels: dls}) => detailLevel == null || (dls || []).includes(detailLevel))
      : datasets;

    const filteredSubCategories =
      detailLevel !== null && subCategories
        ? getFilteredTreeWithPaths(
            getMappedTree(subCategories, "childrenCategories", cat => ({
              ...cat,
              datasetIdentifiers: (cat.datasetIdentifiers || []).filter(id =>
                (catalog.datasets[id].detailsLevels || []).includes(detailLevel)
              )
            })),
            "childrenCategories",
            ({datasetIdentifiers}) => datasetIdentifiers && datasetIdentifiers.length > 0
          )
        : subCategories;

    const categoriesWithImages = (filteredSubCategories || []).find(({image}) => !!image) !== undefined;

    const params = new URLSearchParams(location.search);
    params.set("accessible", "true");
    const paramsStr = params.toString();
    const path = location.pathname;

    const isTreeViewMode =
      catalogNavigationMode === CATALOG_NAVIGATION_MODE_TREE &&
      ((catalog.hasCategorySchemes && (categoryPath || []).length === 2) ||
        (!catalog.hasCategorySchemes && (categoryPath || []).length === 1));

    let catalogForTreeView = null;

    if (isTreeViewMode) {
      const categorySchemaId = catalog.hasCategorySchemes ? categoryPath[0] : filteredCatalog.categoryGroups[0].id;
      const categoryId = catalog.hasCategorySchemes ? categoryPath[1] : categoryPath[0];

      const categoryGroup = filteredCatalog.categoryGroups.find(({id}) => id === categorySchemaId);

      catalogForTreeView = {
        ...filteredCatalog,
        hasCategorySchemes: false,
        categoryGroups: categoryGroup
          ? [
              {
                ...categoryGroup,
                categories: categoryGroup.categories.find(({id}) => id === categoryId).childrenCategories
              }
            ]
          : [],
        uncategorizedDatasets: null,
        rootDatasets: filteredDatasets
      };
    }

    return (
      <Fragment>
        {!isAccessible && (
          <a
            href={"./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr}
            target="_self"
            className="skip-link sr-only"
          >
            {t("commons.hashLinks.accessible")}
          </a>
        )}
        <HashLink to={{hash: "#main", search: location.search}} className="skip-link sr-only">
          {t("commons.hashLinks.main")}
        </HashLink>
        <HashLink to={{hash: "#footer", search: location.search}} className="skip-link sr-only">
          {t("commons.hashLinks.footer")}
        </HashLink>
        <Page
          title={
            query
              ? getPageTitle(
                  [t("scenes.results.title", {query: query, nodeName: nodeMinimalInfo?.name}), hub?.hub?.name],
                  t
                )
              : getPageTitle(
                  [categoryPathLabels[categoryPathLabels.length - 1], nodeMinimalInfo?.name, hub?.hub?.name],
                  t
                )
          }
        >
          <NodeHeader
            hub={hub.hub}
            query={query}
            nodes={hub.nodes}
            catalog={catalog}
            title={nodeMinimalInfo.name}
            node={node}
            nodeId={nodeMinimalInfo.nodeId}
            isDefault={isDefault}
            selectedCategoryPath={categoryPath}
          />
          <main id="main" className={`${classes.fullWidthContainer} results`}>
            <Container>
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
                wrap="nowrap"
                className={classes.toolbar}
              >
                <Grid item>
                  {query ? (
                    <Typography variant={"h1"} className={classes.searchTitle}>
                      {t("scenes.results.searchTitle", {query})}
                    </Typography>
                  ) : (
                    <Breadcrumbs
                      aria-label={t("components.results.breadcrumbs.ariaLabel")}
                      classes={{
                        separator: classes.breadcrumbsSeparator
                      }}
                      className={classes.breadcrumbs}
                    >
                      <CustomLink
                        to={getHomeInternalUrl()}
                        text={t("components.results.breadcrumbs.links.home")}
                        textStyle={{padding: 4}}
                      />
                      {(hub.nodes || []).length > 1 && (
                        <CustomLink
                          to={getNodeInternalUrl(node.code)}
                          text={node?.name || node.code}
                          textStyle={{padding: 4}}
                        />
                      )}
                      <CustomLink
                        to={getNodeCategoriesInternalUrl(node.code)}
                        text={t("scenes.categories.title")}
                        textStyle={{padding: 4}}
                      />
                      {categoryPath.map((id, idx) => {
                        if (idx < categoryPath.length - 1 && id !== "uncategorized") {
                          return (
                            <CustomLink
                              key={idx}
                              to={getDatasetsInternalUrl(node.code, categoryPath.slice(0, idx + 1))}
                              text={categoryPathLabels[idx]}
                              textStyle={{padding: 4}}
                            />
                          );
                        } else {
                          return (
                            <span key={idx} className={classes.breadcrumbsLastElem}>
                              {categoryPathLabels[idx]}
                            </span>
                          );
                        }
                      })}
                    </Breadcrumbs>
                  )}
                </Grid>
                {!isAccessible && catalogNavigationMode !== CATALOG_NAVIGATION_MODE_TREE && (
                  <Grid item>
                    {filteredDatasets.length > 0 && (
                      <Tooltip
                        title={
                          catalogNavigationMode === CATALOG_NAVIGATION_MODE_LIST
                            ? t("components.results.actions.listModeToggle.grid.tooltip")
                            : t("components.results.actions.listModeToggle.list.tooltip")
                        }
                      >
                        <IconButton
                          onClick={() =>
                            setCatalogNavigationMode(
                              catalogNavigationMode === CATALOG_NAVIGATION_MODE_LIST
                                ? CATALOG_NAVIGATION_MODE_GRID
                                : CATALOG_NAVIGATION_MODE_LIST
                            )
                          }
                          aria-label={
                            catalogNavigationMode === CATALOG_NAVIGATION_MODE_LIST
                              ? t("components.results.actions.listModeToggle.grid.ariaLabel")
                              : t("components.results.actions.listModeToggle.list.ariaLabel")
                          }
                        >
                          {catalogNavigationMode === CATALOG_NAVIGATION_MODE_LIST ? <GridOnIcon /> : <ListIcon />}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                )}
              </Grid>
              {!query && (
                <Typography variant={"h1"} className={classes.categoriesTitle}>
                  {categoryPathLabels[categoryPath.length - 1]}
                </Typography>
              )}
              {catalog.detailLevels.length > 0 && (
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  className={classes.detailLevels}
                >
                  <Grid item>
                    <Typography>{t("components.results.detailLevelSelectTitle")}</Typography>
                  </Grid>
                  <Grid item>
                    <DetailLevelSelect />
                  </Grid>
                </Grid>
              )}
              <Grid container justifyContent="space-between" className={classes.results}>
                {query && (
                  <Grid item style={{width: 256}}>
                    <ResultTree
                      query={query}
                      filters={filtersParams}
                      catalog={filteredCatalog}
                      node={node}
                      datasets={filteredDatasets}
                      categoryGroupsWithCount={categoryGroupsWithCount}
                      uncategorizedDatasetsCount={uncategorizedDatasetsCount}
                    />
                  </Grid>
                )}
                <Grid item style={{width: query ? `calc(100% - 256px - 16px)` : "100%"}}>
                  {!query ? (
                    <Fragment>
                      <nav aria-label={t("components.results.subcategories.ariaLabel")}>
                        {isTreeViewMode ? (
                          <div className={classes.categoriesTree}>
                            <CategoriesTree
                              catalog={catalogForTreeView}
                              node={node}
                              initialPath={categoryPath}
                              showDatasetList
                              showExpandControls
                              showCategoriesFirst
                              treeItemDatasetClasses={classes.treeItemDataset}
                              treeItemCategoryClasses={classes.treeItemCategory}
                            />
                          </div>
                        ) : (
                          <Grid container spacing={2} className={classes.row}>
                            {(filteredSubCategories || []).map(
                              ({id, label, datasetIdentifiers, description, metadataUrl, image}) => (
                                <Grid
                                  item
                                  key={id}
                                  xs={categoriesWithImages ? 4 : 12}
                                  sm={categoriesWithImages ? 4 : 12}
                                  md={categoriesWithImages ? 3 : 6}
                                  lg={categoriesWithImages ? 2 : 4}
                                  xl={categoriesWithImages ? 2 : 4}
                                >
                                  <Card className={classes.card}>
                                    <CustomLink
                                      to={getDatasetsInternalUrl(node.code.toLowerCase(), [...categoryPath, id])}
                                      text={
                                        hideLabelOnCategoryWithImage && image
                                          ? null
                                          : `${label} ${
                                              datasetIdentifiers && datasetIdentifiers.length
                                                ? `(${datasetIdentifiers.length})`
                                                : ""
                                            }`
                                      }
                                      textStyle={
                                        hideLabelOnCategoryWithImage && image
                                          ? {
                                              padding: 0
                                            }
                                          : {
                                              padding: 16,
                                              fontSize: 20
                                            }
                                      }
                                      image={image}
                                      imageStyle={{
                                        maxHeight: undefined
                                      }}
                                      icon={image ? undefined : <FolderIcon />}
                                    />
                                    {(description || metadataUrl) && (
                                      <CardActions classes={{root: classes.cardActions}}>
                                        {description && <CatalogInfoButton description={description} />}
                                        {metadataUrl && (
                                          <CatalogMetadataButton metadataUrl={metadataUrl} iconSize="small" />
                                        )}
                                      </CardActions>
                                    )}
                                  </Card>
                                </Grid>
                              )
                            )}
                          </Grid>
                        )}
                      </nav>
                      {filteredDatasets.length > 0 && !isTreeViewMode && <Divider className={classes.divider} />}
                    </Fragment>
                  ) : (
                    filteredDatasets &&
                    filteredDatasets.length > 0 && (
                      <Typography variant="body1" className={classes.row}>
                        {filteredDatasets.length > 1
                          ? t("scenes.results.datasetsCount.plural", {datasetsCount: filteredDatasets.length})
                          : t("scenes.results.datasetsCount.singular", {datasetsCount: filteredDatasets.length})}
                        :
                      </Typography>
                    )
                  )}
                  {isTreeViewMode ? null : filteredDatasets.length > 0 ? (
                    <nav aria-label={t("components.results.datasets.ariaLabel")}>
                      <Grid container spacing={2}>
                        {filteredDatasets.map((dataset, idx) => (
                          <DatasetCard
                            key={idx}
                            isExpanded={expandedIndexes.includes(idx)}
                            to={
                              !isAccessible
                                ? getDatasetInternalUrl(node.code, dataset.categoryPath, dataset.identifier, undefined)
                                : undefined
                            }
                            onClick={!isAccessible ? undefined : () => onAccessibleDatasetFetch(dataset)}
                            node={node}
                            dataset={dataset}
                            onExpand={() => setExpandedIndexes([...expandedIndexes, idx])}
                            onCollapse={() => setExpandedIndexes([...expandedIndexes.filter(i => i !== idx)])}
                            xs={12}
                            md={catalogNavigationMode === CATALOG_NAVIGATION_MODE_GRID ? 4 : 12}
                            isMultiCategorized={
                              !!query &&
                              JSON.stringify(catalog?.categoryGroups || {}).split(dataset.identifier).length - 1 > 1
                            }
                          />
                        ))}
                      </Grid>
                    </nav>
                  ) : query || !filteredSubCategories || !filteredSubCategories.length ? (
                    <CustomEmpty
                      text={t("scenes.results.datasetsCount.none")}
                      textStyle={{fontSize: 16}}
                      style={{marginTop: 32, marginLeft: query ? -128 : 0}}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Container>
            <Footer />
          </main>
        </Page>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps))(Results);

import React from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import I18nInputAdornmentSelect from "../i18n-input-adornment-select";
import {backupDatasetMVState} from "../../state/dataset/multi-viewer/actions";
import {backupDatasetSVPState} from "../../state/dataset/single-viewer-plus/actions";
import {backupDatasetSVState} from "../../state/dataset/single-viewer/actions";

const mapStateToProps = ({app, dataset}) => ({
  language: app.language,
  datasetUuidSV: dataset.singleViewer.datasetUuid,
  datasetUuidSVP: dataset.singleViewerPlus.datasetUuid,
  datasetUuidMV: dataset.multiViewer.datasetUuid
});
const mapDispatchToProps = dispatch => ({
  onDatasetSVBackup: datasetUuid => dispatch(backupDatasetSVState(datasetUuid)),
  onDatasetSVPBackup: datasetUuid => dispatch(backupDatasetSVPState(datasetUuid)),
  onDatasetMVBackup: datasetUuid => dispatch(backupDatasetMVState(datasetUuid))
});

const AppLanguageSelect = ({
  language,
  datasetUuidSV,
  datasetUuidSVP,
  datasetUuidMV,
  onDatasetSVBackup,
  onDatasetSVPBackup,
  onDatasetMVBackup
}) => {
  const location = useLocation();
  const {t} = useTranslation();

  return (
    <I18nInputAdornmentSelect
      value={language}
      onChange={val => {
        if (val !== language) {
          onDatasetSVBackup(datasetUuidSV);
          onDatasetSVPBackup(datasetUuidSVP);
          onDatasetMVBackup(datasetUuidMV);

          const url = location.pathname.includes(language)
            ? "./#" + location.pathname.replace(language, val) + location.search
            : `./#/${val}`;
          window.open(url, "_self");
        }
      }}
      ariaLabel={t("components.header.actions.appLanguage.ariaLabel")}
      tooltip={t("components.header.actions.appLanguage.title")}
      showAsIcon
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLanguageSelect);

import React, {Fragment} from "react";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

export const FONT_SIZE_SELECTOR_FONT_SIZE_SM = "sm";
export const FONT_SIZE_SELECTOR_FONT_SIZE_MD = "md";
export const FONT_SIZE_SELECTOR_FONT_SIZE_LG = "lg";

const styles = theme => ({
  fontSizeIcon: {
    width: 24,
    height: 24,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fontSizeIconSm: {
    fontSize: 14
  },
  fontSizeIconMd: {
    fontSize: 16
  },
  fontSizeIconLg: {
    fontSize: 20
  },
  fontSizeSelected: {
    boxShadow: "inset 0 -2px 0 0 rgba(0, 0, 0, .54)"
  }
});

const getFontSizeIcon = styleClasses => <span className={styleClasses}>A</span>;

const FontSizeSelector = ({t, classes, fontSize, setFontSize}) => (
  <Fragment>
    <Tooltip title={t("components.fontSizeSelector.small.tooltip")}>
      <IconButton
        onClick={() => setFontSize(FONT_SIZE_SELECTOR_FONT_SIZE_SM)}
        aria-label={t("components.fontSizeSelector.small.tooltip")}
      >
        {getFontSizeIcon(
          `${classes.fontSizeIcon} ${classes.fontSizeIconSm} ${
            fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_SM ? classes.fontSizeSelected : ""
          }`
        )}
      </IconButton>
    </Tooltip>
    <Tooltip title={t("components.fontSizeSelector.normal.tooltip")}>
      <IconButton
        onClick={() => setFontSize(FONT_SIZE_SELECTOR_FONT_SIZE_MD)}
        aria-label={t("components.fontSizeSelector.normal.tooltip")}
      >
        {getFontSizeIcon(
          `${classes.fontSizeIcon} ${classes.fontSizeIconMd} ${
            fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_MD ? classes.fontSizeSelected : ""
          }`
        )}
      </IconButton>
    </Tooltip>
    <Tooltip title={t("components.fontSizeSelector.big.tooltip")}>
      <IconButton
        onClick={() => setFontSize(FONT_SIZE_SELECTOR_FONT_SIZE_LG)}
        aria-label={t("components.fontSizeSelector.big.tooltip")}
      >
        {getFontSizeIcon(
          `${classes.fontSizeIcon} ${classes.fontSizeIconLg} ${
            fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_LG ? classes.fontSizeSelected : ""
          }`
        )}
      </IconButton>
    </Tooltip>
  </Fragment>
);

export default compose(withStyles(styles), withTranslation())(FontSizeSelector);

import {getDatasetExportMetadataUrl} from "../../serverApi/urls";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";

/* dataset */

export const DATASET_METADATA_FETCH = "dataset/metadata/fetch";
export const DATASET_METADATA_HIDE = "dataset/metadata/hide";

export const fetchDatasetMetadata = (nodeId: number, datasetId: string, metadataUrl: string) => {
  return initRequest(
    DATASET_METADATA_FETCH,
    getDatasetExportMetadataUrl(nodeId, datasetId),
    RequestMethod.POST,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDatasetMetadata")
    }),
    {
      metadataUrl: metadataUrl
    }
  );
};

export const hideDatasetMetadata = () => ({
  type: DATASET_METADATA_HIDE
});

export const ValidatorStates = {
  ExpressionStart: "ExpressionStart",
  IntegerPart: "IntegerPart",
  AfterComma: "AfterComma",
  FractionalPart: "FractionalPart",
  ExpressionEnd: "ExpressionEnd"
};

export const ValidatorInputs = {
  LeftParenthesis: "LeftParenthesis",
  RightParenthesis: "RightParenthesis",
  Digit: "Digit",
  Variable: "Variable",
  Operation: "Operation",
  Comma: "Comma"
};

export const ValidatorFSM = {
  [ValidatorStates.ExpressionStart]: {
    [ValidatorInputs.LeftParenthesis]: ValidatorStates.ExpressionStart,
    [ValidatorInputs.Digit]: ValidatorStates.IntegerPart,
    [ValidatorInputs.Variable]: ValidatorStates.ExpressionEnd
  },
  [ValidatorStates.IntegerPart]: {
    [ValidatorInputs.RightParenthesis]: ValidatorStates.ExpressionEnd,
    [ValidatorInputs.Digit]: ValidatorStates.IntegerPart,
    [ValidatorInputs.Operation]: ValidatorStates.ExpressionStart,
    [ValidatorInputs.Comma]: ValidatorStates.AfterComma
  },
  [ValidatorStates.AfterComma]: {
    [ValidatorInputs.Digit]: ValidatorStates.FractionalPart
  },
  [ValidatorStates.FractionalPart]: {
    [ValidatorInputs.Digit]: ValidatorStates.FractionalPart,
    [ValidatorInputs.Operation]: ValidatorStates.ExpressionStart,
    [ValidatorInputs.RightParenthesis]: ValidatorStates.ExpressionEnd
  },
  [ValidatorStates.ExpressionEnd]: {
    [ValidatorInputs.RightParenthesis]: ValidatorStates.ExpressionEnd,
    [ValidatorInputs.Operation]: ValidatorStates.ExpressionStart
  }
};

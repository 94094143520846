import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AttributeIcon = props => (
  <SvgIcon {...props}>
    <text>
      <tspan
        x="0.48025009"
        y="20.142769"
        style={{
          fontFamily: "Do Hyeon",
          fontSize: "23.5313px",
          fontWeight: "bold",
          strokeWidth: "1.26047"
        }}
      >
        (*)
      </tspan>
    </text>
  </SvgIcon>
);

export default AttributeIcon;

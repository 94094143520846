import React from "react";
import {withTranslation} from "react-i18next";
import CustomEmpty from "../custom-empty";

class DashboardViewErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    this.setState({hasError: true});
  }

  render() {
    return this.state.hasError ? (
      <CustomEmpty>{this.props.t("components.dashboard.genericError")}</CustomEmpty>
    ) : (
      this.props.children
    );
  }
}

export default withTranslation()(DashboardViewErrorBoundary);

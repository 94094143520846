import React from "react";

class MissingModuleErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    this.setState({hasError: true});
  }

  render() {
    return this.state.hasError ? null : this.props.children;
  }
}

export default MissingModuleErrorBoundary;

import React from "react";
import Grid from "@material-ui/core/Grid";
import SanitizedHTML from "../sanitized-html";
import {getAttributeLabel, getAttributeValueLabel, getFormattedDimensionValueLabel} from "../../utils/dataset";

const DimensionAttributeList = ({datasetId, jsonStat, dimension, dimValuesAttributeList, labelFormat, style}) => (
  <div style={{...style}}>
    <Grid container spacing={2}>
      {(dimValuesAttributeList || []).map(({dimVal, attributes}) => (
        <Grid item key={dimVal} xs={12}>
          <Grid container spacing={1}>
            {dimension && (dimValuesAttributeList || []).length > 1 && (
              <Grid item key={dimVal} xs={12}>
                <b>
                  <u>{getFormattedDimensionValueLabel(jsonStat, datasetId, dimension, dimVal, labelFormat) + ":"}</u>
                </b>
              </Grid>
            )}
            {(attributes || []).map((attribute, idx) => (
              <Grid item key={dimVal + idx} xs={12}>
                <SanitizedHTML
                  html={`- <b>${getAttributeLabel(attribute, labelFormat)}</b>: ${getAttributeValueLabel(
                    attribute,
                    labelFormat
                  )}`}
                  allowTarget
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  </div>
);

export default DimensionAttributeList;

import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import DatasetStaticFilters from "./DatasetStaticFilters";
import {getFiltersValueInfo} from "../../utils/dataset";

const DatasetFiltersCodelistsWrapper = ({jsonStat, layout, labelFormat, isDimensionAllowed}) => {
  const {t} = useTranslation();

  const filtersValueInfo = useMemo(() => {
    if (!layout || !jsonStat) {
      return {};
    } else {
      const filterDims = layout.filters.filter(dim => !isDimensionAllowed || isDimensionAllowed(dim));
      return getFiltersValueInfo(filterDims, layout.filtersValue, jsonStat, labelFormat, t);
    }
  }, [layout, isDimensionAllowed, jsonStat, labelFormat, t]);

  return (
    jsonStat && (
      <DatasetStaticFilters
        datasetId={filtersValueInfo?.datasetId || null}
        values={filtersValueInfo?.values || []}
        jsonStat={jsonStat}
        labelFormat={labelFormat}
      />
    )
  );
};

export default DatasetFiltersCodelistsWrapper;

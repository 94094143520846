import {v4 as uuidv4} from "uuid";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../request/requestActions";
import {CRITERIA_SELECTION_MODE_ALL, CRITERIA_SELECTION_TYPE_PARTIAL} from "../../state/dataset/constants";
import {
  DATASET_SV_DATASET_FETCH,
  fetchDatasetSVDatasetError,
  fetchDatasetSVDatasetInit,
  fetchDatasetSVDatasetSuccess
} from "../../state/dataset/single-viewer/actions";
import {addSpinnerMessage, markSpinnerMessage} from "../../state/spinner/spinnerActions";
import {
  getFilteredChartLayout,
  getFilteredMapLayout,
  getFilteredTableLayout,
  getInitialChartLayout,
  getInitialMapLayout,
  getInitialTableLayout
} from "../../utils/dataset";

const fetchDatasetSVAsyncHandlerMiddlewareFactory =
  t =>
  ({dispatch, getState}) =>
  next =>
  action => {
    const result = next(action);

    if (action?.payload?.label === DATASET_SV_DATASET_FETCH) {
      if (action.type === REQUEST_INIT) {
        dispatch(fetchDatasetSVDatasetInit());
      } else if (action.type === REQUEST_SUCCESS) {
        const datasetState = getState()?.dataset?.commons || {};
        const datasetSVState = getState()?.dataset?.singleViewer || {};

        const spinnerUuid = uuidv4();
        dispatch(
          addSpinnerMessage(
            spinnerUuid,
            t("middlewares.fetchDatasetAsyncHandlerMiddlewareFactory.spinners.layoutHandling")
          )
        );

        const {response, extra} = action.payload;

        const isEmptyData = extra.status === 204 || (response?.id || "").length === 0;
        const isPartialData = extra.status === 206;

        let isResponseNotValid = !response || isEmptyData;

        let tableLayout = null;
        let mapLayout = null;
        let chartLayout = null;

        const territoryDim = datasetSVState.territoryDim;
        const timeDim = datasetSVState.timeDim;

        if (!isResponseNotValid) {
          if (datasetSVState.tableLayout) {
            tableLayout = getFilteredTableLayout(datasetSVState.tableLayout, response, false);
          } else {
            tableLayout = getInitialTableLayout(response, territoryDim, timeDim);
          }
        }

        if (!isResponseNotValid && territoryDim && response.id.includes(territoryDim)) {
          if (response.dimension[territoryDim].category.index.length > 1) {
            if (datasetSVState.mapLayout) {
              mapLayout = getFilteredMapLayout(datasetSVState.mapLayout, response);
            } else {
              mapLayout = getInitialMapLayout(response, territoryDim);
            }
          }
        }

        if (!isResponseNotValid) {
          if (datasetSVState.chartLayout) {
            chartLayout = getFilteredChartLayout(datasetSVState.chartLayout, response);
          } else {
            chartLayout = getInitialChartLayout(response, territoryDim);
          }
        }

        const myWorker = new Worker("./workers/fetchDatasetAsyncHandlerWorker.js");
        myWorker.onmessage = event => {
          const {
            tableLayout,
            tableFilterTree,
            mapLayout,
            mapFilterTree,
            chartLayout,
            chartFilterTree,
            timePeriodsByFreq
          } = event.data;
          dispatch(
            fetchDatasetSVDatasetSuccess(
              response,
              extra.status,
              extra.responseHeaders,
              isEmptyData,
              isPartialData,
              isResponseNotValid,
              tableLayout,
              tableFilterTree,
              mapLayout,
              mapFilterTree,
              chartLayout,
              chartFilterTree,
              timePeriodsByFreq,
              myWorker
            )
          );
          dispatch(markSpinnerMessage(spinnerUuid));
        };
        myWorker.onerror = () => {
          dispatch(fetchDatasetSVDatasetError());
          dispatch(markSpinnerMessage(spinnerUuid, true));
          window.error.show(t("middlewares.fetchDatasetAsyncHandlerMiddlewareFactory.feedback.layoutHandlingError"));
        };
        myWorker.postMessage({
          response,
          tableLayout: tableLayout,
          mapLayout: mapLayout,
          chartLayout: chartLayout,
          tableDefaultFiltersValue:
            datasetState.mode === CRITERIA_SELECTION_MODE_ALL && datasetState.type === CRITERIA_SELECTION_TYPE_PARTIAL
              ? datasetSVState.tableDefaultFiltersValue
              : null,
          chartDefaultFiltersValue:
            datasetState.mode === CRITERIA_SELECTION_MODE_ALL && datasetState.type === CRITERIA_SELECTION_TYPE_PARTIAL
              ? datasetSVState.chartDefaultFiltersValue
              : null,
          criteria: datasetSVState.criteria,
          isFirstFetch: datasetSVState.isFirstFetch,
          isMultiViewer: false
        });
      } else if (action.type === REQUEST_ERROR) {
        dispatch(fetchDatasetSVDatasetError(action.payload.statusCode));
      }
    }

    return result;
  };

export default fetchDatasetSVAsyncHandlerMiddlewareFactory;

import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {
  CHART_DATA_LABEL_TYPE_NONE,
  CHART_DATA_LABEL_TYPE_VALUE,
  CHART_LEGEND_POSITION_BOTTOM,
  CHART_LEGEND_POSITION_lEFT,
  CHART_LEGEND_POSITION_RIGHT,
  CHART_LEGEND_POSITION_TOP
} from "../chart/constants";
import I18nTextField from "../i18n-text-field";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    "& :last-child": {
      marginBottom: 0
    }
  },
  field: {
    marginBottom: theme.spacing(3)
  }
});

function ChartSettingsGeneral(props) {
  const {t, classes, settings, onSettingsSet} = props;

  const {
    stacked,
    legendPosition,
    showAxesLabel,
    customizeCategoryAxis,
    categoryAxisLabel,
    valueAxisLabel,
    dataLabelType
  } = settings;

  return (
    <div className={classes.root}>
      <FormControl fullWidth className={classes.field}>
        <FormControlLabel
          label={t("components.chartSettings.general.isStacked.label")}
          control={<Checkbox checked={stacked} onChange={(e, value) => onSettingsSet({...settings, stacked: value})} />}
        />
      </FormControl>
      <FormControl fullWidth className={classes.field}>
        <TextField
          select
          fullWidth
          label={t("components.chartSettings.general.legendPosition.label")}
          value={legendPosition}
          variant="outlined"
          onChange={ev => onSettingsSet({...settings, legendPosition: ev.target.value})}
        >
          <MenuItem value={CHART_LEGEND_POSITION_TOP}>
            {t("components.chartSettings.general.legendPosition.values.top")}
          </MenuItem>
          <MenuItem value={CHART_LEGEND_POSITION_RIGHT}>
            {t("components.chartSettings.general.legendPosition.values.right")}
          </MenuItem>
          <MenuItem value={CHART_LEGEND_POSITION_BOTTOM}>
            {t("components.chartSettings.general.legendPosition.values.bottom")}
          </MenuItem>
          <MenuItem value={CHART_LEGEND_POSITION_lEFT}>
            {t("components.chartSettings.general.legendPosition.values.left")}
          </MenuItem>
        </TextField>
      </FormControl>
      <FormControl fullWidth className={classes.field}>
        <FormControlLabel
          label={t("components.chartSettings.general.dataLabelType.label")}
          control={
            <Checkbox
              checked={dataLabelType === CHART_DATA_LABEL_TYPE_VALUE}
              onChange={(e, value) => {
                onSettingsSet({
                  ...settings,
                  dataLabelType: value ? CHART_DATA_LABEL_TYPE_VALUE : CHART_DATA_LABEL_TYPE_NONE
                });
              }}
            />
          }
        />
      </FormControl>
      <FormControl fullWidth className={classes.field}>
        <FormControlLabel
          label={t("components.chartSettings.general.showAxesLabel.label")}
          control={
            <Checkbox
              checked={showAxesLabel}
              onChange={(e, value) => onSettingsSet({...settings, showAxesLabel: value})}
            />
          }
        />
      </FormControl>
      {showAxesLabel && (
        <Paper variant="outlined" className={classes.paper}>
          <FormControl fullWidth className={classes.field}>
            <I18nTextField
              label={t("components.chartSettings.general.valueAxisLabel.label")}
              variant="outlined"
              value={valueAxisLabel}
              onChange={value => onSettingsSet({...settings, valueAxisLabel: value})}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <FormControlLabel
              label={t("components.chartSettings.general.customizeCategoryAxis.label")}
              control={
                <Checkbox
                  checked={customizeCategoryAxis}
                  onChange={(e, value) => onSettingsSet({...settings, customizeCategoryAxis: value})}
                />
              }
            />
          </FormControl>
          {customizeCategoryAxis && (
            <FormControl fullWidth className={classes.field}>
              <I18nTextField
                label={t("components.chartSettings.general.categoryAxisLabel.label")}
                variant="outlined"
                value={categoryAxisLabel}
                onChange={value => onSettingsSet({...settings, categoryAxisLabel: value})}
              />
            </FormControl>
          )}
        </Paper>
      )}
    </div>
  );
}

export default compose(withStyles(styles), withTranslation())(ChartSettingsGeneral);

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../table";
import {getMultiViewerInitialTableLayout} from "../../../utils/dataset";
import {TABLE_HEADER_MERGED} from "../../table/utils";

const styles = () => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden"
  }
});

const IndicatorCreateDialogPreview = ({classes, jsonStat, territoryDim, timeDim}) => (
  <div className={classes.root}>
    {jsonStat && (
      <Table
        jsonStat={jsonStat}
        layout={{
          ...getMultiViewerInitialTableLayout(jsonStat, territoryDim, timeDim),
          filtersValue: {}
        }}
        tableHeaderType={TABLE_HEADER_MERGED}
      />
    )}
  </div>
);

export default withStyles(styles)(IndicatorCreateDialogPreview);

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import ErrorIcon from "@material-ui/icons/Error";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomEmpty from "../custom-empty";
import MultiViewer from "./multi-viewer";
import SingleViewer from "./single-viewer";
import SingleViewerPlus from "./single-viewer-plus";
import {ViewerMode} from "../../state/dataset/constants";

const styles = theme => ({
  viewerContainer: {
    width: "100%",
    height: "100%",
    overflow: "auto"
  }
});

const mapStateToProps = ({dataset}) => ({
  viewerMode: dataset.commons.viewerMode
});

const DataViewer = ({
  classes,
  nodeId,
  nodeCode,
  categoryPath,
  datasetId,
  datasetTitle,
  viewId,
  attachedFiles,
  nodeExtras,

  viewerMode
}) => {
  const {t} = useTranslation();

  switch (viewerMode) {
    case ViewerMode.SingleViewer: {
      return (
        <div id="data-viewer-container" className={classes.viewerContainer}>
          <SingleViewer
            nodeId={nodeId}
            nodeCode={nodeCode}
            categoryPath={categoryPath}
            datasetId={datasetId}
            datasetTitle={datasetTitle}
            viewId={viewId}
            attachedFiles={attachedFiles}
            nodeExtras={nodeExtras}
          />
        </div>
      );
    }
    case ViewerMode.SingleViewerPlus: {
      return (
        <div id="data-viewer-container" className={classes.viewerContainer}>
          <SingleViewerPlus
            nodeId={nodeId}
            nodeCode={nodeCode}
            categoryPath={categoryPath}
            datasetId={datasetId}
            datasetTitle={datasetTitle}
            viewId={viewId}
            attachedFiles={attachedFiles}
            nodeExtras={nodeExtras}
          />
        </div>
      );
    }
    case ViewerMode.MultiViewer: {
      return (
        <MultiViewer
          nodeId={nodeId}
          nodeCode={nodeCode}
          datasetId={datasetId}
          datasetTitle={datasetTitle}
          viewId={viewId}
          attachedFiles={attachedFiles}
          nodeExtras={nodeExtras}
        />
      );
    }
    case ViewerMode.Error: {
      return <CustomEmpty text={t("scenes.dataViewer.errors.fetchStructure")} image={<ErrorIcon />} />;
    }
    default: {
      return null;
    }
  }
};

export default compose(withStyles(styles), connect(mapStateToProps))(DataViewer);

import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import Data from "../components/data";
import Call from "../hocs/call";
import {goToNode} from "../links";
import {enableDatasetStructureFetch, fetchDatasetStructure, resetDatasetState} from "../state/dataset/datasetActions";
import {showUserLoginForm} from "../state/user/userActions";
import {getCategoryFromCatalog, getDatasetFromCatalog} from "../utils/catalog";
import {getIsUserAuthenticated} from "../utils/user";

const mapStateToProps = ({app, dataset, user}) => ({
  isMultiViewerTheme: app.themeConfig.isMultiViewer,
  isFetchStructureDisabled: dataset.commons.isFetchStructureDisabled,
  dsDatasetId: dataset.commons.datasetId,
  dsNodeCode: dataset.commons.nodeCode,
  dsCategoryPath: dataset.commons.categoryPath,
  dsViewId: dataset.commons.viewId,
  user: user
});

const mapDispatchToProps = dispatch => ({
  onFetchStructureEnable: (nodeCode, categoryPath, datasetId, viewId) =>
    dispatch(enableDatasetStructureFetch(nodeCode, categoryPath, datasetId, viewId)),
  fetchStructure: ({nodeId, datasetId, viewId, isMultiViewerTheme}) =>
    dispatch(fetchDatasetStructure(nodeId, datasetId, viewId, isMultiViewerTheme)),
  onDatasetReset: () => dispatch(resetDatasetState()),
  onLoginShow: () => dispatch(showUserLoginForm("NotAccessibleDataset", "warning", true))
});

const DatasetDomain = ({
  hub,
  node,
  nodeCode,
  isDefault,
  catalog,
  categoryPath,
  datasetId: externalDatasetId,

  isMultiViewerTheme,
  dsDatasetId,
  dsNodeCode,
  dsCategoryPath,
  dsViewId,
  isFetchStructureDisabled,
  user,

  onFetchStructureEnable,
  fetchStructure,
  onDatasetReset,
  onLoginShow
}) => {
  const location = useLocation();
  const {datasetId: urlDatasetId} = useParams();

  const datasetId = externalDatasetId ? externalDatasetId : urlDatasetId;

  const isAccessible = !!new URLSearchParams(location.search).get("accessible");
  const viewId = new URLSearchParams(location.search).get("view") || undefined;

  const [catalogDataset] = useState(datasetId ? getDatasetFromCatalog(datasetId, catalog) : null);

  useEffect(() => {
    return () => {
      onDatasetReset();
    };
  }, [onDatasetReset]);

  useEffect(() => {
    if (catalogDataset && datasetId) {
      const isStateAndUrlSynced =
        nodeCode === dsNodeCode &&
        (categoryPath || []).join() === (dsCategoryPath || []).join() &&
        datasetId === dsDatasetId &&
        (viewId || "") === (dsViewId || "");

      if (!isStateAndUrlSynced) {
        onFetchStructureEnable(nodeCode, categoryPath, datasetId, viewId);
      }
    }
  });

  const isDatasetVisible =
    !!datasetId &&
    catalogDataset !== null &&
    (categoryPath.length === 0 ||
      (categoryPath.length === 1 && categoryPath[0] === "uncategorized") ||
      getCategoryFromCatalog(categoryPath, catalog) !== null);

  return (
    <Call
      cb={() => {
        if (!getIsUserAuthenticated(user)) {
          onLoginShow();
        } else {
          goToNode(node.code);
        }
      }}
      cbParam={hub}
      disabled={isDatasetVisible}
    >
      {isDatasetVisible && (
        <Call
          cb={fetchStructure}
          cbParam={{
            nodeId: node.nodeId,
            datasetId: datasetId,
            viewId: viewId,
            isMultiViewerTheme: isMultiViewerTheme
          }}
          disabled={isFetchStructureDisabled}
        >
          <Data
            hub={hub}
            node={node}
            isDefault={isDefault}
            catalog={catalog}
            categoryPath={categoryPath}
            isAccessible={isAccessible}
            datasetId={datasetId}
            datasetTitle={catalogDataset?.title}
            viewId={viewId}
            attachedFiles={catalogDataset?.attachedDataFiles}
          />
        </Call>
      )}
    </Call>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetDomain);

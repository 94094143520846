import React, {useMemo} from "react";
import {withStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import DatasetFilterAttributeIcon from "../dataset-filter-attribute-icon";
import {getDimensionAttributeMap} from "../../utils/dataset";

const styles = theme => ({
  filters: {
    fontSize: 14
  },
  filter: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  filterElement: {
    display: "inline-block",
    verticalAlign: "middle"
  }
});

const DatasetStaticFilters = ({classes, datasetId, values, jsonStat, labelFormat}) => {
  const {t} = useTranslation();

  const dimAttributeMap = useMemo(
    () => (jsonStat && (jsonStat?.id || []).length > 0 ? getDimensionAttributeMap(jsonStat, t) : null),
    [jsonStat, t]
  );

  return (
    <div className={classes.filters}>
      {(values || []).map(({dim, dimLabel, value, valueLabel}, idx, arr) => (
        <span key={idx} className={classes.filter}>
          <span className={classes.filterElement}>
            <b>{dimLabel}</b>:&nbsp;
          </span>
          <span className={classes.filterElement}>
            <i>{valueLabel}</i>
          </span>
          {jsonStat && (jsonStat?.id || []).length > 0 && (
            <span className={classes.filterElement}>
              <DatasetFilterAttributeIcon
                datasetId={datasetId}
                jsonStat={jsonStat}
                dimension={dim}
                dimensionValues={[value]}
                datasetDimAttributeMap={dimAttributeMap?.[datasetId] || {}}
                labelFormat={labelFormat}
              />
            </span>
          )}
          <span className={classes.filterElement}>{idx < arr.length - 1 ? "," : ""}&nbsp;</span>
        </span>
      ))}
    </div>
  );
};

export default withStyles(styles)(DatasetStaticFilters);

import React, {Fragment, useCallback, useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import RefreshIcon from "@material-ui/icons/Refresh";
import SettingsIcon from "@material-ui/icons/Settings";
import Alert from "@material-ui/lab/Alert";
import {Trans, useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import Call from "../../../hocs/call";
import ChartSettingsColors from "../../chart-settings-forms/Colors";
import ChartSettingsGeneral from "../../chart-settings-forms/General";
import ChartJsonstatWrapper from "../../chart/ChartJsonstatWrapper";
import {CHART_TYPE_BAR, CHART_TYPE_LINE} from "../../chart/constants";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomEmpty from "../../custom-empty";
import DatasetFiltersCodelistsWrapper from "../../dataset-filters/DatasetFiltersCodelistsWrapper";
import FontSizeSelector, {
  FONT_SIZE_SELECTOR_FONT_SIZE_MD,
  FONT_SIZE_SELECTOR_FONT_SIZE_SM
} from "../../font-size-selector";
import LabelFormatSelector from "../../label-format-selector";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID} from "../../label-format-selector/constants";
import MapSettingsSettings from "../../map-settings-form/Settings";
import Map from "../../map/Map";
import Table, {
  JSONSTAT_TABLE_FONT_SIZE_LG,
  JSONSTAT_TABLE_FONT_SIZE_MD,
  JSONSTAT_TABLE_FONT_SIZE_SM
} from "../../table";
import TableLayoutLight from "../../table-layout-light";
import TemporalDimOrderSelector from "../../temporal-dim-order-selector";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../../temporal-dim-order-selector/constants";
import VariationSelector from "../../variation-selector";
import {getViewerTypeFromIdx, viewersFactory} from "../constant";
import {
  enableDatasetSVPDatasetFetch,
  fetchDatasetSVPDataset,
  setDatasetSVPChartSettings,
  setDatasetSVPHtmlGeneratingTime,
  setDatasetSVPLabelFormat,
  setDatasetSVPMapDetailLevel,
  setDatasetSVPMapSettings,
  setDatasetSVPTemporalDimOrder,
  setDatasetSVPVariation,
  showDatasetSVPCriteria,
  showDatasetSVPLayout,
  submitDatasetSVPChartLayout,
  submitDatasetSVPDownload,
  submitDatasetSVPMapLayout,
  submitDatasetSVPTableLayout
} from "../../../state/dataset/single-viewer-plus/actions";
import {getFilteredCriteriaFromLayout} from "../../../utils/criteria";
import {getDatasetSize, getSVPFilteredTableLayout, getUpdatedLayout, MAX_ALLOWED_CELLS} from "../../../utils/dataset";
import {DOWNLOAD_FORMAT_CSV, downloadFormats} from "../../../utils/download";
import {numberFormatter} from "../../../utils/formatters";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    padding: 16
  },
  filtersAndActions: {
    width: "100%",
    paddingBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  filters: {},
  actions: {
    marginBottom: 8
  },
  action: {
    height: 40,
    "& button": {
      padding: 8
    }
  },
  viewer: {
    width: "100%",
    height: "100%",
    position: "relative"
  },
  warningAlert: {
    width: "100%",
    marginBottom: 16
  },
  settingsTabs: {
    height: 48
  },
  settingsContent: {
    marginTop: 12,
    padding: 12,
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100% - 60px)",
    display: "flex"
  }
});

const mapStateToProps = ({app, appConfig, hub, dataset}) => {
  const viewerIdx = dataset.singleViewerPlus.viewerIdx;

  const tableLayout = dataset.singleViewerPlus.tableLayout;
  const mapLayout = dataset.singleViewerPlus.mapLayout;
  const chartLayout = dataset.singleViewerPlus.chartLayout;

  return {
    defaultLanguage: app.language,
    languages: app.languages,
    maxObservations: hub.hub.maxObservationsAfterCriteria || Number.MAX_SAFE_INTEGER,
    maxCells: hub.hub.maxCells || MAX_ALLOWED_CELLS,
    showSingleGeometry: appConfig.mapConfig.showSingleGeometry,
    dataset: dataset.singleViewerPlus.dataset,
    isFetchStarted: dataset.singleViewerPlus.isFetchStarted,
    isFetchFailed: dataset.singleViewerPlus.isFetchFailed,
    isPartialData: dataset.singleViewerPlus.isPartialData,
    isEmptyData: dataset.singleViewerPlus.isEmptyData,
    isTooBigData: dataset.singleViewerPlus.isTooBigData,
    dimensions: dataset.singleViewerPlus.dimensions,
    dimensionsInfo: dataset.singleViewerPlus.dimensionsInfo,
    timeDim: dataset.singleViewerPlus.timeDim,
    freqDim: dataset.singleViewerPlus.freqDim,
    territoryDim: dataset.singleViewerPlus.territoryDim,
    viewerIdx: viewerIdx,
    isViewerHoverVisible: dataset.singleViewerPlus.isViewerHoverVisible,
    layout: viewerIdx === 0 ? tableLayout : viewerIdx === 1 ? mapLayout : chartLayout,
    tableLayout: dataset.singleViewerPlus.tableLayout,
    mapLayout: dataset.singleViewerPlus.mapLayout,
    chartLayout: dataset.singleViewerPlus.chartLayout,
    labelFormat: dataset.singleViewerPlus.labelFormat,
    temporalDimOrder: dataset.singleViewerPlus.temporalDimOrder,
    showTrend: dataset.singleViewerPlus.showTrend,
    showCyclical: dataset.singleViewerPlus.showCyclical,
    areCriteriaApplied: dataset.singleViewerPlus.areCriteriaApplied,
    criteria: dataset.singleViewerPlus.criteria,
    decimalSeparator: dataset.singleViewerPlus.decimalSeparator,
    decimalPlaces: dataset.singleViewerPlus.decimalPlaces,
    tableEmptyChar: dataset.singleViewerPlus.tableEmptyChar,
    chartSettings: dataset.singleViewerPlus.chartSettings,
    mapSettings: dataset.singleViewerPlus.mapSettings,
    enableVariation: dataset.singleViewerPlus.enableVariation,
    codelistTrees: dataset.singleViewerPlus.codelistTrees,
    codelistLists: dataset.singleViewerPlus.codelistFilteredLists,
    codelistMaps: dataset.singleViewerPlus.codelistMaps,
    detailLevel: dataset.singleViewerPlus.detailLevel,
    isFetchDatasetDisabled: dataset.singleViewerPlus.isFetchDatasetDisabled,
    isFullscreen: dataset.singleViewerPlus.isFullscreen
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchDatasetEnable: limit => dispatch(enableDatasetSVPDatasetFetch(limit)),
  onDatasetFetch: (nodeId, datasetId, datasetTitle, criteria) =>
    dispatch(fetchDatasetSVPDataset(nodeId, datasetId, datasetTitle, criteria)),
  onCriteriaShow: initialCriteriaDimension => dispatch(showDatasetSVPCriteria(initialCriteriaDimension)),
  onLayoutShow: () => dispatch(showDatasetSVPLayout()),
  onTableLayoutSet: (layout, enableFetch) => dispatch(submitDatasetSVPTableLayout(layout, enableFetch)),
  onMapLayoutSet: layout => dispatch(submitDatasetSVPMapLayout(layout)),
  onChartLayoutSet: layout => dispatch(submitDatasetSVPChartLayout(layout)),
  onLabelFormatSet: labelFormat => dispatch(setDatasetSVPLabelFormat(labelFormat)),
  onVariationSet: variation => dispatch(setDatasetSVPVariation(variation)),
  onChartSettingsSet: chartSetting => dispatch(setDatasetSVPChartSettings(chartSetting)),
  onMapSettingsSet: mapSettings => dispatch(setDatasetSVPMapSettings(mapSettings)),
  onDetailLevelSet: detailLevel => dispatch(setDatasetSVPMapDetailLevel(detailLevel)),
  onTimeSet: time => dispatch(setDatasetSVPHtmlGeneratingTime(time)),
  onDownloadSubmit: (
    nodeId,
    datasetId,
    datasetTitle,
    criteria,
    layout,
    format,
    extension,
    zipped,
    params,
    defaultLanguage,
    languages,
    t
  ) =>
    dispatch(
      submitDatasetSVPDownload(
        nodeId,
        datasetId,
        datasetTitle,
        criteria,
        layout,
        format,
        extension,
        zipped,
        params,
        defaultLanguage,
        languages,
        t
      )
    ),
  onTemporalDimOrderSet: temporalDimOrder => dispatch(setDatasetSVPTemporalDimOrder(temporalDimOrder))
});

const handleStyle = mapId => {
  const $actions = $("#data-viewer__viewer__header__actions");
  const $filters = $("#data-viewer__viewer__header__filters");
  const $filtersAndActions = $("#data-viewer__viewer__header__filters-actions-container");

  const actionsWidth = $actions.outerWidth(true) || 0;
  const filtersWidth = $filters.outerWidth(true) || 0;
  const filtersAndActionsWidth = $filtersAndActions.width() || 0;

  if (Math.ceil(filtersAndActionsWidth) > Math.ceil(actionsWidth + filtersWidth + 8)) {
    $filtersAndActions.css({flexDirection: "row"});
    $actions.css({alignSelf: "unset"});
  } else {
    $filtersAndActions.css({flexDirection: "column-reverse"});
    $actions.css({alignSelf: "end"});
  }

  const viewerHeaderHeight = $("#data-viewer__viewer__header").outerHeight(true) || 0;
  $("#data-viewer__viewer__viewers").height(`calc(100% - ${viewerHeaderHeight}px)`);

  if (window.LMap && mapId) {
    window.LMap.handleViewportChange(mapId);
  }
};

function SingleViewerPlusViewer(props) {
  const {
    classes,

    nodeId,
    nodeCode,
    nodeExtras,
    datasetId,
    datasetTitle,
    chartId,
    mapId,

    defaultLanguage,
    languages,
    maxObservations,
    maxCells,
    showSingleGeometry,
    dataset,
    isFetchStarted,
    isFetchFailed,
    isPartialData,
    isEmptyData,
    isTooBigData,
    dimensions,
    dimensionsInfo,
    timeDim,
    freqDim,
    territoryDim,
    viewerIdx,
    isViewerHoverVisible,
    layout,
    tableLayout,
    mapLayout,
    chartLayout,
    labelFormat,
    temporalDimOrder,
    showTrend,
    showCyclical,
    areCriteriaApplied,
    criteria,
    decimalSeparator,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    enableVariation,
    codelistLists,
    codelistMaps,
    detailLevel,
    isFetchDatasetDisabled,
    isFullscreen,

    onFetchDatasetEnable,
    onDatasetFetch,
    onCriteriaShow,
    onLayoutShow,
    onTableLayoutSet,
    onMapLayoutSet,
    onChartLayoutSet,
    onLabelFormatSet,
    onVariationSet,
    onChartSettingsSet,
    onMapSettingsSet,
    onDetailLevelSet,
    onTimeSet,
    onDownloadSubmit,
    onTemporalDimOrderSet
  } = props;

  const {t} = useTranslation();

  const [tableViewerLayout, setTableViewerLayout] = useState(null);
  const [mapViewerLayout, setMapViewerLayout] = useState(null);
  const [chartViewerLayout, setChartViewerLayout] = useState(null);

  const [fontSize, setFontSize] = useState(FONT_SIZE_SELECTOR_FONT_SIZE_MD);

  const [isChartSettingVisible, setChartSettingsVisibility] = useState(false);
  const [chartSettingsTabId, setChartSettingsTabId] = useState(null);
  const [tmpChartSettings, setTmpChartSettings] = useState(null);

  const [isMapSettingVisible, setMapSettingsVisibility] = useState(false);
  const [tmpMapSettings, setTmpMapSettings] = useState(null);
  const [isMapSettingsValid, setMapSettingsValidity] = useState(true);

  const [isTableLayoutLightVisible, setTableLayoutLightVisibility] = useState(false);

  const [hierarchyOnlyAttributes] = useState(nodeExtras?.HierarchyOnlyAttributes || []);
  const [hideHierarchyOnlyRows] = useState(nodeExtras?.HideHierarchyOnlyRows || false);

  useEffect(() => {
    const func = () => handleStyle(mapId);
    window.addEventListener("resize", func);
    return () => window.removeEventListener("resize", func);
  }, [mapId]);

  useEffect(() => {
    handleStyle(mapId);
  });

  useEffect(() => {
    setTableViewerLayout(null);
    setMapViewerLayout(null);
    setChartViewerLayout(null);
  }, [viewerIdx]);

  useEffect(() => {
    if (!isViewerHoverVisible && viewerIdx === 0) {
      setTableViewerLayout(tableLayout);
    }
  }, [tableLayout, isViewerHoverVisible, viewerIdx]);

  useEffect(() => {
    if (!isViewerHoverVisible && viewerIdx === 1) {
      setMapViewerLayout(mapLayout);
    }
  }, [mapLayout, isViewerHoverVisible, viewerIdx]);

  useEffect(() => {
    if (!isViewerHoverVisible && viewerIdx >= 2) {
      setChartViewerLayout(chartLayout);
    }
  }, [chartLayout, isViewerHoverVisible, viewerIdx]);

  useEffect(() => {
    setTmpChartSettings(chartSettings);
  }, [chartSettings]);

  useEffect(() => {
    setTmpMapSettings(mapSettings);
  }, [mapSettings]);

  const onTablePageGenerationComplete = useCallback(
    generatedTableStructure => {
      const timings = generatedTableStructure.timings;
      if (timings) {
        onTimeSet(Math.round(generatedTableStructure.timings * 100) / 100);
      }
    },
    [onTimeSet]
  );

  const handleFilterSelect = useCallback(
    (dimension, value) => {
      if (viewerIdx === 0) {
        onTableLayoutSet(getUpdatedLayout(dimension, value, tableLayout, dataset, null, timeDim));
      } else if (viewerIdx === 1) {
        onMapLayoutSet(getUpdatedLayout(dimension, value, mapLayout, dataset, null, timeDim));
      } else if (viewerIdx >= 2) {
        onChartLayoutSet(getUpdatedLayout(dimension, value, chartLayout, dataset, null, timeDim));
      }
    },
    [
      viewerIdx,
      dataset,
      tableLayout,
      mapLayout,
      chartLayout,
      timeDim,
      onTableLayoutSet,
      onMapLayoutSet,
      onChartLayoutSet
    ]
  );

  const handleFetchDataset = useCallback(
    ({nodeId, datasetId, datasetTitle, criteria}) => onDatasetFetch(nodeId, datasetId, datasetTitle, criteria),
    [onDatasetFetch]
  );

  const handleFontSizeSelect = size => {
    setFontSize(size);
  };

  const handleChartSettingsOpen = () => {
    setChartSettingsTabId("general");
    setChartSettingsVisibility(true);
  };

  const handleChartSettingsClose = () => {
    setChartSettingsVisibility(false);
    setTmpChartSettings(chartSettings);
  };

  const handleChartSettingsSubmit = () => {
    setChartSettingsVisibility(false);
    onChartSettingsSet(tmpChartSettings);
  };

  const handleMapSettingsOpen = useCallback(settings => {
    setTmpMapSettings(settings);
    setMapSettingsValidity(true);
    setMapSettingsVisibility(true);
  }, []);

  const handleMapSettingsClose = () => {
    setMapSettingsVisibility(false);
  };

  const handleMapSettingsSubmit = () => {
    setMapSettingsVisibility(false);
    onMapSettingsSet(tmpMapSettings);
  };

  const datasetSize = getDatasetSize(dimensionsInfo, layout);

  return (
    <Fragment>
      <Card className={classes.root}>
        {viewerIdx !== null && (
          <div id="data-viewer__viewer__header">
            {isPartialData && (
              <Alert severity="warning" className={classes.warningAlert}>
                {t("scenes.dataViewer.warnings.maxObservations.label", {
                  maxObservations: maxObservations ? numberFormatter(maxObservations) : ""
                })}
              </Alert>
            )}
            <div id="data-viewer__viewer__header__filters-actions-container" className={classes.filtersAndActions}>
              <div id="data-viewer__viewer__header__filters" className={classes.filters}>
                <DatasetFiltersCodelistsWrapper
                  nodeCode={nodeCode}
                  datasetId={datasetId}
                  dimensions={dimensions}
                  dimensionsInfo={dimensionsInfo}
                  codelistLists={codelistLists}
                  codelistMaps={codelistMaps}
                  timeDim={timeDim}
                  layout={layout}
                  jsonStat={dataset}
                  labelFormat={labelFormat}
                  onSelect={handleFilterSelect}
                  onCriteriaShow={onCriteriaShow}
                  limit={maxObservations}
                  enclosingContainerId={"#data-viewer__viewer__header__filters-actions-container"} // data-viewer__viewer__header
                />
              </div>
              <div id="data-viewer__viewer__header__actions" className={classes.actions}>
                <Grid container>
                  {(viewerIdx === 0 || viewerIdx >= 2) && (
                    <Grid item id="temporal-dim-order-selector" className={classes.action}>
                      <TemporalDimOrderSelector
                        temporalDimOrder={temporalDimOrder}
                        setTemporalDimOrder={onTemporalDimOrderSet}
                      />
                    </Grid>
                  )}
                  {enableVariation &&
                    (viewerIdx === 0 ||
                      getViewerTypeFromIdx(viewerIdx) === CHART_TYPE_BAR ||
                      getViewerTypeFromIdx(viewerIdx) === CHART_TYPE_LINE) && (
                      <Grid item id="variation-selector" className={classes.action}>
                        <VariationSelector
                          showTrend={showTrend}
                          showCyclical={showCyclical}
                          onVariationSet={onVariationSet}
                        />
                      </Grid>
                    )}
                  <Grid item id="label-format-selector" className={classes.action}>
                    <LabelFormatSelector labelFormat={labelFormat} setLabelFormat={onLabelFormatSet} />
                  </Grid>
                  <Grid item id="export-csv" className={classes.action}>
                    <Tooltip title={t("scenes.dataViewer.actions.exportCsv.tooltip")}>
                      <IconButton
                        aria-label={t("scenes.dataViewer.actions.exportCsv.ariaLabel")}
                        onClick={() => {
                          const exportParams = {
                            labelFormat: labelFormat,
                            customLabelFormat: {
                              [timeDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
                            },
                            decimalNumber: decimalPlaces,
                            decimalSeparator: decimalSeparator,
                            emptyCellPlaceHolder: tableEmptyChar,
                            hasVariation: enableVariation,
                            showTrend: showTrend,
                            showCyclical: showCyclical,
                            temporalDimOrder: temporalDimOrder
                          };

                          const newCriteria = {...criteria};
                          if (viewerIdx === 1 && layout?.territoryDim) {
                            const territoryDim = layout.territoryDim;
                            newCriteria[territoryDim] = {
                              id: territoryDim,
                              filterValues: window.LMap.getDataIds(mapId)
                            };
                          }

                          onDownloadSubmit(
                            nodeId,
                            datasetId,
                            datasetTitle,
                            newCriteria,
                            layout,
                            DOWNLOAD_FORMAT_CSV,
                            downloadFormats()[DOWNLOAD_FORMAT_CSV].extension,
                            false,
                            exportParams,
                            defaultLanguage,
                            languages,
                            t
                          );
                        }}
                      >
                        <GetAppIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {viewerIdx >= 2 && (
                    <Grid item id="chart-settings" className={classes.action}>
                      <Tooltip title={t("scenes.dataViewer.actions.chartSettings.tooltip")}>
                        <IconButton
                          aria-label={t("scenes.dataViewer.actions.chartSettings.ariaLabel")}
                          onClick={handleChartSettingsOpen}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  {false && viewerIdx === 1 && (
                    <Grid item id="map-settings" className={classes.action}>
                      <Tooltip title={t("scenes.dataViewer.actions.mapSettings.tooltip")}>
                        <IconButton
                          aria-label={t("scenes.dataViewer.actions.mapSettings.ariaLabel")}
                          onClick={handleMapSettingsOpen}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  {viewerIdx === 0 && (
                    <Grid item id="font-size-selector" className={classes.action}>
                      <FontSizeSelector fontSize={fontSize} setFontSize={handleFontSizeSelect} />
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </div>
        )}
        <div id="data-viewer__viewer__viewers" className={classes.viewer}>
          {isViewerHoverVisible && (
            <CustomEmpty style={{position: "absolute", zIndex: 1}}>
              <Button
                id="update-viewer-btn"
                variant="contained"
                color="primary"
                endIcon={<RefreshIcon />}
                onClick={() =>
                  onFetchDatasetEnable(viewerIdx === 0 ? Math.min(maxObservations, maxCells) : maxObservations)
                }
              >
                <Trans
                  i18nKey={
                    viewerIdx === 0
                      ? t("scenes.dataViewer.singleViewerPlus.viewerUpdateHover.table.label")
                      : viewerIdx === 1
                      ? t("scenes.dataViewer.singleViewerPlus.viewerUpdateHover.map.label")
                      : t("scenes.dataViewer.singleViewerPlus.viewerUpdateHover.chart.label")
                  }
                />
              </Button>
            </CustomEmpty>
          )}
          <div style={{width: "100%", height: "100%", filter: isViewerHoverVisible ? "blur(2px)" : undefined}}>
            {!dimensions || !codelistLists ? (
              <CustomEmpty>{""}</CustomEmpty>
            ) : !areCriteriaApplied ? (
              <CustomEmpty>{t("scenes.dataViewer.errors.applyCriteria")}</CustomEmpty>
            ) : viewerIdx === 0 && (isTooBigData || datasetSize > Math.min(maxObservations, maxCells)) ? (
              <Call cb={setTableLayoutLightVisibility} cbParam={true}>
                <CustomEmpty text={t("scenes.dataViewer.singleViewerPlus.errors.tooBigTable")} />
              </Call>
            ) : viewerIdx === 1 && (isTooBigData || datasetSize > maxObservations) ? (
              <Call cb={onCriteriaShow}>
                <CustomEmpty text={t("scenes.dataViewer.singleViewerPlus.errors.tooBigMap")} />
              </Call>
            ) : viewerIdx >= 2 && (isTooBigData || datasetSize > maxObservations) ? (
              <Call cb={onLayoutShow}>
                <CustomEmpty text={t("scenes.dataViewer.singleViewerPlus.errors.tooBigChart")} />
              </Call>
            ) : (
              <Call
                cb={handleFetchDataset}
                cbParam={{
                  nodeId,
                  datasetId,
                  datasetTitle,
                  criteria: getFilteredCriteriaFromLayout(criteria, dimensions, layout, timeDim)
                }}
                disabled={isFetchDatasetDisabled}
              >
                {isFetchStarted ? (
                  <CustomEmpty>{""}</CustomEmpty>
                ) : isFetchFailed ? (
                  <CustomEmpty>{t("scenes.dataViewer.errors.fetchData")}</CustomEmpty>
                ) : isEmptyData ? (
                  <CustomEmpty>{t("scenes.dataViewer.errors.emptyData")}</CustomEmpty>
                ) : dataset && viewerIdx === 0 && tableViewerLayout ? (
                  <Table
                    jsonStat={dataset}
                    layout={tableViewerLayout}
                    isFullscreen={isFullscreen}
                    labelFormat={labelFormat}
                    showTrend={showTrend}
                    showCyclical={showCyclical}
                    fontSize={
                      fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_SM
                        ? JSONSTAT_TABLE_FONT_SIZE_SM
                        : fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_MD
                        ? JSONSTAT_TABLE_FONT_SIZE_MD
                        : JSONSTAT_TABLE_FONT_SIZE_LG
                    }
                    decimalSeparator={decimalSeparator}
                    decimalPlaces={decimalPlaces}
                    emptyChar={tableEmptyChar}
                    invertedDims={
                      temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC &&
                      !tableViewerLayout.filters.includes(timeDim)
                        ? [timeDim]
                        : null
                    }
                    hierarchyOnlyAttributes={hierarchyOnlyAttributes}
                    hideHierarchyOnlyRows={hideHierarchyOnlyRows}
                    onPageGenerationComplete={onTablePageGenerationComplete}
                    filterable
                    sortable
                  />
                ) : dataset && viewerIdx === 1 && mapViewerLayout ? (
                  <Map
                    mapId={mapId}
                    nodeId={nodeId}
                    jsonStat={dataset}
                    layout={mapViewerLayout}
                    labelFormat={labelFormat}
                    decimalSeparator={decimalSeparator}
                    decimalPlaces={decimalPlaces}
                    defaultDetailLevel={detailLevel}
                    onDetailLevelChange={onDetailLevelSet}
                    initialBaseLayer={mapSettings.baseLayer}
                    defaultSettings={{
                      isLegendCollapsed: mapSettings.isLegendCollapsed,
                      opacity: mapSettings.opacity
                    }}
                    settings={{
                      classificationMethod: mapSettings.classificationMethod,
                      paletteStartColor: mapSettings.paletteStartColor,
                      paletteEndColor: mapSettings.paletteEndColor,
                      paletteCardinality: mapSettings.paletteCardinality,
                      customIntervals: mapSettings.customIntervals
                    }}
                    setSettings={onMapSettingsSet}
                    isFullscreen={isFullscreen}
                    showSelection
                    disableSettings
                    onSettingsShow={handleMapSettingsOpen}
                    showSingleGeometry={showSingleGeometry}
                  />
                ) : dataset && viewerIdx >= 2 && chartViewerLayout ? (
                  <ChartJsonstatWrapper
                    chartId={chartId}
                    type={viewersFactory(t)[viewerIdx].chartType}
                    jsonStat={dataset}
                    layout={chartViewerLayout}
                    labelFormat={labelFormat}
                    showTrend={showTrend}
                    showCyclical={showCyclical}
                    decimalSeparator={decimalSeparator}
                    decimalPlaces={decimalPlaces}
                    chartSettings={chartSettings}
                    invertedDims={
                      temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC &&
                      !chartViewerLayout.filters.includes(timeDim)
                        ? [timeDim]
                        : null
                    }
                  />
                ) : (
                  <CustomEmpty text={t("scenes.dataViewer.errors.viewerNotAvailable")} />
                )}
              </Call>
            )}
          </div>
        </div>
      </Card>

      <Dialog open={isChartSettingVisible} maxWidth="md" fullWidth onClose={handleChartSettingsClose}>
        <CustomDialogTitle onClose={handleChartSettingsClose}>
          {t("scenes.dataViewer.singleViewerPlus.dialogs.chartSettings.title")}
        </CustomDialogTitle>
        <DialogContent style={{height: 480}}>
          <Box className={classes.settingsTabs}>
            <Tabs
              value={chartSettingsTabId}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(event, newValue) => setChartSettingsTabId(newValue)}
            >
              <Tab
                value={"general"}
                label={t("scenes.dataViewer.singleViewerPlus.dialogs.chartSettings.tabs.general")}
              />
              <Tab value={"colors"} label={t("scenes.dataViewer.singleViewerPlus.dialogs.chartSettings.tabs.colors")} />
            </Tabs>
          </Box>
          <div className={classes.settingsContent}>
            {chartSettingsTabId === "general" && (
              <ChartSettingsGeneral settings={tmpChartSettings} onSettingsSet={setTmpChartSettings} />
            )}
            {chartSettingsTabId === "colors" && (
              <ChartSettingsColors jsonStat={dataset} settings={tmpChartSettings} onSettingsSet={setTmpChartSettings} />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChartSettingsClose}>{t("commons.confirm.cancel")}</Button>
          <Button autoFocus onClick={handleChartSettingsSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isMapSettingVisible} maxWidth="md" fullWidth onClose={handleMapSettingsClose}>
        <CustomDialogTitle onClose={handleMapSettingsClose}>
          {t("scenes.dataViewer.singleViewerPlus.dialogs.mapSettings.title")}
        </CustomDialogTitle>
        <DialogContent>
          <MapSettingsSettings
            mapId={mapId}
            settings={tmpMapSettings}
            onSettingsSet={setTmpMapSettings}
            setSettingsValidity={setMapSettingsValidity}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMapSettingsClose}>{t("commons.confirm.cancel")}</Button>
          <Button color="primary" onClick={handleMapSettingsSubmit} disabled={!isMapSettingsValid}>
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <TableLayoutLight
        open={isTableLayoutLightVisible}
        onClose={() => setTableLayoutLightVisibility(false)}
        onSubmit={newLayout =>
          onTableLayoutSet(getSVPFilteredTableLayout(newLayout, codelistLists, timeDim, freqDim, territoryDim), true)
        }
        onLayoutOpen={onLayoutShow}
        layout={tableLayout}
        dimensionsInfo={dimensionsInfo}
        limit={Math.min(maxObservations, maxCells)}
      />
    </Fragment>
  );
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(SingleViewerPlusViewer);

import React, {Fragment} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {compose} from "redux";

const styles = theme => ({
  root: {
    height: "100%",
    width: "100%",
    background: "#eeeeee"
  },
  sectionHeaderContainer: {
    height: 48,
    padding: "16px 16px 0"
  },
  sectionHeaderContent: {
    width: "100%"
  },
  sectionHeaderIcon: {
    width: 24,
    marginRight: 8,
    display: "inline-block",
    verticalAlign: "middle"
  },
  sectionHeaderTitle: {
    width: `calc(100% - 32px)`,
    height: 30,
    display: "inline-block",
    verticalAlign: "middle",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  sectionContentWrapper: {
    width: "100%",
    height: "calc(100% - 48px)",
    padding: 16
  },
  draggingHover: {
    border: "1px dashed #999999"
  },
  sectionContent: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  },
  sectionItem: {
    padding: "2px 16px"
  },
  dimension: {
    marginTop: 0,
    marginBottom: 0
  },
  dimensionLabel: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  lockIcon: {
    display: "flex",
    alignItems: "center",
    "& > svg": {
      color: "gray"
    }
  }
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  marginBottom: 8,
  ...draggableStyle
});

const LayoutSection = ({classes, id, title, Icon, dimensions, dimensionsInfo, disabled, lockedDimensions}) => (
  <Card variant="outlined" className={classes.root}>
    <CardHeader
      className={classes.sectionHeaderContainer}
      title={
        <Tooltip title={title}>
          <Fragment>
            <span className={classes.sectionHeaderIcon}>{Icon}</span>
            <Typography className={classes.sectionHeaderTitle} variant="button">
              {title}
            </Typography>
          </Fragment>
        </Tooltip>
      }
      classes={{
        content: classes.sectionHeaderContent
      }}
    />
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div className={`${classes.sectionContentWrapper} ${snapshot.isDraggingOver ? classes.draggingHover : ""}`}>
          <div ref={provided.innerRef} className={classes.sectionContent}>
            {dimensions.map((item, idx) => (
              <Draggable key={idx} index={idx} draggableId={`${id}-${idx}`} isDragDisabled={disabled}>
                {(provided, snapshot) => {
                  const hasLockIcon = (lockedDimensions || []).includes(item);
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <Tooltip title={dimensionsInfo[item].label ? `[${item}] ${dimensionsInfo[item].label}` : item}>
                        <Paper className={classes.sectionItem}>
                          <Grid container spacing={1} justifyContent="space-between" wrap="nowrap">
                            <Grid item style={{width: `calc(100% - ${hasLockIcon ? 28 : 0}px)`}}>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                wrap="nowrap"
                                className={classes.dimension}
                              >
                                <Grid item className={classes.dimensionLabel}>
                                  {dimensionsInfo[item].label || item}
                                </Grid>
                                <Grid item>{`(${dimensionsInfo[item].size})`}</Grid>
                              </Grid>
                            </Grid>
                            {hasLockIcon && (
                              <Grid item className={classes.lockIcon}>
                                <LockIcon fontSize="small" />
                              </Grid>
                            )}
                          </Grid>
                        </Paper>
                      </Tooltip>
                    </div>
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  </Card>
);

export default compose(withStyles(styles))(LayoutSection);

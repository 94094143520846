import {getNodeCatalogUrl} from "../../serverApi/urls";
import {initRequest} from "../../middlewares/request/requestActions";

export const CATALOG_FETCH = "catalog/fetch";
export const CATALOG_CLEAR = "catalog/clear";

export const fetchCatalog = (nodeId: number, nodeCode: string) =>
  initRequest(
    CATALOG_FETCH,
    getNodeCatalogUrl(nodeId),
    undefined,
    undefined,
    t => ({
      onStart: t("domains.catalog.messages.fetch.start")
    }),
    {
      nodeId,
      nodeCode
    }
  );

export const clearCatalog = () => ({
  type: CATALOG_CLEAR
});

import {goToNode} from "../../links";
import {REQUEST_ERROR} from "../request/requestActions";
import {CATALOG_FETCH} from "../../state/catalog/catalogActions";
import {NODE_FETCH} from "../../state/node/nodeActions";

const nodeMiddleware = () => next => action => {
  const res = next(action);

  if (
    action.type === REQUEST_ERROR &&
    (action.payload.label === NODE_FETCH || action.payload.label === CATALOG_FETCH)
  ) {
    goToNode(action.payload.extra.nodeCode.toLowerCase());
  }

  return res;
};

export default nodeMiddleware;

export const DASHBOARD_ELEM_TYPE_KEY = "type";
export const DASHBOARD_ELEM_VALUE_KEY = "value";
export const DASHBOARD_ELEM_WIDTH_KEY = "widthPercentage";
export const DASHBOARD_ELEM_SHOW_TITLE_KEY = "showTitle";
export const DASHBOARD_ELEM_ENABLE_FILTERS_KEY = "enableFilters";
export const DASHBOARD_ELEM_FILTER_DIMENSION_KEY = "filterDimension";

export const DASHBOARD_ELEM_TYPE_VALUE_VIEW = "view";
export const DASHBOARD_ELEM_TYPE_VALUE_TEXT = "text";

export const emptyDashboardElem = {
  [DASHBOARD_ELEM_TYPE_KEY]: null,
  [DASHBOARD_ELEM_VALUE_KEY]: null,
  [DASHBOARD_ELEM_WIDTH_KEY]: 100,
  [DASHBOARD_ELEM_SHOW_TITLE_KEY]: true,
  [DASHBOARD_ELEM_ENABLE_FILTERS_KEY]: false,
  [DASHBOARD_ELEM_FILTER_DIMENSION_KEY]: null
};

export const DASHBOARD_STATUS_REQUEST_START = "DASHBOARD_STATUS_REQUEST_START";
export const DASHBOARD_STATUS_REQUEST_SUCCESS = "DASHBOARD_STATUS_REQUEST_SUCCESS";
export const DASHBOARD_STATUS_REQUEST_ERROR = "DASHBOARD_STATUS_REQUEST_ERROR";
export const DASHBOARD_STATUS_MISSING = "DASHBOARD_STATUS_MISSING";

export const DASHBOARD_VIEW_STATUS_REQUEST_START = "DASHBOARD_VIEW_STATUS_REQUEST_START";
export const DASHBOARD_VIEW_STATUS_REQUEST_ERROR = "DASHBOARD_VIEW_STATUS_REQUEST_ERROR";
export const DASHBOARD_VIEW_STATUS_EMPTY_DATASET = "DASHBOARD_VIEW_STATUS_EMPTY_DATASET";

export const getViewIdxFromRowAndCol = (rowIdx, colIdx) => `${rowIdx}x${colIdx}`;

export const getRowAndColFromViewIdx = viewIdx => ({
  row: viewIdx.split("x")[0],
  col: viewIdx.split("x")[1]
});

export const getViewIdxFromDashboardConfig = (dashboardConfig, viewId) => {
  const viewIdxMap = {};
  dashboardConfig.forEach((row, rowIdx) => {
    row.forEach((col, colIdx) => {
      if (col[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_VIEW) {
        viewIdxMap[col.value] = getViewIdxFromRowAndCol(rowIdx, colIdx);
      }
    });
  });
  return viewIdxMap[viewId];
};

export const getViewFromViewIdx = (dashboardConfig, viewIdx) => {
  const {row, col} = getRowAndColFromViewIdx(viewIdx);
  return dashboardConfig?.[row]?.[col];
};

export const getFilterDimensionId = (layout, filterDimensionIds) => {
  const dimArraysKey = ["rows", "cols", "filters", "sections", "primaryDim", "secondaryDim", "territoryDim"];
  let viewDims = [];

  dimArraysKey.forEach(key => (viewDims = viewDims.concat(layout[key] || [])));

  return filterDimensionIds.find(dimId => viewDims.includes(dimId)) || null;
};

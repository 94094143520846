import React, {forwardRef, useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import _ from "lodash";
import MaterialTable, {MTableToolbar} from "material-table";
import {useTranslation} from "react-i18next";
import {compose} from "redux";
import {v4 as uuidv4} from "uuid";

const $ = window.jQuery;

// https://github.com/mbrn/material-table#installation
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const styles = theme => ({
  table: {
    "& > div > div:nth-child(2) > div": {
      height: "100%"
    },
    "& > div > div:nth-child(2) > div > div": {
      height: "100%"
    }
  },
  toolbar: {
    width: "100%"
  },
  toolbarSearch: {
    width: "100%",
    "& > .MuiToolbar-root > *": {
      display: "none"
    },
    "& > .MuiToolbar-root > .MuiFormControl-root.MuiTextField-root": {
      display: "inline-flex",
      width: "100%"
    }
  },
  toolbarActions: {
    marginLeft: 24
  }
});

const CustomMaterialTable = ({classes, components, rightActions, ...props}, ref) => {
  const {t} = useTranslation();

  const [uuid] = useState(uuidv4);

  useEffect(() => {
    const toolbarRightActionsWidth = $(
      `#custom-material-table__${uuid} #custom-material-table__toolbar__right-actions`
    ).outerWidth(true);
    $(`#custom-material-table__${uuid} #custom-material-table__toolbar__search`).width(
      `calc(100% - ${toolbarRightActionsWidth}px)`
    );

    const toolbarHeight = $(`#custom-material-table__${uuid} #custom-material-table__toolbar`).height();
    $(`#custom-material-table__${uuid} > div > div:nth-child(2)`).height(`calc(100% - ${toolbarHeight}px)`);
  });

  // these props are meant to be used for customizing material table all spread the app
  const customProps = {
    localization: {
      body: {
        emptyDataSourceMessage: t("components.customMaterialTable.empty")
      },
      header: {
        actions: ""
      },
      toolbar: {
        searchPlaceholder: t("components.customMaterialTable.search.placeholder"),
        searchTooltip: t("components.customMaterialTable.search.tooltip")
      }
    },
    options: {
      paging: false,
      draggable: false,
      showTitle: false,
      searchFieldAlignment: "left"
    }
  };

  // props passed to this component are merged with and can override the custom props
  const mergedProps = _.merge(customProps, props);

  return (
    <div
      id={`custom-material-table__${uuid}`}
      className={`custom-material-table ${classes.table}`}
      style={{height: "100%"}}
    >
      <MaterialTable
        {...mergedProps}
        tableRef={ref}
        components={{
          ...components,
          Container: Box,
          Toolbar: toolbarProps => (
            <Grid
              container
              id={"custom-material-table__toolbar"}
              justifyContent="space-between"
              alignItems="center"
              className={classes.toolbar}
            >
              <Grid item id={"custom-material-table__toolbar__search"} className={classes.toolbarSearch}>
                <MTableToolbar {...toolbarProps} />
              </Grid>
              <Grid item id={"custom-material-table__toolbar__right-actions"}>
                {rightActions && <div className={classes.toolbarActions}>{rightActions}</div>}
              </Grid>
            </Grid>
          )
        }}
        style={{height: "100%"}}
        icons={tableIcons}
      />
    </div>
  );
};

export default compose(withStyles(styles), forwardRef)(CustomMaterialTable);

import {Reducer} from "redux";
import {INIT} from "../rootActions";

export type ConfigState = {
  baseURL: string;
  externalServices: {[key: string]: string} | null;
} | null;

const configReducer: Reducer<ConfigState> = (state = null, action) => {
  switch (action.type) {
    case INIT: {
      return {
        baseURL: action.payload.baseURL,
        externalServices: action.payload.externalServices
      };
    }
    default:
      return state;
  }
};

export default configReducer;

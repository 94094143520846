import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC, TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "./constants";

const styles = theme => ({
  root: {
    margin: "0 8px"
  },
  label: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 13,
    height: 40,
    lineHeight: "40px",
    marginRight: 8
  },
  formControl: {
    margin: "8px 0"
  },
  select: {
    "& .MuiSelect-root.MuiSelect-select": {
      padding: "2px 24px 3px 0",
      fontSize: 14
    }
  },
  menuItem: {
    fontSize: 14
  }
});

const TemporalDimOrderSelector = ({t, classes, temporalDimOrder, setTemporalDimOrder}) => (
  <Grid container className={classes.root}>
    <Grid item className={classes.label} id="label-format-selector-label">
      {t("components.temporalDimOrderSelector.title")}:
    </Grid>
    <Grid item>
      <FormControl className={classes.formControl}>
        <Select
          className={classes.select}
          value={temporalDimOrder || ""}
          onChange={ev => setTemporalDimOrder(ev.target.value)}
          SelectDisplayProps={{
            "aria-haspopup": true,
            "aria-labelledby": "label-format-selector-label"
          }}
        >
          <MenuItem className={classes.menuItem} value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC}>
            {t("components.temporalDimOrderSelector.values.asc")}
          </MenuItem>
          <MenuItem className={classes.menuItem} value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC}>
            {t("components.temporalDimOrderSelector.values.desc")}
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  </Grid>
);

export default compose(withStyles(styles), withTranslation())(TemporalDimOrderSelector);

import React from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import CustomDialogTitle from "../custom-dialog-title";
import {getAttributeLabel, getAttributeValueLabel} from "../../utils/dataset";

const styles = theme => ({
  attributesGrid: {
    display: "table",
    width: "calc(100% + 24px)",
    margin: "-8px -12px"
  },
  attributesTitle: {
    fontSize: 16,
    fontWeight: "bold"
  }
});

const AttributeDialog = ({t, classes, isOpen, onClose, obsAttributes, dimAttributes, labelFormat}) => (
  <Dialog open={isOpen} onClose={onClose}>
    <CustomDialogTitle onClose={onClose}>{t("components.map.dialogs.attributes.title")}</CustomDialogTitle>
    <DialogContent>
      <Grid container spacing={3} className={classes.attributesGrid}>
        {obsAttributes && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.attributesTitle}>
                {t("components.map.dialogs.attributes.content.obsAttributes.title")}
              </Grid>
              {obsAttributes.map((attribute, idx) => (
                <Grid item key={idx} xs={12}>
                  <b>{getAttributeLabel(attribute, labelFormat)}</b>: {getAttributeValueLabel(attribute, labelFormat)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {dimAttributes && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.attributesTitle}>
                {t("components.map.dialogs.attributes.content.dimAttributes.title")}
              </Grid>
              {dimAttributes.map((attribute, idx) => (
                <Grid item key={idx} xs={12}>
                  <b>{getAttributeLabel(attribute, labelFormat)}</b>: {getAttributeValueLabel(attribute, labelFormat)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{t("commons.confirm.close")}</Button>
    </DialogActions>
  </Dialog>
);

export default compose(withStyles(styles), withTranslation())(AttributeDialog);

import React, {Fragment, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DeleteIcon from "@material-ui/icons/Delete";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomMaterialTable from "../../custom-material-table";
import {deleteDatasetMVAdditionalDataset} from "../../../state/dataset/multi-viewer/actions";

const mapStateToProps = ({dataset}) => ({
  additionalDatasets: dataset.multiViewer.additionalDatasets
});

const mapDispatchToProps = dispatch => ({
  onDelete: datasetIdx => dispatch(deleteDatasetMVAdditionalDataset(datasetIdx))
});

const AdditionalDatasetListDialog = ({
  isOpen,
  onClose,
  onDelete,

  additionalDatasets
}) => {
  const {t} = useTranslation();

  const [datasetIdx, setDatasetIdx] = useState(null);

  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => {
    onDelete(datasetIdx);
    setDatasetIdx(null);
  };

  return (
    <Fragment>
      <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
        <CustomDialogTitle onClose={handleClose}>
          {t("components.additionalDatasetDialog.list.dialogs.list.title")}
        </CustomDialogTitle>
        <DialogContent>
          <CustomMaterialTable
            columns={[
              {
                field: "datasetTitle"
              }
            ]}
            data={(additionalDatasets || []).map((ds, idx) => ({...ds, idx: idx}))}
            actions={[
              {
                icon: DeleteIcon,
                tooltip: t("components.additionalDatasetDialog.list.dialogs.list.actions.delete"),
                onClick: (_, {idx}) => {
                  setDatasetIdx(idx);
                }
              }
            ]}
            options={{
              actionsColumnIndex: 1,
              header: false
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={datasetIdx !== null} maxWidth="xs" onClose={() => setDatasetIdx(null)}>
        <CustomDialogTitle onClose={() => setDatasetIdx(null)}>
          {t("components.additionalDatasetDialog.list.dialogs.deleteDataset.title")}
        </CustomDialogTitle>
        <DialogContent>{t("components.additionalDatasetDialog.list.dialogs.deleteDataset.content")}</DialogContent>
        <DialogActions>
          <Button onClick={() => setDatasetIdx(null)} autoFocus>
            {t("commons.confirm.cancel")}
          </Button>
          <Button onClick={handleDelete}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalDatasetListDialog);

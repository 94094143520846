import _ from "lodash";
import {Reducer} from "redux";
import {ViewerMode} from "../dataset/constants";
import {
  OTHER_CONFIG_DASHBOARD_CHANGE,
  OTHER_CONFIG_DASHBOARD_CREATE,
  OTHER_CONFIG_DASHBOARD_CREATE_SUBMIT,
  OTHER_CONFIG_DASHBOARD_DATASET_CLEAR,
  OTHER_CONFIG_DASHBOARD_DATASET_FETCH,
  OTHER_CONFIG_DASHBOARD_DATASET_FILTER_SET,
  OTHER_CONFIG_DASHBOARD_DELETE,
  OTHER_CONFIG_DASHBOARD_HIDE,
  OTHER_CONFIG_DASHBOARD_UPDATE,
  OTHER_CONFIG_DASHBOARD_UPDATE_SUBMIT,
  OTHER_CONFIG_DASHBOARD_VIEWS_CLEAR,
  OTHER_CONFIG_DASHBOARD_VIEWS_FETCH,
  OTHER_CONFIG_DASHBOARDS_CLEAR,
  OTHER_CONFIG_DASHBOARDS_FETCH,
  OTHER_CONFIG_VIEW_DELETE,
  OTHER_CONFIG_VIEWS_CLEAR,
  OTHER_CONFIG_VIEWS_FETCH
} from "./otherConfigActions";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";
import {
  DASHBOARD_ELEM_ENABLE_FILTERS_KEY,
  DASHBOARD_ELEM_TYPE_KEY,
  DASHBOARD_ELEM_TYPE_VALUE_VIEW,
  DASHBOARD_ELEM_VALUE_KEY,
  DASHBOARD_VIEW_STATUS_EMPTY_DATASET,
  DASHBOARD_VIEW_STATUS_REQUEST_ERROR,
  DASHBOARD_VIEW_STATUS_REQUEST_START,
  getViewIdxFromRowAndCol
} from "../../utils/dashboards";
import {
  FREQ_DIMENSION_KEY,
  getFilteredChartLayout,
  getFilteredMapLayout,
  getFilteredTableLayout,
  getFilterTreeFromJsonStat
} from "../../utils/dataset";
import {getChartSettingsFromViewTemplateLayouts, getMapSettingsFromViewTemplateLayouts} from "../../utils/viewTemplate";

export type OtherConfigState = {
  views: any[] | null;
  needDashboards: boolean;
  dashboards: any[] | null;
  dashboard: any | null;
  dashboardViews: any[] | null;
  dashboardJsonStats: any;
  dashboardLayoutObjs: any;
  dashboardFilterTrees: any;
  dashboardTimePeriodsByFreq: any;
};

const otherConfigReducer: Reducer<OtherConfigState> = (
  state = {
    views: null,
    needDashboards: false,
    dashboards: null,
    dashboard: null,
    dashboardViews: null,
    dashboardJsonStats: null,
    dashboardLayoutObjs: null,
    dashboardFilterTrees: null,
    dashboardTimePeriodsByFreq: null
  },
  action
) => {
  switch (action.type) {
    case OTHER_CONFIG_VIEWS_CLEAR: {
      return {
        ...state,
        views: null
      };
    }
    case OTHER_CONFIG_DASHBOARD_VIEWS_CLEAR: {
      return {
        ...state,
        dashboardViews: null
      };
    }
    case OTHER_CONFIG_DASHBOARDS_CLEAR: {
      return {
        ...state,
        dashboards: null
      };
    }
    case OTHER_CONFIG_DASHBOARD_CREATE: {
      return {
        ...state,
        dashboard: action.payload.dashboard
      };
    }
    case OTHER_CONFIG_DASHBOARD_CHANGE: {
      return {
        ...state,
        dashboard: action.payload.dashboard
      };
    }
    case OTHER_CONFIG_DASHBOARD_HIDE: {
      return {
        ...state,
        dashboard: null
      };
    }
    case OTHER_CONFIG_DASHBOARD_DATASET_CLEAR: {
      return {
        ...state,
        dashboardJsonStats: null
      };
    }
    case OTHER_CONFIG_DASHBOARD_DATASET_FILTER_SET: {
      return {
        ...state,
        dashboardLayoutObjs: {
          ...state.dashboardLayoutObjs,
          [action.payload.viewIdx]: {
            ...state.dashboardLayoutObjs[action.payload.viewIdx],
            layout: action.payload.layout
          }
        }
      };
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case OTHER_CONFIG_DASHBOARDS_FETCH: {
          return {
            ...state,
            needDashboards: false
          };
        }
        case OTHER_CONFIG_DASHBOARD_DATASET_FETCH: {
          let jsonStats = _.cloneDeep(state.dashboardJsonStats);
          let layoutObjs = _.cloneDeep(state.dashboardLayoutObjs);
          let filterTrees = _.cloneDeep(state.dashboardFilterTrees);
          let timePeriodsByFreq = _.cloneDeep(state.dashboardTimePeriodsByFreq);

          (action.payload.extra.requestIds || []).forEach((id: string) => {
            jsonStats = {
              ...jsonStats,
              [id]: DASHBOARD_VIEW_STATUS_REQUEST_START
            };
            layoutObjs = {
              ...layoutObjs,
              [id]: null
            };
            filterTrees = {
              ...filterTrees,
              [id]: null
            };
            timePeriodsByFreq = {
              ...timePeriodsByFreq,
              [id]: null
            };
          });

          return {
            ...state,
            dashboardJsonStats: jsonStats,
            dashboardLayoutObjs: layoutObjs,
            dashboardFilterTrees: filterTrees,
            dashboardTimePeriodsByFreq: timePeriodsByFreq
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case OTHER_CONFIG_VIEWS_FETCH: {
          return {
            ...state,
            views: action.payload.response.map((v: any) => ({
              ...v,
              datasetId: v.datasetId ? v.datasetId.split("+").join(",") : undefined
            }))
          };
        }
        case OTHER_CONFIG_DASHBOARD_VIEWS_FETCH: {
          return {
            ...state,
            dashboardViews: action.payload.response
              .filter((v: any) => (v?.mode || ViewerMode.SingleViewer) !== ViewerMode.MultiViewer)
              .map((v: any) => ({
                ...v,
                datasetId: v.datasetId ? v.datasetId.split("+").join(",") : undefined
              }))
          };
        }
        case OTHER_CONFIG_VIEW_DELETE: {
          return {
            ...state,
            views: null
          };
        }
        case OTHER_CONFIG_DASHBOARDS_FETCH: {
          return {
            ...state,
            dashboards: action.payload.response
          };
        }
        case OTHER_CONFIG_DASHBOARD_UPDATE: {
          const parsedDashboardConfig = JSON.parse(action.payload.response?.dashboardConfig || "{}");
          const parsedFilterLevels = JSON.parse(action.payload.response?.filterLevels || "{}");

          return {
            ...state,
            dashboard: {
              ...action.payload.response,
              dashboardConfig: parsedDashboardConfig,
              filterLevels: parsedFilterLevels
            }
          };
        }
        case OTHER_CONFIG_DASHBOARD_DELETE:
        case OTHER_CONFIG_DASHBOARD_CREATE_SUBMIT:
        case OTHER_CONFIG_DASHBOARD_UPDATE_SUBMIT: {
          return {
            ...state,
            needDashboards: true
          };
        }
        case OTHER_CONFIG_DASHBOARD_DATASET_FETCH: {
          let jsonStats = _.cloneDeep(state.dashboardJsonStats);
          let layoutObjs = _.cloneDeep(state.dashboardLayoutObjs);
          let filterTrees = _.cloneDeep(state.dashboardFilterTrees);
          let timePeriodsByFreq = _.cloneDeep(state.dashboardTimePeriodsByFreq);

          state.dashboard.dashboardConfig.forEach((row: any, rowIdx: number) => {
            row.forEach((col: any, colIdx: number) => {
              const viewIdx = getViewIdxFromRowAndCol(rowIdx, colIdx);
              if (
                col[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_VIEW &&
                action.payload.extra.requestIds.includes(viewIdx)
              ) {
                const view = state.dashboard.views[col[DASHBOARD_ELEM_VALUE_KEY]];
                const jsonStat = action.payload.response;

                if ((jsonStat?.id || []).length === 0) {
                  jsonStats[viewIdx] = DASHBOARD_VIEW_STATUS_EMPTY_DATASET;
                  layoutObjs[viewIdx] = null;
                  filterTrees[viewIdx] = null;
                  timePeriodsByFreq[viewIdx] = null;
                } else {
                  const timeDim = jsonStat.role?.time?.[0];

                  const viewLayouts = JSON.parse(view.layouts);
                  let viewLayout;
                  if (viewLayouts.tableLayout) {
                    viewLayout = getFilteredTableLayout(viewLayouts.tableLayout, jsonStat);
                  } else if (viewLayouts.mapLayout) {
                    viewLayout = getFilteredMapLayout(viewLayouts.mapLayout, jsonStat);
                  } else {
                    viewLayout = getFilteredChartLayout(viewLayouts.chartLayout, jsonStat);
                  }

                  jsonStats[viewIdx] = jsonStat;
                  layoutObjs[viewIdx] = {
                    layout: viewLayout,
                    labelFormat: viewLayouts.labelFormat,
                    showTrend: viewLayouts.showTrend,
                    showCyclical: viewLayouts.showCyclical,
                    tableEmptyChar: viewLayouts.tableEmptyChar,
                    chartSettings: getChartSettingsFromViewTemplateLayouts(viewLayouts),
                    mapSettings: getMapSettingsFromViewTemplateLayouts(viewLayouts),
                    detailLevel:
                      viewLayouts.detailLevel !== null && viewLayouts.detailLevel !== undefined
                        ? viewLayouts.detailLevel
                        : viewLayouts.mapDetailLevel !== null && viewLayouts.mapDetailLevel !== undefined
                        ? viewLayouts.mapDetailLevel
                        : null
                  };
                  const filters = [
                    ...(viewLayout.primaryDim || []),
                    ...(viewLayout.secondaryDim || []),
                    ...(viewLayout.filters || [])
                  ];
                  filterTrees[viewIdx] = col[DASHBOARD_ELEM_ENABLE_FILTERS_KEY]
                    ? getFilterTreeFromJsonStat(filters, jsonStat)
                    : null;
                  if (jsonStat.id.includes(FREQ_DIMENSION_KEY) && jsonStat.id.includes(timeDim)) {
                    const timePeriodsByFreqTree: {[key: string]: any} = getFilterTreeFromJsonStat(
                      [FREQ_DIMENSION_KEY, timeDim],
                      jsonStat
                    );
                    timePeriodsByFreq[viewIdx] = {};
                    Object.keys(timePeriodsByFreqTree).forEach((freq: string) => {
                      timePeriodsByFreq[viewIdx][freq] = Object.keys(timePeriodsByFreqTree[freq]);
                    });
                  }
                }
              }
            });
          });

          return {
            ...state,
            dashboardJsonStats: jsonStats,
            dashboardLayoutObjs: layoutObjs,
            dashboardFilterTrees: filterTrees,
            dashboardTimePeriodsByFreq: timePeriodsByFreq
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR:
      switch (action.payload.label) {
        case OTHER_CONFIG_DASHBOARDS_FETCH: {
          return {
            ...state,
            dashboards: action.payload.statusCode === 404 ? [] : null
          };
        }
        case OTHER_CONFIG_DASHBOARD_DATASET_FETCH: {
          let jsonStats = _.cloneDeep(state.dashboardJsonStats);
          let layoutObjs = _.cloneDeep(state.dashboardLayoutObjs);
          let filterTrees = _.cloneDeep(state.dashboardFilterTrees);
          let timePeriodsByFreq = _.cloneDeep(state.dashboardTimePeriodsByFreq);

          (action.payload.extra.requestIds || []).forEach((id: string) => {
            jsonStats = {
              ...jsonStats,
              [id]: DASHBOARD_VIEW_STATUS_REQUEST_ERROR
            };
            layoutObjs = {
              ...layoutObjs,
              [id]: null
            };
            filterTrees = {
              ...filterTrees,
              [id]: null
            };
            timePeriodsByFreq = {
              ...timePeriodsByFreq,
              [id]: null
            };
          });

          return {
            ...state,
            dashboardJsonStats: jsonStats,
            dashboardLayoutObjs: layoutObjs,
            dashboardFilterTrees: filterTrees,
            dashboardTimePeriodsByFreq: timePeriodsByFreq
          };
        }
        default:
          return state;
      }
    default:
      return state;
  }
};

export default otherConfigReducer;

export const numberFormatter = new Intl.NumberFormat().format;

export const DECIMAL_SEPARATOR_DEFAULT = ",";
export const DECIMAL_PLACES_DEFAULT = 2;
export const TABLE_EMPTY_CHAR_DEFAULT = "";

const getFormattedInt = (valueStr, separator) => {
  let subStrs = [];
  let sign = "";

  if (valueStr[0] === "-" || valueStr[0] === "+") {
    sign = valueStr[0];
    valueStr = valueStr.slice(1);
  }

  while (valueStr.length > 3) {
    subStrs.push(valueStr.slice(-3));
    valueStr = valueStr.slice(0, -3);
  }
  if (valueStr.length > 0) {
    subStrs.push(valueStr);
  } else {
    subStrs.pop();
  }
  subStrs.reverse();

  return sign + subStrs.join(separator);
};

const getRoundedNumber = (number, decimalPlaces) =>
  Math.round(number * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

export const getFormattedValue = (
  value,
  decimalSeparator = DECIMAL_SEPARATOR_DEFAULT,
  decimalPlaces = DECIMAL_PLACES_DEFAULT,
  emptyChar = TABLE_EMPTY_CHAR_DEFAULT
) => {
  const thousandsSeparator = decimalSeparator === "." ? "," : ".";

  if (value === null || value === undefined || (typeof value === "string" && value.length === 0)) {
    return emptyChar;
  }

  let val = parseFloat(value);

  if (isNaN(val)) {
    return value;
  }

  val = decimalPlaces >= 0 ? getRoundedNumber(val, decimalPlaces) : val;

  const valParts = val.toString().split(".");

  let integerPart = valParts[0];
  integerPart = getFormattedInt(integerPart, thousandsSeparator);

  let decimalPart = valParts[1] || "";
  if (decimalPlaces >= 0) {
    decimalPart += "0".repeat(decimalPlaces - decimalPart.length);
  }
  decimalPart =
    decimalPart.length > 0 && decimalPart.search(/[^0]/gm) !== -1 // value has a decimal part greater than 0
      ? decimalPart
      : "";

  return integerPart + (decimalPart.length > 0 ? decimalSeparator + decimalPart : "");
};

export const getTrimmedStr = str => {
  if (!str || str.length === 0) {
    return "";
  }

  if (String.prototype.trim) {
    return str.trim();
  } else {
    return str.replace(/^\s+|\s+$/gm, "");
  }
};

export const toUpperCaseFirst = string =>
  string !== null && string !== undefined && string.length > 0 ? string[0].toUpperCase() + string.slice(1) : string;

const SECONDS_PER_DAY = 86400;
const SECONDS_PER_HOUR = 3600;

/**
 * Convert seconds to HH:MM:SS
 * If seconds exceeds 24 hours, hours will be greater than 24 (30:05:10)
 *
 * @param {number} totalSeconds
 * @param {function} t
 * @returns {string}
 */
export const secondsToHms = (totalSeconds, t) => {
  if (totalSeconds < 0) {
    return t("scenes.nodeSettings.cacheSettings.alwaysValid");
  } else if (totalSeconds === 0) {
    return t("scenes.nodeSettings.cacheSettings.disabled");
  } else {
    var days = Math.floor(totalSeconds / SECONDS_PER_DAY);
    var hours = Math.floor((totalSeconds - days * SECONDS_PER_DAY) / SECONDS_PER_HOUR);
    var minutes = Math.floor((totalSeconds - days * SECONDS_PER_DAY - hours * SECONDS_PER_HOUR) / 60);
    var seconds = totalSeconds - days * SECONDS_PER_DAY - hours * SECONDS_PER_HOUR - minutes * 60;

    // round seconds
    seconds = Math.round(seconds * 100) / 100;

    return wellFormattedTimeString(days, hours, minutes, seconds);
  }
};

const wellFormattedTimeString = (days, hours, mins, sec) => {
  var result = "";
  result += days > 0 ? days + "d " : ""; // hide days if 0
  result += (hours < 10 ? " " + hours : hours) + "h ";
  result += (mins < 10 ? " " + mins : mins) + "m ";

  if (days === 0) {
    // truncate if days is greater than zero
    result += (sec < 10 ? " " + sec : sec) + "s";
  }

  return result;
};

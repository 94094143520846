import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomMaterialTable from "../../custom-material-table";
import SettingsDialog from "../../settings-dialog";
import UserSettingsForm from "./user-settings-form";
import {
  clearUsersConfigUsers,
  deleteUsersConfigUser,
  fetchUsersConfigUsers,
  hideUserConfigForm,
  showUserConfigForm
} from "../../../state/usersConfig/usersConfigActions";
import {localizeI18nObj} from "../../../utils/i18n";
import "./style.css";

const styles = theme => ({
  tableToolbar: {
    marginBottom: theme.spacing(1)
  },
  buttonAction: {
    ...theme.typography.button
  }
});

const mapStateToProps = state => ({
  config: state.usersConfig.users,
  userErrors: state.usersConfig.userErrors,
  userId: state.usersConfig.userId,
  appConfig: state.appConfig,
  languages: state.app.languages,
  language: state.app.language
});

const mapDispatchToProps = dispatch => ({
  fetchConfig: () => dispatch(fetchUsersConfigUsers()),
  deleteUser: userId => dispatch(deleteUsersConfigUser(userId)),
  clearConfig: () => dispatch(clearUsersConfigUsers()),
  onUserFormShow: userId => dispatch(showUserConfigForm(userId)),
  onUserFormHide: () => dispatch(hideUserConfigForm())
});

const UsersSettingsForm = (
  {
    classes,
    config,
    fetchConfig,
    deleteUser,
    clearConfig,
    users,
    userErrors,
    userId,
    onUserFormShow,
    onUserFormHide,
    appConfig,
    languages,
    language
  },
  ref
) => {
  const [needConfig, setNeedConfig] = useState(true);

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchConfig();
    }
  }, [config, needConfig, setNeedConfig, fetchConfig]);

  useImperativeHandle(ref, () => ({
    cancel(f) {
      clearConfig();
      f();
    }
  }));

  const [userDeleteFormUserId, setUserDeleteFormUserId] = useState(null);

  const userFormRef = useRef();

  const {t} = useTranslation();

  return (
    config && (
      <div className="users-settings-form__table" style={{height: "100%"}}>
        <CustomMaterialTable
          rightActions={
            <Button size="small" startIcon={<AddIcon />} onClick={() => onUserFormShow(-1)}>
              {t("scenes.usersSettings.createUser")}
            </Button>
          }
          columns={[
            {field: "firstName", title: t("scenes.usersSettings.table.columns.userFirstName")},
            {field: "lastName", title: t("scenes.usersSettings.table.columns.userLastName")},
            {
              field: "type",
              title: t("scenes.usersSettings.table.columns.type"),
              render: ({type}) =>
                localizeI18nObj(appConfig.user.typeOptions?.find(({id}) => type === id)?.label, language, languages)
            },
            {field: "organization", title: t("scenes.usersSettings.table.columns.userOrganization")},
            {field: "providerLabel", title: t("scenes.usersSettings.table.columns.userProvider")},
            {field: "email", title: t("scenes.usersSettings.table.columns.userEmail")},
            {
              field: "isActive",
              title: t("scenes.usersSettings.table.columns.isActive.title"),
              render: ({isActive}) =>
                isActive
                  ? t("scenes.usersSettings.table.columns.isActive.values.true")
                  : t("scenes.usersSettings.table.columns.isActive.values.false")
            }
          ]}
          data={config}
          actions={[
            {
              icon: EditIcon,
              tooltip: t("scenes.usersSettings.table.actions.editUser"),
              onClick: (_, {userId}) => {
                onUserFormShow(userId);
              }
            },
            {
              icon: DeleteIcon,
              tooltip: t("scenes.usersSettings.table.actions.deleteUser"),
              onClick: (_, {userId}) => {
                setUserDeleteFormUserId(userId);
              }
            }
          ]}
          options={{
            actionsColumnIndex: -1
          }}
        />
        <SettingsDialog
          title={
            userId === -1 ? t("scenes.usersSettings.modals.createUser") : t("scenes.usersSettings.modals.editUser")
          }
          open={userId !== null || userErrors !== null}
          onClose={() => {
            if (userFormRef.current) {
              userFormRef.current.cancel(() => {
                onUserFormHide();
              });
            } else {
              onUserFormHide();
            }
          }}
          onSubmit={() => {
            if (userFormRef.current) {
              userFormRef.current.submit(() => {});
            }
          }}
          hasSubmit
        >
          <UserSettingsForm ref={userFormRef} userId={userId} />
        </SettingsDialog>

        <Dialog maxWidth="xs" open={userDeleteFormUserId !== null} onClose={() => setUserDeleteFormUserId(null)}>
          <CustomDialogTitle onClose={() => setUserDeleteFormUserId(null)}>
            {t("scenes.usersSettings.modals.deleteUser.title")}
          </CustomDialogTitle>
          <DialogContent>{t("scenes.usersSettings.modals.deleteUser.content")}</DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setUserDeleteFormUserId(null)}>
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              onClick={() => {
                deleteUser(userDeleteFormUserId);
                setUserDeleteFormUserId(null);
              }}
            >
              {t("commons.confirm.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  );
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(UsersSettingsForm);

import _ from "lodash";
import moment from "moment";
import {getNutsLevelTranslations} from "../../constants/getNutsLevelTranslations";
import {MAP_CLASSIFICATION_METHOD_CUSTOM_INTERVALS, MAP_LAYERS_ALL, MAP_LAYERS_NONE} from "./constants";
import {
  getCompleteValueDims,
  getCoordinatesArrayFromDataIdx,
  getDataIdxFromCoordinatesArray,
  getDimensionAttributeMap,
  getDimensionValuesIndexesMap,
  getFormattedDimensionValueLabel,
  getObservationAttributeMap,
  VARIATION_DIMENSION_KEY,
  VARIATION_VALUE_VALUE_KEY
} from "../../utils/dataset";
import {localizeI18nObj} from "../../utils/i18n";
import {isNumeric} from "../../utils/validator";

export const getLayerOptions = (mapLayer, initialBaseLayer, mapLayersConfig, defaultLanguage, languages) => {
  let baseLayer, layers;

  if (mapLayer === MAP_LAYERS_NONE) {
    baseLayer = null;
    layers = null;
  } else if (mapLayer === MAP_LAYERS_ALL) {
    baseLayer = initialBaseLayer;
    layers = mapLayersConfig;
  } else {
    baseLayer = mapLayer;
    layers = mapLayersConfig.filter(({id}) => id === mapLayer);
  }

  if (layers) {
    layers = layers.map(({name, credits, ...layer}) => ({
      ...layer,
      name: localizeI18nObj(name, defaultLanguage, languages),
      credits: localizeI18nObj(credits, defaultLanguage, languages)
    }));
  }

  return {
    baseLayer: baseLayer,
    layers: layers
  };
};

export const getTranslationOptions = t => ({
  configure: t("components.map.translation.configure"),
  opacity: t("components.map.translation.opacity"),
  from: t("components.map.translation.from"),
  to: t("components.map.translation.to"),
  cancel: t("components.map.translation.cancel"),
  apply: t("components.map.translation.apply"),
  classification: t("components.map.translation.classification"),
  equalInterval: t("components.map.translation.equalInterval"),
  naturalBreaks: t("components.map.translation.naturalBreaks"),
  quantile: t("components.map.translation.quantile"),
  customIntervals: t("components.map.translation.customIntervals"),
  customIntervalsFrom: t("components.map.translation.customIntervalsFrom"),
  customIntervalsTo: t("components.map.translation.customIntervalsTo"),
  numberOfClasses: t("components.map.translation.numberOfClasses"),
  startColor: t("components.map.translation.startColor"),
  endColor: t("components.map.translation.endColor"),
  preview: t("components.map.translation.preview"),
  collapse: t("components.map.translation.collapse"),
  uncollapse: t("components.map.translation.uncollapse"),
  allowedPaletteCardinality: ({min, max}) =>
    t("components.map.translation.allowedPaletteCardinality", {min: min, max: max}),
  invalidCustomIntervals: t("components.map.translation.invalidCustomIntervals"),
  linearSelectionTooltip: step => {
    if (step === 0) {
      return t("components.map.translation.linearSelectionTooltip.step0");
    } else {
      return t("components.map.translation.linearSelectionTooltip.steps");
    }
  },
  polygonalSelectionTooltip: step => {
    if (step === 0) {
      return t("components.map.translation.polygonalSelectionTooltip.step0");
    } else if (step === 1) {
      return t("components.map.translation.polygonalSelectionTooltip.step1");
    } else {
      return t("components.map.translation.polygonalSelectionTooltip.steps");
    }
  },
  rectangularSelectionTooltip: step => {
    if (step === 0) {
      return t("components.map.translation.rectangularSelectionTooltip.step0");
    } else {
      return t("components.map.translation.rectangularSelectionTooltip.steps");
    }
  },
  circularSelectionTooltip: (step, infos) => {
    if (step === 0) {
      return t("components.map.translation.circularSelectionTooltip.step0");
    } else {
      if (infos && infos.diameter) {
        const formatter = diameter => {
          if (diameter < 1000) {
            return Math.round(diameter) + " m";
          } else if (diameter < 10000) {
            return (diameter / 1000).toFixed(1) + " km";
          } else {
            return Math.round(diameter / 1000) + " km";
          }
        };
        return t("components.map.translation.circularSelectionTooltip.stepsWithDiameter", {
          diameter: formatter(infos.diameter)
        });
      } else {
        return t("components.map.translation.circularSelectionTooltip.steps");
      }
    }
  },
  zoomIn: t("components.map.translation.zoomIn"),
  zoomOut: t("components.map.translation.zoomOut"),
  zoomHome: t("components.map.translation.zoomHome"),
  linearSelection: t("components.map.translation.linearSelection"),
  polygonalSelection: t("components.map.translation.polygonalSelection"),
  rectangularSelection: t("components.map.translation.rectangularSelection"),
  circularSelection: t("components.map.translation.circularSelection"),
  clearSelection: t("components.map.translation.clearSelection"),
  configureLayers: t("components.map.translation.configureLayers"),
  changeBaseLayer: t("components.map.translation.changeBaseLayer"),
  abortSelection: t("components.map.translation.abortSelection"),
  expandSelectionButtons: t("components.map.translation.expandSelectionButtons"),
  collapseSelectionButtons: t("components.map.translation.collapseSelectionButtons"),
  noBaseLayer: t("components.map.translation.noBaseLayer")
});

export const getDetailLevelsFromGeometries = (geometries, showSingleGeometry, levelsOrder, t) =>
  geometries
    .reduce((acc, geom) => {
      const level = geom.level;
      const levelOrder = (levelsOrder || []).indexOf(level);
      if (!acc[level]) {
        acc[level] = {
          value: level,
          label: getNutsLevelTranslations(t)[level],
          order: levelOrder > -1 ? levelOrder : Number.MAX_SAFE_INTEGER,
          counter: 0
        };
      }
      acc[level].counter++;
      return acc;
    }, [])
    .filter(val => showSingleGeometry || val.counter > 1)
    .sort((a, b) => a.order - b.order);

export const getLayoutGeometries = (
  geometries,
  jsonStat,
  layout,
  labelFormat,
  t,
  keepEmptyGeometries = false,
  dimensionValueTree = null,
  isPointData = false,
  latAttributeId = null,
  longAttributeId = null
) => {
  const layoutGeometries = [];
  const geometryMap = {};
  const territoryMap = {};

  const geometryMapByTerritoryId = {};
  (geometries || []).forEach(g => (geometryMapByTerritoryId[g.id] = g));

  const datasetId = jsonStat?.extension?.datasets?.[0];

  const territoryDim = layout.territoryDim;
  const territoryDimValues = jsonStat.dimension[territoryDim].category.index;
  const indexesMap = getDimensionValuesIndexesMap(jsonStat);
  const obsAttributeMap = getObservationAttributeMap(jsonStat);
  const dimAttributeMap = getDimensionAttributeMap(jsonStat, t)[datasetId];

  let filtersValue = {...layout.filtersValue};
  if (jsonStat.id.includes(VARIATION_DIMENSION_KEY)) {
    filtersValue = {...filtersValue, [VARIATION_DIMENSION_KEY]: VARIATION_VALUE_VALUE_KEY};
  }

  const orderedDims = [
    layout.territoryDim,
    ...layout.filters,
    ...jsonStat.id.filter(dim => dim !== layout.territoryDim && !layout.filters.includes(dim))
  ];

  territoryDimValues.forEach(territoryDimValue => {
    const serverGeometry = geometryMapByTerritoryId[territoryDimValue];

    if (serverGeometry || keepEmptyGeometries) {
      let dimValueArray = jsonStat.id.map(dim => {
        if (dim === territoryDim) {
          return territoryDimValue;
        } else if (filtersValue[dim]) {
          return filtersValue[dim];
        } else {
          return null;
        }
      });

      if (dimValueArray.findIndex(val => val === null) > -1 && dimensionValueTree) {
        dimValueArray = getCompleteValueDims(dimValueArray, jsonStat.id, orderedDims, dimensionValueTree);
      }

      if (dimValueArray) {
        const dimValueIndexArray = dimValueArray.map((value, idx) => indexesMap[jsonStat.id[idx]][value]);
        const valueIdx = getDataIdxFromCoordinatesArray(dimValueIndexArray, jsonStat.size);

        const jsonStatValue = jsonStat.value[valueIdx];
        const value =
          jsonStatValue !== null && jsonStatValue !== "" && !isNaN(jsonStatValue) ? Number(jsonStatValue) : null;

        if (value !== null) {
          if (!serverGeometry) {
            layoutGeometries.push({
              value: value,
              id: territoryDimValue,
              identifier: Number.parseInt(territoryDimValue), // TODO !!!
              geometry: {
                type: "Polygon",
                coordinates: []
              },
              label: getFormattedDimensionValueLabel(jsonStat, null, territoryDim, territoryDimValue, labelFormat, t)
            });
          } else {
            const obsAttributes = obsAttributeMap?.[valueIdx] || null;
            const dimAttributes = dimAttributeMap[territoryDim][territoryDimValue] || null;
            const hasAttributes = (obsAttributes || []).concat(dimAttributes || []).length > 0;

            let geometry;
            if (!isPointData) {
              geometry = serverGeometry.geoJson || serverGeometry.wkt;
            } else {
              const long = (dimAttributes || []).find(({id}) => id === longAttributeId)?.valueId;
              const lat = (dimAttributes || []).find(({id}) => id === latAttributeId)?.valueId;
              if (isNumeric(long) && isNumeric(lat)) {
                geometry = {
                  type: "Point",
                  coordinates: [Number(long), Number(lat)]
                };
              }
            }

            if (geometry) {
              layoutGeometries.push({
                value: value,
                id: serverGeometry.id,
                identifier: serverGeometry.uniqueId,
                level: serverGeometry.nutsLevel,
                geometry: geometry,
                label: getFormattedDimensionValueLabel(jsonStat, null, territoryDim, territoryDimValue, labelFormat, t),
                clickableTextPrefix: hasAttributes
                  ? t("components.map.tooltip.attributes.clickableTextPrefix")
                  : undefined,
                clickableText: hasAttributes ? t("components.map.tooltip.attributes.clickableText") : undefined,
                clickableTextSuffix: hasAttributes
                  ? t("components.map.tooltip.attributes.clickableTextSuffix")
                  : undefined,
                isTooltipClickable: hasAttributes
              });

              geometryMap[serverGeometry.uniqueId] = {
                hasAttributes: hasAttributes,
                obsAttributes: obsAttributes,
                dimAttributes: dimAttributes,
                territoryId: territoryDimValue
              };

              territoryMap[serverGeometry.id] = serverGeometry.uniqueId;
            }
          }
        }
      }
    }
  });

  return {
    layoutGeometries,
    geometryMap,
    territoryMap
  };
};

export const getTerritoriesWithValue = (jsonStat, territoryDim) => {
  const map = {};
  const territoryDimIdx = jsonStat.id.indexOf(territoryDim);

  Object.keys(jsonStat.value).forEach(key => {
    const val = jsonStat.value[key];
    if (val !== undefined && val !== null && val !== "") {
      const coordinates = getCoordinatesArrayFromDataIdx(key, jsonStat.size);
      const territoryDimValue = jsonStat.dimension[territoryDim].category.index[coordinates[territoryDimIdx]];
      map[territoryDimValue] = true;
    }
  });

  return Object.keys(map);
};

export const getTimeDimValueFromLayout = (jsonStat, layout) => {
  const timeDim = jsonStat.role?.time?.[0];
  const timeDimValue = timeDim && layout.filtersValue[timeDim] ? layout.filtersValue[timeDim] : null;

  const timeDimValueYear = moment(timeDimValue);

  return timeDimValueYear.isValid() ? timeDimValueYear.format("YYYY") : null;
};

export const isSettingsChanged = (newSettings, prevSettings) => {
  let isChanged = false;

  if (!prevSettings) {
    return true;
  }

  for (let key in newSettings) {
    if (newSettings.hasOwnProperty(key)) {
      if (!_.isEqual(newSettings?.[key] || null, prevSettings?.[key] || null)) {
        isChanged = true;
      }
    }
  }

  return isChanged;
};

export const getCleanedSetting = settings => {
  const newSettings = {
    ..._.cloneDeep(settings),
    customIntervals:
      settings?.classificationMethod !== undefined
        ? settings.classificationMethod === MAP_CLASSIFICATION_METHOD_CUSTOM_INTERVALS
          ? settings.customIntervals
          : null
        : undefined
  };

  Object.keys(newSettings).forEach(key => {
    if (newSettings[key] === undefined || newSettings[key] === null) {
      delete newSettings[key];
    }
  });

  return newSettings;
};

export const getOverlayStyle = (theme, solidOverlay) => ({
  position: "absolute",
  zIndex: 3,
  color: "white",
  background: solidOverlay ? "#F5F5F5" : "rgba(0, 0, 0, 0.1)"
});

export const getOverlayTextStyle = (theme, solidOverlay) => ({
  fontWeight: solidOverlay ? "normal" : "bold",
  color: solidOverlay ? theme.palette.primary.main : "white"
});

export const getOverlayImageStyle = (theme, solidOverlay) => ({
  color: solidOverlay ? theme.palette.primary.main : "white"
});

export const getGeneralizationLevelFromZoomLevel = (zoomLevel, zoomLevelSteps, generalizationLevels) => {
  const idx = (zoomLevelSteps || []).findIndex(el => el >= zoomLevel);

  return idx === -1
    ? generalizationLevels[0]
    : idx === 0
    ? generalizationLevels[generalizationLevels.length - 1]
    : generalizationLevels[idx];
};

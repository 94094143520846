import React, {Fragment, useCallback, useRef, useState} from "react";
import {DialogActions, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {getUserErrorsTranslations} from "../../constants/getUserErrorsTranslations";
import {goToHome} from "../../links";
import CustomDialogTitle from "../custom-dialog-title";
import SettingsDialog from "../settings-dialog";
import UserRecoverPasswordForm from "./user-recover-password-form";

const styles = theme => ({
  field: {
    marginTop: theme.spacing(3)
  },
  recoverPassword: {
    textDecoration: "underline",
    color: theme.palette.secondary.main,
    cursor: "pointer"
  }
});

const defaultValue = {
  email: null,
  password: null
};

const UserLoginDialog = ({
  open,
  disableRegistration,
  onSubmit,
  onHide,
  onRegister,
  classes,
  alert,
  alertSeverity = "error",
  loginOrHome
}) => {
  const {
    register,
    formState: {errors},
    handleSubmit,
    reset
  } = useForm(defaultValue);

  const {t} = useTranslation();

  const [isRecoverPasswordModalOpen, setIsRecoverPasswordModalOpen] = useState(false);

  const recoverPasswordFormRef = useRef();

  const handleFormClose = useCallback(() => {
    onHide();
    reset(defaultValue);
    if (loginOrHome) {
      goToHome();
    }
  }, [onHide, reset, loginOrHome]);

  const handleFormSubmit = useCallback(() => {
    handleSubmit(data => {
      onSubmit(data);
      // reset(defaultValue); reset should be done only on submit success
    })();
  }, [handleSubmit, onSubmit]);

  const keyDownHandler = useCallback(
    evt => {
      if (evt.key === "Enter") {
        handleFormSubmit();
      }
    },
    [handleFormSubmit]
  );

  return (
    <Dialog
      open={open || false}
      maxWidth="xs"
      fullWidth
      onClose={handleFormClose}
      TransitionProps={{
        onEntered: () => {
          const userField = document.getElementById("user-login-form__text-field__email");
          const pswField = document.getElementById("user-login-form__text-field__password");
          userField.addEventListener("keydown", keyDownHandler);
          pswField.addEventListener("keydown", keyDownHandler);
        },
        onExit: () => {
          const userField = document.getElementById("user-login-form__text-field__email");
          const pswField = document.getElementById("user-login-form__text-field__password");
          userField.removeEventListener("keydown", keyDownHandler);
          pswField.removeEventListener("keydown", keyDownHandler);
        }
      }}
    >
      <CustomDialogTitle onClose={handleFormClose}>{t("components.userLoginDialog.title")}</CustomDialogTitle>
      <DialogContent>
        {alert && (
          <Alert severity={alertSeverity}>{getUserErrorsTranslations(t)[alert] || t("errors.user.generic")}</Alert>
        )}
        <FormControl fullWidth className={alert ? classes.field : undefined}>
          <TextField
            {...register("email", {
              required: t("commons.validation.required")
            })}
            id="user-login-form__text-field__email"
            name="email"
            label={t("components.userLoginDialog.fields.email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            variant="outlined"
            required
            autoFocus
          />
        </FormControl>
        <FormControl fullWidth className={classes.field}>
          <TextField
            {...register("password")}
            id="user-login-form__text-field__password"
            name="password"
            type="password"
            label={t("components.userLoginDialog.fields.password")}
            error={!!errors.password}
            helperText={
              <Fragment>
                {errors.password?.message}
                <span
                  onClick={() => setIsRecoverPasswordModalOpen(true)}
                  className={classes.recoverPassword}
                  style={{display: "block"}}
                >
                  {t("components.userLoginDialog.recoverPassword")}
                </span>
              </Fragment>
            }
            variant="outlined"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        {loginOrHome ? (
          <Button onClick={handleFormClose}>{t("components.userLoginDialog.goToHome")}</Button>
        ) : (
          <Fragment>
            <Button onClick={handleFormClose}>{t("commons.confirm.cancel")}</Button>
            {!disableRegistration && <Button onClick={onRegister}>{t("components.userLoginDialog.register")}</Button>}
          </Fragment>
        )}
        <Button onClick={handleFormSubmit}>{t("components.userLoginDialog.login")}</Button>
      </DialogActions>
      <SettingsDialog
        title={t("components.userLoginDialog.recoverPasswordModal.title")}
        maxWidth={"xs"}
        open={isRecoverPasswordModalOpen}
        onClose={() => {
          if (recoverPasswordFormRef.current) {
            recoverPasswordFormRef.current.cancel(() => {
              setIsRecoverPasswordModalOpen(false);
            });
          } else {
            setIsRecoverPasswordModalOpen(false);
          }
        }}
        onSubmit={() => {
          if (recoverPasswordFormRef.current) {
            recoverPasswordFormRef.current.submit(() => {
              setIsRecoverPasswordModalOpen(false);
            });
          }
        }}
        hasSubmit
        noMinHeight
        noFullScreen
      >
        <UserRecoverPasswordForm ref={recoverPasswordFormRef} />
      </SettingsDialog>
    </Dialog>
  );
};

export default withStyles(styles)(UserLoginDialog);

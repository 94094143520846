import React from "react";
import Box from "@material-ui/core/Box";
import {sanitize} from "dompurify";

const SanitizedHTML = ({html, allowTarget, className, ...props}) => (
  <Box
    {...props}
    className={"sanitized-html" + (className ? ` ${className}` : "")}
    html={undefined}
    dangerouslySetInnerHTML={{
      __html: sanitize(html, {
        ADD_ATTR: [allowTarget ? "target" : ""]
      })
    }}
  />
);

export default SanitizedHTML;

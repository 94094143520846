import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {compose} from "redux";
import {localizeI18nObj} from "../../utils/i18n";
import "./style.css";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "relative"
  },
  background: {
    "&, & > *": {
      position: "fixed",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      zIndex: -9999
    }
  },
  textContainer: {
    color: "white",
    textAlign: "center",
    padding: 16,
    background: "rgba(0,0,0,0.5)"
  },
  title: {
    fontSize: 60,
    padding: 8,
    letterSpacing: 0
  },
  slogan: {
    fontSize: 24,
    fontWeight: 400,
    padding: 8,
    letterSpacing: 0
  },
  buttonsContainer: {
    marginTop: 24
  },
  logo: {
    position: "absolute",
    top: "80%",
    width: "80%",
    textAlign: "center",
    "& > *": {
      maxWidth: 340,
      maxHeight: 64
    }
  },
  extraLogos: {
    top: 24,
    right: 24,
    position: "absolute",
    "& img": {
      maxHeight: 80
    }
  }
});

const Hero = ({
  classes,
  defaultLanguage,
  languages,
  homeExtraLogos,
  title,
  slogan,
  logo,
  background,
  showExtraLogos = false,
  children
}) => (
  <Fragment>
    <Helmet>
      <style type="text/css">
        {`
          html, body, #root {
            height: 100%
          }
        `}
      </style>
    </Helmet>
    <div id="hero" className={classes.root + " hero"}>
      <div id="hero__extra-logos" className={classes.extraLogos}>
        {showExtraLogos &&
          (homeExtraLogos || []).map(({src, href, alt}, idx) => (
            <Tooltip key={idx} title={localizeI18nObj(alt, defaultLanguage, languages) || ""}>
              <a href={href} target="_blank" rel="noopener noreferrer">
                <img src={src} alt={localizeI18nObj(alt, defaultLanguage, languages)} />
              </a>
            </Tooltip>
          ))}
      </div>
      {(title?.length > 0 || slogan?.length > 0) && (
        <div id="hero__title" className={classes.textContainer}>
          {title?.length > 0 && (
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
          )}
          {slogan?.length > 0 && (
            <Typography id="hero__slogan" variant="h2" className={classes.slogan}>
              {slogan}
            </Typography>
          )}
        </div>
      )}
      <Box id="hero__buttons" className={classes.buttonsContainer}>
        {children}
      </Box>
      <Box id="hero__logo" className={classes.logo}>
        {logo}
      </Box>
      <div id="hero__background" className={classes.background + " hero__background"}>
        {background}
      </div>
    </div>
  </Fragment>
);

export default compose(
  withStyles(styles),
  connect(state => ({
    defaultLanguage: state.app.language,
    languages: state.app.languages,
    homeExtraLogos: state.appConfig.homeExtraLogos
  }))
)(Hero);

import {ICategoryGroup} from "./ICategoryGroup";
import {ICategoryProvider} from "./ICategoryProvider";
import {IDataset} from "./IDataset";
import {getDetailLevelTree} from "../utils/catalog";
import {getNodes} from "../utils/tree";

export class LocalCategoryProvider implements ICategoryProvider {
  categoryGroups: Array<ICategoryGroup>;

  datasets: {[key: string]: IDataset};

  uncategorizedDatasets: IDataset[];

  hasCategorySchemes: boolean;

  isEmpty: boolean;

  nodeId: number;

  detailLevelTree: any = [];

  detailLevels: number[] = [];

  constructor(
    categories: Array<ICategoryGroup>,
    datasets: {[key: string]: IDataset},
    uncategorizedDatasets: IDataset[] = [],
    nodeId: number = -1,
    detailLevelTree: any = [],
    t: any
  ) {
    this.categoryGroups = categories || [];
    this.datasets = datasets || {};
    this.uncategorizedDatasets = uncategorizedDatasets || [];
    this.hasCategorySchemes = this.categoryGroups.length > 1;
    this.isEmpty = Object.keys(this.datasets).length === 0 && this.uncategorizedDatasets.length === 0;

    this.nodeId = nodeId || -1;

    const tree = getDetailLevelTree(detailLevelTree, t);
    this.detailLevelTree = tree;
    this.detailLevels = getNodes(tree, "layers", (node: any) => node.catalogSelection).sort(
      (a, b) => a.order - b.order || a.id - b.id
    );
  }
}

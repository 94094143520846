import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from "react";
import {Checkbox, FormControlLabel, withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import DashboardsManager from "../../dashboards-manager";
import FileInput from "../../file-input";
import FormLabelWithTooltip from "../../form-label-with-tooltip";
import I18nHtmlEditor from "../../i18n-html-editor";
import I18nTextField from "../../i18n-text-field";
import {getMapLayerOptions} from "../../map/constants";
import TabPanel from "../../tab-panel";
import {
  addHubConfigDashboardsDashboard,
  clearAllHubConfigDashboards,
  clearHubConfig,
  clearHubConfigDashboards,
  closeHubConfig,
  fetchAllHubConfigDashboards,
  fetchHubConfig,
  fetchHubConfigDashboards,
  removeHubConfigDashboardsDashboard,
  sendHubConfig,
  sendHubConfigDashboardsOrders
} from "../../../state/hubConfig/hubConfigActions";
import {localizeI18nObj} from "../../../utils/i18n";
import {canManageAppDashboards} from "../../../utils/user";
import {isValidIntegerInInclusiveRange} from "../../../utils/validator";
import themeConfig from "../../../theme-config/config.json";

const styles = theme => ({
  root: {
    height: "100%"
  },
  field: {
    marginTop: theme.spacing(3)
  },
  tabContent: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100% - 56px)",
    marginTop: 8,
    padding: "0 4px"
  },
  disclaimerLabel: {
    fontSize: "1rem",
    marginTop: theme.spacing(3)
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3)
  },
  paperHeader: {
    padding: 0
  }
});

const mapStateToProps = state => ({
  config: state.hubConfig.hub,
  user: state.user,
  dashboards: state.hubConfig.hubDashboards,
  allDashboards: state.hubConfig.allDashboards,
  mapLayersConfig: state.maps.mapLayersConfig,
  appLanguage: state.app.language,
  appLanguages: state.app.languages
});

const mapDispatchToProps = dispatch => ({
  clearConfig: () => dispatch(clearHubConfig()),
  fetchConfig: () => dispatch(fetchHubConfig()),
  sendConfig: config => dispatch(sendHubConfig(config)),
  fetchDashboards: () => dispatch(fetchHubConfigDashboards()),
  fetchAllDashboards: () => dispatch(fetchAllHubConfigDashboards()),
  clearDashboards: () => dispatch(clearHubConfigDashboards()),
  clearAllDashboards: () => dispatch(clearAllHubConfigDashboards()),
  addDashboard: dashboardId => dispatch(addHubConfigDashboardsDashboard(dashboardId)),
  removeDashboard: dashboardId => dispatch(removeHubConfigDashboardsDashboard(dashboardId)),
  sendDashboardsOrders: orderedDashboarIds => dispatch(sendHubConfigDashboardsOrders(orderedDashboarIds)),
  onClose: () => dispatch(closeHubConfig())
});

const Form = compose(
  withStyles(styles),
  forwardRef
)(
  (
    {
      classes,
      config,
      onSubmit,
      onCancel,
      user,
      dashboards,
      allDashboards,
      mapLayersConfig,
      appLanguage,
      appLanguages,
      fetchDashboards,
      fetchAllDashboards,
      clearDashboards,
      clearAllDashboards,
      addDashboard,
      removeDashboard,
      sendDashboardsOrders
    },
    ref
  ) => {
    const [tab, setTab] = useState("general");
    const dashboardsManagerRef = React.createRef();
    const {t} = useTranslation();

    const configExtras = config.extras ? JSON.parse(config.extras) : {};

    const {
      register,
      formState: {errors},
      handleSubmit,
      watch,
      setValue
    } = useForm({
      defaultValues: {
        ...config,
        mapLayer: configExtras.MapLayer,
        treePageSize: configExtras.TreePageSize,
        headerLogoSmallURL: configExtras.HeaderLogoSmallURL,
        headerLogoAlt: configExtras.HeaderLogoAlt,
        headerLogoHref: configExtras.HeaderLogoHref,
        headerLogoTitle: configExtras.headerLogoTitle,
        disableArtefactsCache: configExtras.DisableArtefactsCache || false
      }
    });

    useImperativeHandle(ref, () => ({
      submit(f) {
        handleSubmit(val => {
          const data = {
            ...config,
            ...val,
            mapLayer: undefined,
            treePageSize: undefined,
            headerLogoSmallURL: undefined,
            headerLogoAlt: undefined,
            headerLogoHref: undefined,
            headerLogoTitle: undefined,
            disableArtefactsCache: false
          };

          const extras = {
            ...JSON.parse(data.extras || "{}"),
            MapLayer: val.mapLayer,
            TreePageSize: val.treePageSize,
            HeaderLogoSmallURL: val.headerLogoSmallURL,
            HeaderLogoAlt: val.headerLogoAlt,
            HeaderLogoHref: val.headerLogoHref,
            headerLogoTitle: val.headerLogoTitle,
            DisableArtefactsCache: val.disableArtefactsCache
          };

          const clearedExtras = Object.keys(extras)
            .filter(k => extras[k] !== null && extras[k] !== undefined)
            .reduce((a, k) => ({...a, [k]: extras[k]}), {});

          data.extras = JSON.stringify(clearedExtras);

          onSubmit(data);
          f(data);
          if (dashboardsManagerRef.current) {
            dashboardsManagerRef.current.destroy();
          }
        })();

        const firstFieldWithErrors = Object.keys(errors)[0];
        if (firstFieldWithErrors) {
          switch (firstFieldWithErrors) {
            case "title":
            case "slogan":
            case "supportedLanguages":
            case "defaultLanguage":
            case "maxObservationsAfterCriteria":
            case "maxCells":
            case "treePageSize":
            case "backgroundMediaURL":
            case "logoURL":
            case "headerLogoURL": {
              setTab("general");
              break;
            }
            case "description": {
              setTab("infos");
              break;
            }
            case "disclaimer": {
              setTab("users");
              break;
            }
            case "mapLayer": {
              setTab("map");
              break;
            }
            case "cache": {
              setTab("cache");
              break;
            }
            default: {
            }
          }
        }
      },
      cancel(f) {
        onCancel();
        f();
        if (dashboardsManagerRef.current) {
          dashboardsManagerRef.current.destroy();
        }
      }
    }));

    /* custom register */
    useEffect(() => {
      register("title");
      register("slogan");
      register("supportedLanguages", {
        validate: val => val.length > 0 || t("commons.validation.required")
      });
      register("defaultLanguage", {
        required: t("commons.validation.required")
      });
      register("maxObservationsAfterCriteria", {
        required: t("commons.validation.required"),
        min: {
          value: 1,
          message: t("commons.validation.positiveInteger")
        }
      });
      register("maxCells", {
        required: t("commons.validation.required"),
        min: {
          value: 1,
          message: t("commons.validation.positiveInteger")
        }
      });
      register("treePageSize", {
        validate: val => !val || isValidIntegerInInclusiveRange(val, 1) || t("commons.validation.positiveInteger")
      });
      register("backgroundMediaURL");
      register("logoURL");
      register("headerLogoURL");
      register("headerLogoSmallURL");
      register("headerLogoAlt");
      register("headerLogoHref");
      register("headerLogoTitle");
      register("description");
      register("disclaimer");
      register("mapLayer", {
        required: t("commons.validation.required")
      });
      register("disableArtefactsCache");
    }, [register, t]);

    const defaultLanguage = watch("defaultLanguage");
    const supportedLanguages = watch("supportedLanguages");

    return (
      <Fragment>
        <Box className={classes.root}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tab}
            onChange={(_, tab) => {
              setTab(tab);
            }}
          >
            <Tab value="general" label={t("scenes.appSettings.tabs.general")} />
            <Tab value="infos" label={t("scenes.appSettings.tabs.information")} />
            <Tab value="users" label={t("scenes.appSettings.tabs.users")} />
            {canManageAppDashboards(user) && themeConfig.enableDashboard && (
              <Tab value="dashboards" label={t("scenes.appSettings.tabs.dashboards")} id="hub-dashboards-tab" />
            )}
            <Tab value="map" label={t("scenes.appSettings.tabs.map")} />
            <Tab value="cache" label={t("scenes.appSettings.tabs.cache")} />
          </Tabs>
          <div className={classes.tabContent}>
            <TabPanel value="general" selected={tab}>
              <FormControl fullWidth className={classes.field}>
                <I18nTextField
                  name="title"
                  label={t("scenes.appSettings.fields.title.label")}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  variant="outlined"
                  value={watch("title") || {}}
                  onChange={value => setValue("title", value)}
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <I18nTextField
                  name="slogan"
                  label={t("scenes.appSettings.fields.slogan.label")}
                  variant="outlined"
                  value={watch("slogan") || {}}
                  onChange={value => setValue("slogan", value)}
                />
              </FormControl>
              <Autocomplete
                multiple
                variant="outlined"
                freeSolo
                options={[]}
                value={supportedLanguages || []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({index})} />)
                }
                required
                onChange={(e, val) => {
                  setValue("supportedLanguages", val);
                  if (defaultLanguage && !val.includes(defaultLanguage)) {
                    setValue("defaultLanguage", "");
                  }
                }}
                renderInput={params => (
                  <FormControl fullWidth className={classes.field}>
                    <TextField
                      label={t("scenes.appSettings.fields.supportedLanguages.label")}
                      {...params}
                      variant="outlined"
                      placeholder={t("scenes.appSettings.fields.supportedLanguages.placeholder")}
                      error={!!errors.supportedLanguages}
                      helperText={
                        errors.supportedLanguages?.message || (
                          <Fragment>
                            {t("scenes.appSettings.fields.supportedLanguages.helper.text")}
                            <a
                              href="https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{marginLeft: 4}}
                            >
                              {t("scenes.appSettings.fields.supportedLanguages.helper.linkText")}
                            </a>
                          </Fragment>
                        )
                      }
                    />
                  </FormControl>
                )}
              />
              <FormControl fullWidth className={classes.field}>
                <TextField
                  name="defaultLanguage"
                  select
                  required
                  label={t("scenes.appSettings.fields.defaultLanguage.label")}
                  onChange={e => setValue("defaultLanguage", e.target.value)}
                  value={watch("defaultLanguage") || ""}
                  error={!!errors.defaultLanguage}
                  helperText={errors.defaultLanguage?.message || t("scenes.appSettings.fields.defaultLanguage.helper")}
                  variant="outlined"
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  {supportedLanguages.map((val, index) => (
                    <MenuItem key={index} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <TextField
                  name="maxObservationsAfterCriteria"
                  label={t("scenes.appSettings.fields.maxObservationsAfterCriteria.label")}
                  required
                  type="number"
                  error={!!errors.maxObservationsAfterCriteria}
                  helperText={errors.maxObservationsAfterCriteria?.message}
                  variant="outlined"
                  value={watch("maxObservationsAfterCriteria") || ""}
                  onChange={({target}) => setValue("maxObservationsAfterCriteria", target.value)}
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <TextField
                  name="maxCells"
                  label={t("scenes.appSettings.fields.maxCells.label")}
                  required
                  error={!!errors.maxCells}
                  helperText={errors.maxCells?.message}
                  variant="outlined"
                  type="number"
                  value={watch("maxCells") || ""}
                  onChange={({target}) => setValue("maxCells", target.value)}
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <TextField
                  name="treePageSize"
                  label={t("scenes.appSettings.fields.treePageSize.label")}
                  error={!!errors.treePageSize}
                  helperText={errors.treePageSize?.message}
                  variant="outlined"
                  type="number"
                  value={watch("treePageSize") || ""}
                  onChange={({target}) => setValue("treePageSize", target.value)}
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <FileInput
                  label={t("scenes.appSettings.fields.backgroundMediaURL.label")}
                  value={watch("backgroundMediaURL")}
                  onChange={value => setValue("backgroundMediaURL", value)}
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <FileInput
                  label={t("scenes.appSettings.fields.logoURL.label")}
                  value={watch("logoURL")}
                  onChange={value => setValue("logoURL", value)}
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <FileInput
                  label={t("scenes.appSettings.fields.headerLogoURL.label")}
                  value={watch("headerLogoURL")}
                  onChange={value => setValue("headerLogoURL", value)}
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <FileInput
                  label={t("scenes.appSettings.fields.headerLogoSmallURL.label")}
                  value={watch("headerLogoSmallURL")}
                  onChange={value => setValue("headerLogoSmallURL", value)}
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <I18nTextField
                  name="headerLogoAlt"
                  label={
                    <FormLabelWithTooltip tooltip={t("scenes.appSettings.fields.headerLogoAlt.tooltip")}>
                      {t("scenes.appSettings.fields.headerLogoAlt.label")}
                    </FormLabelWithTooltip>
                  }
                  error={!!errors.headerLogoAlt}
                  helperText={errors.headerLogoAlt?.message}
                  variant="outlined"
                  value={watch("headerLogoAlt") || {}}
                  onChange={value => setValue("headerLogoAlt", value)}
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <TextField
                  name="headerLogoHref"
                  label={t("scenes.appSettings.fields.headerLogoHref.label")}
                  error={!!errors.headerLogoHref}
                  helperText={errors.headerLogoHref?.message}
                  variant="outlined"
                  value={watch("headerLogoHref") || ""}
                  onChange={({target}) => setValue("headerLogoHref", target.value)}
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <I18nTextField
                  name="headerLogoTitle"
                  label={t("scenes.appSettings.fields.headerLogoTitle.label")}
                  error={!!errors.headerLogoTitle}
                  helperText={errors.headerLogoTitle?.message}
                  variant="outlined"
                  value={watch("headerLogoTitle") || ""}
                  onChange={value => setValue("headerLogoTitle", value)}
                />
              </FormControl>
            </TabPanel>
            <TabPanel value="infos" selected={tab}>
              <I18nHtmlEditor value={watch("description")} onChange={val => setValue("description", val)} />
            </TabPanel>
            <TabPanel value="users" selected={tab}>
              <Fragment>
                <div className={classes.disclaimerLabel}>{t("scenes.appSettings.fields.disclaimer.label")}:</div>
                <I18nHtmlEditor value={watch("disclaimer")} onChange={val => setValue("disclaimer", val)} />
              </Fragment>
            </TabPanel>
            {canManageAppDashboards(user) && themeConfig.enableDashboard && (
              <TabPanel value="dashboards" selected={tab}>
                <DashboardsManager
                  dashboards={dashboards}
                  allDashboards={allDashboards}
                  fetchDashboards={fetchDashboards}
                  fetchAllDashboards={fetchAllDashboards}
                  clearDashboards={clearDashboards}
                  clearAllDashboards={clearAllDashboards}
                  addDashboard={addDashboard}
                  removeDashboard={removeDashboard}
                  sendDashboardsOrders={sendDashboardsOrders}
                  ref={dashboardsManagerRef}
                />
              </TabPanel>
            )}
            <TabPanel value="map" selected={tab}>
              <FormControl fullWidth className={classes.field}>
                <TextField
                  name="mapLayer"
                  select
                  required
                  label={t("scenes.nodeSettings.fields.mapLayer.label")}
                  onChange={e => setValue("mapLayer", e.target.value)}
                  value={watch("mapLayer") || ""}
                  error={!!errors.mapLayer}
                  variant="outlined"
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  {getMapLayerOptions(t).map(({value, label}, idx) => (
                    <MenuItem key={idx} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                  {(mapLayersConfig || []).map(({id, name}, idx) => (
                    <MenuItem key={idx} value={id}>
                      {name ? localizeI18nObj(name, appLanguage, appLanguages) : id}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </TabPanel>
            <TabPanel value="cache" selected={tab}>
              <FormControl fullWidth className={classes.field}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.disableArtefactsCache.label")}
                  control={
                    <Checkbox
                      name="disableArtefactsCache"
                      required
                      checked={watch("disableArtefactsCache") || false}
                      onChange={(e, value) => setValue("disableArtefactsCache", value)}
                    />
                  }
                />
              </FormControl>
            </TabPanel>
          </div>
        </Box>
      </Fragment>
    );
  }
);

const AppSettingsForm = (
  {
    config,
    fetchConfig,
    sendConfig,
    clearConfig,
    user,
    dashboards,
    allDashboards,
    mapLayersConfig,
    appLanguage,
    appLanguages,
    fetchDashboards,
    fetchAllDashboards,
    clearDashboards,
    clearAllDashboards,
    addDashboard,
    removeDashboard,
    sendDashboardsOrders,
    onClose
  },
  ref
) => {
  const [needConfig, setNeedConfig] = useState(true);

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchConfig();
    }
  }, [config, needConfig, setNeedConfig, fetchConfig]);

  return (
    config && (
      <Form
        config={config}
        ref={ref}
        onSubmit={sendConfig}
        onCancel={() => {
          clearConfig();
          onClose();
        }}
        user={user}
        dashboards={dashboards}
        allDashboards={allDashboards}
        mapLayersConfig={mapLayersConfig}
        appLanguage={appLanguage}
        appLanguages={appLanguages}
        fetchDashboards={fetchDashboards}
        fetchAllDashboards={fetchAllDashboards}
        clearDashboards={clearDashboards}
        clearAllDashboards={clearAllDashboards}
        addDashboard={addDashboard}
        removeDashboard={removeDashboard}
        sendDashboardsOrders={sendDashboardsOrders}
      />
    )
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(AppSettingsForm);

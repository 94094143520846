import {useLocation} from "react-router-dom";
import Results from "../components/results";
import {goToNode} from "../links";

const SearchDomain = ({hub, node, nodeCode, isDefault, catalog, setAccessibleDataset}) => {
  const location = useLocation();

  const a11yParam = new URLSearchParams(location.search).get("accessible");
  const datasetIdParam = new URLSearchParams(location.search).get("datasetId");
  const queryParam = new URLSearchParams(location.search).get("q");
  const filtersParams = (new URLSearchParams(location.search).get("c") || "")
    .split("//")
    .filter(str => str.length > 0)
    .map(decodeURIComponent)
    .map(filter => filter.split("/"))
    .filter(path => path.length > 0);

  if (!queryParam || queryParam.length === 0) {
    goToNode(nodeCode);
  }

  let filters = [];
  filtersParams.forEach(filter => filter.forEach(id => filters.push(id)));
  filters = [...new Set(filters)]; // remove duplicates

  return (
    queryParam &&
    queryParam.length > 0 && (
      <Results
        query={queryParam}
        filtersParams={filtersParams}
        filters={filters}
        filtered
        hub={hub}
        node={node}
        nodeCode={nodeCode}
        isDefault={isDefault}
        catalog={catalog}
        scrollToDatasetId={datasetIdParam}
        isAccessible={!!a11yParam}
        onAccessibleDatasetFetch={setAccessibleDataset}
      />
    )
  );
};

export default SearchDomain;

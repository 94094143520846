import {Reducer} from "@reduxjs/toolkit";
import {DATASET_METADATA_FETCH, DATASET_METADATA_HIDE} from "./miscellaneousActions";
import {REQUEST_ERROR, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";

export type MiscellaneousState = {
  datasetMetadataHtml: string | null;
  datasetMetadataUrl: string | null;
};

const initialState: MiscellaneousState = {
  datasetMetadataHtml: null,
  datasetMetadataUrl: null
};

const miscellaneousReducer: Reducer<MiscellaneousState> = (state = initialState, action) => {
  switch (action.type) {
    case DATASET_METADATA_HIDE: {
      return {
        ...state,
        datasetMetadataHtml: null,
        datasetMetadataUrl: null
      };
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case DATASET_METADATA_FETCH: {
          const metadataHtml = (action.payload.response || "").length > 0 ? action.payload.response : null;
          const metadataUrl = metadataHtml === null ? action.payload.extra.metadataUrl : null;
          return {
            ...state,
            datasetMetadataHtml: metadataHtml,
            datasetMetadataUrl: metadataUrl
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case DATASET_METADATA_FETCH: {
          return {
            ...state,
            datasetMetadataHtml: null,
            datasetMetadataUrl: action.payload.extra.metadataUrl
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default miscellaneousReducer;

import React, {useEffect, useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import HeightIcon from "@material-ui/icons/Height";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {v4 as uuidv4} from "uuid";
import AutoSearchInput from "../auto-search-input";
import CustomEmpty from "../custom-empty";
import CustomLink from "../custom-link";
import {getFilteredTreeWithPaths, getMaxTreeDepth, getNodes, getNodesAtDepth, getTreeFromArray} from "../../utils/tree";

const $ = window.jQuery;

const DEFAULT_TREE_PAGE_SIZE = 30;

const TREE_NODE_KEY_OF_LEVEL_PREFIX = "TREE_NODE_KEY_OF_LEVEL_PREFIX_";

const styles = theme => ({
  root: {
    height: "100%",
    width: "100%"
  },
  treeActions: {
    marginBottom: 4
  },
  treeLevelSelector: {
    marginBottom: 4
  },
  tree: {
    overflow: "auto",
    "& .MuiTreeItem-label": {
      width: "calc(100% - 19px)"
    }
  },
  paddings: {
    padding: 4
  },
  treeNode: {
    display: "flex",
    alignItems: "center",
    padding: "2px 0",
    minHeight: 32
  },
  treeNodeCheckbox: {
    padding: 4,
    marginRight: 4
  },
  treeNodeCheckboxPlaceholder: {
    width: 8
  },
  treeNodeLabel: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.65)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  levelTreeNodeLabel: {
    color: "rgba(0, 0, 0, 0.85)",
    fontWeight: 500
  },
  treeNodeAction: {
    marginLeft: 4,
    "& > button": {
      padding: 4
    }
  },
  treeNodeActionPlaceholder: {
    height: 32
  },
  divider: {
    margin: "4px 0"
  },
  showMoreNode: {
    marginLeft: 10,
    "& i": {
      fontSize: 14
    }
  },
  treeAction: {
    padding: 8
  }
});

const getKeys = (tree, childrenKey, idKey, test) => {
  const res = [];

  const recursive = subTree =>
    subTree
      ? subTree.map(node => {
          if (!test || test(node)) {
            res.push(node[idKey]);
          }
          if (node[childrenKey] && node[childrenKey].length) {
            recursive(node[childrenKey]);
          }
          return null;
        })
      : [];

  recursive(tree);

  return res;
};

const isSearchedNode = (node, labelKey, searchText) => {
  const searchedStr = searchText.toLowerCase();
  const nodeLabel = node[labelKey];

  return (nodeLabel || "").toLowerCase().includes(searchedStr);
};

const handleHeight = (uuid, lsExpandedKeys, showLevelSelector) => {
  const actionHeight = $(`#enhanced-tree__${uuid} .enhanced-tree__actions`).outerHeight(true) || 0;

  let levelSelectorHeight = 0;
  if (showLevelSelector) {
    const nodeHeight =
      $(
        `#enhanced-tree__${uuid} .enhanced-tree__level-selector .enhanced-tree__level-selector__node:first`
      ).outerHeight(true) || 0;
    levelSelectorHeight += nodeHeight;
    levelSelectorHeight += 12; // paddings + margins
    levelSelectorHeight += 9; // divider

    let found = false;
    for (let i = 1; i <= lsExpandedKeys.length; i++) {
      if (lsExpandedKeys.find(key => key === TREE_NODE_KEY_OF_LEVEL_PREFIX + i) && !found) {
        levelSelectorHeight += nodeHeight;
      } else {
        found = true;
      }
    }
  }

  $(`#enhanced-tree__${uuid} .enhanced-tree__tree`).height(`calc(100% - ${actionHeight + levelSelectorHeight}px)`);
};

function EnhancedTree(props) {
  const {
    hubExtras,
    classes,
    tree,
    idKey,
    labelKey,
    childrenKey,
    defaultExpandedKeys,
    selectable,
    singleSelect,
    selectOnNodeClick,
    getIsNodeSelectable,
    defaultSelectedKeys,
    onSelect,
    hierarchicalSelect,
    hideSearchBar,
    hideCheckControls,
    hideExpandControls,
    hideDisabledCheckbox,
    showLevelSelector,
    showChildrenSelector,
    checkboxCheckedIcon,
    checkboxUncheckedIcon,
    treeActionsDirection
  } = props;

  const parsedHubExtras = JSON.parse(hubExtras || "{}");
  const treePageSize =
    (parsedHubExtras?.TreePageSize || "").length > 0 ? Number(parsedHubExtras.TreePageSize) : DEFAULT_TREE_PAGE_SIZE;

  const {t} = useTranslation();

  const [uuid] = useState(uuidv4());

  const [filteredTree, setFilteredTree] = useState(null);
  const [levelTree, setLevelTree] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [expandedKeys, setExpandedKeys] = useState(() => {
    const filteredTree = getFilteredTreeWithPaths(tree || [], childrenKey, node =>
      (defaultExpandedKeys || []).includes(node[idKey])
    );
    return getNodes(filteredTree, childrenKey, () => true).map(node => node[idKey]);
  });
  const [levelTreeExpandedKeys, setLevelTreeExpandedKeys] = useState([]);

  const [selectedKeys, setSelectedKeys] = useState(defaultSelectedKeys || []);

  const [showMoreKeyClicks, setShowMoreKeyClicks] = useState({});

  useEffect(() => {
    handleHeight(uuid, levelTreeExpandedKeys, showLevelSelector);
  });

  useEffect(() => {
    const filteredTree = getFilteredTreeWithPaths(tree, childrenKey, node =>
      isSearchedNode(node, labelKey, searchText)
    );
    setFilteredTree(filteredTree);

    if (searchText && searchText.length > 0) {
      const filteredTreeKeys = getKeys(filteredTree, childrenKey, idKey);
      setExpandedKeys(filteredTreeKeys);
      setLevelTreeExpandedKeys([]);
    }
  }, [tree, idKey, labelKey, childrenKey, searchText]);

  useEffect(() => {
    if (showLevelSelector) {
      let levelTree = [];
      const maxTreeDepth = getMaxTreeDepth(filteredTree, childrenKey);
      const flatLevelTree = [];
      if (maxTreeDepth > 0) {
        [...Array(maxTreeDepth)].forEach((_, idx) => {
          flatLevelTree.push({
            id: TREE_NODE_KEY_OF_LEVEL_PREFIX + (idx + 1),
            label: t("components.enhancedTree.levelSelector", {level: idx}),
            level: idx + 1,
            parent: idx !== 0 ? TREE_NODE_KEY_OF_LEVEL_PREFIX + idx : undefined
          });
        });
        levelTree = getTreeFromArray(flatLevelTree, "parent", childrenKey);
      }
      setLevelTree(levelTree);
    }
  }, [showLevelSelector, filteredTree, childrenKey, t]);

  const handleSelect = (selectedNode, selected) => {
    let newSelectedKeys;

    if (singleSelect) {
      newSelectedKeys = [selectedNode[idKey]];
    } else if (selected) {
      if (hierarchicalSelect) {
        const keysToAdd = getNodes([selectedNode], "children", () => true).map(({id}) => id);
        newSelectedKeys = _.uniq((selectedKeys || []).concat(keysToAdd));
      } else {
        newSelectedKeys = [...selectedKeys, selectedNode[idKey]];
      }
    } else {
      if (hierarchicalSelect) {
        const parentTree = getFilteredTreeWithPaths(tree, "children", ({id}) => id === selectedNode.id);
        const keysToRemove = getNodes(parentTree, "children", () => true)
          .map(({id}) => id)
          .concat(getNodes(selectedNode.children || [], "children", () => true).map(({id}) => id));
        newSelectedKeys = selectedKeys.filter(key => !keysToRemove.includes(key));
      } else {
        newSelectedKeys = selectedKeys.filter(key => key !== selectedNode[idKey]);
      }
    }

    setSelectedKeys(newSelectedKeys);
    if (onSelect) {
      onSelect(newSelectedKeys, selectedNode, selected);
    }
  };

  const handleLevelTreeSelect = (selectedNode, selected) => {
    const nodesAtDepthKeys = getNodesAtDepth(filteredTree, childrenKey, selectedNode.level)
      .filter(node => !getIsNodeSelectable || getIsNodeSelectable(node))
      .map(node => node[idKey]);
    const previouslyUnselectedKeys = nodesAtDepthKeys.filter(key => !selectedKeys.includes(key));

    const newSelectedKeys = selected
      ? selectedKeys.concat(previouslyUnselectedKeys)
      : selectedKeys.filter(key => !nodesAtDepthKeys.includes(key));

    setSelectedKeys(newSelectedKeys);
    if (onSelect) {
      onSelect(newSelectedKeys);
    }
  };

  const getShowMoreNode = parent => {
    const key = `${parent ? parent[idKey] : "root"}`;

    const remaining =
      (parent ? parent[childrenKey] : (filteredTree || []).filter(node => node != null)).length -
      ((showMoreKeyClicks[key] || 0) + 1) * treePageSize;

    return (
      <TreeItem
        key={`${key}_showMore`}
        nodeId={`${key}_showMore`}
        className={classes.showMoreNode}
        tabIndex={-1}
        label={
          <CustomLink
            to={"#"}
            text={
              <i>
                {t("components.enhancedTree.showMore", {more: Math.min(remaining, treePageSize), remaining: remaining})}
              </i>
            }
            onClick={() =>
              setShowMoreKeyClicks({
                ...showMoreKeyClicks,
                [key]: showMoreKeyClicks[key] ? showMoreKeyClicks[key] + 1 : 1
              })
            }
          />
        }
      />
    );
  };

  const getTreeNode = (node, idKey, labelKey, isLevelTree) => {
    const nodeKey = node[idKey];
    const nodeLabel = node[labelKey];

    const isNodeSelectable = isLevelTree
      ? getNodesAtDepth(filteredTree, childrenKey, node.level).filter(
          node => selectable && (!getIsNodeSelectable || getIsNodeSelectable(node))
        ).length > 0
      : selectable && (!getIsNodeSelectable || getIsNodeSelectable(node));

    const isNodeSelected = isLevelTree
      ? !getNodesAtDepth(filteredTree, childrenKey, node.level)
          .filter(node => selectable && (!getIsNodeSelectable || getIsNodeSelectable(node)))
          .map(node => node[idKey])
          .some(key => !selectedKeys.includes(key))
      : selectedKeys.includes(nodeKey);

    const hasChildrenSelector =
      selectable &&
      showChildrenSelector &&
      !isLevelTree &&
      (node[childrenKey] || []).filter(child => !getIsNodeSelectable || getIsNodeSelectable(child)).length > 0;

    return (
      <TreeItem
        key={nodeKey}
        nodeId={nodeKey}
        tabIndex={0}
        label={
          <div className={`${classes.treeNode} ${isLevelTree ? "enhanced-tree__level-selector__node" : ""}`}>
            {isNodeSelectable || !hideDisabledCheckbox ? (
              <Tooltip
                title={
                  isNodeSelected
                    ? t("components.enhancedTree.checkBox.uncheck.tooltip", {label: nodeLabel})
                    : t("components.enhancedTree.checkBox.check.tooltip", {label: nodeLabel})
                }
              >
                <Checkbox
                  className={classes.treeNodeCheckbox}
                  icon={checkboxUncheckedIcon ? checkboxUncheckedIcon : undefined}
                  checkedIcon={checkboxCheckedIcon ? checkboxCheckedIcon : undefined}
                  checked={isLevelTree ? isNodeSelectable && isNodeSelected : isNodeSelected}
                  onClick={event => {
                    if (isLevelTree) {
                      handleLevelTreeSelect(node, event.target.checked);
                    } else {
                      handleSelect(node, event.target.checked);
                    }
                    event.stopPropagation();
                  }}
                  disabled={!isNodeSelectable}
                  inputProps={{
                    "aria-label": isNodeSelected
                      ? t("components.enhancedTree.checkBox.uncheck.ariaLabel", {label: nodeLabel})
                      : t("components.enhancedTree.checkBox.check.ariaLabel", {label: nodeLabel})
                  }}
                />
              </Tooltip>
            ) : (
              <span className={classes.treeNodeCheckboxPlaceholder} />
            )}
            <Tooltip title={nodeLabel}>
              <div
                className={`${classes.treeNodeLabel} ${isLevelTree ? classes.levelTreeNodeLabel : ""}`}
                style={{maxWidth: `calc(100% - ${(selectable ? 40 : 0) + (hasChildrenSelector ? 36 : 0)}px)`}}
              >
                {nodeLabel}
              </div>
            </Tooltip>
            {hasChildrenSelector && !singleSelect ? (
              <div className={classes.treeNodeAction}>
                <Tooltip title={t("components.enhancedTree.actions.selectChildren.title")}>
                  <IconButton
                    onClick={event => {
                      const childrenKeys = node[childrenKey]
                        .filter(({isSelectable}) => isSelectable !== false)
                        .map(child => child[idKey]);
                      const previouslyUnselectedKeys = childrenKeys.filter(key => !selectedKeys.includes(key));

                      const newSelectedKeys =
                        previouslyUnselectedKeys.length > 0
                          ? [...selectedKeys, ...previouslyUnselectedKeys]
                          : selectedKeys.filter(key => !childrenKeys.includes(key));

                      setSelectedKeys(newSelectedKeys);
                      if (onSelect) {
                        onSelect(newSelectedKeys);
                      }

                      event.stopPropagation();
                    }}
                    aria-label={t("components.enhancedTree.actions.selectChildren.ariaLabel")}
                  >
                    <PlaylistAddCheckIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <span className={classes.treeNodeActionPlaceholder} />
            )}
          </div>
        }
        onLabelClick={evt => {
          if (isNodeSelectable && selectOnNodeClick) {
            if (isLevelTree) {
              handleLevelTreeSelect(node, !isNodeSelected);
            } else {
              handleSelect(node, !isNodeSelected);
            }
            evt.preventDefault();
          }
        }}
      >
        {node[childrenKey]
          ? node[childrenKey]
              .filter((child, index) => index < treePageSize * ((showMoreKeyClicks[nodeKey] || 0) + 1))
              .map(child => getTreeNode(child, idKey, labelKey, isLevelTree))
              .concat(
                node[childrenKey].length > treePageSize * ((showMoreKeyClicks[nodeKey] || 0) + 1)
                  ? [getShowMoreNode(node)]
                  : []
              )
          : null}
      </TreeItem>
    );
  };

  return (
    <div id={`enhanced-tree__${uuid}`} className={`enhanced-tree ${classes.root}`}>
      <div className={`enhanced-tree__actions ${classes.treeActions} ${classes.paddings}`}>
        <Grid container spacing={2} justifyContent="space-between" direction={treeActionsDirection || "row"}>
          <Grid item xs={6}>
            <Grid container spacing={1} justifyContent="flex-start">
              {!hideCheckControls && selectable && !singleSelect && (
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <Tooltip title={t("components.enhancedTree.selectAll.tooltip")}>
                        <div>
                          <IconButton
                            aria-label={t("components.enhancedTree.selectAll.ariaLabel")}
                            onClick={() => {
                              const newSelectedKeys = getKeys(filteredTree, childrenKey, idKey, getIsNodeSelectable);
                              setSelectedKeys(newSelectedKeys);
                              if (onSelect) {
                                onSelect(newSelectedKeys);
                              }
                            }}
                            className={classes.treeAction}
                          >
                            <LibraryAddCheckIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={t("components.enhancedTree.deselectAll.tooltip")}>
                        <div>
                          <IconButton
                            aria-label={t("components.enhancedTree.deselectAll.ariaLabel")}
                            onClick={() => {
                              setSelectedKeys([]);
                              if (onSelect) {
                                onSelect([]);
                              }
                            }}
                            className={classes.treeAction}
                          >
                            <FilterNoneIcon style={{padding: 1}} />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!hideExpandControls && (
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <Tooltip title={t("components.enhancedTree.expandAll.tooltip")}>
                        <IconButton
                          aria-label={t("components.enhancedTree.expandAll.ariaLabel")}
                          className={classes.treeAction}
                          onClick={() => setExpandedKeys(getKeys(filteredTree, childrenKey, idKey))}
                        >
                          <HeightIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={t("components.enhancedTree.collapseAll.tooltip")}>
                        <IconButton
                          aria-label={t("components.enhancedTree.collapseAll.ariaLabel")}
                          className={classes.treeAction}
                          onClick={() => setExpandedKeys([])}
                        >
                          <VerticalAlignCenterIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {!hideSearchBar && <AutoSearchInput onSearch={setSearchText} />}
          </Grid>
        </Grid>
      </div>
      {showLevelSelector && selectable && !singleSelect && getKeys(filteredTree, childrenKey, idKey).length > 1 && (
        <div className={`enhanced-tree__level-selector ${classes.treeLevelSelector} ${classes.paddings}`}>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            disableSelection
            expanded={levelTreeExpandedKeys}
            onNodeToggle={(ev, nodeIds) => setLevelTreeExpandedKeys(nodeIds)}
          >
            {levelTree.map(node => getTreeNode(node, "id", "label", true))}
          </TreeView>
          <Divider className={classes.divider} />
        </div>
      )}
      <div className={`enhanced-tree__tree ${classes.tree} ${classes.paddings}`}>
        {filteredTree && filteredTree.length > 0 && expandedKeys ? (
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            disableSelection
            expanded={expandedKeys}
            onNodeToggle={(ev, nodeIds) => setExpandedKeys(nodeIds)}
          >
            {filteredTree.map(node => getTreeNode(node, idKey, labelKey, false))}
          </TreeView>
        ) : (
          <CustomEmpty />
        )}
      </div>
    </div>
  );
}

export default compose(
  withStyles(styles),
  connect(state => ({
    hubExtras: state.hub.hub?.extras
  }))
)(EnhancedTree);

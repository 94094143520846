import {Reducer} from "redux";
import {SEARCH_DIALOG_CLOSE, SEARCH_DIALOG_OPEN} from "./searchDialogActions";

export type SearchDialogState = {
  isOpen: boolean;
};

const searchDialogReducer: Reducer<SearchDialogState> = (
  state = {
    isOpen: false
  },
  action
) => {
  switch (action.type) {
    case SEARCH_DIALOG_OPEN: {
      return {
        ...state,
        isOpen: true
      };
    }
    case SEARCH_DIALOG_CLOSE: {
      return {
        ...state,
        isOpen: false
      };
    }
    default:
      return state;
  }
};

export default searchDialogReducer;

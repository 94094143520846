/** criteria selection mode **/
export const CRITERIA_SELECTION_MODE_STEP_BY_STEP = "stepByStep";
export const CRITERIA_SELECTION_MODE_ALL = "all";

export const CRITERIA_SELECTION_TYPE_FULL = "full";
export const CRITERIA_SELECTION_TYPE_PARTIAL = "partial";
export const CRITERIA_SELECTION_TYPE_DYNAMIC = "dynamic";

export const ALL_FULL = "FullAll";
export const ALL_PARTIAL = "PartialAll";
export const ALL_PARTIAL_OPTIMIZED = "PartialAllOptimized";
export const STEP_BY_STEP_FULL = "FullStep";
export const STEP_BY_STEP_PARTIAL = "PartialStep";
export const STEP_BY_STEP_DYNAMIC = "Dynamic";

/** timings **/
export const GENERATING_HTML_TIME_KEY = "generatingHtml";
export const OBSERVATION_COUNT_KEY = "observationCount";
export const SERVER_TIMINGS_KEY = "serverTimings";

/** types **/
export enum ViewerMode {
  SingleViewer = "SINGLE_VIEWER",
  SingleViewerPlus = "SINGLE_VIEWER_PLUS",
  MultiViewer = "MULTI_VIEWER",
  Error = "VIEWER_ERROR"
}

export type Criteria = {
  id: string;
  type: string;
  filterValues?: string[];
  period?: number;
  from?: string;
  to?: string;
};

export type TimePeriod = {
  selectorType: string;
  freq: string;
  minDate: string;
  maxDate: string;
  fromDate: string;
  toDate: string;
  periods: number;
  missingRange: boolean;
};

export type ChartSettings = {
  stacked?: boolean;
  legendPosition?: string;
  showAxesLabel?: boolean;
  dataLabelType?: string;
  colors?: any;
  customizeCategoryAxis?: boolean;
  categoryAxisLabel?: {[key: string]: string};
  valueAxisLabel?: {[key: string]: string};
};

export type MapSettings = {
  baseLayer?: string | null;
  classificationMethod?: string;
  paletteStartColor?: string;
  paletteEndColor?: string;
  paletteCardinality?: number;
  opacity?: number;
  isLegendCollapsed?: boolean;
  customIntervals?: any[] | null;
};

export type DatasetState = {
  viewerMode: ViewerMode | null;

  mode: string | null;
  type: string | null;

  isFetchStructureDisabled: boolean;

  datasetId: string | null;
  nodeCode: string | null;
  categoryPath: string[] | null;
  viewId: string | null;

  datasetCacheInfo: any | null;
};

import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from "react";
import {Checkbox, withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import validator from "validator";
import {NodeDataAccessibility, NodeVisibility} from "../../../../model/IHubMinimalNode.d.ts";
import ComponentVariantLoader from "../../../component-variant-loader";
import CustomDatePicker from "../../../custom-date-picker";
import FileInput from "../../../file-input";
import FormLabelWithTooltip from "../../../form-label-with-tooltip";
import I18nHtmlEditor from "../../../i18n-html-editor";
import I18nTextField from "../../../i18n-text-field";
import PasswordInput from "../../../password-input";
import TabPanel from "../../../tab-panel";
import {
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC,
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC,
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET
} from "../../../temporal-dim-order-selector/constants";
import {
  ALL_FULL,
  ALL_PARTIAL,
  ALL_PARTIAL_OPTIMIZED,
  STEP_BY_STEP_DYNAMIC,
  STEP_BY_STEP_FULL,
  STEP_BY_STEP_PARTIAL
} from "../../../../state/dataset/constants";
import {
  clearNodesConfigNode,
  fetchNodesConfigNode,
  sendNodesConfigNodeCreate,
  sendNodesConfigNodeEdit
} from "../../../../state/nodesConfig/nodesConfigActions";
import {downloadFormats} from "../../../../utils/download";
import {getI18nObjFromLanguages, validateI18nObj} from "../../../../utils/i18n";
import {isValidIntegerInInclusiveRange} from "../../../../utils/validator";

const styles = theme => ({
  root: {
    height: "100%"
  },
  field: {
    marginTop: theme.spacing(3)
  },
  warningAlert: {
    marginTop: 8
  },
  subfield: {
    marginLeft: theme.spacing(6),
    width: `calc(100% - ${theme.spacing(6)}px)`
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3)
  },
  paperHeader: {
    padding: 0
  },
  tabContent: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100% - 56px)",
    marginTop: 8,
    padding: "0 4px"
  },
  title: {
    fontSize: 16
  }
});

export const CATALOG_NAVIGATION_MODE_TREE = "Tree";
export const CATALOG_NAVIGATION_MODE_GRID = "Card";
export const CATALOG_NAVIGATION_MODE_LIST = "List";

const Form = compose(
  withStyles(styles),
  forwardRef
)(({classes, config, initialNodeVisibility, languages, hub, onSubmit, onCancel}, ref) => {
  const [tab, setTab] = useState("general");
  const {t} = useTranslation();

  const {
    register,
    formState: {errors},
    handleSubmit,
    watch,
    getValues,
    setValue
  } = useForm({
    defaultValues: {
      active: false,
      ...config,
      visible: NodeVisibility.Yes,
      dataAccess: NodeDataAccessibility.All,
      ...config,
      annotationConfig: config?.extras?.find(({key}) => key === "AnnotationConfig")
        ? JSON.parse(config.extras.find(({key}) => key === "AnnotationConfig").value)
        : {
            ORDER: "ORDER", // Order Annotation
            ORDER_CATEGORY: "ORDER", // Category Schemes Order Annotation
            ORDER_CODELIST: "ORDER", // Codelists Order Annotation
            NOT_DISPLAYED: "NOT_DISPLAYED", // Not Displayed Annotation
            DEFAULT: "DEFAULT", // Default Annotation
            TIME_PERIOD_START: "TIME_PERIOD_START", // Time Period Start Default
            TIME_PERIOD_END: "TIME_PERIOD_END", // Time Period End Default
            LASTNPERIOD: "LAST_N_PERIOD", // Last N Period Default
            LAYOUT_ROW: "LAYOUT_ROW", // Layout Row Annotation
            LAYOUT_COLUMN: "LAYOUT_COLUMN", // Layout Column Annotation
            LAYOUT_FILTER: "LAYOUT_FILTER", // Layout Filter Annotation
            LAYOUT_ROW_SECTION: "LAYOUT_ROW_SECTION", // Layout Row Section Annotation
            LAYOUT_CHART_PRIMARY_DIM: "LAYOUT_CHART_PRIMARY_DIM", // Layout Chart Primary Dim Annotation
            LAYOUT_CHART_SECONDARY_DIM: "LAYOUT_CHART_SECONDARY_DIM", // Layout Chart Secondary Dim Annotation
            LAYOUT_CHART_FILTER: "LAYOUT_CHART_FILTER", // Layout Chart Filter Annotation
            LAYOUT_MAP_FILTER: "LAYOUT_MAP_FILTER", // Layout Map Filter Annotation
            CRITERIA_SELECTION: "LAYOUT_CRITERIA_SELECTION", // Layout Criteria Selection Annotation
            ATTACHED_DATA_FILES: "LAYOUT_ATTACHED_DATA_FILES", // Layout Attached DataFiles Annotation
            LAYOUT_DECIMAL_SEPARATOR: "LAYOUT_DECIMAL_SEPARATOR", // Layout Decimal Separator Annotation
            LAYOUT_NUMBER_OF_DECIMALS: "LAYOUT_DECIMALS_NUMBER", // Layout Decimals Number Annotation
            LAYOUT_EMPTY_CELL_PLACEHOLDER: "LAYOUT_EMPTY_CELL_PLACEHOLDER", // Layout Empty Cell Placeholder Annotation
            DATAFLOW_NOTES: "LAYOUT_DATAFLOW_NOTES", // Layout Dataflow Notes Annotation
            DATAFLOW_SOURCE: "LAYOUT_DATAFLOW_SOURCE", // Layout Dataflow Source Annotation
            METADATA_URL: "LAYOUT_REFERENCE_METADATA", // Layout Reference Metadata Annotation
            KEYWORDS: "LAYOUT_DATAFLOW_KEYWORDS", // Layout Dataflow Keywords Annotation
            DEFAULT_VIEW: "LAYOUT_DEFAULT_PRESENTATION", // LayoutDefaultPresentation Annotation
            GEO_ID: "LAYOUT_GEO_IDS", // Layout Territorial DimensionIds Annotation
            LAST_UPDATE: "LAST_UPDATE", // LastUpdate Annotation
            VIRTUAL_DATAFLOW_NODE: "LINKED_DATAFLOW_NODE", // Linked Dataflow Node Annotation
            DATAFLOW_CATALOG_TYPE: "DATAFLOW_CATALOG_TYPE", // DataflowCatalogType Annotation
            DATAFLOW_HIDDEN: "LAYOUT_DATAFLOW_HIDDEN", // Layout Dataflow Hidden Annotation
            DISABLED_VIEWERS: "DISABLED_VIEWERS", // Disabled Viewers Annotation
            TABLE_LOCKED_DIMS: "LAYOUT_TABLE_LOCKED_DIMS", // Table Locked Dimensions Annotation
            GRAPH_LOCKED_DIMS: "LAYOUT_GRAPH_LOCKED_DIMS", // Graph Locked Dimensions Annotation
            TEMPORAL_DIM_ORDER: "LAYOUT_TEMPORAL_ORDER", // Temporal Dimension Order Annotation
            ANTICIP_EXCEEDING_MAX_NUM_REC: "ANTICIP_EXCEEDING_MAX_NUM_REC", // Anticipation Exceeding Max Num Records Annotation
            DDB_CONTENT_CONSTRAINT: "DDB_CONTENT_CONSTRAINT" // Partial codelist Content Constraint
          },
      showCategoryLevels: config?.showCategoryLevels || 1,
      decimalNumber:
        config?.decimalNumber === null || config?.decimalNumber === undefined || config?.decimalNumber < 0
          ? ""
          : config?.decimalNumber,
      decimalSeparator: config?.decimalSeparator || getI18nObjFromLanguages(languages, ","),
      catalogNavigationMode: config?.catalogNavigationMode || "List",
      hiddenAttributes: config?.extras?.find(({key}) => key === "HiddenAttributes")
        ? JSON.parse(config.extras.find(({key}) => key === "HiddenAttributes").value)
        : [],
      restDataResponseXml:
        config?.extras?.find(({key}) => key === "RestDataResponseXml") &&
        (config.extras.find(({key}) => key === "RestDataResponseXml").value === "false" ||
          config.extras.find(({key}) => key === "RestDataResponseXml").value === "true")
          ? config.extras.find(({key}) => key === "RestDataResponseXml").value
          : "",
      downloadFormats: config?.extras?.find(({key}) => key === "DownloadFormats")
        ? JSON.parse(config.extras.find(({key}) => key === "DownloadFormats").value)
        : [],
      supportPostFilters:
        config?.extras?.find(({key}) => key === "SupportPostFilters") &&
        (config.extras.find(({key}) => key === "SupportPostFilters").value === "false" ||
          config.extras.find(({key}) => key === "SupportPostFilters").value === "true")
          ? JSON.parse(config.extras.find(({key}) => key === "SupportPostFilters").value)
          : false,
      supportPostFiltersStructure:
        config?.extras?.find(({key}) => key === "SupportPostFiltersStructure") &&
        (config.extras.find(({key}) => key === "SupportPostFiltersStructure").value === "false" ||
          config.extras.find(({key}) => key === "SupportPostFiltersStructure").value === "true")
          ? JSON.parse(config.extras.find(({key}) => key === "SupportPostFiltersStructure").value)
          : false,
      enableEndpointV20:
        config?.extras?.find(({key}) => key === "EnableEndPointV20") &&
        (config.extras.find(({key}) => key === "EnableEndPointV20").value === "false" ||
          config.extras.find(({key}) => key === "EnableEndPointV20").value === "true")
          ? JSON.parse(config.extras.find(({key}) => key === "EnableEndPointV20").value)
          : false,
      endpointV20: config?.extras?.find(({key}) => key === "EndPointV20")
        ? config.extras.find(({key}) => key === "EndPointV20").value || ""
        : "",
      defaultLastNPeriods: config?.extras?.find(({key}) => key === "DefaultLastNPeriods")
        ? config.extras.find(({key}) => key === "DefaultLastNPeriods").value || ""
        : "",
      showVirtualDataflow:
        config?.extras?.find(({key}) => key === "ShowVirtualDataflow") &&
        (config.extras.find(({key}) => key === "ShowVirtualDataflow").value === "false" ||
          config.extras.find(({key}) => key === "ShowVirtualDataflow").value === "true")
          ? JSON.parse(config.extras.find(({key}) => key === "ShowVirtualDataflow").value)
          : false,
      queryInfo:
        config?.extras?.find(({key}) => key === "QueryInfo") &&
        (config.extras.find(({key}) => key === "QueryInfo").value === "false" ||
          config.extras.find(({key}) => key === "QueryInfo").value === "true")
          ? JSON.parse(config.extras.find(({key}) => key === "QueryInfo").value)
          : false,
      queryInfoBaseUrl: config?.extras?.find(({key}) => key === "QuerySDMXBaseUrl")
        ? config.extras.find(({key}) => key === "QuerySDMXBaseUrl").value || ""
        : "",
      showOnlyFileDataflow:
        config?.extras?.find(({key}) => key === "ShowOnlyFileDataflow") &&
        (config.extras.find(({key}) => key === "ShowOnlyFileDataflow").value === "false" ||
          config.extras.find(({key}) => key === "ShowOnlyFileDataflow").value === "true")
          ? JSON.parse(config.extras.find(({key}) => key === "ShowOnlyFileDataflow").value)
          : true,
      timePeriodRangeStart: config?.extras?.find(({key}) => key === "TimePeriodRangeStart")
        ? config.extras.find(({key}) => key === "TimePeriodRangeStart").value || null
        : null,
      timePeriodRangeEnd: config?.extras?.find(({key}) => key === "TimePeriodRangeEnd")
        ? config.extras.find(({key}) => key === "TimePeriodRangeEnd").value || null
        : null,
      hideLabelOnCategoryWithImage:
        config?.extras?.find(({key}) => key === "HideLabelOnCategoryWithImage") &&
        (config.extras.find(({key}) => key === "HideLabelOnCategoryWithImage").value === "false" ||
          config.extras.find(({key}) => key === "HideLabelOnCategoryWithImage").value === "true")
          ? JSON.parse(config.extras.find(({key}) => key === "HideLabelOnCategoryWithImage").value)
          : false,
      temporalDimOrder:
        config?.extras?.find(({key}) => key === "TemporalDimOrder") &&
        (config.extras.find(({key}) => key === "TemporalDimOrder").value === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC ||
          config.extras.find(({key}) => key === "TemporalDimOrder").value === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC)
          ? config.extras.find(({key}) => key === "TemporalDimOrder").value
          : TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET,
      hierarchyOnlyAttributes: config?.extras?.find(({key}) => key === "HierarchyOnlyAttributes")
        ? JSON.parse(config.extras.find(({key}) => key === "HierarchyOnlyAttributes").value)
        : [],
      hideHierarchyOnlyRows:
        config?.extras?.find(({key}) => key === "HideHierarchyOnlyRows") &&
        (config.extras.find(({key}) => key === "HideHierarchyOnlyRows").value === "false" ||
          config.extras.find(({key}) => key === "HideHierarchyOnlyRows").value === "true")
          ? JSON.parse(config.extras.find(({key}) => key === "HideHierarchyOnlyRows").value)
          : false,
      supportedLanguages: (config?.extras?.find(({key}) => key === "SupportedLanguages")
        ? JSON.parse(config.extras.find(({key}) => key === "SupportedLanguages").value)
        : []
      ).filter(lang => hub.supportedLanguages.includes(lang)),
      defaultLanguage:
        config?.extras?.find(({key}) => key === "DefaultLanguage") &&
        hub.supportedLanguages.includes(config.extras.find(({key}) => key === "DefaultLanguage").value)
          ? config.extras.find(({key}) => key === "DefaultLanguage").value || null
          : null,
      enableCriteriaCache:
        config?.extras?.find(({key}) => key === "EnableCriteriaCache") &&
        (config.extras.find(({key}) => key === "EnableCriteriaCache").value === "false" ||
          config.extras.find(({key}) => key === "EnableCriteriaCache").value === "true")
          ? JSON.parse(config.extras.find(({key}) => key === "EnableCriteriaCache").value)
          : false,
      anticipationExceedingMaxNumRecords:
        config?.extras?.find(({key}) => key === "AnticipationExceedingMaxNumRecords") &&
        (config.extras.find(({key}) => key === "AnticipationExceedingMaxNumRecords").value === "false" ||
          config.extras.find(({key}) => key === "AnticipationExceedingMaxNumRecords").value === "true")
          ? JSON.parse(config.extras.find(({key}) => key === "AnticipationExceedingMaxNumRecords").value)
          : false
    }
  });

  const enableHttpAuth = watch("enableHttpAuth");
  const enableProxy = watch("enableProxy");
  const supportPostFiltersStructure = watch("supportPostFiltersStructure");
  const enableEndpointV20 = watch("enableEndpointV20");
  const isCriteriaSelectionModeDynamic = (watch("criteriaSelectionMode") || "") === STEP_BY_STEP_DYNAMIC;
  const isLastNPeriodsDisabled =
    (watch("criteriaSelectionMode") || "") !== STEP_BY_STEP_DYNAMIC &&
    (watch("criteriaSelectionMode") || "") !== STEP_BY_STEP_PARTIAL &&
    (watch("criteriaSelectionMode") || "") !== ALL_PARTIAL;
  const supportedLanguages = getValues("supportedLanguages");
  const defaultLanguage = getValues("defaultLanguage");

  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(val => {
        const data = {
          ...config,
          ...val,
          type: "SDMX-REST",
          decimalNumber:
            val?.decimalNumber === null || val?.decimalNumber === undefined || val?.decimalNumber === ""
              ? -1
              : val?.decimalNumber,
          annotationConfig: undefined,
          restDataResponseXml: undefined,
          hiddenAttributes: undefined,
          downloadFormats: undefined,
          supportPostFilters: undefined,
          supportPostFiltersStructure: undefined,
          enableEndpointV20: undefined,
          endpointV20: undefined,
          defaultLastNPeriods: undefined,
          showVirtualDataflow: undefined,
          queryInfo: undefined,
          queryInfoBaseUrl: undefined,
          showOnlyFileDataflow: undefined,
          timePeriodRangeStart: undefined,
          timePeriodRangeEnd: undefined,
          hideLabelOnCategoryWithImage: undefined,
          temporalDimOrder: undefined,
          hierarchyOnlyAttributes: undefined,
          hideHierarchyOnlyRows: undefined,
          supportedLanguages: undefined,
          defaultLanguage: undefined,
          enableCriteriaCache: undefined,
          anticipationExceedingMaxNumRecords: undefined
        };

        if (data.extras?.find(({key}) => key === "AnnotationConfig")) {
          data.extras.find(({key}) => key === "AnnotationConfig").value = JSON.stringify(val.annotationConfig);
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "AnnotationConfig",
            value: JSON.stringify(val.annotationConfig),
            isPublic: false
          });
        }

        if (data.extras?.find(({key}) => key === "RestDataResponseXml")) {
          data.extras.find(({key}) => key === "RestDataResponseXml").value = val.restDataResponseXml;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "RestDataResponseXml",
            value: val.restDataResponseXml,
            isPublic: false
          });
        }

        if (data.extras?.find(({key}) => key === "SupportPostFilters")) {
          data.extras.find(({key}) => key === "SupportPostFilters").value = JSON.stringify(val.supportPostFilters);
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "SupportPostFilters",
            value: JSON.stringify(val.supportPostFilters),
            isPublic: false
          });
        }

        if (data.extras?.find(({key}) => key === "SupportPostFiltersStructure")) {
          data.extras.find(({key}) => key === "SupportPostFiltersStructure").value = JSON.stringify(
            val.supportPostFiltersStructure
          );
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "SupportPostFiltersStructure",
            value: JSON.stringify(val.supportPostFiltersStructure),
            isPublic: false
          });
        }

        if (data.extras?.find(({key}) => key === "HiddenAttributes")) {
          data.extras.find(({key}) => key === "HiddenAttributes").value = JSON.stringify(val.hiddenAttributes);
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "HiddenAttributes",
            value: JSON.stringify(val.hiddenAttributes),
            isPublic: true
          });
        }

        if (data.extras?.find(({key}) => key === "DownloadFormats")) {
          data.extras.find(({key}) => key === "DownloadFormats").value = JSON.stringify(val.downloadFormats);
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "DownloadFormats",
            value: JSON.stringify(val.downloadFormats),
            isPublic: true
          });
        }

        if (data.extras?.find(({key}) => key === "EnableEndPointV20")) {
          data.extras.find(({key}) => key === "EnableEndPointV20").value = JSON.stringify(val.enableEndpointV20);
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "EnableEndPointV20",
            value: JSON.stringify(val.enableEndpointV20),
            isPublic: false
          });
        }

        if (data.extras?.find(({key}) => key === "EndPointV20")) {
          data.extras.find(({key}) => key === "EndPointV20").value = val.endpointV20;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "EndPointV20",
            value: val.endpointV20 || "",
            isPublic: false
          });
        }

        const defaultLastNPeriodsExtra = data.extras?.find(({key}) => key === "DefaultLastNPeriods");
        const defaultLastNPeriodsExtraPublic = true;
        if (defaultLastNPeriodsExtra) {
          defaultLastNPeriodsExtra.value = val.defaultLastNPeriods;
          defaultLastNPeriodsExtra.isPublic = defaultLastNPeriodsExtraPublic;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "DefaultLastNPeriods",
            value: val.defaultLastNPeriods,
            isPublic: defaultLastNPeriodsExtraPublic
          });
        }

        if (data.extras?.find(({key}) => key === "ShowVirtualDataflow")) {
          data.extras.find(({key}) => key === "ShowVirtualDataflow").value = JSON.stringify(val.showVirtualDataflow);
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "ShowVirtualDataflow",
            value: JSON.stringify(val.showVirtualDataflow),
            isPublic: false
          });
        }

        const queryInfoExtra = data.extras?.find(({key}) => key === "QueryInfo");
        const queryInfoExtraPublic = true;
        if (queryInfoExtra) {
          queryInfoExtra.value = JSON.stringify(val.queryInfo);
          queryInfoExtra.isPublic = queryInfoExtraPublic;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "QueryInfo",
            value: JSON.stringify(val.queryInfo),
            isPublic: queryInfoExtraPublic
          });
        }

        const queryInfoBaseUrlExtra = data.extras?.find(({key}) => key === "QuerySDMXBaseUrl");
        const queryInfoBaseUrlExtraPublic = false;
        if (queryInfoBaseUrlExtra) {
          queryInfoBaseUrlExtra.value = val.queryInfoBaseUrl;
          queryInfoBaseUrlExtra.isPublic = queryInfoBaseUrlExtraPublic;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "QuerySDMXBaseUrl",
            value: val.queryInfoBaseUrl,
            isPublic: queryInfoBaseUrlExtraPublic
          });
        }

        const showOnlyFileDataflowExtra = data.extras?.find(({key}) => key === "ShowOnlyFileDataflow");
        const showOnlyFileDataflowExtraPublic = false;
        if (showOnlyFileDataflowExtra) {
          showOnlyFileDataflowExtra.value = JSON.stringify(val.showOnlyFileDataflow);
          showOnlyFileDataflowExtra.isPublic = showOnlyFileDataflowExtraPublic;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "ShowOnlyFileDataflow",
            value: JSON.stringify(val.showOnlyFileDataflow),
            isPublic: showOnlyFileDataflowExtraPublic
          });
        }

        const timePeriodRangeStartExtra = data.extras?.find(({key}) => key === "TimePeriodRangeStart");
        const timePeriodRangeStartExtraPublic = true;
        if (timePeriodRangeStartExtra) {
          timePeriodRangeStartExtra.value = val.timePeriodRangeStart;
          timePeriodRangeStartExtra.isPublic = timePeriodRangeStartExtraPublic;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "TimePeriodRangeStart",
            value: val.timePeriodRangeStart,
            isPublic: timePeriodRangeStartExtraPublic
          });
        }

        const timePeriodRangeEndExtra = data.extras?.find(({key}) => key === "TimePeriodRangeEnd");
        const timePeriodRangeEndExtraPublic = true;
        if (timePeriodRangeEndExtra) {
          timePeriodRangeEndExtra.value = val.timePeriodRangeEnd;
          timePeriodRangeEndExtra.isPublic = timePeriodRangeEndExtraPublic;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "TimePeriodRangeEnd",
            value: val.timePeriodRangeEnd,
            isPublic: timePeriodRangeEndExtraPublic
          });
        }

        const hideLabelOnCategoryWithImageExtra = data.extras?.find(({key}) => key === "HideLabelOnCategoryWithImage");
        const hideLabelOnCategoryWithImageExtraPublic = true;
        if (hideLabelOnCategoryWithImageExtra) {
          hideLabelOnCategoryWithImageExtra.value = JSON.stringify(val.hideLabelOnCategoryWithImage);
          hideLabelOnCategoryWithImageExtra.isPublic = hideLabelOnCategoryWithImageExtraPublic;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "HideLabelOnCategoryWithImage",
            value: JSON.stringify(val.hideLabelOnCategoryWithImage),
            isPublic: hideLabelOnCategoryWithImageExtraPublic
          });
        }

        const temporalDimOrderExtra = data.extras?.find(({key}) => key === "TemporalDimOrder");
        const temporalDimOrderExtraPublic = false;
        if (temporalDimOrderExtra) {
          if (val.temporalDimOrder !== TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET) {
            temporalDimOrderExtra.value = val.temporalDimOrder;
            temporalDimOrderExtra.isPublic = temporalDimOrderExtraPublic;
          } else {
            data.extras = data.extras.filter(({key}) => key !== "TemporalDimOrder");
          }
        } else if (val.temporalDimOrder !== TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET) {
          data.extras = data.extras || [];
          data.extras.push({
            key: "TemporalDimOrder",
            value: val.temporalDimOrder,
            isPublic: temporalDimOrderExtraPublic
          });
        }

        const hierarchyOnlyAttributesExtra = data.extras?.find(({key}) => key === "HierarchyOnlyAttributes");
        const hierarchyOnlyAttributesExtraPublic = true;
        if (hierarchyOnlyAttributesExtra) {
          hierarchyOnlyAttributesExtra.value = JSON.stringify(val.hierarchyOnlyAttributes);
          hierarchyOnlyAttributesExtra.isPublic = hierarchyOnlyAttributesExtraPublic;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "HierarchyOnlyAttributes",
            value: JSON.stringify(val.hierarchyOnlyAttributes),
            isPublic: hierarchyOnlyAttributesExtraPublic
          });
        }

        const hideHierarchyOnlyRowsExtra = data.extras?.find(({key}) => key === "HideHierarchyOnlyRows");
        const hideHierarchyOnlyRowsExtraPublic = true;
        if (hideHierarchyOnlyRowsExtra) {
          hideHierarchyOnlyRowsExtra.value = JSON.stringify(val.hideHierarchyOnlyRows);
          hideHierarchyOnlyRowsExtra.isPublic = hideHierarchyOnlyRowsExtraPublic;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "HideHierarchyOnlyRows",
            value: JSON.stringify(val.hideHierarchyOnlyRows),
            isPublic: hideHierarchyOnlyRowsExtraPublic
          });
        }

        const supportedLanguagesExtra = data.extras?.find(({key}) => key === "SupportedLanguages");
        const supportedLanguagesExtraPublic = true;
        if (supportedLanguagesExtra) {
          supportedLanguagesExtra.value = JSON.stringify(val.supportedLanguages);
          supportedLanguagesExtra.isPublic = supportedLanguagesExtraPublic;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "SupportedLanguages",
            value: JSON.stringify(val.supportedLanguages),
            isPublic: supportedLanguagesExtraPublic
          });
        }

        const defaultLanguageExtra = data.extras?.find(({key}) => key === "DefaultLanguage");
        const defaultLanguageExtraPublic = true;
        if (defaultLanguageExtra) {
          defaultLanguageExtra.value = val.defaultLanguage;
          defaultLanguageExtra.isPublic = defaultLanguageExtraPublic;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "DefaultLanguage",
            value: val.defaultLanguage,
            isPublic: defaultLanguageExtraPublic
          });
        }

        const enableCriteriaCacheExtra = data.extras?.find(({key}) => key === "EnableCriteriaCache");
        if (enableCriteriaCacheExtra) {
          enableCriteriaCacheExtra.value = val.enableCriteriaCache;
          enableCriteriaCacheExtra.isPublic = false;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "EnableCriteriaCache",
            value: JSON.stringify(val.enableCriteriaCache),
            isPublic: false
          });
        }

        const anticipationExceedingMaxNumRecordsExtra = data.extras?.find(
          ({key}) => key === "AnticipationExceedingMaxNumRecords"
        );
        if (anticipationExceedingMaxNumRecordsExtra) {
          anticipationExceedingMaxNumRecordsExtra.value = val.anticipationExceedingMaxNumRecords;
          anticipationExceedingMaxNumRecordsExtra.isPublic = false;
        } else {
          data.extras = data.extras || [];
          data.extras.push({
            key: "AnticipationExceedingMaxNumRecords",
            value: JSON.stringify(val.anticipationExceedingMaxNumRecords),
            isPublic: false
          });
        }

        onSubmit(data);
        f(data);
      })();

      const firstFieldWithErrors = Object.keys(errors)[0];
      if (firstFieldWithErrors) {
        switch (firstFieldWithErrors) {
          case "code":
          case "agency":
          case "title":
          case "slogan":
          case "supportedLanguages":
          case "defaultLanguage": {
            setTab("general");
            break;
          }
          case "showCategoryLevels":
          case "decimalNumber":
          case "decimalSeparator":
          case "labelDimensionTerritorials":
          case "categorySchemaExcludes":
          case "catalogNavigationMode":
          case "hiddenAttributes":
          case "downloadFormats":
          case "defaultLastNPeriods":
          case "timePeriodRangeStart":
          case "timePeriodRangeEnd":
          case "hideLabelOnCategoryWithImage":
          case "temporalDimOrder":
          case "hierarchyOnlyAttributes":
          case "hideHierarchyOnlyRows": {
            setTab("view");
            break;
          }
          case "ttlCatalog":
          case "ttlDataflow": {
            setTab("cache");
            break;
          }
          default: {
            setTab("endpoint");
          }
        }
      }
    },
    cancel(f) {
      onCancel();
      f();
    }
  }));

  /* custom register */
  useEffect(() => {
    register("code", {
      required: t("commons.validation.required")
    });
    register("title");
    register("agency", {
      required: t("commons.validation.required")
    });
    register("active");
    register("visible");
    register("dataAccess");
    register("default");
    register("slogan");
    register("backgroundMediaURL");
    register("logo");
    register("description");
    register("criteriaSelectionMode", {
      required: t("commons.validation.required")
    });
    register("endPoint", {
      required: t("commons.validation.required")
    });
    register("supportPostFilters");
    register("supportPostFiltersStructure");
    register("enableEndpointV20");
    register("endpointV20", {
      validate: val =>
        getValues("enableEndpointV20") ? (val || "").length > 0 || t("commons.validation.required") : true
    });
    register("enableHttpAuth");
    register("authHttpUsername", {
      validate: val => (getValues("enableHttpAuth") ? (val || "").length > 0 || t("commons.validation.required") : true)
    });
    register("authHttpPassword");
    register("authHttpDomain");
    register("enableProxy");
    register("proxyAddress", {
      validate: val => (getValues("enableProxy") ? (val || "").length > 0 || t("commons.validation.required") : true)
    });
    register("proxyPort", {
      validate: val =>
        getValues("enableProxy")
          ? (isValidIntegerInInclusiveRange(val) && validator.isPort(val + "")) ||
            t("scenes.nodeSettings.fields.proxyPort.validation")
          : true
    });
    register("proxyUsername");
    register("proxyPassword");
    register("annotationConfig.ORDER");
    register("annotationConfig.ORDER_CATEGORY");
    register("annotationConfig.ORDER_CODELIST");
    register("annotationConfig.NOT_DISPLAYED");
    register("annotationConfig.DEFAULT");
    register("annotationConfig.TIME_PERIOD_START");
    register("annotationConfig.TIME_PERIOD_END");
    register("annotationConfig.LASTNPERIOD");
    register("annotationConfig.LAYOUT_ROW");
    register("annotationConfig.LAYOUT_COLUMN");
    register("annotationConfig.LAYOUT_FILTER");
    register("annotationConfig.LAYOUT_ROW_SECTION");
    register("annotationConfig.LAYOUT_CHART_PRIMARY_DIM");
    register("annotationConfig.LAYOUT_CHART_SECONDARY_DIM");
    register("annotationConfig.LAYOUT_CHART_FILTER");
    register("annotationConfig.LAYOUT_MAP_FILTER");
    register("annotationConfig.CRITERIA_SELECTION");
    register("annotationConfig.ATTACHED_DATA_FILES");
    register("annotationConfig.LAYOUT_DECIMAL_SEPARATOR");
    register("annotationConfig.LAYOUT_NUMBER_OF_DECIMALS");
    register("annotationConfig.LAYOUT_EMPTY_CELL_PLACEHOLDER");
    register("annotationConfig.DATAFLOW_NOTES");
    register("annotationConfig.DATAFLOW_SOURCE");
    register("annotationConfig.METADATA_URL");
    register("annotationConfig.KEYWORDS");
    register("annotationConfig.DEFAULT_VIEW");
    register("annotationConfig.GEO_ID");
    register("annotationConfig.LAST_UPDATE");
    register("annotationConfig.VIRTUAL_DATAFLOW_NODE");
    register("annotationConfig.DATAFLOW_CATALOG_TYPE");
    register("annotationConfig.DATAFLOW_HIDDEN");
    register("annotationConfig.DISABLED_VIEWERS");
    register("annotationConfig.TABLE_LOCKED_DIMS");
    register("annotationConfig.GRAPH_LOCKED_DIMS");
    register("annotationConfig.TEMPORAL_DIM_ORDER");
    register("annotationConfig.ANTICIP_EXCEEDING_MAX_NUM_REC");
    register("annotationConfig.DDB_CONTENT_CONSTRAINT");
    register("ttlCatalog", {
      validate: val =>
        (val || "").length === 0 ||
        isValidIntegerInInclusiveRange(val, -1) ||
        t("scenes.nodeSettings.validation.ttlCatalog")
    });
    register("ttlDataflow", {
      validate: val =>
        (val || "").length === 0 ||
        isValidIntegerInInclusiveRange(val, -1) ||
        t("scenes.nodeSettings.validation.ttlDataflow")
    });
    register("showDataflowUncategorized");
    register("showDataflowNotInProduction");
    register("showCategoryLevels", {
      required: t("commons.validation.required")
    });
    register("decimalNumber", {
      validate: val =>
        (val || "").length === 0 ||
        isValidIntegerInInclusiveRange(val, 0, 20) ||
        t("commons.validation.inRange", {min: 0, max: 20})
    });
    register("decimalSeparator", {
      validate: val => validateI18nObj(val) || t("commons.validation.requiredAnyLanguage")
    });
    register("labelDimensionTerritorials");
    register("categorySchemaExcludes");
    register("catalogNavigationMode", {
      required: t("commons.validation.required")
    });
    register("hiddenAttributes");
    register("restDataResponseXml", {
      required: t("commons.validation.required")
    });
    register("downloadFormats");
    register("defaultLastNPeriods", {
      validate: val => !val || val > 0 || t("commons.validation.positiveInteger")
    });
    register("showVirtualDataflow");
    register("queryInfo");
    register("queryInfoBaseUrl");
    register("showOnlyFileDataflow");
    register("timePeriodRangeStart");
    register("timePeriodRangeEnd");
    register("hideLabelOnCategoryWithImage");
    register("temporalDimOrder");
    register("hierarchyOnlyAttributes");
    register("hideHierarchyOnlyRows");
    register("supportedLanguages");
    register("defaultLanguage", {
      validate: val =>
        (getValues("supportedLanguages") || []).length > 0
          ? (val || "").length > 0 || t("commons.validation.required")
          : true
    });
    register("enableCriteriaCache");
    register("anticipationExceedingMaxNumRecords");
  }, [register, getValues, t]);

  return (
    <Box className={classes.root}>
      <Tabs value={tab} onChange={(_, tab) => setTab(tab)} variant="scrollable" scrollButtons="auto">
        <Tab value="general" label={t("scenes.nodeSettings.tabs.general.label")} />
        <Tab value="infos" label={t("scenes.nodeSettings.tabs.information.label")} />
        <Tab value="endpoint" label={t("scenes.nodeSettings.tabs.endpoint.label")} />
        <Tab value="annotations" label={t("scenes.nodeSettings.tabs.annotations.label")} />
        <Tab value="view" label={t("scenes.nodeSettings.tabs.view.label")} />
        <Tab value="cache" label={t("scenes.nodeSettings.tabs.cache.label")} />
        <Tab
          value="extra"
          label={t("scenes.nodeSettings.tabs.extra.label")}
          id="node-settings__tabs__extra"
          style={{display: "none"}}
        />
      </Tabs>
      <div className={classes.tabContent}>
        <TabPanel value="general" selected={tab}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <FormControl fullWidth className={classes.field}>
                <TextField
                  disabled={!!config}
                  name="code"
                  variant="outlined"
                  label={
                    <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.code.tooltip")}>
                      {t("scenes.nodeSettings.fields.code.label")}
                    </FormLabelWithTooltip>
                  }
                  required
                  error={!!errors.code}
                  helperText={errors.code?.message}
                  value={watch("code") || ""}
                  onChange={({target}) => setValue("code", (target.value || "").toUpperCase())}
                  inputProps={{
                    style: {textTransform: "uppercase"}
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth className={classes.field}>
                <I18nTextField
                  name="title"
                  label={t("scenes.nodeSettings.fields.title.label")}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  variant="outlined"
                  value={watch("title") || {}}
                  onChange={value => setValue("title", value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth className={classes.field}>
                <TextField
                  name="agency"
                  label={t("scenes.nodeSettings.fields.agency.label")}
                  required
                  error={!!errors.agency}
                  helperText={errors.agency?.message}
                  variant="outlined"
                  value={watch("agency") || ""}
                  onChange={({target}) => setValue("agency", target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl fullWidth className={classes.field}>
            <FormControlLabel
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.active.tooltip")} tooltipOnRight>
                  {t("scenes.nodeSettings.fields.active.label")}
                </FormLabelWithTooltip>
              }
              control={
                <Checkbox name="active" checked={watch("active")} onChange={(e, value) => setValue("active", value)} />
              }
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <ComponentVariantLoader
              componentId="node-and-data-visibility-select"
              visible={watch("visible")}
              dataAccess={watch("dataAccess")}
              onChange={({visible, dataAccess}) => {
                setValue("visible", visible);
                setValue("dataAccess", dataAccess);
                if (visible !== NodeVisibility.Yes) {
                  setValue("default", false);
                }
              }}
            />
          </FormControl>
          {initialNodeVisibility === NodeVisibility.Profiled && watch("visible") !== NodeVisibility.Profiled && (
            <Alert severity="warning" className={classes.warningAlert}>
              {t("scenes.nodeSettings.fields.visible.warning.label")}
            </Alert>
          )}
          <FormControl fullWidth className={classes.field}>
            <FormControlLabel
              label={t("scenes.nodeSettings.fields.default.label")}
              control={
                <Checkbox
                  name="default"
                  checked={watch("default")}
                  onChange={(e, value) => setValue("default", value)}
                  disabled={watch("visible") !== NodeVisibility.Yes}
                />
              }
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <I18nTextField
              name="slogan"
              label={t("scenes.nodeSettings.fields.slogan.label")}
              variant="outlined"
              value={watch("slogan") || {}}
              onChange={value => setValue("slogan", value)}
            />
          </FormControl>
          <Autocomplete
            multiple
            variant="outlined"
            options={hub?.supportedLanguages || []}
            value={watch("supportedLanguages") || []}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({index})} />)
            }
            onChange={(e, val) => {
              setValue("supportedLanguages", val);
              if (defaultLanguage && !val.includes(defaultLanguage)) {
                setValue("defaultLanguage", null);
              }
            }}
            renderInput={params => (
              <FormControl fullWidth className={classes.field}>
                <TextField
                  {...params}
                  label={t("scenes.nodeSettings.fields.supportedLanguages.label")}
                  variant="outlined"
                />
              </FormControl>
            )}
          />
          <FormControl fullWidth className={classes.field}>
            <TextField
              select
              required={(supportedLanguages || []).length > 0}
              label={t("scenes.nodeSettings.fields.defaultLanguage.label")}
              onChange={e => setValue("defaultLanguage", e.target.value)}
              value={watch("defaultLanguage") || ""}
              error={!!errors.defaultLanguage}
              helperText={errors.defaultLanguage?.message}
              variant="outlined"
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
              disabled={(supportedLanguages || []).length === 0}
            >
              {supportedLanguages.map((val, index) => (
                <MenuItem key={index} value={val}>
                  {val}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <FileInput
              label={t("scenes.nodeSettings.fields.backgroundMediaURL.label")}
              value={watch("backgroundMediaURL")}
              onChange={value => setValue("backgroundMediaURL", value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <FileInput
              label={t("scenes.nodeSettings.fields.logo.label")}
              value={watch("logo")}
              onChange={value => setValue("logo", value)}
            />
          </FormControl>
        </TabPanel>
        <TabPanel value="infos" selected={tab}>
          <I18nHtmlEditor value={watch("description")} onChange={val => setValue("description", val)} />
        </TabPanel>
        <TabPanel value="endpoint" selected={tab}>
          <Paper variant="outlined" className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <FormControl fullWidth>
                  <TextField
                    name="type"
                    select
                    label={t("scenes.nodeSettings.fields.type.label")}
                    value="SDMX-REST"
                    variant="outlined"
                    SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                  >
                    <MenuItem value="SDMX-REST">SDMX-REST</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl fullWidth>
                  <TextField
                    name="criteriaSelectionMode"
                    select
                    label={
                      <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.criteriaSelectionMode.tooltip")}>
                        {t("scenes.nodeSettings.fields.criteriaSelectionMode.label")}
                      </FormLabelWithTooltip>
                    }
                    variant="outlined"
                    onChange={e => setValue("criteriaSelectionMode", e.target.value)}
                    value={watch("criteriaSelectionMode") || ""}
                    helperText={errors.criteriaSelectionMode?.message}
                    error={!!errors.criteriaSelectionMode}
                    SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                  >
                    <MenuItem value={ALL_FULL}>ALL_FULL</MenuItem>
                    <MenuItem value={ALL_PARTIAL}>ALL_PARTIAL</MenuItem>
                    <MenuItem value={ALL_PARTIAL_OPTIMIZED}>ALL_PARTIAL_OPTIMIZED</MenuItem>
                    <MenuItem value={STEP_BY_STEP_FULL}>STEP_BY_STEP_FULL</MenuItem>
                    <MenuItem value={STEP_BY_STEP_PARTIAL}>STEP_BY_STEP_PARTIAL</MenuItem>
                    <MenuItem value={STEP_BY_STEP_DYNAMIC}>STEP_BY_STEP_DYNAMIC</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" className={classes.paper}>
            <FormControl fullWidth>
              <TextField
                name="endPoint"
                label={t("scenes.nodeSettings.fields.endPoint.label")}
                required
                error={!!errors.endPoint}
                helperText={errors.endPoint?.message}
                variant="outlined"
                value={watch("endPoint") || ""}
                onChange={({target}) => setValue("endPoint", target.value)}
              />
            </FormControl>
            <FormControl fullWidth className={classes.field}>
              <TextField
                select
                label={t("scenes.nodeSettings.fields.responseFormat.label")}
                value={watch("restDataResponseXml") || ""}
                required
                variant="outlined"
                error={!!errors.restDataResponseXml}
                helperText={errors.restDataResponseXml?.message}
                onChange={({target}) => setValue("restDataResponseXml", target.value)}
                SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
              >
                <MenuItem value="true">XML</MenuItem>
                <MenuItem value="false">JSON</MenuItem>
              </TextField>
            </FormControl>
            <FormControl fullWidth className={classes.field}>
              <FormControlLabel
                label={t("scenes.nodeSettings.fields.supportPostFilters.label")}
                control={
                  <Checkbox
                    name="supportPostFilters"
                    checked={watch("supportPostFilters") || false}
                    onChange={(e, value) => setValue("supportPostFilters", value)}
                  />
                }
              />
            </FormControl>
            {isCriteriaSelectionModeDynamic && (
              <Paper variant="outlined" className={classes.paper}>
                <CardHeader
                  subheader={
                    <FormLabelWithTooltip
                      tooltip={t("scenes.nodeSettings.tabs.endpoint.cards.structureFilters.tooltip")}
                      tooltipOnRight
                    >
                      {t("scenes.nodeSettings.tabs.endpoint.cards.structureFilters.label")}
                    </FormLabelWithTooltip>
                  }
                  className={classes.paperHeader}
                />
                <FormControl component="fieldset">
                  {(() => {
                    let radioValue;
                    if (supportPostFiltersStructure && enableEndpointV20) {
                      radioValue = null;
                    } else if (supportPostFiltersStructure) {
                      radioValue = "supportPostFiltersStructure";
                    } else if (enableEndpointV20) {
                      radioValue = "enableEndpointV20";
                    } else {
                      radioValue = "GET";
                    }

                    return (
                      <RadioGroup
                        value={radioValue}
                        onChange={event => {
                          switch (event.target.value) {
                            case "supportPostFiltersStructure": {
                              setValue("supportPostFiltersStructure", true);
                              setValue("enableEndpointV20", false);
                              break;
                            }
                            case "enableEndpointV20": {
                              setValue("supportPostFiltersStructure", false);
                              setValue("enableEndpointV20", true);
                              break;
                            }
                            default: {
                              setValue("supportPostFiltersStructure", false);
                              setValue("enableEndpointV20", false);
                            }
                          }
                        }}
                      >
                        <FormControlLabel
                          value="GET"
                          control={<Radio />}
                          label={t("scenes.nodeSettings.fields.structureFilters.default.label")}
                        />
                        <FormControlLabel
                          value="supportPostFiltersStructure"
                          control={<Radio />}
                          label={t("scenes.nodeSettings.fields.structureFilters.supportPostFiltersStructure.label")}
                        />
                        <FormControlLabel
                          value="enableEndpointV20"
                          control={<Radio />}
                          label={
                            <FormLabelWithTooltip
                              tooltip={t("scenes.nodeSettings.fields.structureFilters.enableEndpointV20.tooltip")}
                              tooltipOnRight
                            >
                              {t("scenes.nodeSettings.fields.structureFilters.enableEndpointV20.label")}
                            </FormLabelWithTooltip>
                          }
                        />
                      </RadioGroup>
                    );
                  })()}
                </FormControl>
                {enableEndpointV20 && (
                  <FormControl fullWidth className={classes.field}>
                    <TextField
                      name="endpointV20"
                      label={t("scenes.nodeSettings.fields.endpointV20.label")}
                      required
                      error={!!errors.endpointV20}
                      helperText={errors.endpointV20?.message}
                      variant="outlined"
                      value={watch("endpointV20") || ""}
                      onChange={({target}) => setValue("endpointV20", target.value)}
                    />
                  </FormControl>
                )}
              </Paper>
            )}
          </Paper>
          <Paper variant="outlined" className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item sm={4}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.enableHttpAuth.label")}
                  control={
                    <Checkbox
                      name="enableHttpAuth"
                      checked={enableHttpAuth || false}
                      onChange={(e, value) => setValue("enableHttpAuth", value)}
                    />
                  }
                />
              </Grid>
              {enableHttpAuth && (
                <Fragment>
                  <Grid item sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        name="authHttpUsername"
                        label={t("scenes.nodeSettings.fields.authHttpUsername.label")}
                        required
                        error={!!errors.authHttpUsername}
                        helperText={errors.authHttpUsername?.message}
                        variant="outlined"
                        value={watch("authHttpUsername") || ""}
                        onChange={({target}) => setValue("authHttpUsername", target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl fullWidth>
                      <PasswordInput
                        disableAutocomplete
                        disableShowPassword
                        name="authHttpPassword"
                        label={t("scenes.nodeSettings.fields.authHttpPassword.label")}
                        variant="outlined"
                        value={watch("authHttpPassword") || ""}
                        onChange={({target}) => setValue("authHttpPassword", target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4} />
                  <Grid item sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        name="authHttpDomain"
                        label={t("scenes.nodeSettings.fields.authHttpDomain.label")}
                        variant="outlined"
                        value={watch("authHttpDomain") || ""}
                        onChange={({target}) => setValue("authHttpDomain", target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Paper>
          <Paper variant="outlined" className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item sm={4}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.enableProxy.label")}
                  control={
                    <Checkbox
                      name="enableProxy"
                      checked={enableProxy || false}
                      onChange={(e, value) => setValue("enableProxy", value)}
                    />
                  }
                />
              </Grid>
              {enableProxy && (
                <Fragment>
                  <Grid item sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        name="proxyAddress"
                        label={t("scenes.nodeSettings.fields.proxyAddress.label")}
                        required
                        error={!!errors.proxyAddress}
                        helperText={errors.proxyAddress?.message}
                        variant="outlined"
                        value={watch("proxyAddress") || ""}
                        onChange={({target}) => setValue("proxyAddress", target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        name="proxyPort"
                        label={t("scenes.nodeSettings.fields.proxyPort.label")}
                        required
                        error={!!errors.proxyPort}
                        helperText={errors.proxyPort?.message}
                        variant="outlined"
                        value={watch("proxyPort") || ""}
                        onChange={({target}) => setValue("proxyPort", target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4} />
                  <Grid item sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        name="proxyUsername"
                        label={t("scenes.nodeSettings.fields.proxyUsername.label")}
                        variant="outlined"
                        value={watch("proxyUsername") || ""}
                        onChange={({target}) => setValue("proxyUsername", target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl fullWidth>
                      <PasswordInput
                        disableAutocomplete
                        disableShowPassword
                        name="proxyPassword"
                        label={t("scenes.nodeSettings.fields.proxyPassword.label")}
                        variant="outlined"
                        value={watch("proxyPassword") || ""}
                        onChange={e => setValue("proxyPassword", e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value="annotations" selected={tab}>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.ORDER"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.ORDER.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.ORDER.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.ORDER") || ""}
              onChange={({target}) => setValue("annotationConfig.ORDER", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.ORDER_CATEGORY"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.ORDER_CATEGORY.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.ORDER_CATEGORY.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.ORDER_CATEGORY") || ""}
              onChange={({target}) => setValue("annotationConfig.ORDER_CATEGORY", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.ORDER_CODELIST"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.ORDER_CODELIST.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.ORDER_CODELIST.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.ORDER_CODELIST") || ""}
              onChange={({target}) => setValue("annotationConfig.ORDER_CODELIST", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.NOT_DISPLAYED"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.NOT_DISPLAYED.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.NOT_DISPLAYED.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.NOT_DISPLAYED") || ""}
              onChange={({target}) => setValue("annotationConfig.NOT_DISPLAYED", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.DEFAULT"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.DEFAULT.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.DEFAULT.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.DEFAULT") || ""}
              onChange={({target}) => setValue("annotationConfig.DEFAULT", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={`${classes.field} ${classes.subfield}`}>
            <TextField
              name="annotationConfig.TIME_PERIOD_START"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.TIME_PERIOD_START.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.TIME_PERIOD_START.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.TIME_PERIOD_START") || ""}
              onChange={({target}) => setValue("annotationConfig.TIME_PERIOD_START", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={`${classes.field} ${classes.subfield}`}>
            <TextField
              name="annotationConfig.TIME_PERIOD_END"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.TIME_PERIOD_END.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.TIME_PERIOD_END.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.TIME_PERIOD_END") || ""}
              onChange={({target}) => setValue("annotationConfig.TIME_PERIOD_END", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={`${classes.field} ${classes.subfield}`}>
            <TextField
              name="annotationConfig.LASTNPERIOD"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LASTNPERIOD.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.LASTNPERIOD.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LASTNPERIOD") || ""}
              onChange={({target}) => setValue("annotationConfig.LASTNPERIOD", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.LAYOUT_ROW"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_ROW.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.LAYOUT_ROW.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LAYOUT_ROW") || ""}
              onChange={({target}) => setValue("annotationConfig.LAYOUT_ROW", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.LAYOUT_COLUMN"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_COLUMN.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.LAYOUT_COLUMN.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LAYOUT_COLUMN") || ""}
              onChange={({target}) => setValue("annotationConfig.LAYOUT_COLUMN", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.LAYOUT_FILTER"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_FILTER.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.LAYOUT_FILTER.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LAYOUT_FILTER") || ""}
              onChange={({target}) => setValue("annotationConfig.LAYOUT_FILTER", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.LAYOUT_ROW_SECTION"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_ROW_SECTION.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.LAYOUT_ROW_SECTION.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LAYOUT_ROW_SECTION") || ""}
              onChange={({target}) => setValue("annotationConfig.LAYOUT_ROW_SECTION", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.LAYOUT_CHART_PRIMARY_DIM"
              label={
                <FormLabelWithTooltip
                  tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_PRIMARY_DIM.tooltip")}
                >
                  {t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_PRIMARY_DIM.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LAYOUT_CHART_PRIMARY_DIM") || ""}
              onChange={({target}) => setValue("annotationConfig.LAYOUT_CHART_PRIMARY_DIM", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.LAYOUT_CHART_SECONDARY_DIM"
              label={
                <FormLabelWithTooltip
                  tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_SECONDARY_DIM.tooltip")}
                >
                  {t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_SECONDARY_DIM.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LAYOUT_CHART_SECONDARY_DIM") || ""}
              onChange={({target}) => setValue("annotationConfig.LAYOUT_CHART_SECONDARY_DIM", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.LAYOUT_CHART_FILTER"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_FILTER.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.LAYOUT_CHART_FILTER.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LAYOUT_CHART_FILTER") || ""}
              onChange={({target}) => setValue("annotationConfig.LAYOUT_CHART_FILTER", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.LAYOUT_MAP_FILTER"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_MAP_FILTER.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.LAYOUT_MAP_FILTER.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LAYOUT_MAP_FILTER") || ""}
              onChange={({target}) => setValue("annotationConfig.LAYOUT_MAP_FILTER", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.CRITERIA_SELECTION"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.CRITERIA_SELECTION.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.CRITERIA_SELECTION.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.CRITERIA_SELECTION") || ""}
              onChange={({target}) => setValue("annotationConfig.CRITERIA_SELECTION", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.ATTACHED_DATA_FILES"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.ATTACHED_DATA_FILES.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.ATTACHED_DATA_FILES.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.ATTACHED_DATA_FILES") || ""}
              onChange={({target}) => setValue("annotationConfig.ATTACHED_DATA_FILES", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.LAYOUT_DECIMAL_SEPARATOR"
              label={
                <FormLabelWithTooltip
                  tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_DECIMAL_SEPARATOR.tooltip")}
                >
                  {t("scenes.nodeSettings.fields.annotations.LAYOUT_DECIMAL_SEPARATOR.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LAYOUT_DECIMAL_SEPARATOR") || ""}
              onChange={({target}) => setValue("annotationConfig.LAYOUT_DECIMAL_SEPARATOR", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.LAYOUT_NUMBER_OF_DECIMALS"
              label={
                <FormLabelWithTooltip
                  tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_NUMBER_OF_DECIMALS.tooltip")}
                >
                  {t("scenes.nodeSettings.fields.annotations.LAYOUT_NUMBER_OF_DECIMALS.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LAYOUT_NUMBER_OF_DECIMALS") || ""}
              onChange={({target}) => setValue("annotationConfig.LAYOUT_NUMBER_OF_DECIMALS", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.LAYOUT_EMPTY_CELL_PLACEHOLDER"
              label={
                <FormLabelWithTooltip
                  tooltip={t("scenes.nodeSettings.fields.annotations.LAYOUT_EMPTY_CELL_PLACEHOLDER.tooltip")}
                >
                  {t("scenes.nodeSettings.fields.annotations.LAYOUT_EMPTY_CELL_PLACEHOLDER.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LAYOUT_EMPTY_CELL_PLACEHOLDER") || ""}
              onChange={({target}) => setValue("annotationConfig.LAYOUT_EMPTY_CELL_PLACEHOLDER", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.DATAFLOW_NOTES"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.DATAFLOW_NOTES.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.DATAFLOW_NOTES.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.DATAFLOW_NOTES") || ""}
              onChange={({target}) => setValue("annotationConfig.DATAFLOW_NOTES", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.DATAFLOW_SOURCE"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.DATAFLOW_SOURCE.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.DATAFLOW_SOURCE.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.DATAFLOW_SOURCE") || ""}
              onChange={({target}) => setValue("annotationConfig.DATAFLOW_SOURCE", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.METADATA_URL"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.METADATA_URL.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.METADATA_URL.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.METADATA_URL") || ""}
              onChange={({target}) => setValue("annotationConfig.METADATA_URL", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.KEYWORDS"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.KEYWORDS.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.KEYWORDS.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.KEYWORDS") || ""}
              onChange={({target}) => setValue("annotationConfig.KEYWORDS", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.DEFAULT_VIEW"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.DEFAULT_VIEW.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.DEFAULT_VIEW.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.DEFAULT_VIEW") || ""}
              onChange={({target}) => setValue("annotationConfig.DEFAULT_VIEW", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.GEO_ID"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.GEO_ID.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.GEO_ID.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.GEO_ID") || ""}
              onChange={({target}) => setValue("annotationConfig.GEO_ID", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.LAST_UPDATE"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.LAST_UPDATE.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.LAST_UPDATE.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.LAST_UPDATE") || ""}
              onChange={({target}) => setValue("annotationConfig.LAST_UPDATE", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.VIRTUAL_DATAFLOW_NODE"
              label={
                <FormLabelWithTooltip
                  tooltip={t("scenes.nodeSettings.fields.annotations.VIRTUAL_DATAFLOW_NODE.tooltip")}
                >
                  {t("scenes.nodeSettings.fields.annotations.VIRTUAL_DATAFLOW_NODE.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.VIRTUAL_DATAFLOW_NODE") || ""}
              onChange={({target}) => setValue("annotationConfig.VIRTUAL_DATAFLOW_NODE", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.DATAFLOW_CATALOG_TYPE"
              label={
                <FormLabelWithTooltip
                  tooltip={t("scenes.nodeSettings.fields.annotations.DATAFLOW_CATALOG_TYPE.tooltip")}
                >
                  {t("scenes.nodeSettings.fields.annotations.DATAFLOW_CATALOG_TYPE.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.DATAFLOW_CATALOG_TYPE") || ""}
              onChange={({target}) => setValue("annotationConfig.DATAFLOW_CATALOG_TYPE", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.DATAFLOW_HIDDEN"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.DATAFLOW_HIDDEN.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.DATAFLOW_HIDDEN.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.DATAFLOW_HIDDEN") || ""}
              onChange={({target}) => setValue("annotationConfig.DATAFLOW_HIDDEN", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.DISABLED_VIEWERS"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.DISABLED_VIEWERS.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.DISABLED_VIEWERS.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.DISABLED_VIEWERS") || ""}
              onChange={({target}) => setValue("annotationConfig.DISABLED_VIEWERS", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.TABLE_LOCKED_DIMS"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.TABLE_LOCKED_DIMS.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.TABLE_LOCKED_DIMS.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.TABLE_LOCKED_DIMS") || ""}
              onChange={({target}) => setValue("annotationConfig.TABLE_LOCKED_DIMS", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.GRAPH_LOCKED_DIMS"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.GRAPH_LOCKED_DIMS.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.GRAPH_LOCKED_DIMS.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.GRAPH_LOCKED_DIMS") || ""}
              onChange={({target}) => setValue("annotationConfig.GRAPH_LOCKED_DIMS", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.TEMPORAL_DIM_ORDER"
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.annotations.TEMPORAL_DIM_ORDER.tooltip")}>
                  {t("scenes.nodeSettings.fields.annotations.TEMPORAL_DIM_ORDER.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.TEMPORAL_DIM_ORDER") || ""}
              onChange={({target}) => setValue("annotationConfig.TEMPORAL_DIM_ORDER", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.ANTICIP_EXCEEDING_MAX_NUM_REC"
              label={
                <FormLabelWithTooltip
                  tooltip={t("scenes.nodeSettings.fields.annotations.ANTICIP_EXCEEDING_MAX_NUM_REC.tooltip")}
                >
                  {t("scenes.nodeSettings.fields.annotations.ANTICIP_EXCEEDING_MAX_NUM_REC.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.ANTICIP_EXCEEDING_MAX_NUM_REC") || ""}
              onChange={({target}) => setValue("annotationConfig.ANTICIP_EXCEEDING_MAX_NUM_REC", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="annotationConfig.DDB_CONTENT_CONSTRAINT"
              label={
                <FormLabelWithTooltip
                  tooltip={t("scenes.nodeSettings.fields.annotations.DDB_CONTENT_CONSTRAINT.tooltip")}
                >
                  {t("scenes.nodeSettings.fields.annotations.DDB_CONTENT_CONSTRAINT.label")}
                </FormLabelWithTooltip>
              }
              variant="outlined"
              value={watch("annotationConfig.DDB_CONTENT_CONSTRAINT") || ""}
              onChange={({target}) => setValue("annotationConfig.DDB_CONTENT_CONSTRAINT", target.value)}
            />
          </FormControl>
        </TabPanel>
        <TabPanel value="cache" selected={tab}>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="ttlCatalog"
              variant="outlined"
              type="number"
              helperText={errors.ttlCatalog?.message}
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.catalogCacheValidity.tooltip")}>
                  {t("scenes.nodeSettings.fields.catalogCacheValidity.label")}
                </FormLabelWithTooltip>
              }
              error={!!errors.ttlCatalog}
              value={watch("ttlCatalog") !== null && watch("ttlCatalog") !== undefined ? watch("ttlCatalog") : ""}
              onChange={({target}) => setValue("ttlCatalog", target.value)}
            />
          </FormControl>
          <FormControl fullWidth className={classes.field}>
            <TextField
              name="ttlDataflow"
              variant="outlined"
              type="number"
              helperText={errors.ttlDataflow?.message}
              label={
                <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.dataflowCacheValidity.tooltip")}>
                  {t("scenes.nodeSettings.fields.dataflowCacheValidity.label")}
                </FormLabelWithTooltip>
              }
              error={!!errors.ttlDataflow}
              value={watch("ttlDataflow") !== null && watch("ttlDataflow") !== undefined ? watch("ttlDataflow") : ""}
              onChange={({target}) => setValue("ttlDataflow", target.value)}
            />
          </FormControl>

          <FormControl fullWidth className={classes.field}>
            <FormControlLabel
              label={
                <FormLabelWithTooltip
                  tooltipOnRight
                  tooltip={t("scenes.nodeSettings.fields.enableCriteriaCache.tooltip")}
                >
                  {t("scenes.nodeSettings.fields.enableCriteriaCache.label")}
                </FormLabelWithTooltip>
              }
              control={
                <Checkbox
                  name="enableCriteriaCache"
                  required
                  checked={watch("enableCriteriaCache") || false}
                  onChange={(e, value) => setValue("enableCriteriaCache", value)}
                />
              }
            />
          </FormControl>
        </TabPanel>
        <TabPanel value="view" selected={tab}>
          <Paper variant="outlined" className={classes.paper}>
            <CardHeader
              subheader={t("scenes.nodeSettings.tabs.view.cards.catalog.title")}
              className={classes.paperHeader}
            />
            <FormControl fullWidth className={classes.field}>
              <FormControlLabel
                label={t("scenes.nodeSettings.fields.showDataflowUncategorized.label")}
                control={
                  <Checkbox
                    name="showDataflowUncategorized"
                    required
                    checked={watch("showDataflowUncategorized") || false}
                    onChange={(e, value) => setValue("showDataflowUncategorized", value)}
                  />
                }
              />
            </FormControl>
            <FormControl fullWidth className={classes.field}>
              <FormControlLabel
                label={t("scenes.nodeSettings.fields.showDataflowNotInProduction.label")}
                control={
                  <Checkbox
                    name="showDataflowNotInProduction"
                    required
                    checked={watch("showDataflowNotInProduction") || false}
                    onChange={(e, value) => setValue("showDataflowNotInProduction", value)}
                  />
                }
              />
            </FormControl>
            <FormControl fullWidth className={classes.field}>
              <FormControlLabel
                label={t("scenes.nodeSettings.fields.showLinkedDataflow.label")}
                control={
                  <Checkbox
                    name="showVirtualDataflow"
                    required
                    checked={watch("showVirtualDataflow") || false}
                    onChange={(e, value) => setValue("showVirtualDataflow", value)}
                  />
                }
              />
            </FormControl>
            <FormControl fullWidth className={classes.field}>
              <FormControlLabel
                label={t("scenes.nodeSettings.fields.showOnlyFileDataflow.label")}
                control={
                  <Checkbox
                    name="showOnlyFileDataflow"
                    required
                    checked={watch("showOnlyFileDataflow") || false}
                    onChange={(e, value) => setValue("showOnlyFileDataflow", value)}
                  />
                }
              />
            </FormControl>
            <FormControl fullWidth className={classes.field}>
              <TextField
                name="showCategoryLevels"
                select
                required
                label={t("scenes.nodeSettings.fields.showCategoryLevels.label")}
                onChange={e => setValue("showCategoryLevels", e.target.value)}
                value={watch("showCategoryLevels") || ""}
                error={!!errors.showCategoryLevels}
                helperText={errors.showCategoryLevels?.message}
                variant="outlined"
                SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </TextField>
            </FormControl>
            <FormControl fullWidth className={classes.field}>
              <FormControlLabel
                label={t("scenes.nodeSettings.fields.hideLabelOnCategoryWithImage.label")}
                control={
                  <Checkbox
                    name="hideLabelOnCategoryWithImage"
                    required
                    checked={watch("hideLabelOnCategoryWithImage") || false}
                    onChange={(e, value) => setValue("hideLabelOnCategoryWithImage", value)}
                  />
                }
              />
            </FormControl>
            <Autocomplete
              multiple
              variant="outlined"
              freeSolo
              options={[]}
              value={watch("categorySchemaExcludes") || []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({index})} />)
              }
              onChange={(e, val) => setValue("categorySchemaExcludes", val)}
              renderInput={params => (
                <FormControl fullWidth className={classes.field}>
                  <TextField
                    label={t("scenes.nodeSettings.fields.categorySchemaExcludes.label")}
                    {...params}
                    variant="outlined"
                    placeholder={t("scenes.nodeSettings.fields.categorySchemaExcludes.placeholder")}
                  />
                </FormControl>
              )}
            />
            <FormControl fullWidth className={classes.field}>
              <TextField
                select
                label={t("scenes.nodeSettings.fields.catalogNavigationMode.label")}
                onChange={e => setValue("catalogNavigationMode", e.target.value)}
                value={watch("catalogNavigationMode") || ""}
                required
                variant="outlined"
                error={!!errors.catalogNavigationMode}
                helperText={errors.catalogNavigationMode?.message}
                SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
              >
                <MenuItem value={CATALOG_NAVIGATION_MODE_TREE}>
                  {t("scenes.nodeSettings.fields.catalogNavigationMode.values.tree")}
                </MenuItem>
                <MenuItem value={CATALOG_NAVIGATION_MODE_GRID}>
                  {t("scenes.nodeSettings.fields.catalogNavigationMode.values.grid")}
                </MenuItem>
                <MenuItem value={CATALOG_NAVIGATION_MODE_LIST}>
                  {t("scenes.nodeSettings.fields.catalogNavigationMode.values.list")}
                </MenuItem>
              </TextField>
            </FormControl>
            <Autocomplete
              multiple
              variant="outlined"
              options={Object.keys(downloadFormats(t))}
              getOptionLabel={option => downloadFormats(t)[option]?.label || ""}
              value={watch("downloadFormats") || []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={downloadFormats(t)[option]?.label || ""} {...getTagProps({index})} />
                ))
              }
              onChange={(e, val) => setValue("downloadFormats", val)}
              renderInput={params => (
                <FormControl fullWidth className={classes.field}>
                  <TextField
                    label={t("scenes.nodeSettings.fields.downloadFormats.label")}
                    {...params}
                    variant="outlined"
                    placeholder={t("scenes.nodeSettings.fields.downloadFormats.placeholder")}
                  />
                </FormControl>
              )}
            />
          </Paper>
          <Paper variant="outlined" className={classes.paper}>
            <CardHeader
              subheader={t("scenes.nodeSettings.tabs.view.cards.dataflow.title")}
              className={classes.paperHeader}
            />
            <FormControl fullWidth className={classes.field}>
              <TextField
                name="decimalNumber"
                label={t("scenes.nodeSettings.fields.decimalNumber.label")}
                variant="outlined"
                required
                type="number"
                error={!!errors.decimalNumber}
                helperText={errors.decimalNumber?.message}
                value={
                  watch("decimalNumber") === null || watch("decimalNumber") === undefined || watch("decimalNumber") < 0
                    ? ""
                    : watch("decimalNumber")
                }
                onChange={({target}) => setValue("decimalNumber", target.value)}
              />
            </FormControl>
            <FormControl fullWidth className={classes.field}>
              <I18nTextField
                name="decimalSeparator"
                select
                required
                label={t("scenes.nodeSettings.fields.decimalSeparator.label")}
                onChange={value => setValue("decimalSeparator", value)}
                value={watch("decimalSeparator") || ""}
                helper={t("scenes.nodeSettings.fields.decimalSeparator.helper")}
                error={!!errors.decimalSeparator}
                helperText={errors.decimalSeparator?.message}
                variant="outlined"
                SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
              >
                <MenuItem value=".">Dot</MenuItem>
                <MenuItem value=",">Comma</MenuItem>
              </I18nTextField>
            </FormControl>
            <Autocomplete
              multiple
              variant="outlined"
              freeSolo
              options={[]}
              value={watch("labelDimensionTerritorials") || []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({index})} />)
              }
              onChange={(e, val) => setValue("labelDimensionTerritorials", val)}
              renderInput={params => (
                <FormControl fullWidth className={classes.field}>
                  <TextField
                    label={t("scenes.nodeSettings.fields.labelDimensionTerritorials.label")}
                    {...params}
                    variant="outlined"
                    placeholder={t("scenes.nodeSettings.fields.labelDimensionTerritorials.placeholder")}
                  />
                </FormControl>
              )}
            />
            <Autocomplete
              multiple
              variant="outlined"
              freeSolo
              options={[]}
              value={watch("hiddenAttributes") || []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({index})} />)
              }
              onChange={(e, val) => setValue("hiddenAttributes", val)}
              renderInput={params => (
                <FormControl fullWidth className={classes.field}>
                  <TextField
                    label={t("scenes.nodeSettings.fields.hiddenAttributes.label")}
                    {...params}
                    variant="outlined"
                    placeholder={t("scenes.nodeSettings.fields.hiddenAttributes.placeholder")}
                  />
                </FormControl>
              )}
            />
            <FormControl fullWidth className={classes.field}>
              <TextField
                select
                label={t("scenes.nodeSettings.fields.temporalDimOrder.label")}
                value={watch("temporalDimOrder")}
                variant="outlined"
                onChange={ev => setValue("temporalDimOrder", ev.target.value)}
                SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
              >
                <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET}>
                  {t("scenes.nodeSettings.fields.temporalDimOrder.values.unset")}
                </MenuItem>
                <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC}>
                  {t("scenes.nodeSettings.fields.temporalDimOrder.values.asc")}
                </MenuItem>
                <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC}>
                  {t("scenes.nodeSettings.fields.temporalDimOrder.values.desc")}
                </MenuItem>
              </TextField>
            </FormControl>
            <Paper variant="outlined" className={classes.paper}>
              <CardHeader subheader={t("scenes.nodeSettings.fields.queryInfo.title")} className={classes.paperHeader} />
              <FormControl fullWidth className={classes.field}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.queryInfo.label")}
                  control={
                    <Checkbox
                      name="queryInfo"
                      required
                      checked={watch("queryInfo") || false}
                      onChange={(e, value) => setValue("queryInfo", value)}
                    />
                  }
                />
              </FormControl>
              <FormControl fullWidth className={classes.field}>
                <TextField
                  label={
                    <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.queryInfoBaseUrl.tooltip")}>
                      {t("scenes.nodeSettings.fields.queryInfoBaseUrl.label")}
                    </FormLabelWithTooltip>
                  }
                  value={watch("queryInfoBaseUrl") || ""}
                  onChange={({target}) => setValue("queryInfoBaseUrl", target.value)}
                  variant="outlined"
                />
              </FormControl>
            </Paper>
            <Paper variant="outlined" className={classes.paper}>
              <CardHeader
                subheader={t("scenes.nodeSettings.tabs.view.cards.dataflow.cards.timePeriod.title")}
                className={classes.paperHeader}
              />
              <FormControl fullWidth className={classes.field}>
                <TextField
                  label={
                    <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.defaultLastNPeriods.tooltip")}>
                      {t("scenes.nodeSettings.fields.defaultLastNPeriods.label")}
                    </FormLabelWithTooltip>
                  }
                  onChange={e => {
                    if (e.target.value.match(/^[0-9]*$/gm)) {
                      setValue("defaultLastNPeriods", e.target.value);
                    }
                  }}
                  value={watch("defaultLastNPeriods") || ""}
                  variant="outlined"
                  error={!!errors.defaultLastNPeriods}
                  helperText={errors.defaultLastNPeriods?.message}
                  disabled={isLastNPeriodsDisabled}
                />
              </FormControl>
              <Grid container spacing={3} className={classes.field}>
                <Grid item xs={12}>
                  {t("scenes.nodeSettings.fields.timePeriodRange.label")}:
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <CustomDatePicker
                      label={t("scenes.nodeSettings.fields.timePeriodRangeStart.label")}
                      inputVariant="outlined"
                      value={watch("timePeriodRangeStart")}
                      onChange={date =>
                        setValue("timePeriodRangeStart", date ? moment(date).format("YYYY-MM-DD") : null)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <CustomDatePicker
                      label={t("scenes.nodeSettings.fields.timePeriodRangeEnd.label")}
                      inputVariant="outlined"
                      value={watch("timePeriodRangeEnd")}
                      onChange={date => setValue("timePeriodRangeEnd", date ? moment(date).format("YYYY-MM-DD") : null)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
            <Paper variant="outlined" className={classes.paper}>
              <CardHeader
                subheader={t("scenes.nodeSettings.fields.hierarchyOnly.title")}
                className={classes.paperHeader}
              />
              <Autocomplete
                multiple
                variant="outlined"
                freeSolo
                options={[]}
                value={watch("hierarchyOnlyAttributes") || []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({index})} />)
                }
                onChange={(e, val) => setValue("hierarchyOnlyAttributes", val)}
                renderInput={params => (
                  <FormControl fullWidth className={classes.field}>
                    <TextField
                      {...params}
                      label={
                        <FormLabelWithTooltip tooltip={t("scenes.nodeSettings.fields.hierarchyOnlyAttributes.tooltip")}>
                          {t("scenes.nodeSettings.fields.hierarchyOnlyAttributes.label")}
                        </FormLabelWithTooltip>
                      }
                      placeholder={t("scenes.nodeSettings.fields.hierarchyOnlyAttributes.placeholder")}
                      variant="outlined"
                    />
                  </FormControl>
                )}
              />
              <FormControl fullWidth className={classes.field}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.hideHierarchyOnlyRows.label")}
                  control={
                    <Checkbox
                      name="hideHierarchyOnlyRows"
                      required
                      checked={watch("hideHierarchyOnlyRows") || false}
                      onChange={(e, value) => setValue("hideHierarchyOnlyRows", value)}
                    />
                  }
                />
              </FormControl>
            </Paper>
            <FormControl fullWidth className={classes.field}>
              <FormControlLabel
                label={
                  <FormLabelWithTooltip
                    tooltip={t("scenes.nodeSettings.fields.anticipationExceedingMaxNumRecords.tooltip")}
                    tooltipOnRight
                  >
                    {t("scenes.nodeSettings.fields.anticipationExceedingMaxNumRecords.label")}
                  </FormLabelWithTooltip>
                }
                control={
                  <Checkbox
                    name="anticipationExceedingMaxNumRecords"
                    required
                    checked={watch("anticipationExceedingMaxNumRecords") || false}
                    onChange={(e, value) => setValue("anticipationExceedingMaxNumRecords", value)}
                  />
                }
              />
            </FormControl>
          </Paper>
        </TabPanel>
        <TabPanel value="extra" selected={tab}></TabPanel>
      </div>
    </Box>
  );
});

const mapStateToProps = state => ({
  config: state.nodesConfig.node,
  initialNodeVisibility: state.nodesConfig.initialNodeVisibility,
  languages: state.app.languages,
  hub: state.hub?.hub
});

const mapDispatchToProps = dispatch => ({
  fetchConfig: nodeId => dispatch(fetchNodesConfigNode(nodeId)),
  sendConfigCreate: config => dispatch(sendNodesConfigNodeCreate(config)),
  sendConfig: config => dispatch(sendNodesConfigNodeEdit(config)),
  clearConfig: () => dispatch(clearNodesConfigNode())
});

const NodeSettingsForm = (
  {config, initialNodeVisibility, languages, hub, nodeId, fetchConfig, sendConfigCreate, sendConfig, clearConfig},
  ref
) => {
  const [needConfig, setNeedConfig] = useState(nodeId !== null);

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchConfig(nodeId);
    }
  }, [config, needConfig, setNeedConfig, fetchConfig, nodeId]);

  return (
    (nodeId === null || config) && (
      <Form
        config={nodeId === null ? undefined : config}
        initialNodeVisibility={initialNodeVisibility}
        languages={languages}
        ref={ref}
        onSubmit={nodeId === null ? sendConfigCreate : sendConfig}
        onCancel={clearConfig}
        hub={hub}
      />
    )
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(NodeSettingsForm);

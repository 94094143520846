import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import DashboardCol from "./DashboardCol";
import {
  DASHBOARD_ELEM_FILTER_DIMENSION_KEY,
  DASHBOARD_ELEM_TYPE_KEY,
  DASHBOARD_ELEM_TYPE_VALUE_VIEW,
  getViewIdxFromRowAndCol
} from "../../utils/dashboards";

const styles = theme => ({
  row: {
    width: "100%"
  }
});

const DashboardRow = ({
  classes,
  dashboardId,
  dashboard,
  filterValue,
  row,
  rowIdx,
  jsonStats,
  layoutObjs,
  filterTrees,
  timePeriodsByFreq,
  onFilterSet,
  onDownloadSubmit,
  hideFullscreen,
  onOptimizedDatasetFetch
}) => {
  const rowWithView =
    row.find(dashboardElem => dashboardElem[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_VIEW) || false;

  return (
    <div className={`${classes.row} dashboard__row`}>
      {row.map((col, idx) => {
        const viewIdx = getViewIdxFromRowAndCol(rowIdx, idx);
        return (
          <DashboardCol
            key={idx}
            dashboardId={dashboardId}
            dashboard={dashboard}
            filterValue={col[DASHBOARD_ELEM_FILTER_DIMENSION_KEY] ? filterValue : null}
            rowIdx={rowIdx}
            colIdx={idx}
            dashboardElem={col}
            jsonStat={jsonStats?.[viewIdx]}
            layoutObj={layoutObjs?.[viewIdx]}
            filterTree={filterTrees?.[viewIdx]}
            timePeriodsByFreq={timePeriodsByFreq?.[viewIdx]}
            rowWithView={rowWithView}
            onFilterSet={onFilterSet}
            onDownloadSubmit={onDownloadSubmit}
            hideFullscreen={hideFullscreen}
            onOptimizedDatasetFetch={onOptimizedDatasetFetch}
          />
        );
      })}
    </div>
  );
};

export default withStyles(styles)(DashboardRow);

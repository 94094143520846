import {PayloadAction} from "@reduxjs/toolkit";
import axios, {AxiosResponse} from "axios";
import {Middleware} from "redux";
import {errorRequest, REQUEST_INIT, RequestActionPayload, startRequest, successRequest} from "./requestActions";
import {CATALOG_FETCH} from "../../state/catalog/catalogActions";
import {DATASET_STRUCTURE_FETCH} from "../../state/dataset/datasetActions";
import {
  DATASET_MV_DATASET_FETCH,
  DATASET_MV_DOWNLOAD_SUBMIT,
  DATASET_MV_STRUCTURE_CODELIST_FETCH,
  DATASET_MV_TERRITORY_TERRITORIES_FETCH
} from "../../state/dataset/multi-viewer/actions";
import {
  DATASET_SV_DATASET_FETCH,
  DATASET_SV_DOWNLOAD_SUBMIT,
  DATASET_SV_STRUCTURE_CODELIST_FETCH
} from "../../state/dataset/single-viewer/actions";
import {addPendingRequest, cancelPendingRequests} from "../../state/pending-request/pendingRequestActions";

const nodeLocalizedRequests: string[] = [
  CATALOG_FETCH,
  DATASET_STRUCTURE_FETCH,
  DATASET_SV_STRUCTURE_CODELIST_FETCH,
  DATASET_MV_STRUCTURE_CODELIST_FETCH,
  DATASET_MV_TERRITORY_TERRITORIES_FETCH,
  DATASET_SV_DATASET_FETCH,
  DATASET_MV_DATASET_FETCH,
  DATASET_SV_DOWNLOAD_SUBMIT,
  DATASET_MV_DOWNLOAD_SUBMIT
];

const requestMiddlewareFactory =
  (options: {
    onSuccess?: (requestAction: object, data?: any, extra?: any) => void;
    onManagedServerError?: (requestAction: RequestActionPayload, statusCode: number, managedServerError: any) => void;
    onGenericError?: (requestAction: RequestActionPayload, statusCode?: number) => void;
  }): Middleware =>
  ({dispatch, getState}) =>
  next =>
  action => {
    const handleSuccess = (requestAction: RequestActionPayload, response: AxiosResponse) => {
      if (options.onSuccess) {
        options.onSuccess(requestAction, response.data, {
          responseHeaders: response.headers
        });
      }

      dispatch(
        successRequest(requestAction, response.data, {
          responseHeaders: response.headers,
          status: response.status
        })
      );
    };

    const handleManagedServerError = (requestAction: RequestActionPayload, response: AxiosResponse) => {
      if (
        options.onManagedServerError &&
        (!requestAction?.getHideErrorMessage || !requestAction.getHideErrorMessage(response?.status))
      ) {
        options.onManagedServerError(requestAction, response.status, response.data);
      }

      dispatch(errorRequest(requestAction, response.status, response.data, {responseHeaders: response.headers}));
    };

    const handleGenericError = (requestAction: RequestActionPayload, response?: AxiosResponse) => {
      if (
        options.onGenericError &&
        (!requestAction?.getHideErrorMessage || !requestAction.getHideErrorMessage(response?.status))
      ) {
        options.onGenericError(requestAction, response?.status);
      }

      dispatch(errorRequest(requestAction, response?.status, response?.data, {responseHeaders: response?.headers}));
    };

    const handleError = (requestAction: RequestActionPayload, response?: AxiosResponse) => {
      if (response?.data) {
        if (response.data.usedBy !== undefined) {
          handleManagedServerError(requestAction, response);
        } else {
          handleGenericError(requestAction, response);
        }
      } else {
        handleGenericError(requestAction, response);
      }
    };

    const result = next(action);

    if (action.type !== REQUEST_INIT) {
      return result;
    }

    const getRequestLanguage = (label: string) => {
      const appLang = getState().app.language;
      const node = getState().node;

      if (!node || !nodeLocalizedRequests.includes(label)) {
        return appLang;
      }

      const nodeExtras = getState().node?.extras || [];
      const nodeSupportedLanguages = JSON.parse(
        nodeExtras.find((extra: any) => extra.key === "SupportedLanguages")?.value || "[]"
      );
      const nodeDefaultLanguage = nodeExtras.find((extra: any) => extra.key === "DefaultLanguage")?.value || null;

      return nodeSupportedLanguages.length === 0 || !nodeDefaultLanguage || nodeSupportedLanguages.includes(appLang)
        ? appLang
        : nodeDefaultLanguage;
    };

    const requestAction: PayloadAction<RequestActionPayload> = action;

    dispatch(startRequest(requestAction.payload));

    let {uuid, label, method, url, data, baseURL, contentType, doNotTransformResponse, disableCache} =
      requestAction.payload;

    const token = getState().user.token;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    dispatch(addPendingRequest(uuid, label, source));

    axios
      .request({
        method,
        url: (baseURL && baseURL.length > 0 ? baseURL : getState().config.baseURL) + url,
        data,
        headers: {
          UserLang: getRequestLanguage(label),
          Authorization: token ? `bearer ${token}` : undefined,
          "Content-Type": contentType || undefined,
          "Cache-Control": disableCache ? "no-cache" : undefined,
          Pragma: disableCache ? "no-cache" : undefined,
          Expires: disableCache ? "0" : undefined
        },
        withCredentials: true,
        transformResponse: doNotTransformResponse ? res => res : undefined,
        responseType: doNotTransformResponse ? "arraybuffer" : undefined,
        cancelToken: source.token
      })
      .then((response: AxiosResponse) => {
        dispatch(cancelPendingRequests([uuid]));

        if (response.status.toString()[0] === "2") {
          handleSuccess(requestAction.payload, response);
        } else {
          handleError(requestAction.payload, response);
        }
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          console.log(error);
          handleError(requestAction.payload, error.response);
        }
      });

    return result;
  };

export default requestMiddlewareFactory;

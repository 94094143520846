/* config */
const configFolderPath = "config/";
export const getInitConfigUrl = () => configFolderPath + "config.json";
export const getAppConfigUrl = () => configFolderPath + "appConfig.json";
export const getDashboardConfigUrl = () => configFolderPath + "dashboardConfig.json";
export const getMapLayersConfigUrl = () => configFolderPath + "mapLayersConfig.json";
export const getModulesConfigUrl = () => configFolderPath + "modulesConfig.json";
export const getCustomCssUrl = () => configFolderPath + "custom.css";
export const getCustomTranslationUrl = (lang: string) => configFolderPath + `i18n_custom/${lang}.json`;

/* local footer html page */
export const getFooterHtmlUrl = (lang: string) => configFolderPath + `footer/${lang}.html`;

/* hub */
export const getHubMinimalInfoUrl = () => "hub/minimalInfo";
export const getHubLanguagesUrl = () => "hub/languages";
export const getSetMachineToMachineCredentialsUrl = () => "hub/SetMachineToMachineCredentials";

/* node */
export const getNodeUrl = (nodeId: number) => `nodes/${nodeId}`;
export const getNodeCatalogUrl = (nodeId: number) => `nodes/${nodeId}/catalog`;

/* cache */
export const getClearMemoryCacheUrl = () => "MemoryCache/Clear";
export const getDataflowsCacheListUrl = (nodeId: number) => `DataflowCache/DataflowData/Nodes/${nodeId}`;
export const getDataflowsCacheUpdateUrl = (nodeId: number, cacheId: string) =>
  `DataflowCache/DataflowData/${cacheId}/Nodes/${nodeId}`;
export const getDataflowsCreateCacheUrl = (nodeId: number) => `DataflowCache/DataflowData/Nodes/${nodeId}`;
export const getDataflowsClearCacheUrl = (nodeId: number, cacheId: string) =>
  `DataflowCache/Clear/${cacheId}/Nodes/${nodeId}`;
export const getDataflowsClearAllCacheUrl = (nodeId: number) => `DataflowCache/ClearAll/Nodes/${nodeId}`;
export const getClearNodeCatalogMemoryCacheUrl = (nodeId: number) => `MemoryCache/Clear/CatalogTree/Nodes/${nodeId}`;

export const getDatasetCacheInfoUrl = (nodeId: number, datasetId: string) =>
  `DataflowCache/DataflowData/${datasetId}/Nodes/${nodeId}`;
export const clearDatasetCacheUrl = (nodeId: number, datasetId: string) =>
  `Caches/Nodes/${nodeId}/ClearDataflow/${datasetId}`;
export const getDatasetCacheUpdateUrl = (nodeId: number, datasetId: string) =>
  `DataflowCache/${datasetId}/Nodes/${nodeId}`;
export const getArtefactCacheClearAllUrl = (nodeId: number, preserveDataflows: boolean) =>
  `ArtefactCache/Nodes/${nodeId}/ClearAll?preserveDataflows=${preserveDataflows}`;
export const getArtefactCacheClearUrl = (
  nodeId: number,
  structureType: string,
  structureId: string,
  references?: string
) =>
  `ArtefactCache/Nodes/${nodeId}/Clear/${structureType}/${structureId}${references ? `?references=${references}` : ""}`;

/* templates */
export const getNodeTemplatesUrl = (nodeId: number) => `nodes/${nodeId}/templates`;
export const getCreateTemplateUrl = (nodeId: number) => `nodes/${nodeId}/templates`;
export const getDeleteTemplateUrl = (nodeId: number, id: number) => `nodes/${nodeId}/templates/${id}`;

/* views */
export const getViewsUrl = () => `views`;
export const getCreateViewUrl = (nodeId: number) => `nodes/${nodeId}/views`;
export const getDeleteViewUrl = (nodeId: number, id: number) => `nodes/${nodeId}/views/${id}`;

/* dataset */
export const getDatasetUrl = (nodeId: number, datasetId: string) => `nodes/${nodeId}/datasets/${datasetId}/data`;
export const getDatasetStructureUrl = (nodeId: number, datasetId: string, viewId?: string) =>
  `nodes/${nodeId}/datasets/${datasetId}/structure${viewId ? `/${viewId}` : ""}`;
export const getDatasetStructureCodelistUrl = (
  nodeId: number,
  datasetId: string,
  dimensionId: string,
  isFull: boolean
) => `nodes/${nodeId}/datasets/${datasetId}/column/${dimensionId}/${isFull ? "full" : "partial"}/values`;
export const getDatasetStructureDynamicCodelistUrl = (nodeId: number, datasetId: string, dimensionId: string) =>
  `nodes/${nodeId}/datasets/${datasetId}/PartialCodelists/${dimensionId}`;
export const getDatasetStructureCodelistsUrl = (nodeId: number, datasetId: string, isFull: boolean = false) =>
  `nodes/${nodeId}/datasets/${datasetId}/columns/${isFull ? "full" : "partial"}/values`;
export const getDatasetDownloadUrl = (nodeId: number, datasetId: string, format: string, zipped?: boolean) =>
  `nodes/${nodeId}/datasets/${datasetId}/${zipped === true ? "downloadZip" : "download"}/${format}`;
export const getDatasetCustomDownloadUrl = (nodeId: number, format: string, zipped?: boolean) =>
  `nodes/${nodeId}/export/${format}/${zipped === true ? "true" : "false"}`;
export const getDatasetSdmxQueryUrl = (nodeId: number, datasetId: string) =>
  `nodes/${nodeId}/datasets/${datasetId}/url`;

/* indicators */
export const getDatasetWithMarginalUrl = () => `marginal`;
export const getDatasetWithIndicatorsUrl = (isPreview: boolean = false) => `data?preview=${isPreview}`;
export const getDatasetWithMarginalCustomDownloadUrl = (format: string, zipped?: boolean) =>
  `export/${format}/${zipped === true ? "true" : "false"}`;

/* detail levels */
export const getDatasetDetailLevels = (nodeId: number, datasetId: string) =>
  `nodes/${nodeId}/datasets/${datasetId}/detailLevels`;
export const getDatasetTerritories = (nodeId: number, datasetId: string, territoryDim: string, detailLevel: number) =>
  `nodes/${nodeId}/datasets/${datasetId}/detailLevelItems/${territoryDim}/${detailLevel}`;

/* metadata */
export const getDatasetExportMetadataUrl = (nodeId: number, datasetId: string) =>
  `nodes/${nodeId}/datasets/${datasetId}/ExportMetadata`;

/* cache */

/* config */
export const getHubConfigGetUrl = () => "hub/config";
export const getHubConfigPutUrl = () => "hub";
export const getNodesConfigUrl = () => "nodes/config";
export const getNodesOrderPutUrl = () => "nodes/order";
export const getNodeConfigGetUrl = (nodeId: number) => `nodes/${nodeId}/config`;
export const getNodesConfigPostUrl = () => "nodes";
export const getNodesConfigPutUrl = (nodeId: number) => `nodes/${nodeId}`;
export const getNodeDeleteUrl = (nodeId: number, force?: boolean) => `nodes/${nodeId}${force ? "/true" : ""}`;

/* log */
export const getQueryLog = (limit: number) => `tracing/limit/${limit}`;

/* files */
export const getFileUploadUrl = () => "File/Upload";

/* authentication */
export const getTokenUrl = () => "auth/token";
export const getRefreshTokenUrl = () => "auth/refreshToken";
export const getRemoveTokenUrl = (userId: number) => `auth/removeToken/${userId}`;
export const getExternalLoginUrl = (providerId: string) => `auth/provider/${providerId}`;

/* users management */
export const getUsersConfigGetUrl = () => "users";
export const getUserConfigGetUrl = (userId: number) => `users/${userId}`;
export const getUserConfigPostUrl = () => "users/register";
export const getUserConfigPutUrl = (userId: number) => `users/${userId}`;
export const getUserDeleteUrl = (userId: number) => `users/${userId}`;
export const getUserSendResetPasswordMailUrl = () => "Users/RecoveryPassword";
export const getUserResetPasswordUrl = () => "Users/ResetPassword";
export const getUserChangePasswordUrl = () => "Users/ChangePassword";
export const getUserVerifyMailUrl = () => "Users/VerifyMail";

/* geometry */
export const getGeometryUrl = (
  nodeId: number,
  format: string,
  detailLevel?: number | null,
  timeDimValue?: string | null,
  territoryDimCodelist?: string | null,
  generalizationLevel?: number | null
) =>
  `geometry/${nodeId}?format=${format}` +
  (detailLevel !== null && detailLevel !== undefined ? `&geoLevel=${detailLevel}` : "") +
  (timeDimValue !== null && timeDimValue !== undefined ? `&year=${timeDimValue}` : "") +
  (territoryDimCodelist !== null && territoryDimCodelist !== undefined
    ? `&codelistId=${encodeURIComponent(territoryDimCodelist)}`
    : "") +
  (generalizationLevel !== null && generalizationLevel !== undefined
    ? `&generalizationLevel=${encodeURIComponent(generalizationLevel)}`
    : "");

/* permissions */
export const getNodePermissionsConfigGetUrl = (nodeId: number) => `Users/Permissions/Nodes/${nodeId}`;
export const getNodePermissionsConfigPutUrl = (nodeId: number) => `Users/Permissions/Nodes/${nodeId}`;

/* dashboards */
export const getUserDashboardsUrl = () => "Dashboards";
export const getDashboardsUrl = (dashboardId: number) => `Dashboards/${dashboardId}`;
export const getDeleteDashboardUrl = (dashboardId: number) => `Dashboards/${dashboardId}`;
export const getCreateDashboardUrl = () => `Dashboards/`;
export const getUpdateDashboardUrl = (dashboardId: number) => `Dashboards/${dashboardId}`;
export const getHubDashboardsUrl = () => "Dashboards/Hub";
export const getNodeDashboardsUrl = (nodeId: number) => `Dashboards/Nodes/${nodeId}`;
export const getAddHubDashboardUrl = (dashboardId: number) => `Dashboards/${dashboardId}/Hub`;
export const getRemoveHubDashboardUrl = (dashboardId: number) => `Dashboards/${dashboardId}/Hub`;
export const getOrderHubDashboardsUrl = () => "Dashboards/Order/Hub";
export const getAddNodeDashboardUrl = (nodeId: number, dashboardId: number) =>
  `Dashboards/${dashboardId}/Nodes/${nodeId}`;
export const getRemoveNodeDashboardUrl = (nodeId: number, dashboardId: number) =>
  `Dashboards/${dashboardId}/Nodes/${nodeId}`;
export const getOrderNodeDashboardsUrl = (nodeId: number) => `Dashboards/Order/Nodes/${nodeId}`;

import React, {useState} from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import copy from "copy-to-clipboard";
import {useTranslation} from "react-i18next";

const styles = theme => ({
  paper: {
    padding: theme.spacing(3)
  },
  paperHeader: {
    fontSize: 17,
    fontWeight: "bold",
    padding: "0 0 16px 0"
  },
  query: {
    overflowWrap: "break-word",
    wordBreak: "break-all"
  }
});

const Query = ({classes, title, query}) => {
  const [isCopiedVisible, setCopiedVisibility] = useState(false);

  const {t} = useTranslation();

  return (
    <Paper variant="outlined" className={classes.paper}>
      <CardHeader className={classes.paperHeader} title={title} disableTypography />
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid id="query-container" item xs={12} className={`${classes.query}`}>
          {query}
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              copy(query);
              setCopiedVisibility(prevVal => !prevVal);
            }}
          >
            {t("commons.confirm.copy")}
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={isCopiedVisible} autoHideDuration={2000} onClose={() => setCopiedVisibility(prevVal => !prevVal)}>
        <Alert severity="success" onClose={() => setCopiedVisibility(prevVal => !prevVal)}>
          {t("components.query.alert.queryCopied.label")}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default withStyles(styles)(Query);

export const DEFAULT_THEME = {
  palette: {
    text: {
      primary: "#00295a"
    },
    primary: {
      main: "#00295a",
      light: "#3b5187",
      dark: "#000030",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#f8a81e",
      light: "#ffd956",
      dark: "#c07900",
      contrastText: "#00295a"
    }
  }
};

export const DEFAULT_ALL1_THEME = {
  palette: {
    text: {
      primary: "#000000"
    },
    primary: {
      main: "#000000",
      light: "#000000",
      dark: "#000000",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#000000",
      light: "#000000",
      dark: "#000000",
      contrastText: "#ffffff"
    }
  }
};

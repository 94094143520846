import React from "react";
import ModuleLoader from "../module-loader";

const ModuleMenuItemPlaceholder = ({module}) => (
  <ModuleLoader
    id={module.id}
    component={module.component}
    fallback={module.fallback || ""} // the length of this string is used by Material-UI to position the Menu in ButtonSelect
  />
);

export default ModuleMenuItemPlaceholder;

import React, {Fragment, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import CustomDialogTitle from "../custom-dialog-title";
import DatasetFilterAttributeIcon from "../dataset-filter-attribute-icon";
import InfiniteScrollTable from "../infinite-scroll-table";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH} from "../label-format-selector/constants";
import {useForceUpdate} from "../../utils/customHooks";
import {MARGINAL_DIMENSION_KEY} from "../../utils/dataset";
import {getTextWidth} from "../../utils/style";
import {isMultiSelectDim} from "./utils";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    maxWidth: "100%"
  },
  filter: {
    display: "inline-block",
    verticalAlign: "bottom",
    marginRight: 16,
    marginBottom: 8,
    maxWidth: "100%"
  },
  item: {
    fontSize: 14,
    maxWidth: "100%"
  },
  itemWrapper: {
    display: "inline-block",
    verticalAlign: "bottom",
    maxWidth: "100%"
  },
  itemDisabled: {
    pointerEvents: "none",
    "& > svg": {
      visibility: "hidden"
    }
  },
  dimensionTitle: {
    marginBottom: 4,
    fontSize: 13,
    color: theme.palette.primary.main
  },
  alert: {
    marginBottom: 8
  },
  alertClickable: {
    textDecoration: "underline",
    fontWeight: "bold",
    cursor: "pointer"
  }
});

const DatasetFilters = ({
  classes,
  datasetId: externalDatasetId,
  jsonStat,
  timeDim,
  layout,
  labelFormat,
  getIsDimensionAllowed,
  getDimensionLabel,
  getFilterValues,
  getFormattedName,
  getFormattedTooltip,
  dimAttributeMap,
  onSelect,
  onCriteriaShow,
  getIsDatasetExceedingLimit,
  enclosingContainerId
}) => {
  const {t} = useTranslation();

  const forceUpdate = useForceUpdate();

  const [visibleId, setVisibleId] = useState(null);
  const [data, setData] = useState(null);

  const [checkedKeys, setCheckedKeys] = useState(null);

  useEffect(() => {
    return () => {
      $("#dataset-filters__text-width-el").remove();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
    return () => window.removeEventListener("resize", forceUpdate);
  }, [forceUpdate]);

  const filters = (layout?.primaryDim || []).concat(layout?.secondaryDim || []).concat(layout?.filters || []);

  const handleFilterOpen = visibleIdx => {
    setVisibleId(visibleIdx);

    const data = [...getFilterValues(filters[visibleIdx])];

    if (filters[visibleIdx] === timeDim) {
      data.reverse();
    }

    setData(data.map(val => ({id: val})));

    const dim = filters[visibleIdx];
    if (isMultiSelectDim(dim, layout)) {
      if (checkedKeys === null && dim === layout?.primaryDim?.[0]) {
        setCheckedKeys(layout.primaryDimValues);
      } else if (checkedKeys === null && dim === layout?.secondaryDim?.[0]) {
        setCheckedKeys(layout.secondaryDimValues);
      }
    }
  };

  const handleFilterClose = () => {
    setVisibleId(null);
    setCheckedKeys(null);
    setData(null);
  };

  const handleFilterSubmit = (dimension, values) => {
    onSelect(dimension, values);
    handleFilterClose();
  };

  return (
    <Fragment>
      <div className={classes.root}>
        {filters.map((dim, idx) => {
          const isDimensionAllowed = getIsDimensionAllowed(dim);

          if (!isDimensionAllowed) {
            return null;
          } else {
            const {filtersValue} = layout;

            if ($("#dataset-filters__text-width-el").length === 0) {
              $("<span id='dataset-filters__text-width-el'>")
                .css({
                  visibility: "hidden",
                  fontSize: 16
                })
                .appendTo("body");
            }

            const dimLabel = getDimensionLabel(dim);
            const minWidth = getTextWidth(dimLabel, $("#dataset-filters__text-width-el").get(0)) + 1;
            let selectStyle = {minWidth: minWidth};

            if (enclosingContainerId) {
              const $containerElement = $(enclosingContainerId);
              if ($containerElement.length > 0) {
                const containerWidth = $containerElement.width() || 0;
                if (containerWidth > 0) {
                  selectStyle.maxWidth = containerWidth;
                }
              }
            }

            const value = isMultiSelectDim(dim, layout)
              ? layout.primaryDim[0] === dim
                ? layout.primaryDimValues
                : layout.secondaryDimValues
              : filtersValue[dim];

            const datasetId = externalDatasetId
              ? externalDatasetId
              : !isMultiSelectDim(dim, layout) &&
                dim === MARGINAL_DIMENSION_KEY &&
                jsonStat?.extension?.marginalvalues?.[value]?.datasetid
              ? jsonStat.extension.marginalvalues[value].datasetid
              : jsonStat?.extension?.datasets?.[0];

            const values = getFilterValues(dim);

            const getValueLabel = value =>
              isMultiSelectDim(dim, layout)
                ? values.length !== 1
                  ? t("components.datasetFilters.selectedCount", {count: value.length})
                  : getFormattedName(dim, value[0], labelFormat)
                : getFormattedName(dim, value, labelFormat);

            return (
              <div key={idx} className={classes.filter}>
                {isMultiSelectDim(dim, layout) && (
                  <InputLabel className={classes.dimensionTitle}>
                    {layout.primaryDim[0] === dim
                      ? t("components.datasetFilters.primaryDim")
                      : t("components.datasetFilters.secondaryDim")}
                  </InputLabel>
                )}
                <FormControl className={classes.itemWrapper}>
                  <InputLabel>{dimLabel}</InputLabel>
                  <Tooltip title={getValueLabel(value)}>
                    <Select
                      open={false}
                      style={selectStyle}
                      value={value}
                      multiple={isMultiSelectDim(dim, layout)}
                      renderValue={value => getValueLabel(value)}
                      className={`${classes.item} ${isDimensionAllowed ? "" : classes.itemDisabled}`}
                      onOpen={() => (isDimensionAllowed ? handleFilterOpen(idx) : null)}
                      SelectDisplayProps={{
                        "aria-haspopup": true,
                        "aria-label": `${dimLabel}: ${getValueLabel(value)}`
                      }}
                    >
                      {values.map((el, idx) => (
                        <MenuItem key={idx + el} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                </FormControl>
                {jsonStat && (
                  <div style={{display: "inline-block", marginBottom: 4}}>
                    <DatasetFilterAttributeIcon
                      datasetId={datasetId}
                      jsonStat={jsonStat}
                      dimension={dim}
                      dimensionValues={isMultiSelectDim(dim, layout) ? value : [value]}
                      datasetDimAttributeMap={dimAttributeMap?.[datasetId]}
                      labelFormat={labelFormat}
                    />
                  </div>
                )}
              </div>
            );
          }
        })}
      </div>
      <Dialog open={data !== null} onClose={handleFilterClose} fullWidth maxWidth="md">
        <CustomDialogTitle onClose={handleFilterClose}>
          {visibleId !== null
            ? !isMultiSelectDim(filters[visibleId], layout)
              ? t("components.datasetFilters.modals.singleSelect", {
                  dimension: getDimensionLabel(filters[visibleId])
                })
              : t("components.datasetFilters.modals.multiSelect", {
                  dimension: getDimensionLabel(filters[visibleId])
                })
            : ""}
        </CustomDialogTitle>
        <DialogContent>
          {getIsDatasetExceedingLimit && getIsDatasetExceedingLimit(filters[visibleId], checkedKeys) && (
            <Alert severity="warning" className={classes.alert}>
              {t("components.datasetFilters.alerts.limitExceeded")}
            </Alert>
          )}
          {onCriteriaShow && (
            <Alert severity="info" className={classes.alert}>
              {t("components.datasetFilters.alerts.openCriteria.prefixLabel")}
              <span
                onClick={() => {
                  handleFilterClose();
                  onCriteriaShow(filters[visibleId]);
                }}
                className={classes.alertClickable}
              >
                {t("components.datasetFilters.alerts.openCriteria.clickableLabel")}
              </span>
              {t("components.datasetFilters.alerts.openCriteria.suffixLabel")}
            </Alert>
          )}
          {!isMultiSelectDim(filters[visibleId], layout) ? (
            <InfiniteScrollTable
              data={data}
              getRowKey={({id}) => id}
              showHeader={false}
              columns={[
                {
                  title: "",
                  dataIndex: "id",
                  render: (_, {id}) =>
                    getFormattedName(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH, true),
                  renderText: (_, {id}) =>
                    getFormattedName(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH),
                  renderTooltip: (_, {id}) =>
                    getFormattedTooltip(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH),
                  minWidth: 100
                }
              ]}
              onRowClick={rowData => handleFilterSubmit(filters[visibleId], rowData.id)}
              getRowStyle={rowData => ({
                background: rowData.id === layout.filtersValue[filters[visibleId]] ? "#fff9e5" : undefined
              })}
              height={400}
            />
          ) : (
            <InfiniteScrollTable
              data={data}
              getRowKey={({id}) => id}
              columns={[
                {
                  title: t("components.datasetFilters.table.columns.label.title"),
                  dataIndex: "id",
                  render: (_, {id}) =>
                    getFormattedName(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH, true),
                  renderText: (_, {id}) =>
                    getFormattedName(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH),
                  renderTooltip: (_, {id}) =>
                    getFormattedTooltip(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH),
                  minWidth: 100,
                  noFilter: true,
                  noSort: true
                }
              ]}
              rowSelection={{
                selectedRowKeys: checkedKeys,
                onChange: checkedKeys => setCheckedKeys(data.map(({id}) => id).filter(key => checkedKeys.includes(key)))
              }}
              height={400}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFilterClose} color="primary">
            {t("commons.confirm.close")}
          </Button>
          {isMultiSelectDim(filters[visibleId], layout) && (
            <Button
              onClick={() => handleFilterSubmit(filters[visibleId], checkedKeys)}
              color="primary"
              disabled={
                (checkedKeys || []).length < 1 ||
                (getIsDatasetExceedingLimit && getIsDatasetExceedingLimit(filters[visibleId], checkedKeys))
              }
            >
              {t("commons.confirm.confirm")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default withStyles(styles)(DatasetFilters);

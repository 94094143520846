import React from "react";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import ComponentVariantLoader from "../component-variant-loader";

const styles = theme => ({
  root: {
    width: 480
  },
  logoContainer: {
    height: 64,
    background: theme.palette.primary.main,
    padding: 16,
    marginBottom: 16
  },
  logo: {
    height: 32,
    display: "block",
    margin: "auto"
  },
  nameContainer: {
    padding: 8,
    marginBottom: 16
  },
  content: {
    marginBottom: 8
  },
  poweredBy: {
    marginBottom: 8
  }
});

const mapStateToProps = state => ({
  baseURL: state.config.baseURL
});

const AppInfo = ({t, classes, baseURL, hub}) => (
  <div className={classes.root}>
    {hub.headerLogoURL && (
      <div className={classes.logoContainer}>
        <img src={baseURL + hub.headerLogoURL} alt="" className={classes.logo} />
      </div>
    )}
    <ComponentVariantLoader
      componentId="app-info"
      fallback={t("components.appInfo.loading") + "..."}
      hub={hub}
      classes={classes}
    />
  </div>
);

export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps))(AppInfo);

import React, {Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import FilterListIcon from "@material-ui/icons/FilterList";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import ViewStreamIcon from "@material-ui/icons/ViewStream";
import Alert from "@material-ui/lab/Alert";
import {DragDropContext} from "react-beautiful-dnd";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {ERROR_SNACKBAR_SEVERITY_WARNING} from "../error-snackbar/ErrorSnackbar";
import LayoutSection from "../layout-section";
import Table from "../table";
import "./style.css";

const styles = theme => ({
  root: {
    margin: -8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  alert: {
    marginBottom: 8
  },
  layoutContainer: {
    width: "60%",
    height: "100%",
    padding: 8
  },
  layoutContainerFullWidth: {
    width: "100%"
  },
  previewContainer: {
    width: "40%",
    height: "100%",
    padding: 8
  },
  section: {
    height: 240,
    padding: 8
  }
});

const reorder = (list, startIndex, endIndex) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const [removed] = source.splice(droppableSource.index, 1);
  destination.splice(droppableDestination.index, 0, removed);
};

const onDragEnd = (result, dimensions, setDimensions, lockedDimensions, t) => {
  const {source, destination} = result;

  // dropped outside the list
  if (!destination) {
    return;
  }

  const newDimensions = JSON.parse(JSON.stringify(dimensions));

  if (source.droppableId === destination.droppableId) {
    reorder(newDimensions[source.droppableId], source.index, destination.index);
  } else {
    const dimension = newDimensions[source.droppableId][source.index];
    if (lockedDimensions.includes(dimension)) {
      window.error.show(t("components.tableLayout.drag.alert"), ERROR_SNACKBAR_SEVERITY_WARNING);
      return;
    }
    move(newDimensions[source.droppableId], newDimensions[destination.droppableId], source, destination);
  }

  setDimensions(newDimensions);
};

const TableLayout = ({
  t,
  classes,
  layout,
  dimensionsInfo,
  lockedDimensions,
  setLayout,
  jsonStat,
  labelFormat,
  alertText
}) => {
  const {filters, sections, rows, cols} = layout;

  let colCount = 1;
  cols.forEach(col => (colCount *= dimensionsInfo[col].size));
  let rowCount = 1;
  rows.forEach(row => (rowCount *= dimensionsInfo[row].size));
  sections.forEach(section => (rowCount *= dimensionsInfo[section].size));

  const layoutLockedDimensions = lockedDimensions || [];

  return (
    <Fragment>
      {alertText && (
        <Alert severity="warning" className={classes.alert}>
          {alertText}
        </Alert>
      )}
      <div id="table-layout" className={classes.root}>
        <div
          id="table-layout__layout-container"
          className={`${classes.layoutContainer} ${!jsonStat ? classes.layoutContainerFullWidth : ""}`}
        >
          <DragDropContext onDragEnd={result => onDragEnd(result, layout, setLayout, layoutLockedDimensions, t)}>
            <Grid container spacing={2} style={{height: "100%"}}>
              <Grid item xs={6} className={classes.section}>
                <LayoutSection
                  id="filters"
                  title={t("components.tableLayout.sections.filters")}
                  Icon={<FilterListIcon />}
                  dimensions={filters}
                  dimensionsInfo={dimensionsInfo}
                  lockedDimensions={layoutLockedDimensions}
                />
              </Grid>
              <Grid item xs={6} className={classes.section}>
                <LayoutSection
                  id="sections"
                  title={t("components.tableLayout.sections.sections")}
                  Icon={<ViewCompactIcon />}
                  dimensions={sections}
                  dimensionsInfo={dimensionsInfo}
                  lockedDimensions={layoutLockedDimensions}
                />
              </Grid>
              <Grid item xs={6} className={classes.section}>
                <LayoutSection
                  id="rows"
                  title={t("components.tableLayout.sections.rows")}
                  Icon={<ViewStreamIcon />}
                  dimensions={rows}
                  dimensionsInfo={dimensionsInfo}
                  lockedDimensions={layoutLockedDimensions}
                />
              </Grid>
              <Grid item xs={6} className={classes.section}>
                <LayoutSection
                  id="cols"
                  title={t("components.tableLayout.sections.columns")}
                  Icon={<ViewColumnIcon />}
                  dimensions={cols}
                  dimensionsInfo={dimensionsInfo}
                  lockedDimensions={layoutLockedDimensions}
                />
              </Grid>
            </Grid>
          </DragDropContext>
        </div>
        {jsonStat && (
          <div id="table-layout__preview-container" className={classes.previewContainer}>
            <Table jsonStat={jsonStat} layout={layout} labelFormat={labelFormat} isPreview={true} />
            <Grid container spacing={1} style={{marginTop: 16}}>
              <Grid item xs={12} style={{textAlign: "end"}}>
                {t("components.tableLayout.rowCount", {rowCount})}
              </Grid>
              <Grid item xs={12} style={{textAlign: "end"}}>
                {t("components.tableLayout.colCount", {colCount})}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default compose(withStyles(styles), withTranslation())(TableLayout);

import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DeleteIcon from "@material-ui/icons/Delete";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomMaterialTable from "../../custom-material-table";
import {deleteDatasetMVIndicator} from "../../../state/dataset/multi-viewer/actions";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../../utils/i18n";

const mapStateToProps = ({app, dataset}) => ({
  defaultLanguage: app.language,
  languages: app.languages,
  indicators: dataset.multiViewer.indicators
});

const mapDispatchToProps = dispatch => ({
  onDelete: indicatorIdx => dispatch(deleteDatasetMVIndicator(indicatorIdx))
});

const IndicatorListDialog = ({defaultLanguage, languages, indicators, isOpen, onClose, onDelete}) => {
  const {t} = useTranslation();

  const [indicatorIdx, setIndicatorIdx] = useState(null);

  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => {
    onDelete(indicatorIdx);
    setIndicatorIdx(null);
  };

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
      <CustomDialogTitle onClose={handleClose}>{t("components.indicatorDialogs.list.title")}</CustomDialogTitle>
      <DialogContent>
        <CustomMaterialTable
          columns={[
            {
              field: "title",
              title: t("components.indicatorDialogs.list.columns.title"),
              render: ({title}) => localizeI18nObj(title, defaultLanguage, languages),
              customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
            },
            // {
            //   field: "formula",
            //   title: _t("components.indicatorDialogs.list.columns.formula"),
            //   render: ({formula}) => formula.join(""),
            //   customFilterAndSearch: (str, {formula}) => (formula.join("") || "").toLowerCase().includes((str || "").toLowerCase())
            // },
            {
              field: "year",
              title: t("components.indicatorDialogs.list.columns.year"),
              customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
            }
          ]}
          data={(indicators || []).map((indicator, idx) => ({...indicator, idx: idx}))}
          actions={[
            {
              icon: DeleteIcon,
              tooltip: t("components.indicatorDialogs.list.actions.delete"),
              onClick: (_, {idx}) => {
                setIndicatorIdx(idx);
              }
            }
          ]}
          options={{
            actionsColumnIndex: 3,
            showTitle: false
          }}
        />

        <Dialog open={indicatorIdx !== null} onClose={() => setIndicatorIdx(null)} maxWidth="xs">
          <CustomDialogTitle onClose={() => setIndicatorIdx(null)}>
            {t("components.indicatorDialogs.list.dialogs.deleteIndicator.title")}
          </CustomDialogTitle>
          <DialogContent>{t("components.indicatorDialogs.list.dialogs.deleteIndicator.content")}</DialogContent>
          <DialogActions>
            <Button onClick={() => setIndicatorIdx(null)} autoFocus>
              {t("commons.confirm.cancel")}
            </Button>
            <Button onClick={handleDelete}>{t("commons.confirm.confirm")}</Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("commons.confirm.close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorListDialog);

import React, {Fragment, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import CustomDialogTitle from "../custom-dialog-title";
import DimensionAttributeList from "../dimension-attribute-list";

const styles = theme => ({
  attributeButton: {
    padding: "0 2px 0 4px",
    cursor: "pointer"
  },
  attributeIcon: {
    height: 18,
    lineHeight: "18px",
    borderRadius: 3
  },
  attributeIconAst: {
    fontFamily: "Do Hyeon"
  },
  attributeIconChar: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(108, 108, 108)",
    marginLeft: 2,
    padding: "0 4px"
  }
});

const DatasetFilterAttributeIcon = ({
  t,
  classes,
  datasetId,
  jsonStat,
  dimension,
  dimensionValues,
  datasetDimAttributeMap,
  labelFormat,
  disabled,
  disabledTooltip
}) => {
  const [isVisible, setVisibility] = useState(false);

  const dimValuesAttributeList = [];
  (dimensionValues || []).forEach(dimValue => {
    if (datasetDimAttributeMap?.[dimension]?.[dimValue]) {
      dimValuesAttributeList.push({
        attributes: datasetDimAttributeMap[dimension][dimValue],
        dimVal: dimValue
      });
    }
  });

  return (
    <Fragment>
      {dimValuesAttributeList.length > 0 && (
        <Tooltip
          title={
            <DimensionAttributeList
              datasetId={datasetId}
              jsonStat={jsonStat}
              dimension={dimension}
              dimValuesAttributeList={dimValuesAttributeList}
              labelFormat={labelFormat}
            />
          }
          placement="top"
          open={disabledTooltip === true ? false : undefined}
        >
          <span
            className={classes.attributeButton}
            role={!disabled ? "button" : undefined}
            onClick={!disabled ? () => setVisibility(true) : undefined}
          >
            {dimValuesAttributeList.length === 1 &&
            dimValuesAttributeList[0].attributes.length === 1 &&
            dimValuesAttributeList[0].attributes[0].valueId !== dimValuesAttributeList[0].attributes[0].valueLabel &&
            dimValuesAttributeList[0].attributes[0].valueId.length <= 2 ? (
              <span className={`${classes.attributeIcon} ${classes.attributeIconChar}`}>
                {dimValuesAttributeList[0].attributes[0].valueId}
              </span>
            ) : (
              <span className={`${classes.attributeIcon} ${classes.attributeIconAst}`}>(*)</span>
            )}
          </span>
        </Tooltip>
      )}

      <Dialog open={isVisible} onClose={() => setVisibility(false)}>
        <CustomDialogTitle onClose={() => setVisibility(false)}>
          {t("components.attributeIcons.dialog.title")}
        </CustomDialogTitle>
        <DialogContent>
          <DimensionAttributeList
            datasetId={datasetId}
            jsonStat={jsonStat}
            dimension={dimension}
            dimValuesAttributeList={dimValuesAttributeList}
            labelFormat={labelFormat}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={event => {
              event.stopPropagation();
              setVisibility(false);
            }}
          >
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default compose(withStyles(styles), withTranslation())(DatasetFilterAttributeIcon);

import moment from "moment";
import {getViewerTypeFromIdx} from "../../../components/data-viewer/constant";
import {ChartSettings, Criteria, MapSettings, ViewerMode} from "../constants";
import {getCriteriaArrayFromObject} from "../../../utils/criteria";
import {
  getSVPFilteredChartLayout,
  getSVPFilteredMapLayout,
  getSVPFilteredTableLayout,
  getSVPInitialChartLayout,
  getSVPInitialMapLayout,
  getSVPInitialTableLayout
} from "../../../utils/dataset";
import {
  FREQ_ANNUAL,
  getTimeValuesByFreq,
  getTimeValuesFromRange,
  SUPPORTED_FREQ_VALUES
} from "../../../utils/timePeriodAndFreq";
import {
  getViewTemplateLayoutsFromChartSettings,
  getViewTemplateLayoutsFromMapSettings
} from "../../../utils/viewTemplate";

export type DatasetSingleViewerState = {
  datasetUuid: string | null;
  dataset: any | null;
  isFetchStarted: boolean;
  isFetchFailed: boolean;
  isPartialData: boolean;
  isEmptyData: boolean;
  isTooBigData: boolean;
  isTooLongQuery: boolean;
  isCriteriaVisible: boolean;
  initialCriteriaDimension: string | null;
  isCriteriaAlertVisible: boolean;
  dimensions: any[] | null;
  dimensionsInfo: {[key: string]: any} | {};
  territoryDim: string | null;
  timeDim: string | null;
  freqDim: string | null;
  viewerIdx: number | null;
  isViewerHoverVisible: boolean;
  view: any | null;
  template: any | null;
  hasViewLayout: boolean;
  hasTemplateLayout: boolean;
  hasAnnotationLayout: boolean;
  isLayoutVisible: boolean;
  tableLayoutPartial: any | null;
  mapLayoutPartial: any | null;
  chartLayoutPartial: any | null;
  tableLayout: any | null;
  mapLayout: any | null;
  chartLayout: any | null;
  labelFormat: string | null;
  temporalDimOrder: string | null;
  showTrend: boolean;
  showCyclical: boolean;
  areCriteriaApplied: boolean;
  criteria: {[key: string]: Criteria} | {};
  initialCriteria: {[key: string]: Criteria} | {};
  decimalSeparator: string | null;
  decimalPlaces: number | null;
  tableEmptyChar: string;
  chartSettings: ChartSettings;
  mapSettings: MapSettings;
  enableCriteria: boolean;
  enableLayout: boolean;
  enableVariation: boolean;
  codelistTrees: {[key: string]: any[]} | null;
  codelistLists: {[key: string]: any[]} | null;
  codelistFilteredLists: {[key: string]: any[]} | null;
  codelistMaps: {[key: string]: {[key: string]: string}} | null;
  codelistsLength: (number | null)[] | null;
  codelistFetchError: boolean;
  timings: any | null;
  isFetchDatasetDisabled: boolean;
  isDownloadWarningVisible: boolean;
  isUnavailableViewWarningVisible: boolean;
  isViewVisible: boolean;
  isViewErrorVisible: boolean;
  viewErrorMessage: string | null;
  isTemplateVisible: boolean;
  isQueryVisible: boolean;
  structureQuery: string | null;
  dataQuery: string | null;
  detailLevel: number | null;
  isFullscreen: boolean;
  isTableEnabled: boolean;
  isMapEnabled: boolean;
  isChartEnabled: boolean;
  tableLockedDimensions: string[] | null;
  graphLockedDimensions: string[] | null;
  missingFilterValues: any[] | null;
};

export const getViewTemplateBackupFromState = (state: DatasetSingleViewerState) => ({
  mode: ViewerMode.SingleViewer,
  defaultView: getViewerTypeFromIdx(state.viewerIdx),
  criteria: getCriteriaArrayFromObject(state.criteria),
  layouts: JSON.stringify({
    detailLevel: state.detailLevel,
    labelFormat: state.labelFormat,
    temporalDimOrder: state.temporalDimOrder,
    showTrend: state.showTrend,
    showCyclical: state.showCyclical,
    tableLayout: state.tableLayout,
    tableEmptyChar: state.tableEmptyChar,
    mapLayout: state.mapLayout,
    ...getViewTemplateLayoutsFromMapSettings(state.mapSettings),
    chartLayout: state.chartLayout,
    ...getViewTemplateLayoutsFromChartSettings(state.chartSettings)
  })
});

export const getFilteredCodelistsList = (
  codelistLists: {[key: string]: string[]} | null,
  criteria: {[key: string]: Criteria},
  timeDim: string | null,
  freqDim: string | null
) => {
  if (codelistLists === null) {
    return {};
  }

  if (Object.keys(criteria).length === 0) {
    return codelistLists;
  }

  const dimensions = Object.keys(codelistLists);
  const filteredCodelistsList: {[key: string]: string[]} = {};

  dimensions.forEach(dim => {
    if (dim !== timeDim) {
      if ((criteria[dim]?.filterValues || []).length > 0) {
        filteredCodelistsList[dim] = criteria[dim].filterValues as string[];
      } else {
        filteredCodelistsList[dim] = codelistLists[dim];
      }
    } else {
      const freqs = freqDim && filteredCodelistsList[freqDim] ? filteredCodelistsList[freqDim] : [FREQ_ANNUAL];
      const freq = SUPPORTED_FREQ_VALUES.find(freq => freqs.includes(freq)) || FREQ_ANNUAL;

      if (criteria[timeDim]?.from && criteria[timeDim]?.to) {
        filteredCodelistsList[timeDim] = getTimeValuesFromRange(
          freqs,
          moment(criteria[timeDim].from),
          moment(criteria[timeDim].to)
        );
      } else if (criteria[timeDim]?.period) {
        const timeDimValuesByFreq = getTimeValuesByFreq(codelistLists[timeDim], freq);
        const timeDimValuesByFreqFiltered = timeDimValuesByFreq.slice((criteria[timeDim].period as number) * -1);
        const from = timeDimValuesByFreqFiltered[0];
        const to = timeDimValuesByFreqFiltered[timeDimValuesByFreqFiltered.length - 1];
        filteredCodelistsList[timeDim] = getTimeValuesFromRange(freqs, from, to);
      } else {
        filteredCodelistsList[timeDim] = codelistLists[timeDim];
      }
    }
  });

  return filteredCodelistsList;
};

export const getCompleteTableLayout = (
  state: DatasetSingleViewerState,
  codelistLists: {[key: string]: any[]},
  hasLastNPeriods: boolean,
  limit: number
) => {
  const dimensionIds = (state.dimensions || []).map(({id}) => id);
  const layout = state.tableLayoutPartial || state.tableLayout;

  return layout
    ? getSVPFilteredTableLayout(
        layout,
        codelistLists,
        state.timeDim,
        state.freqDim,
        state.territoryDim,
        hasLastNPeriods
      )
    : getSVPInitialTableLayout(dimensionIds, codelistLists, state.timeDim, state.freqDim, state.territoryDim, limit);
};

export const getCompleteMapLayout = (
  state: DatasetSingleViewerState,
  codelistLists: {[key: string]: any[]},
  hasLastNPeriods: boolean,
  showSingleGeometry: boolean
) => {
  if (state.territoryDim === null || (codelistLists[state.territoryDim] || []).length === 0) {
    return null;
  }
  if (!showSingleGeometry && (codelistLists[state.territoryDim] || []).length === 1) {
    return null;
  }

  const dimensionIds = (state.dimensions || []).map(({id}) => id);
  const layout = state.mapLayoutPartial || state.mapLayout;

  return layout
    ? getSVPFilteredMapLayout(layout, codelistLists, state.timeDim, state.freqDim, state.territoryDim, hasLastNPeriods)
    : getSVPInitialMapLayout(dimensionIds, codelistLists, state.timeDim, state.freqDim, state.territoryDim);
};

export const getCompleteChartLayout = (
  state: DatasetSingleViewerState,
  codelistLists: {[key: string]: any[]},
  hasLastNPeriods: boolean
) => {
  const dimensionIds = (state.dimensions || []).map(({id}) => id);
  const layout = state.chartLayoutPartial || state.chartLayout;

  return layout
    ? getSVPFilteredChartLayout(
        layout,
        codelistLists,
        state.timeDim,
        state.freqDim,
        state.territoryDim,
        hasLastNPeriods
      )
    : getSVPInitialChartLayout(dimensionIds, codelistLists, state.timeDim);
};

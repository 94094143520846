export enum NodeVisibility {
  No = 0,
  Yes = 1,
  Profiled = 2
}

export enum NodeDataAccessibility {
  All = 0,
  Registered = 1,
  Profiled = 2
}

export interface IHubMinimalNode {
  nodeId: number;
  code: string;
  name: string;
  order: number;
  backgroundMediaURL: string;
  visible: NodeVisibility;
  dataAccess: NodeDataAccessibility;
}

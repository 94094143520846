import React, {lazy, Suspense, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import ErrorBoundary from "./ErrorBoundary";

const ModuleLoader = ({t, id, component, fallback = "", ...props}) => {
  const [Module, setModule] = useState(null);

  useEffect(() => {
    const Module = lazy(() => import(`../../modules/${id}/${component}`));
    setModule(Module);
  }, [id, component]);

  return (
    Module && (
      <ErrorBoundary>
        <Suspense fallback={fallback}>
          <Module {...props} />
        </Suspense>
      </ErrorBoundary>
    )
  );
};

export default withTranslation()(ModuleLoader);

import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch() {
    const {component, variant} = this.props;

    console.error(`Error loading variant "${variant}" for component "${component}"`);

    this.setState({hasError: true});
  }

  render() {
    return this.state.hasError ? null : this.props.children;
  }
}

export default ErrorBoundary;

import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import Call from "../hocs/call";
import {goToHome} from "../links";
import {showUserSetPasswordForm} from "../state/user/userActions";

const mapDispatchToProps = dispatch => ({
  onUserSetPasswordFormShow: token => dispatch(showUserSetPasswordForm(token))
});

const ResetPasswordDomain = ({onUserSetPasswordFormShow, children}) => {
  const location = useLocation();

  const tokenParam = new URLSearchParams(location.search).get("token");

  if (!tokenParam) {
    goToHome();
  }

  return (
    <Call cb={onUserSetPasswordFormShow} cbParam={tokenParam} disabled={!tokenParam}>
      {children}
    </Call>
  );
};

export default connect(null, mapDispatchToProps)(ResetPasswordDomain);

import {
  getDashboardsUrl,
  getDatasetCustomDownloadUrl,
  getDatasetDownloadUrl,
  getDatasetUrl
} from "../../serverApi/urls";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";
import {getCriteriaArrayFromObject} from "../../utils/criteria";
import {
  DOWNLOAD_FORMAT_CSV,
  DOWNLOAD_FORMAT_EXCEL,
  downloadFormats,
  getCustomExportRequestBody
} from "../../utils/download";

export const DASHBOARDS_DASHBOARD_FETCH = "dashboards/dashboard/fetch";
export const DASHBOARDS_DASHBOARDS_CLEAR = "dashboards/dashboards/clear";
export const DASHBOARDS_DATASET_FETCH = "dashboards/dataset/fetch";
export const DASHBOARDS_OPTIMIZED_DATASET_FETCH = "dashboards/optimizedDataset/fetch";
export const DASHBOARDS_DATASET_FILTER_SET = "dashboards/dataset/filter/set";
export const DASHBOARDS_DATASET_FILTER_APPLY = "dashboards/dataset/filter/apply";
export const DASHBOARDS_DATASET_DOWNLOAD_SUBMIT = "dashboards/dataset/download/submit";

export const fetchDashboardsDashboard = (dashboardId: number) =>
  initRequest(
    DASHBOARDS_DASHBOARD_FETCH,
    getDashboardsUrl(dashboardId),
    RequestMethod.GET,
    undefined,
    t => ({
      onStart: t("scenes.dashboard.actions.fetchingDashboard")
    }),
    {
      dashboardId: dashboardId
    }
  );

export const clearDashboardsDashboards = () => ({
  type: DASHBOARDS_DASHBOARDS_CLEAR
});

export const fetchDashboardsDataset = (
  dashboardId: number,
  isFetchingDynamicView: boolean,
  nodeId: number,
  datasetId: string,
  criteria: any,
  requestIds: string[],
  worker: any
) =>
  initRequest(
    DASHBOARDS_DATASET_FETCH,
    getDatasetUrl(nodeId, datasetId),
    RequestMethod.POST,
    getCriteriaArrayFromObject(criteria),
    undefined,
    {
      nodeId: nodeId,
      dashboardId: dashboardId,
      isFetchingDynamicView: isFetchingDynamicView,
      requestIds: requestIds,
      criteria: criteria,
      worker: worker
    },
    "",
    () => true,
    true
  );

export const fetchDashboardsOptimizedDataset = (
  dashboardId: number,
  viewIdx: string,
  nodeId: number,
  datasetId: string,
  criteria: any
) =>
  initRequest(
    DASHBOARDS_OPTIMIZED_DATASET_FETCH,
    getDatasetUrl(nodeId, datasetId),
    RequestMethod.POST,
    getCriteriaArrayFromObject(criteria),
    undefined,
    {
      dashboardId: dashboardId,
      viewIdx: viewIdx
    },
    undefined,
    (statusCode: number) => statusCode === 413 || statusCode === 414,
    true
  );

export const setDashboardsDatasetFilter = (
  dashboardId: number,
  viewIdx: string,
  layout: any,
  isOptimized: boolean
) => ({
  type: DASHBOARDS_DATASET_FILTER_SET,
  payload: {
    dashboardId,
    viewIdx,
    layout,
    isOptimized
  }
});

export const applyDashboardsDatasetFilter = () => ({
  type: DASHBOARDS_DATASET_FILTER_APPLY
});

export const submitDashboardDatasetDownload = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string,
  criteria: any,
  layout: any,
  format: string,
  extension: string,
  zipped: boolean,
  params: any,
  defaultLanguage: string,
  languages: string[],
  t: any
) => {
  let url, body;

  if (format === DOWNLOAD_FORMAT_EXCEL || format === DOWNLOAD_FORMAT_CSV) {
    url = getDatasetCustomDownloadUrl(nodeId, format, zipped);
    body = getCustomExportRequestBody(datasetId, datasetTitle, criteria, layout, params, defaultLanguage, languages, t);
  } else {
    // @ts-ignore
    const formatParam = downloadFormats()[format]?.param || format;
    url = getDatasetDownloadUrl(nodeId, datasetId, formatParam, zipped);
    body = getCriteriaArrayFromObject(criteria);
  }

  const type = format === DOWNLOAD_FORMAT_EXCEL ? "application/vnd.ms-excel" : "text/plain;charset=utf-8";

  return initRequest(
    DASHBOARDS_DATASET_DOWNLOAD_SUBMIT,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDataset")
    }),
    {
      fileSave: {
        name: `${datasetTitle} (${datasetId})`,
        extension: extension,
        type: type
      }
    },
    undefined,
    (statusCode: number) => statusCode === 406,
    undefined,
    undefined,
    true
  );
};

import React, {useCallback, useMemo} from "react";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import DatasetFilters from "./DatasetFilters";
import {
  getDatasetSize,
  getDimensionAttributeMap,
  getFormattedDimensionValueLabelFromCodelists
} from "../../utils/dataset";

const DatasetFiltersCodelistsWrapper = ({
  nodeCode,
  datasetId,
  dimensions,
  dimensionsInfo,
  dimensionsHidden,
  codelistLists,
  codelistMaps,
  timeDim,
  layout,
  jsonStat,
  labelFormat,
  onSelect,
  onCriteriaShow,
  limit,
  enclosingContainerId
}) => {
  const {t} = useTranslation();

  const dimAttributeMap = useMemo(
    () => (jsonStat && (jsonStat?.id || []).length > 0 ? getDimensionAttributeMap(jsonStat, t) : null),
    [jsonStat, t]
  );

  const getIsDimensionAllowed = useCallback(
    dim => (codelistLists?.[dim] || []).length > 1 && !(dimensionsHidden || []).includes(dim),
    [codelistLists, dimensionsHidden]
  );

  const getDimensionLabel = useCallback(
    dimension => dimensions.find(({id}) => id === dimension)?.label || dimension,
    [dimensions]
  );

  const getFilterValues = useCallback(dim => codelistLists[dim], [codelistLists]);

  const getFormattedName = useCallback(
    (dimension, value, labelFormat) => {
      return getFormattedDimensionValueLabelFromCodelists(dimension, value, codelistMaps, labelFormat);
    },
    [codelistMaps]
  );

  const getFormattedTooltip = useCallback(
    (dimension, value, labelFormat) => {
      return getFormattedDimensionValueLabelFromCodelists(dimension, value, codelistMaps, labelFormat);
    },
    [codelistMaps]
  );

  const handleFilterSelect = useCallback(
    (dimension, value) => {
      const orderedValue = Array.isArray(value)
        ? codelistLists[dimension].filter(dimVal => value.includes(dimVal))
        : value;

      onSelect(dimension, orderedValue);
    },
    [codelistLists, onSelect]
  );

  const getIsDatasetExceedingLimit = useCallback(
    (dim, dimValues) => {
      if (!dimensions || limit === null || limit === undefined) {
        return false;
      }
      const tmpLayout = _.cloneDeep(layout);
      if (tmpLayout.primaryDim && tmpLayout.primaryDim.includes(dim)) {
        tmpLayout.primaryDimValues = dimValues;
      } else if (tmpLayout.secondaryDim && tmpLayout.secondaryDim.includes(dim)) {
        tmpLayout.secondaryDimValues = dimValues;
      }
      const datasetSize = getDatasetSize(dimensionsInfo, tmpLayout);
      return datasetSize > limit;
    },
    [dimensions, dimensionsInfo, layout, limit]
  );

  return (
    layout && (
      <DatasetFilters
        datasetId={`${nodeCode},${datasetId}`}
        jsonStat={jsonStat}
        timeDim={timeDim}
        layout={layout}
        labelFormat={labelFormat}
        getIsDimensionAllowed={getIsDimensionAllowed}
        getDimensionLabel={getDimensionLabel}
        getFilterValues={getFilterValues}
        getFormattedName={getFormattedName}
        getFormattedTooltip={getFormattedTooltip}
        dimAttributeMap={dimAttributeMap}
        onSelect={handleFilterSelect}
        onCriteriaShow={onCriteriaShow}
        getIsDatasetExceedingLimit={getIsDatasetExceedingLimit}
        enclosingContainerId={enclosingContainerId}
      />
    )
  );
};

export default DatasetFiltersCodelistsWrapper;

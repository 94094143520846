import _ from "lodash";
import moment from "moment";
import {Reducer} from "redux";
import {
  DASHBOARDS_DASHBOARD_FETCH,
  DASHBOARDS_DASHBOARDS_CLEAR,
  DASHBOARDS_DATASET_FILTER_APPLY,
  DASHBOARDS_DATASET_FILTER_SET,
  DASHBOARDS_OPTIMIZED_DATASET_FETCH
} from "./dashboardActions";
import {getCurrentNodeConfig} from "../../middlewares/action-decorator/actionDecoratorMiddlewareFactory";
import {
  FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_ERROR,
  FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_INIT,
  FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_RESET,
  FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_SUCCESS
} from "../../middlewares/fetch-dashboard-dataset-async-handler/actions";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";
import {CRITERIA_FILTER_TYPE_PERIODS, getCriteriaObjectFromArray} from "../../utils/criteria";
import {
  DASHBOARD_ELEM_FILTER_DIMENSION_KEY,
  DASHBOARD_ELEM_TYPE_KEY,
  DASHBOARD_ELEM_TYPE_VALUE_VIEW,
  DASHBOARD_STATUS_MISSING,
  DASHBOARD_STATUS_REQUEST_ERROR,
  DASHBOARD_STATUS_REQUEST_START,
  DASHBOARD_STATUS_REQUEST_SUCCESS,
  DASHBOARD_VIEW_STATUS_EMPTY_DATASET,
  DASHBOARD_VIEW_STATUS_REQUEST_ERROR,
  DASHBOARD_VIEW_STATUS_REQUEST_START,
  getViewFromViewIdx,
  getViewIdxFromDashboardConfig,
  getViewIdxFromRowAndCol
} from "../../utils/dashboards";
import {
  getDimensionsInfo,
  getSVPFilteredChartLayout,
  getSVPFilteredMapLayout,
  getSVPFilteredTableLayout
} from "../../utils/dataset";
import {FREQ_ANNUAL, getMinAndMax, getTimeValuesFromRange, SUPPORTED_FREQ_VALUES} from "../../utils/timePeriodAndFreq";
import {getChartSettingsFromViewTemplateLayouts, getMapSettingsFromViewTemplateLayouts} from "../../utils/viewTemplate";

type DashboardState = {
  dashboards: any | null;
  dashboardsStatus: {[key: string]: string};
  fetchedDatasets: {[key: string]: {[key: string]: boolean}};
  jsonStats: any | null;
  layoutObjs: any | null;
  filterTrees: any | null;
  timePeriodsByFreq: any | null;
  dynamicViewPendingRequests: string[];
};

const initialState = {
  dashboards: null,
  dashboardsStatus: {},
  fetchedDatasets: {},
  jsonStats: null,
  layoutObjs: null,
  filterTrees: null,
  timePeriodsByFreq: null,
  dynamicViewPendingRequests: []
};

const dashboardReducer: Reducer<DashboardState> = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARDS_DASHBOARDS_CLEAR: {
      return {
        ...initialState
      };
    }
    case DASHBOARDS_DATASET_FILTER_APPLY: {
      const newFetchedDatasets = _.cloneDeep(state.fetchedDatasets);
      Object.keys(newFetchedDatasets).forEach(
        id =>
          (newFetchedDatasets[id] = {
            ...newFetchedDatasets[id],
            dynamic: false
          })
      );

      const newJsonStats = _.cloneDeep(state.jsonStats);
      Object.keys(state.dashboards).forEach(dashboardId => {
        (state.dashboards[dashboardId]?.viewIds || []).forEach((viewId: number) => {
          const viewIdx = getViewIdxFromDashboardConfig(state.dashboards[dashboardId].dashboardConfig, viewId);
          const view = getViewFromViewIdx(state.dashboards[dashboardId].dashboardConfig, viewIdx);

          const filterDim = view?.filterDimension || "";
          const isDynamic = filterDim.length > 0;
          const isOptimized = state.dashboards[dashboardId]?.views?.[viewId]?.isOptimized || false;
          if (isDynamic && isOptimized) {
            newJsonStats[dashboardId][viewIdx] = null;
          }
        });
      });

      return {
        ...state,
        fetchedDatasets: newFetchedDatasets,
        jsonStats: newJsonStats
      };
    }
    case FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_INIT: {
      let currentDashboardJsonStats = _.cloneDeep(state.jsonStats?.[action.payload.dashboardId]);
      let currentDashboardLayoutObjs = _.cloneDeep(state.layoutObjs?.[action.payload.dashboardId]);
      let currentDashboardFilterTrees = _.cloneDeep(state.filterTrees?.[action.payload.dashboardId]);
      let currentDashboardTimePeriodsByFreq = _.cloneDeep(state.timePeriodsByFreq?.[action.payload.dashboardId]);

      (state.dashboards?.[action.payload.dashboardId]?.dashboardConfig || []).forEach((row: any, rowIdx: number) => {
        row.forEach((col: any, colIdx: number) => {
          const viewIdx = getViewIdxFromRowAndCol(rowIdx, colIdx);
          if (
            col[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_VIEW &&
            action.payload.requestIds.includes(viewIdx)
          ) {
            const viewIdx = getViewIdxFromRowAndCol(rowIdx, colIdx);
            const isStaticView = (col[DASHBOARD_ELEM_FILTER_DIMENSION_KEY] || "").length === 0;

            const jsonStat = currentDashboardJsonStats?.[viewIdx];
            currentDashboardJsonStats = {
              ...currentDashboardJsonStats,
              [viewIdx]: isStaticView && jsonStat ? jsonStat : DASHBOARD_VIEW_STATUS_REQUEST_START
            };

            const layout = currentDashboardLayoutObjs?.[viewIdx];
            currentDashboardLayoutObjs = {
              ...currentDashboardLayoutObjs,
              [viewIdx]: isStaticView && jsonStat ? layout : null
            };

            const filterTree = currentDashboardFilterTrees?.[viewIdx];
            currentDashboardFilterTrees = {
              ...currentDashboardFilterTrees,
              [viewIdx]: isStaticView && jsonStat ? filterTree : null
            };

            const timePeriodsByFreq = currentDashboardTimePeriodsByFreq?.[viewIdx];
            currentDashboardTimePeriodsByFreq = {
              ...currentDashboardTimePeriodsByFreq,
              [viewIdx]: timePeriodsByFreq || null
            };
          }
        });
      });

      const dynamicViewPendingRequests = [...state.dynamicViewPendingRequests];
      if (action.payload.isFetchingDynamicView) {
        dynamicViewPendingRequests.push(action.payload.requestUuid);
      }

      return {
        ...state,
        jsonStats: {
          ...state.jsonStats,
          [action.payload.dashboardId]: currentDashboardJsonStats
        },
        layoutObjs: {
          ...state.layoutObjs,
          [action.payload.dashboardId]: currentDashboardLayoutObjs
        },
        filterTrees: {
          ...state.filterTrees,
          [action.payload.dashboardId]: currentDashboardFilterTrees
        },
        timePeriodsByFreq: {
          ...state.timePeriodsByFreq,
          [action.payload.dashboardId]: currentDashboardTimePeriodsByFreq
        },
        dynamicViewPendingRequests: dynamicViewPendingRequests
      };
    }
    case FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_SUCCESS: {
      const fetchedDashboard = state.fetchedDatasets[action.payload.dashboardId] || {};

      return state.jsonStats && state.layoutObjs && state.filterTrees && state.timePeriodsByFreq
        ? {
            ...state,
            fetchedDatasets: {
              ...state.fetchedDatasets,
              [action.payload.dashboardId]: {
                static: !action.payload.isFetchingDynamicView ? true : fetchedDashboard.static,
                dynamic: action.payload.isFetchingDynamicView ? true : fetchedDashboard.dynamic
              }
            },
            jsonStats: {
              ...state.jsonStats,
              [action.payload.dashboardId]: {
                ...state.jsonStats[action.payload.dashboardId],
                ...action.payload.dashboardJsonStats
              }
            },
            layoutObjs: {
              ...state.layoutObjs,
              [action.payload.dashboardId]: {
                ...state.layoutObjs[action.payload.dashboardId],
                ...action.payload.dashboardLayoutObjs
              }
            },
            filterTrees: {
              ...state.filterTrees,
              [action.payload.dashboardId]: {
                ...state.filterTrees[action.payload.dashboardId],
                ...action.payload.dashboardFilterTrees
              }
            },
            timePeriodsByFreq: {
              ...state.timePeriodsByFreq,
              [action.payload.dashboardId]: {
                ...state.timePeriodsByFreq[action.payload.dashboardId],
                ...action.payload.dashboardTimePeriodsByFreq
              }
            }
          }
        : {
            ...state
          };
    }
    case FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_ERROR: {
      const jsonStats = _.cloneDeep(state.jsonStats);
      const layoutObjs = _.cloneDeep(state.layoutObjs);
      const filterTrees = _.cloneDeep(state.filterTrees);
      const timePeriodsByFreq = _.cloneDeep(state.timePeriodsByFreq);

      (action.payload.requestIds || []).forEach((id: string) => {
        if (jsonStats) {
          jsonStats[action.payload.dashboardId] = {
            ...jsonStats[action.payload.dashboardId],
            [id]: DASHBOARD_VIEW_STATUS_REQUEST_ERROR
          };
        }
        if (layoutObjs) {
          layoutObjs[action.payload.dashboardId] = {
            ...layoutObjs[action.payload.dashboardId],
            [id]: null
          };
        }
        if (filterTrees) {
          filterTrees[action.payload.dashboardId] = {
            ...filterTrees[action.payload.dashboardId],
            [id]: null
          };
        }
        if (timePeriodsByFreq) {
          timePeriodsByFreq[action.payload.dashboardId] = {
            ...timePeriodsByFreq[action.payload.dashboardId],
            [id]: null
          };
        }
      });

      return {
        ...state,
        jsonStats: jsonStats,
        layoutObjs: layoutObjs,
        filterTrees: filterTrees,
        timePeriodsByFreq: timePeriodsByFreq
      };
    }
    case FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_RESET: {
      const jsonStats = _.cloneDeep(state.jsonStats);
      const layoutObjs = _.cloneDeep(state.layoutObjs);
      const filterTrees = _.cloneDeep(state.filterTrees);
      const timePeriodsByFreq = _.cloneDeep(state.timePeriodsByFreq);

      (action.payload.requestIds || []).forEach((id: string) => {
        if (jsonStats) {
          jsonStats[action.payload.dashboardId] = {
            ...jsonStats[action.payload.dashboardId],
            [id]: DASHBOARD_VIEW_STATUS_REQUEST_START
          };
        }
        if (layoutObjs) {
          layoutObjs[action.payload.dashboardId] = {
            ...layoutObjs[action.payload.dashboardId],
            [id]: null
          };
        }
        if (filterTrees) {
          filterTrees[action.payload.dashboardId] = {
            ...filterTrees[action.payload.dashboardId],
            [id]: null
          };
        }
        if (timePeriodsByFreq) {
          timePeriodsByFreq[action.payload.dashboardId] = {
            ...timePeriodsByFreq[action.payload.dashboardId],
            [id]: null
          };
        }
      });

      return {
        ...state,
        jsonStats: jsonStats,
        layoutObjs: layoutObjs,
        filterTrees: filterTrees,
        timePeriodsByFreq: timePeriodsByFreq
      };
    }
    case DASHBOARDS_DATASET_FILTER_SET: {
      return {
        ...state,
        layoutObjs: {
          ...state.layoutObjs,
          [action.payload.dashboardId]: {
            ...state.layoutObjs[action.payload.dashboardId],
            [action.payload.viewIdx]: {
              ...state.layoutObjs[action.payload.dashboardId][action.payload.viewIdx],
              layout: action.payload.layout
            }
          }
        },
        jsonStats: !action.payload.isOptimized
          ? state.jsonStats
          : {
              ...state.jsonStats,
              [action.payload.dashboardId]: {
                ...state.jsonStats[action.payload.dashboardId],
                [action.payload.viewIdx]: null
              }
            }
      };
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case DASHBOARDS_DASHBOARD_FETCH: {
          return {
            ...state,
            dashboardsStatus: {
              ...state.dashboardsStatus,
              [action.payload.extra.dashboardId]: DASHBOARD_STATUS_REQUEST_START
            }
          };
        }
        case DASHBOARDS_OPTIMIZED_DATASET_FETCH: {
          return {
            ...state,
            jsonStats: {
              ...state.jsonStats,
              [action.payload.extra.dashboardId]: {
                ...state.jsonStats?.[action.payload.extra.dashboardId],
                [action.payload.extra.viewIdx]: DASHBOARD_VIEW_STATUS_REQUEST_START
              }
            }
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case DASHBOARDS_DASHBOARD_FETCH: {
          if (action.payload.extra.status === 200) {
            const parsedDashboardConfig = JSON.parse(action.payload.response?.dashboardConfig || "{}");
            const parsedFilterLevels = JSON.parse(action.payload.response?.filterLevels || "{}");
            const dashboardJsonStats: {[key: string]: any} = {};
            const dashboardLayoutObjs: {[key: string]: any} = {};

            const views = _.cloneDeep(action.payload.response.views);
            action.payload.response.viewIds.forEach((viewId: number) => {
              if (views[viewId]) {
                const parsedCriteria: any = getCriteriaObjectFromArray(views[viewId].criteria);
                const parsedLayouts = JSON.parse(views[viewId].layouts);

                const isOptimizedData = views[viewId]?.allPartialOptimizedInfo !== undefined;
                if (isOptimizedData) {
                  const freqDim = views[viewId]?.allPartialOptimizedInfo?.freqDimension;
                  const timeDim = views[viewId]?.allPartialOptimizedInfo?.timeDimension;
                  const territoryDim = views[viewId]?.allPartialOptimizedInfo?.territorialDimension;

                  const hasLastNPeriods = timeDim
                    ? parsedCriteria[timeDim]?.type === CRITERIA_FILTER_TYPE_PERIODS
                    : false;

                  const codelistLists: {[key: string]: string[]} = {};
                  const codelistMaps: {[key: string]: {[key: string]: string}} = {};

                  const dimensionValues = views[viewId]?.allPartialOptimizedInfo?.dimensionValues || [];
                  dimensionValues.forEach((dimension: any) => {
                    const ids: string[] = [];
                    const map: {[key: string]: string} = {};
                    (dimension.values || []).forEach((code: any) => {
                      if (code.isSelectable) {
                        ids.push(code.id);
                        map[code.id] = code.name;
                      }
                    });
                    codelistLists[dimension.id] = ids;
                    codelistMaps[dimension.id] = map;
                  });
                  if (timeDim) {
                    const freqs = freqDim && codelistLists[freqDim] ? codelistLists[freqDim] : [FREQ_ANNUAL];
                    const freq = SUPPORTED_FREQ_VALUES.find(freq => freqs.includes(freq)) || FREQ_ANNUAL;
                    const timeCodelist = dimensionValues.find(({id}: any) => id === timeDim);
                    const {min, max} = getMinAndMax(timeCodelist, freq, getCurrentNodeConfig(action));

                    codelistLists[timeDim] = getTimeValuesFromRange(freqs, moment(min), moment(max));
                    codelistMaps[timeDim] = {};
                  }

                  let layout = null;
                  if (parsedLayouts.tableLayout) {
                    layout = getSVPFilteredTableLayout(
                      parsedLayouts.tableLayout,
                      codelistLists,
                      timeDim,
                      freqDim,
                      territoryDim,
                      hasLastNPeriods
                    );
                  } else if (parsedLayouts.mapLayout) {
                    layout = getSVPFilteredMapLayout(
                      parsedLayouts.mapLayout,
                      codelistLists,
                      timeDim,
                      freqDim,
                      territoryDim,
                      hasLastNPeriods
                    );
                  } else if (parsedLayouts.chartLayout) {
                    layout = getSVPFilteredChartLayout(
                      parsedLayouts.chartLayout,
                      codelistLists,
                      timeDim,
                      freqDim,
                      territoryDim,
                      hasLastNPeriods
                    );
                  }

                  if (hasLastNPeriods) {
                    const timeDimValues = codelistLists[timeDim];
                    const lastTimeDimValue = timeDimValues[timeDimValues.length - 1];

                    if (layout && (layout?.filters || []).includes(timeDim)) {
                      layout.filtersValue[timeDim] = lastTimeDimValue;
                    } else if (layout && (layout.primaryDim || []).includes(timeDim)) {
                      layout.primaryDimValues = timeDimValues;
                    } else if (layout && (layout.secondaryDim || []).includes(timeDim)) {
                      layout.secondaryDimValues = timeDimValues;
                    }
                  }

                  views[viewId] = {
                    ...views[viewId],
                    allPartialOptimizedInfo: null,
                    isOptimized: true,
                    dimensionValues: dimensionValues,
                    dimensionsInfo: getDimensionsInfo(dimensionValues, codelistLists),
                    freqDim: freqDim,
                    timeDim: timeDim,
                    territoryDim: territoryDim,
                    codelistLists: codelistLists,
                    codelistMaps: codelistMaps
                  };

                  const viewLayoutObj = {
                    layout: layout,
                    labelFormat: parsedLayouts.labelFormat,
                    temporalDimOrder: parsedLayouts.temporalDimOrder,
                    showTrend: parsedLayouts.showTrend,
                    showCyclical: parsedLayouts.showCyclical,
                    tableEmptyChar: parsedLayouts.tableEmptyChar,
                    chartSettings: getChartSettingsFromViewTemplateLayouts(parsedLayouts),
                    mapSettings: getMapSettingsFromViewTemplateLayouts(parsedLayouts),
                    detailLevel:
                      parsedLayouts.detailLevel !== null && parsedLayouts.detailLevel !== undefined
                        ? parsedLayouts.detailLevel
                        : parsedLayouts.mapDetailLevel !== null && parsedLayouts.mapDetailLevel !== undefined
                        ? parsedLayouts.mapDetailLevel
                        : null
                  };

                  const viewIdx = getViewIdxFromDashboardConfig(parsedDashboardConfig, viewId);
                  dashboardJsonStats[viewIdx] = null;
                  dashboardLayoutObjs[viewIdx] = viewLayoutObj;
                }

                views[viewId] = {
                  ...views[viewId],
                  datasetId: views[viewId].datasetId ? views[viewId].datasetId.split("+").join(",") : undefined,
                  criteria: parsedCriteria,
                  layouts: parsedLayouts
                };
              }
            });

            return {
              ...state,
              dashboards: {
                ...state.dashboards,
                [action.payload.extra.dashboardId]: {
                  ...action.payload.response,
                  dashboardConfig: parsedDashboardConfig,
                  filterLevels: parsedFilterLevels,
                  views: views
                }
              },
              dashboardsStatus: {
                ...state.dashboardsStatus,
                [action.payload.extra.dashboardId]: DASHBOARD_STATUS_REQUEST_SUCCESS
              },
              layoutObjs: {
                ...state.layoutObjs,
                [action.payload.extra.dashboardId]: dashboardLayoutObjs
              },
              jsonStats: {
                ...state.jsonStats,
                [action.payload.extra.dashboardId]: dashboardJsonStats
              }
            };
          } else {
            return {
              ...state,
              dashboards: {
                ...state.dashboards,
                [action.payload.extra.dashboardId]: null
              },
              dashboardsStatus: {
                ...state.dashboardsStatus,
                [action.payload.extra.dashboardId]: DASHBOARD_STATUS_MISSING
              }
            };
          }
        }
        case DASHBOARDS_OPTIMIZED_DATASET_FETCH: {
          return {
            ...state,
            jsonStats: {
              ...state.jsonStats,
              [action.payload.extra.dashboardId]: {
                ...state.jsonStats?.[action.payload.extra.dashboardId],
                [action.payload.extra.viewIdx]:
                  (action.payload.response?.id || []).length > 0
                    ? action.payload.response
                    : DASHBOARD_VIEW_STATUS_EMPTY_DATASET
              }
            }
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case DASHBOARDS_DASHBOARD_FETCH: {
          return {
            ...state,
            dashboards: {
              ...state.dashboards,
              [action.payload.extra.dashboardId]: null
            },
            dashboardsStatus: {
              ...state.dashboardsStatus,
              [action.payload.extra.dashboardId]: DASHBOARD_STATUS_REQUEST_ERROR
            }
          };
        }
        case DASHBOARDS_OPTIMIZED_DATASET_FETCH: {
          return {
            ...state,
            jsonStats: {
              ...state.jsonStats,
              [action.payload.extra.dashboardId]: {
                ...state.jsonStats?.[action.payload.extra.dashboardId],
                [action.payload.extra.viewIdx]: DASHBOARD_VIEW_STATUS_REQUEST_ERROR
              }
            }
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default dashboardReducer;

import React from "react";
import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import SanitizedHTML from "../sanitized-html";

const styles = theme => ({
  footer: {
    margin: "auto 16px 0 16px",
    paddingTop: 16
  }
});

const getLocalizedFooter = (footers, appLang, languages) => {
  const footerLangs = [appLang, "default", ...languages];
  const footerLang = footerLangs.find(lang => footers[lang]);

  return footerLang ? footers[footerLang] : null;
};

const Footer = ({t, classes, appLanguage, languages, footers}) => (
  <footer id="footer" className={classes.footer}>
    <Divider id="footer__divider" className={classes.divider} />
    <nav id="footer__content" aria-label={t("components.footer.ariaLabel")}>
      <SanitizedHTML html={getLocalizedFooter(footers, appLanguage, languages)} />
    </nav>
  </footer>
);

export default compose(
  connect(state => ({
    appLanguage: state.app.language,
    languages: state.app.languages,
    footers: state.app.footers
  })),
  withStyles(styles),
  withTranslation()
)(Footer);

import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../request/requestActions";
import {
  errorFetchDashboardDatasetAsyncHandler,
  initFetchDashboardDatasetAsyncHandler,
  resetFetchDashboardDatasetAsyncHandler,
  successFetchDashboardDatasetAsyncHandler
} from "./actions";
import {DASHBOARDS_DATASET_FETCH} from "../../state/dashboard/dashboardActions";

const fetchDashboardDatasetAsyncHandlerMiddlewareFactory =
  t =>
  ({getState, dispatch}) =>
  next =>
  action => {
    const result = next(action);

    if (action?.payload?.label === DASHBOARDS_DATASET_FETCH) {
      const {dashboardId, isFetchingDynamicView, requestIds, criteria, worker} = action.payload.extra;

      if (action.type === REQUEST_INIT) {
        dispatch(
          initFetchDashboardDatasetAsyncHandler(dashboardId, isFetchingDynamicView, requestIds, action.payload.uuid)
        );
      } else if (action.type === REQUEST_SUCCESS) {
        const state = getState();

        worker.onmessage = event => {
          const {
            dashboardId,
            dashboardJsonStats,
            dashboardLayoutObjs,
            dashboardFilterTrees,
            dashboardTimePeriodsByFreq,
            dashboardMaps
          } = event.data;
          dispatch(
            successFetchDashboardDatasetAsyncHandler(
              dashboardId,
              isFetchingDynamicView,
              dashboardJsonStats,
              dashboardLayoutObjs,
              dashboardFilterTrees,
              dashboardTimePeriodsByFreq,
              dashboardMaps
            )
          );
        };
        worker.onerror = () => {
          dispatch(errorFetchDashboardDatasetAsyncHandler(dashboardId, requestIds));
          window.error.show(t("middlewares.fetchDatasetAsyncHandlerMiddlewareFactory.feedback.layoutHandlingError"));
        };
        worker.postMessage({
          dashboardId,
          requestIds,
          criteria,
          response: action.payload.response,
          dashboard: state.dashboard.dashboards?.[dashboardId]
        });
      } else if (action.type === REQUEST_ERROR) {
        if (action.payload.statusCode !== undefined) {
          dispatch(errorFetchDashboardDatasetAsyncHandler(dashboardId, requestIds));
        } else {
          dispatch(resetFetchDashboardDatasetAsyncHandler(dashboardId, requestIds));
        }
      }
    }

    return result;
  };

export default fetchDashboardDatasetAsyncHandlerMiddlewareFactory;

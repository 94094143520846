import React, {Fragment, useState} from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import Call from "../../../hocs/call";
import {NodeDataAccessibility, NodeVisibility} from "../../../model/IHubMinimalNode.d.ts";
import CategoriesTree from "../../categories-tree";
import Criteria from "../../criteria";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomEmpty from "../../custom-empty";
import FullscreenDialog from "../../fullscreen-dialog";
import {ViewerMode} from "../../../state/dataset/constants";
import {
  changeDatasetMVAdditionalDataset,
  fetchDatasetMVAdditionalDatasetStructure,
  fetchDatasetMVStructureCodelist,
  fetchDatasetMVStructureCodelistFull,
  hideDatasetMVCriteriaObsCountWarning,
  hideDatasetMVStructureCodelistFull,
  submitDatasetMVAdditionalDataset
} from "../../../state/dataset/multi-viewer/actions";

const styles = theme => ({
  root: {},
  steps: {
    height: 108
  },
  stepContent: {
    height: "calc(100% - 108px)",
    overflow: "hidden"
  },
  nodeSelect: {
    marginBottom: 8
  },
  catalog: {
    height: "calc(100% - 64px)",
    overflow: "auto"
  }
});

const mapStateToProps = ({hub, node, dataset}) => ({
  nodes: hub.nodes,
  currentNode: node,
  mainDatasetId: dataset.commons.datasetId,
  mode: dataset.commons.mode,
  type: dataset.commons.type,
  isObsCountWarningVisible: dataset.multiViewer.isObsCountWarningVisible,
  territoryDimCriteria: dataset.multiViewer.criteria?.[dataset.multiViewer.territoryDim] || null,
  codelistFetchError: dataset.multiViewer.codelistFetchError,
  additionalDataset: dataset.multiViewer.additionalDataset,
  missingFilterValues: dataset.multiViewer.missingFilterValues
});

const mapDispatchToProps = dispatch => ({
  onADChange: additionalDataset => dispatch(changeDatasetMVAdditionalDataset(additionalDataset)),
  fetchADStructure: ({nodeId, datasetId}) => dispatch(fetchDatasetMVAdditionalDatasetStructure(nodeId, datasetId)),
  onDatasetSubmit: () => dispatch(submitDatasetMVAdditionalDataset()),
  fetchCodelist: (
    nodeId,
    nodeCode,
    datasetId,
    mode,
    type,
    dimensionId,
    criteria,
    freq,
    defaultLastNPeriods,
    preserveFiltersWithDynamic
  ) =>
    dispatch(
      fetchDatasetMVStructureCodelist(
        nodeId,
        nodeCode,
        datasetId,
        mode,
        type,
        dimensionId,
        criteria,
        freq,
        defaultLastNPeriods,
        preserveFiltersWithDynamic
      )
    ),
  onCriteriaObsCountWarningHide: () => dispatch(hideDatasetMVCriteriaObsCountWarning()),
  fetchCodelistFull: (nodeId, datasetId, dimensionId, missingFilterValueIds) =>
    dispatch(fetchDatasetMVStructureCodelistFull(nodeId, datasetId, dimensionId, missingFilterValueIds)),
  onCodelistFullHide: () => dispatch(hideDatasetMVStructureCodelistFull())
});

const AdditionalDatasetCreateDialog = ({
  classes,

  isOpen,
  onClose,
  defaultLastNPeriods,
  catalog,
  fetchCatalog,

  nodes,
  currentNode,
  mainDatasetId,
  mode,
  type,
  isObsCountWarningVisible,
  territoryDimCriteria,
  codelistFetchError,
  additionalDataset,
  missingFilterValues,

  onADChange,
  fetchADStructure,
  onDatasetSubmit,
  fetchCodelist,
  onCriteriaObsCountWarningHide,
  fetchCodelistFull,
  onCodelistFullHide
}) => {
  const {t} = useTranslation();

  const [step, setStep] = useState(0);

  const [selectedNodeId, setSelectedNodeId] = useState(currentNode.nodeId);

  const [isCriteriaValid, setCriteriaValidity] = useState(true);

  const goToStepData = () => {
    setStep(0);
  };

  const goToStepFilters = () => {
    setStep(1);
  };

  return (
    <Call
      cb={fetchCatalog}
      cbParam={{
        selectedNodeId: selectedNodeId,
        mainDatasetId: mainDatasetId
      }}
    >
      <FullscreenDialog open={isOpen} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>
          {t("components.additionalDatasetDialog.create.dialogs.wizard.title", {step: step + 1})}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" className={classes.steps}>
            <Grid item xs={9}>
              <Stepper activeStep={step} nonLinear alternativeLabel className={classes.steps}>
                <Step>
                  <StepButton onClick={goToStepData}>
                    {t("components.additionalDatasetDialog.create.dialogs.wizard.steps.data.title")}
                  </StepButton>
                </Step>
                <Step>
                  <StepButton onClick={goToStepFilters} disabled={!additionalDataset?.datasetId}>
                    {t("components.additionalDatasetDialog.create.dialogs.wizard.steps.filter.title")}
                  </StepButton>
                </Step>
              </Stepper>
            </Grid>
          </Grid>
          <div key={step} className={classes.stepContent}>
            {step === 0 && (
              <Fragment>
                <FormControl fullWidth className={classes.nodeSelect}>
                  <TextField
                    select
                    value={selectedNodeId}
                    onChange={ev => setSelectedNodeId(ev.target.value)}
                    variant="outlined"
                    SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                  >
                    {nodes
                      .filter(
                        ({visible, dataAccess}) =>
                          visible === NodeVisibility.Yes || dataAccess === NodeDataAccessibility.Profiled
                      )
                      .map(({nodeId, name}, idx) => (
                        <MenuItem key={idx} value={nodeId}>
                          {name}
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>
                <div className={classes.catalog}>
                  {!catalog ? (
                    <span />
                  ) : catalog.isEmpty ? (
                    <CustomEmpty text={t("components.additionalDatasetDialog.create.dialogs.emptyCatalog.content")} />
                  ) : (
                    <CategoriesTree
                      catalog={catalog}
                      showDatasetList
                      selectedDataset={additionalDataset?.datasetId}
                      onClick={datasetId => {
                        const node = nodes.find(({nodeId}) => nodeId === selectedNodeId);
                        onADChange({
                          nodeId: node.nodeId,
                          nodeCode: node.code,
                          datasetId: datasetId,
                          datasetTitle: catalog?.datasets?.[datasetId]
                            ? catalog.datasets[datasetId].title
                            : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).title
                        });
                      }}
                    />
                  )}
                </div>
              </Fragment>
            )}
            {step === 1 && (
              <Call
                cb={fetchADStructure}
                cbParam={{
                  nodeId: selectedNodeId,
                  datasetId: additionalDataset.datasetId
                }}
              >
                {additionalDataset.dimensions ? (
                  <Criteria
                    viewerMode={ViewerMode.MultiViewer}
                    nodeId={additionalDataset.nodeId}
                    nodeCode={additionalDataset.nodeCode}
                    datasetId={additionalDataset.datasetId}
                    dimensions={additionalDataset.dimensions}
                    timeDim={additionalDataset.timeDim}
                    freqDim={additionalDataset.freqDim}
                    territoryDim={additionalDataset.territoryDim}
                    hideTerritoryDim
                    mode={mode}
                    type={type}
                    criteria={additionalDataset.criteria}
                    territoryDimCriteria={territoryDimCriteria}
                    onSetCriteria={criteria =>
                      onADChange({
                        criteria: criteria
                      })
                    }
                    codelists={additionalDataset.codelists}
                    codelistsLength={additionalDataset.codelistsLength}
                    fetchCodelist={fetchCodelist}
                    codelistFetchError={codelistFetchError}
                    isCriteriaValid={isCriteriaValid}
                    setCriteriaValidity={setCriteriaValidity}
                    isObsCountWarningVisible={isObsCountWarningVisible}
                    onCriteriaObsCountWarningHide={onCriteriaObsCountWarningHide}
                    onSubmit={onDatasetSubmit}
                    defaultLastNPeriods={defaultLastNPeriods}
                    preserveFiltersWithDynamic
                    missingFilterValues={missingFilterValues}
                    fetchCodelistFull={fetchCodelistFull}
                    onCodelistFullHide={onCodelistFullHide}
                  />
                ) : (
                  <span />
                )}
              </Call>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {step === 0 && (
            <Fragment>
              <Button onClick={onClose}>{t("commons.confirm.cancel")}</Button>
              <Button autoFocus color="primary" onClick={goToStepFilters} disabled={!additionalDataset?.datasetId}>
                {t("commons.confirm.next")}
              </Button>
            </Fragment>
          )}
          {step === 1 && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button color="primary" onClick={goToStepData}>
                  {t("commons.confirm.back")}
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={onClose}>{t("commons.confirm.cancel")}</Button>
                <Button autoFocus color="primary" onClick={onDatasetSubmit} disabled={!isCriteriaValid}>
                  {t("commons.confirm.apply")}
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </FullscreenDialog>
    </Call>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(AdditionalDatasetCreateDialog);

import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomDialogTitle from "../../../custom-dialog-title";
import CustomMaterialTable from "../../../custom-material-table";
import {
  clearDataflowCache,
  createDataflowCache,
  deleteAllArtefactsCache,
  deleteAllDataflowCache,
  deleteArtefactCache,
  deleteCatalogCache,
  deleteDataflowCache,
  fetchDataflowCache,
  updateDataflowCache
} from "../../../../state/cache/cacheActions";
import {secondsToHms} from "../../../../utils/formatters";
import {isValidIntegerInInclusiveRange} from "../../../../utils/validator";

const DELETE_TYPE_CATALOG = "catalog";
const DELETE_TYPE_DATAFLOWS = "dataflows";
const DELETE_TYPE_ARTEFACTS = "artefacts";
const DELETE_TYPE_ARTEFACT = "artefact";

const styles = theme => ({
  paper: {
    padding: 24,
    marginBottom: 16
  },
  checkbox: {
    paddingLeft: 8,
    marginTop: 16
  },
  buttonAction: {
    ...theme.typography.button
  }
});

const mapStateToProps = state => ({
  config: state.cacheConfig
});

const mapDispatchToProps = dispatch => ({
  fetchCache: nodeId => dispatch(fetchDataflowCache(nodeId)),
  clearCache: () => dispatch(clearDataflowCache()),
  updateCache: (nodeId, cacheId, ttl) => dispatch(updateDataflowCache(nodeId, cacheId, ttl)),
  createCache: (nodeId, data) => dispatch(createDataflowCache(nodeId, data)),
  deleteFromCache: (nodeId, dataflowId) => dispatch(deleteDataflowCache(nodeId, dataflowId)),
  deleteAllFromCache: nodeId => dispatch(deleteAllDataflowCache(nodeId)),
  deleteCatalogCache: nodeId => dispatch(deleteCatalogCache(nodeId)),
  deleteArtefactCacheAll: (nodeId, preserveDataflows) => dispatch(deleteAllArtefactsCache(nodeId, preserveDataflows)),
  deleteArtefactCache: (nodeId, dataflowId) =>
    dispatch(deleteArtefactCache(nodeId, "Dataflow", dataflowId, "Descendants"))
});

const CacheSettingsForm = (
  {
    classes,
    config,
    nodeId,
    fetchCache,
    createCache,
    updateCache,
    clearCache,
    deleteFromCache,
    deleteAllFromCache,
    deleteCatalogCache,
    deleteArtefactCacheAll,
    deleteArtefactCache
  },
  ref
) => {
  const {t} = useTranslation();

  const [needConfig, setNeedConfig] = useState(nodeId !== null && (!config || config.length === 0));
  const [hideEmptyDataflowCache, setEmptyDataflowCacheHidden] = useState(true);
  const [emptyRowsCounter, setEmptyRowsCounter] = useState(0);
  const [rowsCounter, setRowsCounter] = useState(0);
  const [isDeleteConfirmVisible, setDeleteConfirmVisibility] = useState(false);
  const [deleteType, setDeleteType] = useState(null);
  const [dataflowId, setDataflowId] = useState(null);

  useEffect(() => {
    if (!config || config.length === 0) {
      setRowsCounter(0);
      setEmptyRowsCounter(0);
      return;
    }
    setRowsCounter(config.length);
    setEmptyRowsCounter(config.filter(val => val?.cacheSize === 0).length);
  }, [config]);

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchCache(nodeId);
    }
  }, [config, fetchCache, nodeId, needConfig, setNeedConfig]);

  useImperativeHandle(ref, () => ({
    submit(f) {
      f();
    },
    cancel(f) {
      clearCache();
      f();
    }
  }));

  const handleDeleteConfirmOpen = (type, dataflowId) => {
    setDeleteType(type);
    setDataflowId(dataflowId);
    setDeleteConfirmVisibility(true);
  };

  const handleDeleteConfirmClose = () => {
    setDeleteType(null);
    setDataflowId(null);
    setDeleteConfirmVisibility(false);
  };

  const handleDeleteConfirmSubmit = () => {
    switch (deleteType) {
      case DELETE_TYPE_CATALOG:
        deleteCatalogCache(nodeId);
        break;
      case DELETE_TYPE_DATAFLOWS:
        deleteAllFromCache(nodeId);
        break;
      case DELETE_TYPE_ARTEFACTS:
        deleteArtefactCacheAll(nodeId, true);
        break;
      case DELETE_TYPE_ARTEFACT:
        deleteArtefactCache(nodeId, dataflowId);
        break;
      default:
        break;
    }
    handleDeleteConfirmClose();
  };

  const getDeleteConfirmDialogContent = () => {
    switch (deleteType) {
      case DELETE_TYPE_CATALOG:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.catalog.content");
      case DELETE_TYPE_DATAFLOWS:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.dataflows.content");
      case DELETE_TYPE_ARTEFACTS:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.structures.content");
      case DELETE_TYPE_ARTEFACT:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.artefact.content");
      default:
        return "";
    }
  };

  const getDeleteConfirmDialogTitle = () => {
    switch (deleteType) {
      case DELETE_TYPE_CATALOG:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.catalog.title");
      case DELETE_TYPE_DATAFLOWS:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.dataflows.title");
      case DELETE_TYPE_ARTEFACTS:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.structures.title");
      case DELETE_TYPE_ARTEFACT:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.artefact.title");
      default:
        return "";
    }
  };

  const tableRef = useRef();

  return (
    <Fragment>
      <Paper variant="outlined" className={classes.paper}>
        <FormControl id="delete-catalog-cache-btn">
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => handleDeleteConfirmOpen(DELETE_TYPE_CATALOG)}
          >
            {t("scenes.nodeSettings.cacheSettings.deleteCatalogCache")}
          </Button>
        </FormControl>
      </Paper>
      <Paper variant="outlined" className={classes.paper}>
        <Grid container>
          <Grid item xs={12} container spacing={2}>
            <Grid item>
              <FormControl id="delete-dataflows-cache-btn">
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => handleDeleteConfirmOpen(DELETE_TYPE_DATAFLOWS)}
                >
                  {t("scenes.nodeSettings.cacheSettings.deleteAllDataflowCache")}
                </Button>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl id="delete-structures-cache-btn">
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => handleDeleteConfirmOpen(DELETE_TYPE_ARTEFACTS)}
                >
                  {t("scenes.nodeSettings.cacheSettings.deleteAllStructuresCache")}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.checkbox}>
              <FormControlLabel
                label={t("scenes.nodeSettings.cacheSettings.hideEmptyEntries")}
                control={
                  <Checkbox
                    name="active"
                    required
                    disabled={emptyRowsCounter === 0 || rowsCounter === 0}
                    checked={hideEmptyDataflowCache}
                    onChange={(e, value) => setEmptyDataflowCacheHidden(value)}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <CustomMaterialTable
              ref={tableRef}
              columns={[
                {
                  title: t("scenes.nodeSettings.cacheSettings.table.dataflowTitle"),
                  field: "title",
                  editable: "never"
                },
                {
                  title: t("scenes.nodeSettings.cacheSettings.table.dataflowValidity"),
                  field: "ttl",
                  editable: "onUpdate",
                  render: rowData => secondsToHms(rowData.ttl, t)
                },
                {
                  title: t("scenes.nodeSettings.cacheSettings.table.dataflowFiles"),
                  field: "cachedDataflow",
                  type: "numeric",
                  editable: "never"
                },
                {
                  title: t("scenes.nodeSettings.cacheSettings.table.dataflowSize"),
                  field: "cacheSize",
                  type: "numeric",
                  editable: "never",
                  render: rowData => rowData.cacheSize + " Kb"
                },
                {
                  title: t("scenes.nodeSettings.cacheSettings.table.dataflowAccess"),
                  field: "cachedDataAccess",
                  type: "numeric",
                  editable: "never"
                },
                {
                  render: rowData => (
                    <Button
                      color="primary"
                      onClick={() => {
                        const datasetId = (rowData?.dataflowId || "").split("+").join(",");
                        handleDeleteConfirmOpen(DELETE_TYPE_ARTEFACT, datasetId);
                      }}
                      disabled={tableRef?.current?.state?.lastEditingRow !== undefined}
                    >
                      {t("scenes.nodeSettings.cacheSettings.table.actions.deleteOnlyStructure")}
                    </Button>
                  ),
                  editable: "never"
                }
              ]}
              data={config?.filter(val => !hideEmptyDataflowCache || val.cacheSize > 0) || []}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    if ((newData.ttl || "").length > 0 && !isValidIntegerInInclusiveRange(newData.ttl, -1)) {
                      // invalid ttl
                      window.error.show(t("scenes.nodeSettings.cacheSettings.invalidTtl.label"));
                      reject();
                      return;
                    }

                    if (newData.ttl === oldData.ttl) {
                      // no update is needed here
                      resolve();
                      return;
                    }

                    const dataUpdate = [...config];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;

                    if (!newData.id || newData.id === "00000000-0000-0000-0000-000000000000") {
                      createCache(nodeId, newData);
                    } else {
                      updateCache(nodeId, newData.id, newData.ttl);
                    }

                    resolve();
                  }),
                onRowDelete: dataflow =>
                  new Promise((resolve, reject) => {
                    deleteFromCache(nodeId, dataflow.dataflowId);
                    resolve();
                  })
              }}
              options={{
                actionsColumnIndex: 5
              }}
              localization={{
                body: {
                  editRow: {
                    deleteText: t("scenes.nodesSettings.cacheSettings.modals.deleteDataflowCache.title")
                  },
                  editTooltip: t("scenes.nodeSettings.cacheSettings.table.actions.editRow"),
                  deleteTooltip: t("scenes.nodeSettings.cacheSettings.table.actions.deleteRow")
                }
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Dialog fullWidth open={isDeleteConfirmVisible} onClose={handleDeleteConfirmClose}>
        <CustomDialogTitle onClose={handleDeleteConfirmClose}>{getDeleteConfirmDialogTitle()}</CustomDialogTitle>
        <DialogContent>{getDeleteConfirmDialogContent()}</DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmClose} color="primary">
            {t("commons.confirm.cancel")}
          </Button>
          <Button onClick={handleDeleteConfirmSubmit}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(CacheSettingsForm);

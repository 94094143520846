import React, {Fragment} from "react";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import Call from "../hocs/call";
import {goToHome} from "../links";
import {fetchCatalog} from "../state/catalog/catalogActions";
import {fetchNode} from "../state/node/nodeActions";
import {showUserLoginForm} from "../state/user/userActions";
import {getIsUserAuthenticated} from "../utils/user";

const mapStateToProps = state => ({
  hub: state.hub,
  node: state.node,
  catalog: state.catalog,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  fetchNode: ({nodeId, nodeCode}) => dispatch(fetchNode(nodeId, nodeCode)),
  fetchCatalog: ({nodeId, nodeCode}) => dispatch(fetchCatalog(nodeId, nodeCode)),
  onLoginShow: () => dispatch(showUserLoginForm("NotAccessibleNode", "warning", true))
});

const NodeDomain = ({
  nodeCode: externalNodeCode,
  hub,
  node,
  catalog,
  user,
  fetchNode,
  fetchCatalog,
  onLoginShow,
  children
}) => {
  const {nodeCode: urlNodeCode} = useParams();

  const nodeCode = externalNodeCode ? externalNodeCode : urlNodeCode;

  const nodeHavingCode = hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase()) || null;

  const isFetchNodeEnabled = !node || node.code.toLowerCase() !== nodeCode.toLowerCase();

  return (
    <Fragment>
      <Call
        cb={() => {
          if (!getIsUserAuthenticated(user)) {
            onLoginShow();
          } else {
            goToHome();
          }
        }}
        cbParam={hub}
        disabled={nodeHavingCode !== null}
      >
        {nodeHavingCode !== null && (
          <Call
            cb={fetchNode}
            cbParam={{nodeId: nodeHavingCode.nodeId, nodeCode: nodeCode}}
            disabled={!nodeCode || !isFetchNodeEnabled || node !== null}
          >
            <Call
              cb={fetchCatalog}
              cbParam={{nodeId: nodeHavingCode.nodeId, nodeCode: nodeCode}}
              disabled={!nodeCode || isFetchNodeEnabled || catalog !== null}
            >
              <div id={`node__${nodeCode}`} style={{width: "100%", height: "100%"}}>
                <Fragment key={nodeCode}>{children}</Fragment>
              </div>
            </Call>
          </Call>
        )}
      </Call>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeDomain);

import {ReducersMapObject} from "redux";
import appReducer from "./app/appReducer";
import appConfigReducer from "./appConfig/appConfigReducer";
import cacheReducer from "./cache/cacheReducer";
import catalogReducer from "./catalog/catalogReducer";
import configReducer from "./config/configReducer";
import dashboardReducer from "./dashboard/dashboardReducer";
import dashboardConfigReducer from "./dashboardConfig/dashboardConfigReducer";
import datasetReducer from "./dataset/datasetReducer";
import deleteConflictModalReducer from "./delete-conflict-modal/deleteConflictModalReducer";
import detailLevelReducer from "./detailLevel/detailLevelReducer";
import hubReducer from "./hub/hubReducer";
import hubConfigReducer from "./hubConfig/hubConfigReducer";
import mapsReducer from "./maps/mapsReducer";
import miscellaneousReducer from "./miscellaneous/miscellaneousReducer";
import nodeReducer from "./node/nodeReducer";
import nodePermissionsConfigReducer from "./nodePermissionsConfig/nodePermissionsConfigReducer";
import nodesConfigReducer from "./nodesConfig/nodesConfigReducer";
import nodeTemplatesConfigReducer from "./noteTemplatesConfig/nodeTemplatesConfigReducer";
import otherConfigReducer from "./otherConfig/otherConfigReducer";
import pendingRequestReducer from "./pending-request/pendingRequestReducer";
import searchDialogReducer from "./search-dialog/searchDialogReducer";
import spinnerReducer from "./spinner/spinnerReducer";
import userReducer from "./user/userReducer";
import usersConfigReducer from "./usersConfig/usersConfigReducer";

const rootReducer: ReducersMapObject = {
  pendingRequest: pendingRequestReducer,
  spinner: spinnerReducer,
  appConfig: appConfigReducer,
  config: configReducer,
  dashboardFilterConfig: dashboardConfigReducer,
  app: appReducer,
  hub: hubReducer,
  node: nodeReducer,
  nodeTemplatesConfig: nodeTemplatesConfigReducer,
  catalog: catalogReducer,
  dataset: datasetReducer,
  hubConfig: hubConfigReducer,
  nodesConfig: nodesConfigReducer,
  cacheConfig: cacheReducer,
  otherConfig: otherConfigReducer,
  user: userReducer,
  usersConfig: usersConfigReducer,
  dashboard: dashboardReducer,
  nodePermissionsConfig: nodePermissionsConfigReducer,
  deleteConflictModal: deleteConflictModalReducer,
  maps: mapsReducer,
  detailLevel: detailLevelReducer,
  searchDialog: searchDialogReducer,
  miscellaneous: miscellaneousReducer
};

export default rootReducer;

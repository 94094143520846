import React, {Fragment, useCallback, useState} from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import BarChartIcon from "@material-ui/icons/BarChart";
import CheckIcon from "@material-ui/icons/Check";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import AdditionalDatasetCreateDialog from "../../additional-datasets-dialogs/create";
import AdditionalDatasetListDialog from "../../additional-datasets-dialogs/list";
import Criteria from "../../criteria";
import CustomDialogTitle from "../../custom-dialog-title";
import CalculateIcon from "../../custom-icons/CalculateIcon";
import FileDownloadIcon from "../../custom-icons/FileDownloadIcon";
import FilterAltIcon from "../../custom-icons/FIlterAltIcon";
import FlagIcon from "../../custom-icons/FlagIcon";
import ExportButton from "../../export-button";
import FullscreenDialog from "../../fullscreen-dialog";
import IndicatorCreateDialog from "../../indicator-dialogs/create";
import IndicatorListDialog from "../../indicator-dialogs/list";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
} from "../../label-format-selector/constants";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../../temporal-dim-order-selector/constants";
import DetailLevelSelector from "../../territory-selectors/DetailLevelSelector";
import TerritoriesSelector from "../../territory-selectors/TerritorySelector";
import {ViewerMode} from "../../../state/dataset/constants";
import {
  enableDatasetMVDatasetFetch,
  fetchDatasetMVAdditionalDatasetCatalog,
  fetchDatasetMVStructureCodelist,
  fetchDatasetMVStructureCodelistFull,
  hideDatasetMVCriteria,
  hideDatasetMVCriteriaAlert,
  hideDatasetMVCriteriaObsCountWarning,
  hideDatasetMVStructureCodelistFull,
  hideDatasetMVTerritory,
  setDatasetMVAdditionalDatasetCreateVisibility,
  setDatasetMVAdditionalDatasetCriteria,
  setDatasetMVAdditionalDatasetListVisibility,
  setDatasetMVIndicatorArithmeticMeanVisibility,
  setDatasetMVIndicatorCoefficientOfVariationVisibility,
  setDatasetMVIndicatorCreateVisibility,
  setDatasetMVIndicatorListVisibility,
  setDatasetMVIndicatorStandardDeviationVisibility,
  setDatasetMVStructureCriteria,
  setDatasetMVTerritoryDetailLevel,
  setDatasetMVTerritoryTerritories,
  setDatasetMVViewerChartVisibility,
  setDatasetMVViewerMapVisibility,
  setDatasetMVViewerTableVisibility,
  showDatasetMVCriteria,
  showDatasetMVTerritory,
  submitDatasetMVDownload,
  submitDatasetMVDownloadMetadata,
  submitDatasetMVTerritoryTerritories
} from "../../../state/dataset/multi-viewer/actions";
import {isFormatAvailableForMergedData} from "../../../utils/download";
import {getFormattedValue} from "../../../utils/formatters";
import {getMappedTree, getNode, getNodes} from "../../../utils/tree";

const styles = theme => ({
  root: {
    width: 80,
    height: "100%",
    overflow: "auto",
    padding: "8px 0",
    marginRight: 16,
    "& .MuiBottomNavigation-root": {
      height: "unset !important"
    },
    "& .MuiBottomNavigation-root button": {
      color: theme.palette.primary.main
    },
    "& .MuiBottomNavigationAction-label": {
      fontSize: "13px !important"
    }
  },
  divider: {
    margin: "4px 0"
  },
  actionGroup: {
    marginBottom: 8,
    "& button:hover": {
      background: "rgba(0, 0, 0, 0.1)",
      color: `${theme.palette.primary.main} !important`,
      "& svg": {
        color: theme.palette.primary.main
      }
    }
  },
  actionGroupBackground: {
    "& button": {
      background: "rgba(0, 0, 0, 0.07)"
    }
  },
  actionDisabled: {
    color: "rgba(0, 0, 0, 0.54) !important",
    "& svg": {
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  criteriaContent: {
    height: "100%"
  },
  datasetSelector: {
    marginBottom: 8
  },
  territoryContent: {
    height: "100%"
  },
  steps: {},
  stepContent: {
    height: "calc(100% - 108px)",
    overflow: "hidden"
  },
  stepContentFullHeight: {
    height: "100%"
  },
  menuItemTitle: {
    width: "100%",
    color: "gray",
    fontStyle: "italic",
    fontSize: 14
  }
});

const mapStateToProps = ({config, appConfig, app, hub, node, dataset}) => ({
  indicatorsBaseUrl: config.externalServices?.indicator,
  shapefilesUrl: appConfig.shapefilesUrl,
  maxTableColCount: appConfig.tableConfig.maxColCount,
  maxMapPolygonCount: appConfig.mapConfig.maxPolygonCount,
  maxMapPointCount: appConfig.mapConfig.maxPointCount,
  hiddenDimensionValueLabels: appConfig.hiddenDimensionValueLabels,
  defaultLanguage: app.language,
  languages: app.languages,
  exportConfig: hub.hub.exportConfig,
  maxObservations: hub.hub.maxObservationsAfterCriteria || Number.MAX_SAFE_INTEGER,
  nodeDecimalSeparator: node.decimalSeparator,
  mode: dataset.commons.mode,
  type: dataset.commons.type,
  dataset: dataset.multiViewer.dataset,
  isEmptyData: dataset.multiViewer.isEmptyData,
  isTooBigData: dataset.multiViewer.isTooBigData,
  isPointData: dataset.multiViewer.isPointData,
  isTooLongQuery: dataset.multiViewer.isTooLongQuery,
  tableColCount: dataset.multiViewer.tableColCount,
  mapPointCount: dataset.multiViewer.mapPointCount,
  isCriteriaVisible: dataset.multiViewer.isCriteriaVisible,
  isCriteriaAlertVisible: dataset.multiViewer.isCriteriaAlertVisible,
  isObsCountWarningVisible: dataset.multiViewer.isObsCountWarningVisible,
  dimensions: dataset.multiViewer.dimensions,
  territoryDim: dataset.multiViewer.territoryDim,
  timeDim: dataset.multiViewer.timeDim,
  freqDim: dataset.multiViewer.freqDim,
  isTableVisible: dataset.multiViewer.isTableVisible,
  isMapVisible: dataset.multiViewer.isMapVisible,
  isChartVisible: dataset.multiViewer.isChartVisible,
  tableLayout: dataset.multiViewer.tableLayout,
  mapLayout: dataset.multiViewer.mapLayout,
  chartLayout: dataset.multiViewer.chartLayout,
  labelFormat: dataset.multiViewer.labelFormat,
  criteria: dataset.multiViewer.criteria,
  decimalSeparator: dataset.multiViewer.decimalSeparator,
  decimalPlaces: dataset.multiViewer.decimalPlaces,
  tableEmptyChar: dataset.multiViewer.tableEmptyChar,
  chartSettings: dataset.multiViewer.chartSettings,
  mapSettings: dataset.multiViewer.mapSettings,
  codelists: dataset.multiViewer.codelists,
  codelistsLength: dataset.multiViewer.codelistsLength,
  codelistFetchError: dataset.multiViewer.codelistFetchError,
  isTerritoryVisible: dataset.multiViewer.isTerritoryVisible,
  detailLevelTree: dataset.multiViewer.detailLevelTree,
  detailLevel: dataset.multiViewer.detailLevel,
  territoryTree: dataset.multiViewer.territoryTree,
  territories: dataset.multiViewer.territories,
  isIndicatorCreateVisible: dataset.multiViewer.isIndicatorCreateVisible,
  isIndicatorListVisible: dataset.multiViewer.isIndicatorListVisible,
  indicators: dataset.multiViewer.indicators,
  showArithmeticMean: dataset.multiViewer.showArithmeticMean,
  showStandardDeviation: dataset.multiViewer.showStandardDeviation,
  showCoefficientOfVariation: dataset.multiViewer.showCoefficientOfVariation,
  isAdditionalDatasetCreateVisible: dataset.multiViewer.isAdditionalDatasetCreateVisible,
  isAdditionalDatasetListVisible: dataset.multiViewer.isAdditionalDatasetListVisible,
  additionalDatasets: dataset.multiViewer.additionalDatasets,
  additionalDatasetCatalog: dataset.multiViewer.additionalDatasetCatalog,
  missingFilterValues: dataset.multiViewer.missingFilterValues
});

const mapDispatchToProps = dispatch => ({
  onFetchDatasetEnable: maxObservations => dispatch(enableDatasetMVDatasetFetch(maxObservations)),
  onCriteriaShow: () => dispatch(showDatasetMVCriteria()),
  onCriteriaHide: () => dispatch(hideDatasetMVCriteria()),
  onSetCriteria: criteria => dispatch(setDatasetMVStructureCriteria(criteria)),
  onSetADCriteria: (datasetIdx, criteria) => dispatch(setDatasetMVAdditionalDatasetCriteria(datasetIdx, criteria)),
  onCriteriaAlertHide: () => dispatch(hideDatasetMVCriteriaAlert()),
  fetchCodelist: (
    nodeId,
    nodeCode,
    datasetId,
    mode,
    type,
    dimensionId,
    criteria,
    freq,
    defaultLastNPeriods,
    preserveFiltersWithDynamic
  ) =>
    dispatch(
      fetchDatasetMVStructureCodelist(
        nodeId,
        nodeCode,
        datasetId,
        mode,
        type,
        dimensionId,
        criteria,
        freq,
        defaultLastNPeriods,
        preserveFiltersWithDynamic
      )
    ),
  onCriteriaObsCountWarningHide: () => dispatch(hideDatasetMVCriteriaObsCountWarning()),
  fetchCodelistFull: (nodeId, datasetId, dimensionId, missingFilterValueIds) =>
    dispatch(fetchDatasetMVStructureCodelistFull(nodeId, datasetId, dimensionId, missingFilterValueIds)),
  onCodelistFullHide: () => dispatch(hideDatasetMVStructureCodelistFull()),
  onTerritoryShow: () => dispatch(showDatasetMVTerritory()),
  onTerritoryHide: (detailLevel, territories) => dispatch(hideDatasetMVTerritory(detailLevel, territories)),
  onTableVisibilitySet: isVisible => dispatch(setDatasetMVViewerTableVisibility(isVisible)),
  onMapVisibilitySet: isVisible => dispatch(setDatasetMVViewerMapVisibility(isVisible)),
  onChartVisibilitySet: isVisible => dispatch(setDatasetMVViewerChartVisibility(isVisible)),
  onDetailLevelSet: detailLevel => dispatch(setDatasetMVTerritoryDetailLevel(detailLevel)),
  onTerritoriesSet: territories => dispatch(setDatasetMVTerritoryTerritories(territories)),
  onTerritoriesSubmit: criteria => dispatch(submitDatasetMVTerritoryTerritories(criteria)),
  onDownloadSubmit: (
    nodeId,
    nodeCode,
    datasetId,
    datasetTitle,
    criteria,
    timeDim,
    territoryDim,
    layout,
    indicators,
    additionalDatasets,
    format,
    extension,
    zipped,
    params,
    defaultLanguage,
    languages,
    t,
    indicatorsBaseUrl
  ) =>
    dispatch(
      submitDatasetMVDownload(
        nodeId,
        nodeCode,
        datasetId,
        datasetTitle,
        criteria,
        timeDim,
        territoryDim,
        layout,
        indicators,
        additionalDatasets,
        format,
        extension,
        zipped,
        params,
        defaultLanguage,
        languages,
        t,
        indicatorsBaseUrl
      )
    ),
  onIndicatorCreateVisibilitySet: isVisible => dispatch(setDatasetMVIndicatorCreateVisibility(isVisible)),
  onIndicatorListVisibilitySet: isVisible => dispatch(setDatasetMVIndicatorListVisibility(isVisible)),
  onArithmeticMeanVisibilitySet: isVisible => dispatch(setDatasetMVIndicatorArithmeticMeanVisibility(isVisible)),
  onStandardDeviationVisibilitySet: isVisible => dispatch(setDatasetMVIndicatorStandardDeviationVisibility(isVisible)),
  onCoefficientOfVariationVisibilitySet: isVisible =>
    dispatch(setDatasetMVIndicatorCoefficientOfVariationVisibility(isVisible)),
  onAdditionalDatasetCreateVisibilitySet: isVisible =>
    dispatch(setDatasetMVAdditionalDatasetCreateVisibility(isVisible)),
  onAdditionalDatasetListVisibilitySet: isVisible => dispatch(setDatasetMVAdditionalDatasetListVisibility(isVisible)),
  fetchAdditionalDatasetCatalog: ({selectedNodeId, mainDatasetId}) =>
    dispatch(fetchDatasetMVAdditionalDatasetCatalog(selectedNodeId, mainDatasetId)),
  onDownloadReferenceMetadataSubmit: (nodeId, datasetId, datasetTitle) =>
    dispatch(submitDatasetMVDownloadMetadata(nodeId, datasetId, datasetTitle))
});

function MultiViewerSidebar(props) {
  const {
    classes,

    nodeId,
    nodeCode,
    nodeExtras,
    datasetId,
    datasetTitle,
    chartId,
    mapId,
    datasetMap,

    indicatorsBaseUrl,
    shapefilesUrl,
    maxTableColCount,
    maxMapPolygonCount,
    maxMapPointCount,
    hiddenDimensionValueLabels,
    defaultLanguage,
    languages,
    exportConfig,
    maxObservations,
    nodeDecimalSeparator,
    mode,
    type,
    dataset,
    isEmptyData,
    isTooBigData,
    isPointData,
    isTooLongQuery,
    tableColCount,
    mapPointCount,
    isCriteriaVisible,
    isCriteriaAlertVisible,
    isObsCountWarningVisible,
    dimensions,
    territoryDim,
    timeDim,
    freqDim,
    isTableVisible,
    isMapVisible,
    isChartVisible,
    tableLayout,
    mapLayout,
    chartLayout,
    labelFormat,
    criteria,
    decimalSeparator,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    codelists,
    codelistsLength,
    codelistFetchError,
    isTerritoryVisible,
    detailLevelTree,
    detailLevel,
    territoryTree,
    territories,
    isIndicatorCreateVisible,
    isIndicatorListVisible,
    indicators,
    showArithmeticMean,
    showStandardDeviation,
    showCoefficientOfVariation,
    isAdditionalDatasetCreateVisible,
    isAdditionalDatasetListVisible,
    additionalDatasets,
    additionalDatasetCatalog,
    missingFilterValues,

    onFetchDatasetEnable,
    onCriteriaShow,
    onCriteriaHide,
    onSetCriteria,
    onSetADCriteria,
    onCriteriaAlertHide,
    fetchCodelist,
    onCriteriaObsCountWarningHide,
    fetchCodelistFull,
    onCodelistFullHide,
    onTerritoryShow,
    onTerritoryHide,
    onTableVisibilitySet,
    onMapVisibilitySet,
    onChartVisibilitySet,
    onDetailLevelSet,
    onTerritoriesSet,
    onTerritoriesSubmit,
    onDownloadSubmit,
    onIndicatorCreateVisibilitySet,
    onIndicatorListVisibilitySet,
    onArithmeticMeanVisibilitySet,
    onStandardDeviationVisibilitySet,
    onCoefficientOfVariationVisibilitySet,
    onAdditionalDatasetCreateVisibilitySet,
    onAdditionalDatasetListVisibilitySet,
    fetchAdditionalDatasetCatalog,
    onDownloadReferenceMetadataSubmit
  } = props;

  const {t} = useTranslation();

  const [datasetIdx, setDatasetIdx] = useState(0);

  const [isCriteriaValid, setCriteriaValidity] = useState(true);

  const [tmpDetailLevel, setTmpDetailLevel] = useState(null);
  const [tmpTerritories, setTmpTerritories] = useState(null);

  const [step, setStep] = useState(isPointData ? 1 : 0);

  const [isTerritoryWarningVisible, setTerritoryWarningVisibility] = useState(false);

  const [indicatorsAnchorEl, setIndicatorsAnchorEl] = useState(null);
  const [additionalDatasetsAnchorEl, setAdditionalDatasetsAnchorEl] = useState(null);

  const [datasetToExportIdx, setDatasetToExportIdx] = useState(null);

  const getDimensionsCombinationCount = useCallback(
    dimensions =>
      dataset && dimensions ? dimensions.reduce((acc, dim) => acc * dataset.size[dataset.id.indexOf(dim)], 1) : 0,
    [dataset]
  );

  const downloadFormats =
    additionalDatasets.length === 0
      ? nodeExtras?.DownloadFormats
      : nodeExtras?.DownloadFormats.filter(format => isFormatAvailableForMergedData(format));

  const defaultLastNPeriods = nodeExtras?.DefaultLastNPeriods;

  const handleCriteriaOpen = () => {
    setDatasetIdx(0);
    onCriteriaShow();
  };

  const handleCriteriaClose = () => {
    onCriteriaHide();
    setCriteriaValidity(true);
  };

  const handleCriteriaSubmit = maxObservations => {
    onFetchDatasetEnable(maxObservations);
  };

  const handleTerritoryOpen = () => {
    setStep(isPointData ? 1 : 0);

    setTmpDetailLevel(detailLevel);
    setTmpTerritories(territories);

    onTerritoryShow();
  };

  const handleTerritoryClose = () => {
    onTerritoryHide(tmpDetailLevel, tmpTerritories);
  };

  const handleTerritorySubmit = () => {
    const markedTree = getMappedTree([{children: territoryTree}], "children", node => ({
      ...node,
      children: (node.children || []).map(child => ({
        ...child,
        marked: node.marked || territories.includes(child.id)
      }))
    }))[0].children;
    const territoryFilterValues = getNodes(
      markedTree,
      "children",
      ({marked, children}) => marked && (children || []).length === 0
    );

    if (territoryFilterValues.length < maxMapPolygonCount) {
      let newCriteria = {
        ...criteria,
        [territoryDim]: {
          id: territoryDim,
          filterValues: territoryFilterValues.map(({id}) => id)
        }
      };
      onTerritoriesSubmit(newCriteria);
    } else {
      setTerritoryWarningVisibility(true);
    }
  };

  const visibleViewerCount = [isTableVisible, isChartVisible, isMapVisible].filter(el => el).length;
  const isTableDisabled = !dataset || !tableLayout;
  const isChartDisabled = !dataset || !chartLayout;
  const isMapDisabled = !dataset || !mapLayout;

  const datasets = [
    {
      nodeId: nodeId,
      nodeCode: nodeCode,
      datasetId: datasetId,
      datasetTitle: datasetTitle,
      dimensions: dimensions,
      timeDim: timeDim,
      freqDim: freqDim,
      territoryDim: territoryDim,
      codelists: codelists,
      codelistsLength: codelistsLength,
      criteria: criteria
    },
    ...additionalDatasets
  ];

  const datasetsWithMetadata = datasets.filter(({datasetId}) => datasetMap[datasetId]?.referenceMetadata);

  return (
    <Card className={classes.root} role="menu">
      <div className={classes.actionGroup}>
        {territoryDim && (
          <BottomNavigation showLabels onChange={handleTerritoryOpen}>
            <BottomNavigationAction
              label={t("scenes.dataViewer.multiViewer.sidebar.territory")}
              icon={<FlagIcon />}
              role="menuitem"
            />
          </BottomNavigation>
        )}
        <BottomNavigation showLabels onChange={handleCriteriaOpen}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.criteria")}
            icon={<FilterAltIcon />}
            className={(territories || []).length === 0 && !isPointData ? classes.actionDisabled : ""}
            disabled={(territories || []).length === 0 && !isPointData}
            role="menuitem"
          />
        </BottomNavigation>
      </div>

      <div className={`${classes.actionGroup} ${classes.actionGroupBackground}`}>
        <BottomNavigation
          showLabels
          value={isTableVisible ? 0 : null}
          onChange={() => onTableVisibilitySet(!isTableVisible)}
        >
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.table")}
            icon={<TableChartOutlinedIcon />}
            className={!isTableVisible ? classes.actionDisabled : ""}
            disabled={isTableDisabled || (isTableVisible && visibleViewerCount <= 1)}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation
          showLabels
          value={isChartVisible ? 0 : null}
          onChange={() => onChartVisibilitySet(!isChartVisible)}
        >
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.chart")}
            icon={<BarChartIcon />}
            className={!isChartVisible ? classes.actionDisabled : ""}
            disabled={isChartDisabled || (isChartVisible && visibleViewerCount <= 1)}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation showLabels value={isMapVisible ? 0 : null} onChange={() => onMapVisibilitySet(!isMapVisible)}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.map")}
            icon={<MapOutlinedIcon />}
            className={!isMapVisible ? classes.actionDisabled : ""}
            disabled={isMapDisabled || (isMapVisible && visibleViewerCount <= 1)}
            role="menuitem"
          />
        </BottomNavigation>
      </div>

      <div className={classes.actionGroup}>
        {!isPointData && (
          <BottomNavigation showLabels onChange={({currentTarget}) => setAdditionalDatasetsAnchorEl(currentTarget)}>
            <BottomNavigationAction
              label={t("scenes.dataViewer.multiViewer.sidebar.addData")}
              icon={<AddCircleOutlineOutlinedIcon />}
              className={!dataset && additionalDatasets.length === 0 ? classes.actionDisabled : ""}
              disabled={!dataset && additionalDatasets.length === 0}
              role="menuitem"
            />
          </BottomNavigation>
        )}
        <BottomNavigation showLabels onChange={({currentTarget}) => setIndicatorsAnchorEl(currentTarget)}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.calculateIndicator")}
            icon={<CalculateIcon />}
            className={!dataset && indicators.length === 0 ? classes.actionDisabled : ""}
            disabled={!dataset && indicators.length === 0}
            role="menuitem"
          />
        </BottomNavigation>
      </div>

      <div className={classes.actionGroup}>
        <ExportButton
          showAsBottomNavigation
          icon={<FileDownloadIcon />}
          formats={downloadFormats}
          jsonStat={dataset}
          viewerIdx={0}
          isTableVisible={isTableVisible}
          isMapVisible={isMapVisible}
          isChartVisible={isChartVisible}
          tableLayout={tableLayout}
          mapId={mapId}
          mapContainerId="data-viewer__viewers__map__viewer"
          mapLayout={mapLayout}
          mapSettings={mapSettings}
          chartId={chartId}
          chartContainerId="data-viewer__viewers__chart__viewer"
          chartLayout={chartLayout}
          chartSettings={chartSettings}
          labelFormat={labelFormat}
          datasetTitle={datasetTitle}
          getDimensionsCombinationCount={getDimensionsCombinationCount}
          submitDownload={(format, extension, additionalParams) => {
            const exportParams = {
              labelFormat: labelFormat,
              customLabelFormat: {
                [timeDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
                [territoryDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH
              },
              decimalNumber: decimalPlaces,
              decimalSeparator: decimalSeparator,
              emptyCellPlaceHolder: tableEmptyChar,
              hiddenDimensionValueLabels: hiddenDimensionValueLabels,
              temporalDimOrder: TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC,
              exportConfig: exportConfig,
              suffixToRemove: {
                [territoryDim]:
                  getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.detailLevelSuffix || ""
              },
              customDimensionLabels: {
                [territoryDim]: getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.label || null
              },
              ...additionalParams
            };

            onDownloadSubmit(
              nodeId,
              nodeCode,
              datasetId,
              datasetTitle,
              criteria,
              timeDim,
              territoryDim,
              tableLayout,
              indicators,
              additionalDatasets,
              format,
              extension,
              false,
              exportParams,
              defaultLanguage,
              languages,
              t,
              indicatorsBaseUrl
            );
          }}
          shapefilesUrl={shapefilesUrl}
          role="menuitem"
          submitMetadataDownload={
            additionalDatasets.length === 0
              ? datasetMap[datasetId]?.referenceMetadata
                ? () => onDownloadReferenceMetadataSubmit(nodeId, datasetId, datasetTitle)
                : null
              : datasetsWithMetadata.length > 0
              ? () => setDatasetToExportIdx(0)
              : null
          }
          className={!dataset ? classes.actionDisabled : ""}
          disabled={!dataset}
        />
      </div>

      <FullscreenDialog open={isCriteriaVisible === true} onClose={handleCriteriaClose}>
        <CustomDialogTitle onClose={handleCriteriaClose}>
          {t("scenes.dataViewer.multiViewer.dialogs.criteria.title")}
        </CustomDialogTitle>
        <DialogContent className={classes.criteriaContent}>
          {datasets.length > 1 && (
            <FormControl id="criteria__dataset-selector" className={classes.datasetSelector} fullWidth>
              <TextField
                select
                value={datasetIdx}
                onChange={ev => setDatasetIdx(ev.target.value)}
                variant="outlined"
                SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
              >
                {datasets.map(({datasetTitle}, idx) => (
                  <MenuItem key={idx} value={idx}>
                    {datasetTitle}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
          <div
            style={{
              height: `calc(100% - ${datasets.length > 1 ? 64 : 0}px)`
            }}
          >
            {datasets[datasetIdx] && (
              <Criteria
                key={`${datasets[datasetIdx].nodeCode}+${datasets[datasetIdx].datasetId}`}
                viewerMode={ViewerMode.MultiViewer}
                nodeId={datasets[datasetIdx].nodeId}
                nodeCode={datasets[datasetIdx].nodeCode}
                datasetId={datasets[datasetIdx].datasetId}
                dimensions={datasets[datasetIdx].dimensions}
                timeDim={datasets[datasetIdx].timeDim}
                freqDim={datasets[datasetIdx].freqDim}
                territoryDim={datasets[datasetIdx].territoryDim}
                hideTerritoryDim={!datasets[datasetIdx].isPointData}
                mode={mode}
                type={type}
                criteria={datasets[datasetIdx].criteria}
                territoryDimCriteria={datasetIdx > 0 && criteria?.[territoryDim] ? criteria[territoryDim] : null}
                onSetCriteria={criteria =>
                  datasetIdx === 0 ? onSetCriteria(criteria) : onSetADCriteria(datasetIdx - 1, criteria)
                }
                codelists={datasets[datasetIdx].codelists}
                codelistsLength={datasets[datasetIdx].codelistsLength}
                fetchCodelist={fetchCodelist}
                codelistFetchError={codelistFetchError}
                isCriteriaValid={isCriteriaValid}
                setCriteriaValidity={setCriteriaValidity}
                isObsCountWarningVisible={isObsCountWarningVisible}
                onCriteriaObsCountWarningHide={onCriteriaObsCountWarningHide}
                onSubmit={handleCriteriaSubmit}
                defaultLastNPeriods={defaultLastNPeriods}
                preserveFiltersWithDynamic
                missingFilterValues={missingFilterValues}
                fetchCodelistFull={fetchCodelistFull}
                onCodelistFullHide={onCodelistFullHide}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCriteriaClose}>{t("commons.confirm.cancel")}</Button>
          <Button
            autoFocus
            color="primary"
            onClick={() => handleCriteriaSubmit(maxObservations)}
            disabled={!isCriteriaValid}
          >
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </FullscreenDialog>

      <Dialog open={isCriteriaAlertVisible} fullWidth maxWidth="sm" onClose={onCriteriaAlertHide}>
        {(() => {
          if (isEmptyData) {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.emptyData.title")}
              </CustomDialogTitle>
            );
          } else if (isTooBigData) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooBigData.title")}
                </CustomDialogTitle>
                <DialogContent>{t("scenes.dataViewer.sidebar.warning.tooBigData.content")}</DialogContent>
              </Fragment>
            );
          } else if (isTooLongQuery) {
            return (
              <Fragment>
                <CustomDialogTitle onClose={onCriteriaAlertHide}>
                  {t("scenes.dataViewer.sidebar.warning.isTooLongQuery.title")}
                </CustomDialogTitle>
                <DialogContent>{t("scenes.dataViewer.sidebar.warning.isTooLongQuery.content")}</DialogContent>
              </Fragment>
            );
          } else if (tableColCount !== null) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooMuchCols.title")}
                </CustomDialogTitle>
                <DialogContent>
                  {t("scenes.dataViewer.sidebar.warning.tooMuchCols.content", {
                    count: getFormattedValue(tableColCount, nodeDecimalSeparator),
                    max: getFormattedValue(maxTableColCount, nodeDecimalSeparator)
                  })}
                </DialogContent>
              </Fragment>
            );
          } else if (mapPointCount !== null) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooMuchPoints.title")}
                </CustomDialogTitle>
                <DialogContent>
                  {t("scenes.dataViewer.sidebar.warning.tooMuchPoints.content", {
                    count: getFormattedValue(mapPointCount, nodeDecimalSeparator),
                    max: getFormattedValue(maxMapPointCount, nodeDecimalSeparator)
                  })}
                </DialogContent>
              </Fragment>
            );
          } else {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.genericError.title")}
              </CustomDialogTitle>
            );
          }
        })()}
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              onCriteriaAlertHide();
              if (!isIndicatorCreateVisible && !isAdditionalDatasetCreateVisible) {
                onCriteriaShow();
              }
            }}
          >
            {tableColCount || mapPointCount ? t("commons.confirm.confirm") : t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <FullscreenDialog open={isTerritoryVisible === true} onClose={handleTerritoryClose} maxWidth={1280}>
        <CustomDialogTitle onClose={handleTerritoryClose}>{datasetTitle}</CustomDialogTitle>
        <DialogContent className={classes.territoryContent}>
          {!isPointData && (
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <Stepper activeStep={step} nonLinear alternativeLabel className={classes.steps}>
                  <Step key={0}>
                    <StepButton onClick={() => setStep(0)}>
                      {t("components.territory.steps.detailLevel.title")}
                    </StepButton>
                  </Step>
                  <Step key={1}>
                    <StepButton onClick={() => setStep(1)} disabled={detailLevel === null}>
                      {t("components.territory.steps.territory.title")}
                    </StepButton>
                  </Step>
                </Stepper>
              </Grid>
            </Grid>
          )}
          <div key={step} className={`${classes.stepContent} ${isPointData && classes.stepContentFullHeight}`}>
            {step === 0 && (
              <DetailLevelSelector
                detailLevelTree={detailLevelTree}
                detailLevel={detailLevel}
                setDetailLevel={onDetailLevelSet}
                additionalDatasets={additionalDatasets}
              />
            )}
            {step === 1 && (
              <TerritoriesSelector
                nodeId={nodeId}
                datasetId={datasetId}
                territoryDim={territoryDim}
                detailLevelTree={detailLevelTree}
                detailLevel={detailLevel}
                territories={territories}
                setTerritories={onTerritoriesSet}
                isFetchEnabled={isTerritoryVisible}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {step === 0 && (
            <Fragment>
              <Button onClick={handleTerritoryClose}>{t("commons.confirm.cancel")}</Button>
              <Button autoFocus color="primary" onClick={() => setStep(1)} disabled={detailLevel === null}>
                {t("commons.confirm.next")}
              </Button>
            </Fragment>
          )}
          {step === 1 && (
            <Grid container justifyContent="space-between">
              <Grid item>
                {!isPointData && (
                  <Button color="primary" onClick={() => setStep(0)}>
                    {t("commons.confirm.back")}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button onClick={handleTerritoryClose}>{t("commons.confirm.cancel")}</Button>
                <Button
                  autoFocus
                  color="primary"
                  onClick={handleTerritorySubmit}
                  disabled={(territories || []).length === 0}
                >
                  {t("commons.confirm.apply")}
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </FullscreenDialog>

      <Dialog open={datasetToExportIdx !== null} maxWidth="sm" fullWidth onClose={() => setDatasetToExportIdx(null)}>
        <CustomDialogTitle onClose={() => setDatasetToExportIdx(null)}>
          {t("scenes.dataViewer.multiViewer.dialogs.exportMetadata.title")}
        </CustomDialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              select
              value={datasetToExportIdx !== null ? datasetToExportIdx : ""}
              onChange={ev => setDatasetToExportIdx(ev.target.value)}
              variant="outlined"
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {datasetsWithMetadata.map(({datasetTitle}, idx) => (
                <MenuItem key={idx} value={idx}>
                  {datasetTitle}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDatasetToExportIdx(null)}>{t("commons.confirm.close")}</Button>
          <Button
            onClick={() => {
              const dataToExport = datasetsWithMetadata[datasetToExportIdx];
              onDownloadReferenceMetadataSubmit(dataToExport.nodeId, dataToExport.datasetId, dataToExport.datasetTitle);
            }}
          >
            {t("commons.confirm.download")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isTerritoryWarningVisible === true}
        maxWidth="sm"
        fullWidth
        onClose={() => setTerritoryWarningVisibility(false)}
      >
        <CustomDialogTitle onClose={() => setTerritoryWarningVisibility(false)}>
          {t("scenes.dataViewer.multiViewer.dialogs.toMuchPolygon.title")}
        </CustomDialogTitle>
        <DialogContent>{t("scenes.dataViewer.multiViewer.dialogs.toMuchPolygon.content")}</DialogContent>
        <DialogActions>
          <Button onClick={() => setTerritoryWarningVisibility(false)}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Menu
        anchorEl={indicatorsAnchorEl}
        keepMounted
        open={indicatorsAnchorEl !== null}
        onClose={() => setIndicatorsAnchorEl(null)}
      >
        {!isPointData && (
          <MenuItem disabled className={classes.menuItemTitle}>
            {t("scenes.dataViewer.multiViewer.indicators.title")}
          </MenuItem>
        )}
        {!isPointData && (
          <MenuItem
            onClick={() => {
              onIndicatorCreateVisibilitySet(true);
              setIndicatorsAnchorEl(null);
            }}
            disabled={!dataset || (indicators || []).length >= 5}
          >
            {t("scenes.dataViewer.multiViewer.indicators.values.create")}
          </MenuItem>
        )}
        {!isPointData && (
          <MenuItem
            onClick={() => {
              onIndicatorListVisibilitySet(true);
              setIndicatorsAnchorEl(null);
            }}
            disabled={(indicators || []).length === 0}
          >
            {t("scenes.dataViewer.multiViewer.indicators.values.list")}
          </MenuItem>
        )}
        <MenuItem disabled className={classes.menuItemTitle}>
          {t("scenes.dataViewer.multiViewer.measuresOfSynthesisAndVariability.title")}
        </MenuItem>
        <Tooltip placement="top" title={t("commons.measuresOfSynthesisAndVariability.values.arithmeticMean.tooltip")}>
          <MenuItem onClick={() => onArithmeticMeanVisibilitySet(!showArithmeticMean)} disabled={!dataset}>
            {t("commons.measuresOfSynthesisAndVariability.values.arithmeticMean.label")}
            {showArithmeticMean && <CheckIcon style={{marginLeft: 8}} />}
          </MenuItem>
        </Tooltip>
        <Tooltip
          placement="top"
          title={t("commons.measuresOfSynthesisAndVariability.values.standardDeviation.tooltip")}
        >
          <MenuItem onClick={() => onStandardDeviationVisibilitySet(!showStandardDeviation)} disabled={!dataset}>
            {t("commons.measuresOfSynthesisAndVariability.values.standardDeviation.label")}
            {showStandardDeviation && <CheckIcon style={{marginLeft: 8}} />}
          </MenuItem>
        </Tooltip>
        <Tooltip
          placement="top"
          title={t("commons.measuresOfSynthesisAndVariability.values.coefficientOfVariation.tooltip")}
        >
          <MenuItem
            onClick={() => onCoefficientOfVariationVisibilitySet(!showCoefficientOfVariation)}
            disabled={!dataset}
          >
            {t("commons.measuresOfSynthesisAndVariability.values.coefficientOfVariation.label")}
            {showCoefficientOfVariation && <CheckIcon style={{marginLeft: 8}} />}
          </MenuItem>
        </Tooltip>
      </Menu>

      <Menu
        anchorEl={additionalDatasetsAnchorEl}
        keepMounted
        open={additionalDatasetsAnchorEl !== null}
        onClose={() => setAdditionalDatasetsAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            onAdditionalDatasetCreateVisibilitySet(true);
            setAdditionalDatasetsAnchorEl(null);
          }}
          disabled={!dataset || additionalDatasets.length >= 2}
        >
          {t("scenes.dataViewer.multiViewer.additionalDataset.create.title")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onAdditionalDatasetListVisibilitySet(true);
            setAdditionalDatasetsAnchorEl(null);
          }}
          disabled={additionalDatasets.length === 0}
        >
          {t("scenes.dataViewer.multiViewer.additionalDataset.list.title")}
        </MenuItem>
      </Menu>

      {isIndicatorCreateVisible && (
        <IndicatorCreateDialog
          isOpen={isIndicatorCreateVisible}
          onClose={() => onIndicatorCreateVisibilitySet(false)}
        />
      )}

      {isIndicatorListVisible && (
        <IndicatorListDialog isOpen={isIndicatorListVisible} onClose={() => onIndicatorListVisibilitySet(false)} />
      )}

      {isAdditionalDatasetCreateVisible && (
        <AdditionalDatasetCreateDialog
          isOpen={isAdditionalDatasetCreateVisible}
          onClose={() => onAdditionalDatasetCreateVisibilitySet(false)}
          defaultLastNPeriods={defaultLastNPeriods}
          catalog={additionalDatasetCatalog}
          fetchCatalog={fetchAdditionalDatasetCatalog}
        />
      )}

      {isAdditionalDatasetListVisible && (
        <AdditionalDatasetListDialog
          isOpen={isAdditionalDatasetListVisible}
          onClose={() => onAdditionalDatasetListVisibilitySet(false)}
        />
      )}
    </Card>
  );
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(MultiViewerSidebar);

import React, {Fragment, useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import DatasetFilterAttributeIcon from "../dataset-filter-attribute-icon";
import DatasetFilters from "./DatasetFilters";
import {
  getDimensionAttributeMap,
  getDimensionFilterValues,
  getDimensionLabel as getDimensionLabelFromJsonStat,
  getFormattedDimensionLabel,
  getFormattedDimensionValueLabel,
  getMarginalDimensions,
  MARGINAL_DIMENSION_KEY
} from "../../utils/dataset";
import {localizeI18nObj} from "../../utils/i18n";
import {isMultiSelectDim} from "./utils";

const mapStateToProps = ({app, appConfig}) => ({
  appLanguage: app.language,
  languages: app.languages,
  hiddenDimensionValueLabels: appConfig.hiddenDimensionValueLabels
});

const DatasetFiltersJsonStatWrapper = ({
  appLanguage,
  languages,
  hiddenDimensionValueLabels,
  jsonStat,
  timeDim,
  layout,
  filterTree,
  labelFormat,
  onSelect,
  onCriteriaShow,
  hideMultiSelectDims = false,
  enclosingContainerId
}) => {
  const {t} = useTranslation();

  const dimAttributeMap = useMemo(
    () => (jsonStat && (jsonStat?.id || []).length > 0 ? getDimensionAttributeMap(jsonStat, t) : null),
    [jsonStat, t]
  );

  const getIsDimensionAllowed = useCallback(
    dim =>
      (isMultiSelectDim(dim, layout) && !hideMultiSelectDims) ||
      (!isMultiSelectDim(dim, layout) && jsonStat.size[jsonStat.id.indexOf(dim)] !== 1),
    [jsonStat, layout, hideMultiSelectDims]
  );

  const getDimensionLabel = useCallback(
    dimension => getDimensionLabelFromJsonStat(jsonStat, null, dimension, t),
    [jsonStat, t]
  );

  const getFilterValues = useCallback(
    dim => getDimensionFilterValues(dim, jsonStat, layout, filterTree),
    [jsonStat, layout, filterTree]
  );

  const getFormattedName = useCallback(
    (dimension, value, labelFormat, withAttribute) => {
      let valueLabel;

      if (dimension !== MARGINAL_DIMENSION_KEY) {
        valueLabel = getFormattedDimensionValueLabel(jsonStat, null, dimension, value, labelFormat, t);
      } else {
        const localizedHiddenDimensionValueLabels =
          localizeI18nObj(hiddenDimensionValueLabels, appLanguage, languages) || [];
        const hiddenLabels = localizedHiddenDimensionValueLabels.map(label => label.toLowerCase());

        const marginal = jsonStat.extension.marginalvalues[value];
        valueLabel = marginal.label
          ? marginal.label
          : getMarginalDimensions(jsonStat, marginal.datasetid)
              .map(dim =>
                getFormattedDimensionValueLabel(
                  jsonStat,
                  marginal.datasetid,
                  dim,
                  marginal.dimensionvalues[dim],
                  labelFormat,
                  t
                )
              )
              .filter(label => !hiddenLabels.includes(label.toLowerCase()))
              .join(", ");
      }

      const datasetId = jsonStat.extension.datasets[0];

      return !withAttribute ? (
        valueLabel
      ) : (
        <Fragment>
          {valueLabel}
          {withAttribute && (
            <DatasetFilterAttributeIcon
              datasetId={datasetId}
              jsonStat={jsonStat}
              dimension={dimension}
              dimensionValues={[value]}
              datasetDimAttributeMap={dimAttributeMap?.[datasetId]}
              labelFormat={labelFormat}
              disabled
            />
          )}
        </Fragment>
      );
    },
    [appLanguage, languages, hiddenDimensionValueLabels, jsonStat, dimAttributeMap, t]
  );

  const getFormattedTooltip = useCallback(
    (dimension, value, labelFormat) => (
      <div style={{fontSize: 13}}>
        {(() => {
          if (dimension !== MARGINAL_DIMENSION_KEY) {
            return getFormattedDimensionValueLabel(jsonStat, null, dimension, value, labelFormat, t);
          } else {
            const marginal = jsonStat.extension.marginalvalues[value];
            if (marginal.label) {
              return marginal.label;
            } else {
              return getMarginalDimensions(jsonStat, marginal.datasetid).map((dim, idx) => {
                const dimLabel = getFormattedDimensionLabel(jsonStat, marginal.datasetid, dim, labelFormat, t);
                const valueLabel = getFormattedDimensionValueLabel(
                  jsonStat,
                  marginal.datasetid,
                  dim,
                  marginal.dimensionvalues[dim],
                  labelFormat,
                  t
                );
                return `${dimLabel}: ${valueLabel}`;
              });
            }
          }
        })()}
      </div>
    ),
    [jsonStat, t]
  );

  const handleFilterSelect = useCallback(
    (dimension, value) => {
      const orderedValue = Array.isArray(value)
        ? jsonStat.dimension[dimension].category.index.filter(dimVal => value.includes(dimVal))
        : value;

      onSelect(dimension, orderedValue);
    },
    [jsonStat, onSelect]
  );

  return (
    jsonStat && (
      <DatasetFilters
        jsonStat={jsonStat}
        timeDim={timeDim}
        layout={layout}
        filterTree={filterTree}
        labelFormat={labelFormat}
        getIsDimensionAllowed={getIsDimensionAllowed}
        getDimensionLabel={getDimensionLabel}
        getFilterValues={getFilterValues}
        getFormattedName={getFormattedName}
        getFormattedTooltip={getFormattedTooltip}
        dimAttributeMap={dimAttributeMap}
        onSelect={handleFilterSelect}
        onCriteriaShow={onCriteriaShow}
        enclosingContainerId={enclosingContainerId} // data-viewer__viewer__header
      />
    )
  );
};

export default connect(mapStateToProps)(DatasetFiltersJsonStatWrapper);

import React, {useCallback, useEffect, useMemo, useState} from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import {createPortal} from "react-dom";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {v4 as uuidv4} from "uuid";
import Call from "../../hocs/call";
import AttributeList from "../attribute-list";
import CustomDialogTitle from "../custom-dialog-title";
import CustomEmpty from "../custom-empty";
import {getViewerIdxFromType} from "../data-viewer/constant";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID} from "../label-format-selector/constants";
import SanitizedHTML from "../sanitized-html";
import DashboardOptimizedView from "./DashboardOptimizedView";
import DashboardView from "./DashboardView";
import DashboardViewErrorBoundary from "./ErrorBoundary";
import {CRITERIA_FILTER_TYPE_CODES} from "../../utils/criteria";
import {useForceUpdate, useIsMounted} from "../../utils/customHooks";
import {
  DASHBOARD_ELEM_FILTER_DIMENSION_KEY,
  DASHBOARD_ELEM_TYPE_KEY,
  DASHBOARD_ELEM_TYPE_VALUE_TEXT,
  DASHBOARD_ELEM_TYPE_VALUE_VIEW,
  DASHBOARD_ELEM_VALUE_KEY,
  DASHBOARD_ELEM_WIDTH_KEY,
  getViewIdxFromRowAndCol
} from "../../utils/dashboards";
import {getDatasetAttributeMap, getSeriesAttributeMap, VARIATION_DIMENSION_KEY} from "../../utils/dataset";
import {DOWNLOAD_FORMAT_CSV, DOWNLOAD_FORMAT_EXCEL} from "../../utils/download";
import {localizeI18nObj} from "../../utils/i18n";

const $ = window.jQuery;

const VIEW_FIXED_HEIGHT = 580;

const styles = () => ({
  col: {
    display: "inline-block",
    verticalAlign: "top",
    padding: 8
  },
  viewContainer: {
    position: "relative",
    padding: 16,
    background: "#FFFFFF !important"
  }
});

const handleStyle = (dashboardId, viewIdx, dashboardElem, minDashboardWidth) => {
  const $viewContainer = $(`#dashboard__${dashboardId}__view-container__${viewIdx}`);

  if ($viewContainer.is(":visible")) {
    const $col = $(`#dashboard__${dashboardId}__col__${viewIdx}`);
    const $header = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header`);
    const $title = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__title`);
    const $controllers = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__controllers`);
    const $placeHolder = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__placeholder`);
    const $staticFilters = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__static-filters`);
    const $activeFilters = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__active-filters`);
    const $view = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__view`);

    $col.css({
      width: `${window.innerWidth > minDashboardWidth ? dashboardElem[DASHBOARD_ELEM_WIDTH_KEY] : 100}%`
    });

    if ($title.length) {
      $title.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight()
      });
      $staticFilters.css({
        width: "100%"
      });
      $activeFilters.css({
        width: "100%"
      });
    } else if ($staticFilters.length) {
      $staticFilters.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight(),
        paddingTop: 12
      });
      $activeFilters.css({
        width: "100%"
      });
    } else if ($activeFilters.length) {
      $activeFilters.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight(),
        paddingTop: 12
      });
    } else {
      $placeHolder.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight()
      });
    }

    $view.css({
      height: `calc(100% - ${$header.outerHeight() || 0}px)`
    });
  }
};

function DashboardCol(props) {
  const {
    t,
    classes,
    hub,
    defaultLanguage,
    languages,
    minDashboardWidth,
    dashboardId,
    dashboard,
    filterValue,
    rowIdx,
    colIdx,
    dashboardElem,
    jsonStat,
    layoutObj,
    filterTree,
    timePeriodsByFreq,
    rowWithView,
    onFilterSet,
    onDownloadSubmit,
    hideFullscreen,
    onOptimizedDatasetFetch
  } = props;

  const isMounted = useIsMounted();
  const forceUpdate = useForceUpdate();

  const [chartId] = useState("chart__" + uuidv4());
  const [mapId] = useState("map__" + uuidv4());

  const [nodeExtras, setNodeExtras] = useState({});

  const [view] = useState(_.cloneDeep((dashboard.views || {})[dashboardElem[DASHBOARD_ELEM_VALUE_KEY]]));
  const [viewIdx] = useState(getViewIdxFromRowAndCol(rowIdx, colIdx));
  const [viewerIdx] = useState(view ? getViewerIdxFromType(view.defaultView) : null);

  const [isDetailLevelFilterEnabled] = useState(
    Object.values(dashboard?.filterLevels || {}).filter(val => val).length > 0
  );

  const [isFullscreen, setFullscreen] = useState(false);
  const [isAttributesVisible, setAttributesVisibility] = useState(false);

  const [hierarchyOnlyAttributes] = useState(nodeExtras?.HierarchyOnlyAttributes || []);
  const [hideHierarchyOnlyRows] = useState(nodeExtras?.HideHierarchyOnlyRows || false);

  const nodeCode = useMemo(
    () => (view?.nodeId ? hub.nodes.find(({nodeId}) => nodeId === view.nodeId)?.code || null : null),
    [hub, view?.nodeId]
  );

  const datasetAttributes = useMemo(() => {
    return jsonStat && layoutObj && nodeCode && view?.datasetId
      ? getDatasetAttributeMap(jsonStat)?.[`${nodeCode},${view.datasetId}`] || []
      : [];
  }, [jsonStat, layoutObj, nodeCode, view?.datasetId]);

  const seriesAttributes = useMemo(() => {
    return jsonStat && layoutObj && nodeCode && view?.datasetId
      ? getSeriesAttributeMap(jsonStat, layoutObj.labelFormat)[`${nodeCode},${view.datasetId}`] || []
      : [];
  }, [jsonStat, layoutObj, nodeCode, view?.datasetId]);

  useEffect(() => {
    const nodeMinimalInfo = view ? hub.nodes.find(({nodeId}) => nodeId === view.nodeId) : {};
    if (nodeMinimalInfo?.extras) {
      const nodeExtras = {};
      nodeMinimalInfo.extras.forEach(({key, value}) => {
        try {
          nodeExtras[key] = JSON.parse(value);
        } catch (e) {
          nodeExtras[key] = value;
        }
      });
      setNodeExtras(nodeExtras);
    }
  }, [hub, view]);

  useEffect(() => {
    const func = () => handleStyle(dashboardId, viewIdx, dashboardElem, minDashboardWidth);
    window.addEventListener("resize", func);
    return () => window.removeEventListener("resize", func);
  }, [dashboardId, viewIdx, dashboardElem, minDashboardWidth]);

  useEffect(() => {
    handleStyle(dashboardId, viewIdx, dashboardElem, minDashboardWidth);
  });

  const handleFullscreen = useCallback(() => {
    const isEnteringFullscreen = !isFullscreen;
    setFullscreen(isEnteringFullscreen);

    const appContainer = document.getElementById("app-container");
    appContainer.style.visibility = isEnteringFullscreen ? "hidden" : "";
    const fullscreenContainer = document.getElementById("fullscreen-container");
    fullscreenContainer.style.display = isEnteringFullscreen ? "block" : "none";

    handleStyle(dashboardId, viewIdx, dashboardElem, minDashboardWidth);
  }, [isFullscreen, dashboardId, viewIdx, dashboardElem, minDashboardWidth]);

  const handleDownload = useCallback(
    (format, extension, additionalParams) => {
      const timeDim = jsonStat.role?.time?.[0];
      const newCriteria = {...view.criteria};

      if (format !== DOWNLOAD_FORMAT_CSV && format !== DOWNLOAD_FORMAT_EXCEL) {
        Object.keys(layoutObj.layout.filtersValue).forEach(filterDim => {
          if (filterDim !== timeDim) {
            newCriteria[filterDim] = {
              id: filterDim,
              filterValues: [layoutObj.layout.filtersValue[filterDim]]
            };
          }
        });
        const primaryDim = layoutObj.layout?.primaryDim?.[0] || null;
        if (primaryDim && primaryDim !== timeDim) {
          newCriteria[primaryDim] = {
            id: primaryDim,
            filterValues: layoutObj.layout.primaryDimValues
          };
        }
        const secondaryDim = layoutObj.layout?.secondaryDim?.[0] || null;
        if (secondaryDim && secondaryDim !== timeDim) {
          newCriteria[secondaryDim] = {
            id: secondaryDim,
            filterValues: layoutObj.layout.secondaryDimValues
          };
        }
      }

      if (viewerIdx === 1 && layoutObj.layout?.territoryDim) {
        if (
          (format !== DOWNLOAD_FORMAT_CSV && format !== DOWNLOAD_FORMAT_EXCEL) ||
          additionalParams?.exportOnlyCurrentView === true
        ) {
          const territoryDim = layoutObj.layout.territoryDim;
          newCriteria[territoryDim] = {
            id: territoryDim,
            filterValues: window.LMap.getDataIds(mapId)
          };
        }
      }

      const filterDim = dashboardElem[DASHBOARD_ELEM_FILTER_DIMENSION_KEY] || null;
      if (filterDim && filterValue) {
        newCriteria[filterDim] = {
          ...newCriteria[filterDim],
          id: filterDim,
          type: CRITERIA_FILTER_TYPE_CODES,
          filterValues: [filterValue]
        };
      }

      const exportParams = {
        decimalNumber: view.decimalNumber,
        decimalSeparator: localizeI18nObj(view.decimalSeparator, defaultLanguage, languages),
        emptyCellPlaceHolder: layoutObj.tableEmptyChar,
        labelFormat: layoutObj.labelFormat,
        customLabelFormat: {
          [timeDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
        },
        hasVariation: jsonStat.id.includes(VARIATION_DIMENSION_KEY),
        showTrend: layoutObj?.showTrend,
        showCyclical: layoutObj?.showCyclical,
        temporalDimOrder: layoutObj?.temporalDimOrder,
        exportConfig: hub.hub.exportConfig,
        ...additionalParams
      };

      onDownloadSubmit(
        view.nodeId,
        view.datasetId,
        localizeI18nObj(view.title, defaultLanguage, languages),
        newCriteria,
        layoutObj.layout,
        format,
        extension,
        false,
        exportParams,
        defaultLanguage,
        languages,
        t
      );
    },
    [
      hub,
      dashboardElem,
      view,
      jsonStat,
      layoutObj,
      filterValue,
      mapId,
      viewerIdx,
      onDownloadSubmit,
      defaultLanguage,
      languages,
      t
    ]
  );

  const showAttributeIcon = datasetAttributes.concat(seriesAttributes).length > 0;

  const dashboardElemType = dashboardElem[DASHBOARD_ELEM_TYPE_KEY];

  return (
    <div
      id={`dashboard__${dashboardId}__col__${viewIdx}`}
      className={`${classes.col} dashboard__col`}
      style={{
        width: `${window.innerWidth > minDashboardWidth ? dashboardElem[DASHBOARD_ELEM_WIDTH_KEY] : 100}%`
      }}
    >
      {!isMounted() ? (
        <Call cb={forceUpdate}>
          <span />
        </Call>
      ) : (
        createPortal(
          <Card
            id={`dashboard__${dashboardId}__view-container__${viewIdx}`}
            className={`${classes.viewContainer} dashboard__view-container`}
            style={{
              width: "100%",
              height: rowWithView ? VIEW_FIXED_HEIGHT : "100%",
              overflowY: dashboardElemType === DASHBOARD_ELEM_TYPE_VALUE_VIEW ? "hidden" : "auto",
              overflowX: "hidden"
            }}
          >
            {dashboardElemType === DASHBOARD_ELEM_TYPE_VALUE_TEXT ? (
              <SanitizedHTML
                html={localizeI18nObj(dashboardElem[DASHBOARD_ELEM_VALUE_KEY], defaultLanguage, languages)}
                allowTarget
              />
            ) : dashboardElemType === DASHBOARD_ELEM_TYPE_VALUE_VIEW && (view === null || view === undefined) ? (
              <CustomEmpty text={t("components.dashboard.missingView")} />
            ) : view.isOptimized ? (
              <DashboardViewErrorBoundary>
                <DashboardOptimizedView
                  defaultLanguage={defaultLanguage}
                  languages={languages}
                  nodeCode={nodeCode}
                  nodeExtras={nodeExtras}
                  dashboardId={dashboardId}
                  viewIdx={viewIdx}
                  view={view}
                  viewerIdx={viewerIdx}
                  filterValue={filterValue}
                  dashboardElem={dashboardElem}
                  jsonStat={jsonStat}
                  layoutObj={layoutObj}
                  filterTree={filterTree}
                  onFilterSet={onFilterSet}
                  timePeriodsByFreq={timePeriodsByFreq}
                  mapId={mapId}
                  chartId={chartId}
                  handleDownload={handleDownload}
                  hideFullscreen={hideFullscreen}
                  isFullscreen={isFullscreen}
                  handleFullscreen={handleFullscreen}
                  showAttributeIcon={showAttributeIcon}
                  setAttributesVisibility={setAttributesVisibility}
                  hierarchyOnlyAttributes={hierarchyOnlyAttributes}
                  hideHierarchyOnlyRows={hideHierarchyOnlyRows}
                  isDetailLevelFilterEnabled={isDetailLevelFilterEnabled}
                  onOptimizedDatasetFetch={onOptimizedDatasetFetch}
                />
              </DashboardViewErrorBoundary>
            ) : (
              <DashboardViewErrorBoundary>
                <DashboardView
                  defaultLanguage={defaultLanguage}
                  languages={languages}
                  nodeExtras={nodeExtras}
                  dashboardId={dashboardId}
                  viewIdx={viewIdx}
                  view={view}
                  viewerIdx={viewerIdx}
                  filterValue={filterValue}
                  dashboardElem={dashboardElem}
                  jsonStat={jsonStat}
                  layoutObj={layoutObj}
                  filterTree={filterTree}
                  onFilterSet={onFilterSet}
                  timePeriodsByFreq={timePeriodsByFreq}
                  mapId={mapId}
                  chartId={chartId}
                  handleDownload={handleDownload}
                  hideFullscreen={hideFullscreen}
                  isFullscreen={isFullscreen}
                  handleFullscreen={handleFullscreen}
                  showAttributeIcon={showAttributeIcon}
                  setAttributesVisibility={setAttributesVisibility}
                  hierarchyOnlyAttributes={hierarchyOnlyAttributes}
                  hideHierarchyOnlyRows={hideHierarchyOnlyRows}
                  isDetailLevelFilterEnabled={isDetailLevelFilterEnabled}
                />
              </DashboardViewErrorBoundary>
            )}
            <Dialog open={isAttributesVisible} fullWidth maxWidth="md" onClose={() => setAttributesVisibility(false)}>
              <CustomDialogTitle onClose={() => setAttributesVisibility(false)}>
                {t("components.dashboard.dialogs.attributes.title")}
              </CustomDialogTitle>
              <DialogContent>
                <AttributeList
                  datasetAttributes={datasetAttributes}
                  seriesAttributes={seriesAttributes}
                  labelFormat={layoutObj?.labelFormat}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setAttributesVisibility(false)}>{t("commons.confirm.close")}</Button>
              </DialogActions>
            </Dialog>
          </Card>,
          !isFullscreen
            ? document.getElementById(`dashboard__${dashboardId}__col__${viewIdx}`)
            : document.getElementById("fullscreen-container")
        )
      )}
    </div>
  );
}

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(state => ({
    hub: state.hub,
    defaultLanguage: state.app.language,
    languages: state.app.languages,
    minDashboardWidth: state.appConfig.minDashboardWidth
  }))
)(DashboardCol);

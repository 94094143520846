import React, {Fragment, useState} from "react";
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {sanitize} from "dompurify";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomDialogTitle from "../custom-dialog-title";
import MetadataIcon from "../custom-icons/MetadataIcon";
import FullscreenDialog from "../fullscreen-dialog";
import {fetchDatasetMetadata, hideDatasetMetadata} from "../../state/miscellaneous/miscellaneousActions";

const mapStateToProps = ({miscellaneous}) => ({
  datasetMetadataHtml: miscellaneous.datasetMetadataHtml,
  datasetMetadataUrl: miscellaneous.datasetMetadataUrl
});

const mapDispatchToProps = dispatch => ({
  onDatasetMetadataFetch: (nodeId, datasetId, metadataUrl) =>
    dispatch(fetchDatasetMetadata(nodeId, datasetId, metadataUrl)),
  onDatasetMetadataHide: () => dispatch(hideDatasetMetadata())
});

const DatasetMetadataButton = ({
  t,
  nodeId,
  datasetId,
  metadataUrl,
  externalClassName,
  label,
  iconSize,
  showAsBottomNavigation = false,
  datasetMetadataHtml,
  datasetMetadataUrl,
  onDatasetMetadataFetch,
  onDatasetMetadataHide
}) => {
  const [isVisible, setVisibility] = useState(false);

  const onOpen = () => {
    onDatasetMetadataFetch(nodeId, datasetId, metadataUrl);
    setVisibility(true);
  };

  const onClose = ev => {
    ev.stopPropagation();
    onDatasetMetadataHide();
    setVisibility(false);
  };

  if (!metadataUrl) {
    return <span />;
  }

  return (
    <Fragment>
      {!showAsBottomNavigation ? (
        <Tooltip title={label || t("components.datasetMetadataButton.label")}>
          <IconButton
            id="dataset-metadata-btn"
            size={iconSize}
            onClick={ev => {
              ev.stopPropagation();
              onOpen();
            }}
            aria-label={label || t("components.datasetMetadataButton.label")}
            className={externalClassName}
          >
            <MetadataIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <BottomNavigation showLabels onChange={() => onOpen()} style={{marginBottom: 8}}>
          <BottomNavigationAction
            label={label || t("components.datasetMetadataButton.label")}
            icon={<MetadataIcon />}
            role="menuitem"
          />
        </BottomNavigation>
      )}

      <FullscreenDialog open={isVisible} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>{label || t("components.datasetMetadataButton.label")}</CustomDialogTitle>
        <DialogContent>
          <iframe
            title={"title"}
            srcDoc={datasetMetadataHtml !== null ? sanitize(datasetMetadataHtml, {FORCE_BODY: true}) : null}
            src={datasetMetadataUrl}
            style={{
              border: 0,
              width: "100%",
              height: "calc(100% - 6px)"
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </FullscreenDialog>
    </Fragment>
  );
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(DatasetMetadataButton);

import React, {Fragment} from "react";
import Helmet from "react-helmet";
import {connect} from "react-redux";
import HubRouter from "./routers/HubRouter";

const App = ({language}) => (
  <Fragment>
    <Helmet>
      <html lang={language} />
    </Helmet>
    <HubRouter />
  </Fragment>
);

export default connect(state => ({
  language: state.app.language
}))(App);

import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {useTranslation} from "react-i18next";
import {getAttributeLabel, getAttributeValueLabel} from "../../utils/dataset";

const styles = theme => ({
  root: {},
  cardTitle: {
    fontSize: 17,
    fontWeight: "bold"
  },
  datasetAttributesCard: {
    background: "#F5F5F5"
  },
  datasetAttributes: {
    margin: 0,
    padding: "0 16px 16px"
  },
  seriesAttributes: {
    margin: 0,
    padding: "0 0 16px"
  },
  attributeGroup: {
    margin: 0,
    padding: "0 16px"
  }
});

const Attribute = ({attribute, labelFormat, list}) => (
  <Grid item xs={12}>
    {`${list ? "- " : ""}${getAttributeLabel(attribute, labelFormat)}: ${getAttributeValueLabel(
      attribute,
      labelFormat
    )}`}
  </Grid>
);

function AttributeList(props) {
  const {classes, datasetAttributes, seriesAttributes, labelFormat} = props;

  const {t} = useTranslation();

  return (
    <div className={classes.root}>
      {datasetAttributes.length > 0 && (
        <Card variant="outlined" className={classes.datasetAttributesCard}>
          <CardHeader
            title={<div className={classes.cardTitle}>{t("components.attributeList.datasetInformation.title")}:</div>}
            disableTypography
          />
          <Grid container spacing={2} className={classes.datasetAttributes}>
            {datasetAttributes.map((attribute, idx) => (
              <Attribute key={idx} attribute={attribute} labelFormat={labelFormat} />
            ))}
          </Grid>
        </Card>
      )}
      {seriesAttributes.length > 0 && (
        <Card variant="outlined" style={{marginTop: 16}}>
          <CardHeader
            title={<div className={classes.cardTitle}>{t("components.attributeList.seriesInformation.title")}:</div>}
            disableTypography
          />
          <Grid container spacing={2} className={classes.seriesAttributes}>
            {seriesAttributes.map(({dimensions, attributes}, idx) => (
              <Grid
                container
                key={idx}
                spacing={2}
                className={classes.attributeGroup}
                style={{background: idx % 2 ? "#FFFFFF" : "#F5F5F5"}}
              >
                <Grid item xs={12}>
                  {dimensions.map(({id, value}, idx) => (
                    <div key={idx} style={{display: "inline-block", marginRight: 8}}>
                      <b>{id}</b>: <i>{value}</i>
                      {idx < dimensions.length - 1 ? "," : ""}
                    </div>
                  ))}
                </Grid>
                {attributes.map((attribute, idx) => (
                  <Attribute key={idx} attribute={attribute} labelFormat={labelFormat} list />
                ))}
              </Grid>
            ))}
          </Grid>
        </Card>
      )}
    </div>
  );
}

export default withStyles(styles)(AttributeList);

import React, {Fragment, useEffect, useState} from "react";
import {withStyles} from "@material-ui/core";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import BarChartIcon from "@material-ui/icons/BarChart";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import ChartLayout from "../../chart-layout";
import Criteria from "../../criteria";
import CustomDialogTitle from "../../custom-dialog-title";
import DatasetMetadataButton from "../../dataset-metadata-button";
import FullscreenDialog from "../../fullscreen-dialog";
import TableLayout from "../../table-layout";
import {viewersFactory} from "../constant";
import {ViewerMode} from "../../../state/dataset/constants";
import {
  enableDatasetSVPDatasetFetch,
  fetchDatasetSVPStructureCodelistFull,
  hideDatasetSVPCriteria,
  hideDatasetSVPCriteriaAlert,
  hideDatasetSVPLayout,
  hideDatasetSVPStructureCodelistFull,
  setDatasetSVPStructureCriteria,
  setDatasetSVPViewer,
  showDatasetSVPCriteria,
  showDatasetSVPLayout,
  submitDatasetSVPChartLayout,
  submitDatasetSVPTableLayout
} from "../../../state/dataset/single-viewer-plus/actions";
import {
  getDatasetSize,
  getSVPFilteredChartLayout,
  getSVPFilteredTableLayout,
  MAX_ALLOWED_CELLS
} from "../../../utils/dataset";

const styles = theme => ({
  root: {
    width: 80,
    height: "100%",
    overflow: "auto",
    padding: "8px 0",
    marginRight: 16,
    "& .MuiBottomNavigation-root": {
      height: "unset !important"
    },
    "& .MuiBottomNavigationAction-label": {
      fontSize: "13px !important"
    }
  },
  floatingMenuOption: {
    color: "gray"
  },
  floatingMenuOptionSelected: {
    color: theme.palette.primary.main
  },
  divider: {
    margin: "4px 0"
  },
  navigationActionDisabled: {
    color: "rgba(0, 0, 0, 0.26) !important",
    "& svg": {
      color: "rgba(0, 0, 0, 0.26)"
    }
  }
});

const mapStateToProps = ({hub, dataset}) => ({
  maxObservations: hub.hub.maxObservationsAfterCriteria || Number.MAX_SAFE_INTEGER,
  maxCells: hub.hub.maxCells || MAX_ALLOWED_CELLS,
  mode: dataset.commons.mode,
  type: dataset.commons.type,
  dataset: dataset.singleViewerPlus.dataset,
  isEmptyData: dataset.singleViewerPlus.isEmptyData,
  isTooBigData: dataset.singleViewerPlus.isTooBigData,
  isTooLongQuery: dataset.singleViewerPlus.isTooLongQuery,
  isCriteriaVisible: dataset.singleViewerPlus.isCriteriaVisible,
  initialCriteriaDimension: dataset.singleViewerPlus.initialCriteriaDimension,
  isCriteriaAlertVisible: dataset.singleViewerPlus.isCriteriaAlertVisible,
  dimensions: dataset.singleViewerPlus.dimensions,
  dimensionsInfo: dataset.singleViewerPlus.dimensionsInfo,
  timeDim: dataset.singleViewerPlus.timeDim,
  freqDim: dataset.singleViewerPlus.freqDim,
  territoryDim: dataset.singleViewerPlus.territoryDim,
  viewerIdx: dataset.singleViewerPlus.viewerIdx,
  isLayoutVisible: dataset.singleViewerPlus.isLayoutVisible,
  tableLayout: dataset.singleViewerPlus.tableLayout,
  mapLayout: dataset.singleViewerPlus.mapLayout,
  chartLayout: dataset.singleViewerPlus.chartLayout,
  templateLayouts: dataset.singleViewerPlus.template?.layouts,
  labelFormat: dataset.singleViewerPlus.labelFormat,
  areCriteriaApplied: dataset.singleViewerPlus.areCriteriaApplied,
  criteria: dataset.singleViewerPlus.criteria,
  enableCriteria: dataset.singleViewerPlus.enableCriteria,
  enableLayout: dataset.singleViewerPlus.enableLayout,
  codelistTrees: dataset.singleViewerPlus.codelistTrees,
  codelistLists: dataset.singleViewerPlus.codelistFilteredLists,
  codelistsLength: dataset.singleViewerPlus.codelistsLength,
  codelistFetchError: dataset.singleViewerPlus.codelistFetchError,
  isTableEnabled: dataset.singleViewerPlus.isTableEnabled,
  isMapEnabled: dataset.singleViewerPlus.isMapEnabled,
  isChartEnabled: dataset.singleViewerPlus.isChartEnabled,
  tableLockedDimensions: dataset.singleViewerPlus.tableLockedDimensions,
  graphLockedDimensions: dataset.singleViewerPlus.graphLockedDimensions,
  missingFilterValues: dataset.singleViewerPlus.missingFilterValues
});

const mapDispatchToProps = dispatch => ({
  onFetchDatasetEnable: limit => dispatch(enableDatasetSVPDatasetFetch(limit)),
  onViewerSet: viewerIdx => dispatch(setDatasetSVPViewer(viewerIdx)),
  onLayoutShow: () => dispatch(showDatasetSVPLayout()),
  onLayoutHide: () => dispatch(hideDatasetSVPLayout()),
  onTableLayoutSet: (layout, enableFetch) => dispatch(submitDatasetSVPTableLayout(layout, enableFetch)),
  onChartLayoutSet: (layout, enableFetch) => dispatch(submitDatasetSVPChartLayout(layout, enableFetch)),
  onCriteriaShow: () => dispatch(showDatasetSVPCriteria()),
  onCriteriaHide: () => dispatch(hideDatasetSVPCriteria()),
  onSetCriteria: criteria => dispatch(setDatasetSVPStructureCriteria(criteria)),
  onCriteriaAlertHide: () => dispatch(hideDatasetSVPCriteriaAlert()),
  fetchCodelistFull: (nodeId, datasetId, dimensionId, missingFilterValueIds) =>
    dispatch(fetchDatasetSVPStructureCodelistFull(nodeId, datasetId, dimensionId, missingFilterValueIds)),
  onCodelistFullHide: () => dispatch(hideDatasetSVPStructureCodelistFull())
});

function SingleViewerPlusSidebar(props) {
  const {
    classes,

    nodeId,
    nodeCode,
    nodeExtras,
    datasetId,
    datasetMap,

    maxObservations,
    maxCells,
    mode,
    type,
    dataset,
    isEmptyData,
    isTooBigData,
    isTooLongQuery,
    isCriteriaVisible,
    initialCriteriaDimension,
    isCriteriaAlertVisible,
    dimensions,
    dimensionsInfo,
    timeDim,
    freqDim,
    territoryDim,
    viewerIdx,
    isLayoutVisible,
    tableLayout,
    mapLayout,
    chartLayout,
    templateLayouts,
    labelFormat,
    areCriteriaApplied,
    criteria,
    enableCriteria,
    enableLayout,
    codelistTrees,
    codelistLists,
    codelistsLength,
    codelistFetchError,
    isTableEnabled,
    isMapEnabled,
    isChartEnabled,
    tableLockedDimensions,
    graphLockedDimensions,
    missingFilterValues,

    onFetchDatasetEnable,
    onViewerSet,
    onLayoutShow,
    onLayoutHide,
    onTableLayoutSet,
    onChartLayoutSet,
    onCriteriaShow,
    onCriteriaHide,
    onSetCriteria,
    onCriteriaAlertHide,
    fetchCodelistFull,
    onCodelistFullHide
  } = props;

  const {t} = useTranslation();

  const viewers = viewersFactory(t);

  const [anchorEl, setAnchorEl] = useState(null);

  const [tmpTableLayout, setTmpTableLayout] = useState(null);
  const [tmpChartLayout, setTmpChartLayout] = useState(null);

  const [isCriteriaValid, setCriteriaValidity] = useState(true);

  const defaultLastNPeriods = nodeExtras?.DefaultLastNPeriods;

  useEffect(() => {
    setTmpTableLayout(tableLayout);
  }, [tableLayout]);

  useEffect(() => {
    setTmpChartLayout(chartLayout);
  }, [chartLayout]);

  const handleViewerSelect = index => {
    onViewerSet(index);
    setAnchorEl(null);
  };

  const handleLayoutOpen = () => {
    onLayoutShow();
  };

  const handleLayoutClose = () => {
    onLayoutHide();

    setTmpTableLayout(tableLayout);
    setTmpChartLayout(chartLayout);
  };

  const handleLayoutSubmit = () => {
    onLayoutHide();

    if (viewerIdx === 0) {
      onTableLayoutSet(getSVPFilteredTableLayout(tmpTableLayout, codelistLists, timeDim, freqDim, territoryDim), true);
    } else if (viewerIdx >= 2) {
      const newChartLayout = _.cloneDeep(tmpChartLayout);
      newChartLayout.primaryDimValues =
        newChartLayout.primaryDim[0] === chartLayout.primaryDim[0] ? newChartLayout.primaryDimValues : [];
      newChartLayout.secondaryDimValues =
        newChartLayout.secondaryDim[0] === chartLayout.secondaryDim[0] ? newChartLayout.secondaryDimValues : [];
      onChartLayoutSet(getSVPFilteredChartLayout(newChartLayout, codelistLists, timeDim, freqDim, territoryDim), true);
    }
  };

  const handleCriteriaOpen = () => {
    onCriteriaShow();
  };

  const handleCriteriaClose = () => {
    onCriteriaHide();
    setCriteriaValidity(true);
  };

  const handleCriteriaSubmit = () => {
    const limit = viewerIdx === 0 ? Math.min(maxObservations, maxCells) : maxObservations;
    onFetchDatasetEnable(limit);
  };

  const isTableDisabled = !areCriteriaApplied || !tableLayout;
  const isChartDisabled = !areCriteriaApplied || !chartLayout;
  const isMapDisabled = !areCriteriaApplied || !mapLayout;
  const isCriteriaDisabled = !enableCriteria;
  const isLayoutDisabled =
    !enableLayout ||
    !areCriteriaApplied ||
    (viewerIdx === 0 && isTableDisabled) ||
    (viewerIdx === 1 && isMapDisabled) ||
    (viewerIdx >= 2 && isChartDisabled);

  const metadataUrl = datasetMap[datasetId]?.referenceMetadata || null;

  const tmpLayout = viewerIdx === 0 ? tmpTableLayout : viewerIdx >= 1 ? tmpChartLayout : null;
  const datasetSize = getDatasetSize(
    dimensionsInfo,
    tmpLayout,
    tmpChartLayout?.primaryDim?.[0] !== chartLayout?.primaryDim?.[0],
    tmpChartLayout?.secondaryDim?.[0] !== chartLayout?.secondaryDim?.[0]
  );

  return (
    <Fragment>
      <Card className={classes.root} role="menu">
        <BottomNavigation showLabels onChange={handleCriteriaOpen}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.singleViewerPlus.sidebar.criteria")}
            icon={<SettingsIcon />}
            className={isCriteriaDisabled ? classes.navigationActionDisabled : ""}
            disabled={isCriteriaDisabled}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation showLabels onChange={handleLayoutOpen}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.singleViewerPlus.sidebar.layout")}
            icon={<EditIcon />}
            className={isLayoutDisabled || viewerIdx === 1 ? classes.navigationActionDisabled : ""}
            disabled={isLayoutDisabled || viewerIdx === 1}
            role="menuitem"
          />
        </BottomNavigation>
        <Divider className={classes.divider} />

        {metadataUrl && (
          <Fragment>
            <DatasetMetadataButton
              metadataUrl={metadataUrl}
              datasetId={datasetId}
              nodeId={nodeId}
              showAsBottomNavigation={true}
            />
            <Divider className={classes.divider} />
          </Fragment>
        )}

        {isTableEnabled && !templateLayouts?.disableTable && (
          <BottomNavigation showLabels value={viewerIdx === 0 ? 0 : null} onChange={() => handleViewerSelect(0)}>
            <BottomNavigationAction
              label={viewers[0].title}
              icon={viewers[0].icon}
              className={isTableDisabled ? classes.navigationActionDisabled : ""}
              disabled={isTableDisabled}
              role="menuitem"
            />
          </BottomNavigation>
        )}

        {isChartEnabled && !templateLayouts?.disableChart && (
          <BottomNavigation
            showLabels
            value={viewerIdx >= 2 ? 0 : null}
            onChange={({currentTarget}) => setAnchorEl(currentTarget)}
          >
            <BottomNavigationAction
              label={viewerIdx >= 2 ? viewers[viewerIdx].title : t("scenes.dataViewer.singleViewerPlus.sidebar.chart")}
              icon={viewerIdx >= 2 ? viewers[viewerIdx].icon : <BarChartIcon />}
              className={isChartDisabled ? classes.navigationActionDisabled : ""}
              disabled={isChartDisabled}
              role="menuitem"
            />
          </BottomNavigation>
        )}

        {isMapEnabled && !templateLayouts?.disableMap && !isMapDisabled && (
          <BottomNavigation showLabels value={viewerIdx === 1 ? 0 : null} onChange={() => handleViewerSelect(1)}>
            <BottomNavigationAction
              label={viewers[1].title}
              icon={viewers[1].icon}
              className={isMapDisabled ? classes.navigationActionDisabled : ""}
              role="menuitem"
            />
          </BottomNavigation>
        )}

        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          {viewers
            .slice(2)
            .filter(({hidden}) => hidden !== true)
            .map(option => (
              <MenuItem
                className={`${classes.floatingMenuOption} ${
                  viewerIdx === option.key ? classes.floatingMenuOptionSelected : ""
                }`}
                key={option.key}
                selected={viewerIdx === option.key}
                onClick={() => handleViewerSelect(option.key)}
              >
                {option.icon}
                <Typography style={{marginLeft: 8}}>{option.title}</Typography>
              </MenuItem>
            ))}
        </Menu>

        <FullscreenDialog open={isCriteriaVisible} onClose={handleCriteriaClose}>
          <CustomDialogTitle onClose={handleCriteriaClose}>
            {t("scenes.dataViewer.singleViewerPlus.dialogs.criteria.title")}
          </CustomDialogTitle>
          <DialogContent className={classes.criteriaContent}>
            <Criteria
              viewerMode={ViewerMode.SingleViewer}
              nodeId={nodeId}
              nodeCode={nodeCode}
              datasetId={datasetId}
              dimensions={dimensions}
              initialDim={initialCriteriaDimension}
              timeDim={timeDim}
              freqDim={freqDim}
              mode={mode}
              type={type}
              criteria={criteria}
              onSetCriteria={onSetCriteria}
              codelists={codelistTrees}
              codelistsLength={codelistsLength}
              codelistFetchError={codelistFetchError}
              isCriteriaValid={isCriteriaValid}
              setCriteriaValidity={setCriteriaValidity}
              defaultLastNPeriods={defaultLastNPeriods}
              showCodelistInfo
              missingFilterValues={missingFilterValues}
              fetchCodelistFull={fetchCodelistFull}
              onCodelistFullHide={onCodelistFullHide}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCriteriaClose}>{t("commons.confirm.cancel")}</Button>
            <Button autoFocus color="primary" onClick={handleCriteriaSubmit} disabled={!isCriteriaValid}>
              {t("commons.confirm.apply")}
            </Button>
          </DialogActions>
        </FullscreenDialog>

        <Dialog open={isLayoutVisible && viewerIdx === 0} fullWidth maxWidth="md" onClose={handleLayoutClose}>
          <CustomDialogTitle onClose={handleLayoutClose}>
            {t("scenes.dataViewer.dialogs.tableLayout.title")}
          </CustomDialogTitle>
          <DialogContent>
            <TableLayout
              layout={tmpTableLayout}
              dimensionsInfo={dimensionsInfo}
              lockedDimensions={tableLockedDimensions}
              setLayout={setTmpTableLayout}
              jsonStat={dataset}
              labelFormat={labelFormat}
              alertText={
                datasetSize > maxObservations
                  ? t("scenes.dataViewer.singleViewerPlus.sidebar.alerts.table.tooMuchObservations")
                  : datasetSize > maxCells
                  ? t("scenes.dataViewer.singleViewerPlus.sidebar.alerts.table.tooMuchCells")
                  : null
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLayoutClose}>{t("commons.confirm.cancel")}</Button>
            <Button autoFocus onClick={handleLayoutSubmit} disabled={datasetSize > Math.min(maxObservations, maxCells)}>
              {t("commons.confirm.apply")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isLayoutVisible && viewerIdx >= 2} fullWidth maxWidth="md" onClose={handleLayoutClose}>
          <CustomDialogTitle onClose={handleLayoutClose}>
            {t("scenes.dataViewer.dialogs.chartLayout.title")}
          </CustomDialogTitle>
          <DialogContent>
            <ChartLayout
              layout={tmpChartLayout}
              dimensionsInfo={dimensionsInfo}
              lockedDimensions={graphLockedDimensions}
              setLayout={setTmpChartLayout}
              alertText={
                datasetSize > maxObservations
                  ? t("scenes.dataViewer.singleViewerPlus.sidebar.alerts.chart.tooMuchObservations")
                  : null
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLayoutClose}>{t("commons.confirm.cancel")}</Button>
            <Button autoFocus onClick={handleLayoutSubmit} disabled={datasetSize > maxObservations}>
              {t("commons.confirm.apply")}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>

      <Dialog open={isCriteriaAlertVisible} fullWidth maxWidth="sm" onClose={onCriteriaAlertHide}>
        {(() => {
          if (isEmptyData) {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.emptyData.title")}
              </CustomDialogTitle>
            );
          } else if (isTooBigData) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooBigData.title")}
                </CustomDialogTitle>
                <DialogContent>{t("scenes.dataViewer.sidebar.warning.tooBigData.content")}</DialogContent>
              </Fragment>
            );
          } else if (isTooLongQuery) {
            return (
              <Fragment>
                <CustomDialogTitle onClose={onCriteriaAlertHide}>
                  {t("scenes.dataViewer.sidebar.warning.isTooLongQuery.title")}
                </CustomDialogTitle>
                <DialogContent>{t("scenes.dataViewer.sidebar.warning.isTooLongQuery.content")}</DialogContent>
              </Fragment>
            );
          } else {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.genericError.title")}
              </CustomDialogTitle>
            );
          }
        })()}
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              onCriteriaAlertHide();
              onCriteriaShow();
            }}
          >
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(SingleViewerPlusSidebar);

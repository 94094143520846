import React, {Fragment, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import AutocompleteSearchInput from "../autocomplete-search-input";
import {setDetailLevel} from "../../state/detailLevel/detailLevelActions";
import {closeSearchDialog, openSearchDialog} from "../../state/search-dialog/searchDialogActions";
import {getTrimmedStr} from "../../utils/formatters";

const SearchDialog = ({dialogTop, query, onSubmit, catalog, isOpen, onOpen, onClose, resetDetailLevel}) => {
  const [uuid] = useState(uuidv4());

  const {t} = useTranslation();

  const datasets = Object.keys(catalog?.datasets || {}).map(key => catalog.datasets[key].title);
  const uncategorizedDatasets = (catalog?.uncategorizedDatasets || []).map(({title}) => title);

  return (
    <Fragment>
      <Tooltip title={t("components.header.actions.search.title")}>
        <IconButton color="inherit" onClick={onOpen} aria-label={t("components.header.actions.search.ariaLabel")}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          style: {
            position: "absolute",
            top: dialogTop,
            height: 40
          }
        }}
        maxWidth={false}
      >
        <div id={`search-dialog__dialog__${uuid}`} style={{width: "75vw"}}>
          <AutocompleteSearchInput
            query={query}
            onSubmit={value => {
              const trimmedValue = getTrimmedStr(value);
              if (trimmedValue.length > 0) {
                onClose();
                onSubmit(trimmedValue);
                resetDetailLevel();
              }
            }}
            options={datasets.concat(uncategorizedDatasets)}
          />
        </div>
      </Dialog>
    </Fragment>
  );
};

export default connect(
  state => ({
    catalog: state.catalog,
    isOpen: state.searchDialog.isOpen
  }),
  dispatch => ({
    onOpen: () => dispatch(openSearchDialog()),
    onClose: () => dispatch(closeSearchDialog()),
    resetDetailLevel: () => dispatch(setDetailLevel(null))
  })
)(SearchDialog);

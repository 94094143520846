import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";

const styles = theme => ({
  root: {},
  closeButton: {
    marginLeft: 8,
    marginRight: -8,
    padding: 4
  }
});

const CustomDialogTitle = ({classes, onClose, children, ...props}) => {
  const {t} = useTranslation();

  return (
    <DialogTitle disableTypography className={classes.root} {...props}>
      <Grid container justifyContent="space-between" alignItems="flex-start" wrap="nowrap">
        <Grid item>
          <Typography variant="h6">{children}&nbsp;</Typography>
        </Grid>
        <Grid item>
          {onClose && (
            <IconButton
              aria-label={t("components.customDialogTitle.close.ariaLabel")}
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default withStyles(styles)(CustomDialogTitle);
